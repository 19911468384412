import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import App from 'App';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';
import ReduxThunk from 'redux-thunk';
/*import './index.css';*/
//import '@babel/polyfill'; // For IE 11 support
import { validate } from 'actions';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo';

ReactGA.initialize('UA-147070452-1');

const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
//const logger = createLogger();
//const createStoreWithMiddleware = compose(applyMiddleware(logger,ReduxThunk))(createStore);
const createStoreWithMiddleware = compose(applyMiddleware(ReduxThunk))(
  createStore
);
const store = createStoreWithMiddleware(reducers);

const USER = JSON.parse(window.localStorage.getItem('user'));

//새로 고침시 로그인 유지
if (USER != null) {
  //console.log('validate');
  ReactGA.set({ userId: USER.id });
  store.dispatch(validate(USER));
}

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const render = (Component) => {
  return ReactDOM.render(Component, document.getElementById('root'));
};

/*
const render = Component => {
	return ReactDOM.render(
		<Provider store={store}>
			<Router history={history}>
				<ScrollToTop>
					<div className="ms-site-container">
						<Route exact path="/" component={Home} />
						<Route path="/views" component={Component} />
					</div>
					<MobileMenu />
				</ScrollToTop>
			</Router>
		</Provider>,
		appElement
	);
};
*/

render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ApolloProvider>
);

serviceWorker.unregister();

/*
if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;
		render(NextApp);
	});
}
*/
