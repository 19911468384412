import React, { Component, Fragment } from 'react';
import { Card, Select as AntdSelect, Badge, Button } from 'antd';
import { EmptyData } from 'sentence/components/utils';
import './DecoWords.css';
import { connect } from 'react-redux';
import * as actions from 'actions';
const AntOption = AntdSelect.Option;

const mapStateToProps = state => ({
	lqInfos: state.info.lqInfos,
});

const mapDispatchToProps = dispatch => ({
	onSearchDeco: highlights => dispatch(actions.onSearchDeco(highlights)),
});

class DecoWords extends Component {
	initialData = (pumsa, lqInfos) => {
		if (lqInfos) {
			const strRateandHomos_ex = lqInfos
				.flatMap(x => x.strRateandHomos)
				.filter(
					i =>
						i.PUMSA === 'NC' ||
						i.PUMSA === 'NP' ||
						i.PUMSA === 'VV' ||
						i.PUMSA === 'VA' ||
						i.PUMSA === 'MA' ||
						i.PUMSA === 'MJ'
				)
				.filter(i => i.DIFFICULTY !== 11);

			switch (pumsa) {
				case 'OWN': {
					const datas = strRateandHomos_ex
						.filter(i => i.OWN_YN === 1)
						.map(i => i.CD_WORD)
						.reduce((count, word) => {
							count[word] = (count[word] || 0) + 1;
							return count;
						}, {});

					const _datas = Object.keys(datas).sort((key1, key2) => {
						if (datas[key1] > datas[key2]) return -1;
						if (datas[key1] < datas[key2]) return 1;
						return 0;
					});

					return {
						orgData: strRateandHomos_ex,
						datas,
						filterData: _datas,
					};
				}
				case 'CHN': {
					const datas = strRateandHomos_ex
						.filter(i => i.CHN_YN === 1)
						.map(i => i.CD_WORD + '(' + i.ORG_LANGUAGE + ')')
						.reduce((count, word) => {
							count[word] = (count[word] || 0) + 1;
							return count;
						}, {});
					const _datas = Object.keys(datas).sort((key1, key2) => {
						if (datas[key1] > datas[key2]) return -1;
						if (datas[key1] < datas[key2]) return 1;
						return 0;
					});

					return {
						orgData: strRateandHomos_ex,
						datas,
						filterData: _datas,
					};
				}
				case 'FRN': {
					const datas = strRateandHomos_ex
						.filter(i => i.FRN_YN === 1)
						.map(i => i.CD_WORD + '(' + i.ORG_LANGUAGE + ')')
						.reduce((count, word) => {
							count[word] = (count[word] || 0) + 1;
							return count;
						}, {});
					const _datas = Object.keys(datas).sort((key1, key2) => {
						if (datas[key1] > datas[key2]) return -1;
						if (datas[key1] < datas[key2]) return 1;
						return 0;
					});

					return {
						orgData: strRateandHomos_ex,
						datas,
						filterData: _datas,
					};
				}
				case 'DIS': {
					const datas = strRateandHomos_ex
						.map(i => i.CD_WORD)
						.reduce((count, word) => {
							count[word] = (count[word] || 0) + 1;
							return count;
						}, {});
					const d = [];
					Object.keys(datas)
						.filter(key => datas[key] > 1)
						.map(key => {
							return (d[key] = datas[key]);
						});

					const _datas = Object.keys(d).sort((key1, key2) => {
						if (d[key1] > d[key2]) return -1;
						if (d[key1] < d[key2]) return 1;
						return 0;
					});
					return {
						orgData: strRateandHomos_ex,
						datas: d,
						filterData: _datas,
					};
				}
				default: {
					const datas = strRateandHomos_ex
						.filter(i => i.PUMSA === pumsa)
						.map(i => i.CD_WORD)
						.reduce((count, word) => {
							count[word] = (count[word] || 0) + 1;
							return count;
						}, {});
					const _datas = Object.keys(datas).sort((key1, key2) => {
						if (datas[key1] > datas[key2]) return -1;
						if (datas[key1] < datas[key2]) return 1;
						return 0;
					});

					return {
						orgData: strRateandHomos_ex,
						datas,
						filterData: _datas,
					};
				}
			}
		}
	};

	constructor(props) {
		super(props);

		if (props.post) {
			const title = props.post.title;
			const order = props.post.order;
			const pumsa = props.post.pumsa;
			const datas = this.initialData(pumsa, props.lqInfos);

			this.state = {
				title,
				order,
				pumsa,
				...datas,
			};
		} else {
			this.state = {
				title: '',
				datas: [],
				filterData: [],
				order: '1',
				orgData: [],
				pumsa: '',
			};
		}
	}

	handleChange = value => {
		this.setState({ order: value });
	};

	componentWillReceiveProps(nextProps) {
		const { title, order, pumsa } = nextProps.post;
		if (nextProps.lqInfos) {
			const datas = this.initialData(pumsa, nextProps.lqInfos);
			this.setState({ title, order, pumsa, ...datas });
		} else {
			this.setState({ title, order, pumsa, datas: [], filterData: [], orgData: [] });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.order !== prevState.order) {
			const { datas } = this.state;
			if (this.state.order === '1') {
				const _datas = Object.keys(datas).sort((key1, key2) => {
					if (datas[key1] > datas[key2]) return -1;
					if (datas[key1] < datas[key2]) return 1;
					return 0;
				});
				this.setState({ filterData: _datas });
			} else {
				const _datas = Object.keys(datas).sort((key1, key2) => {
					if (key1 > key2) return 1;
					if (key1 < key2) return -1;
					return 0;
				});
				this.setState({ filterData: _datas });
			}
		}
	}

	onClickDecoBtn = key => {
		const { pumsa, orgData } = this.state;
		if (pumsa !== 'CHN' && pumsa !== 'FRN') {
			console.log(orgData, key);
			const _orgData = orgData.filter(i => i.CD_WORD === key).map(item => item.name);
			this.props.onSearchDeco(_orgData);
		} else {
			const _orgData = orgData.filter(i => i.CD_WORD + '(' + i.ORG_LANGUAGE + ')' === key).map(item => item.name);

			this.props.onSearchDeco(_orgData);
		}
	};

	componentWillUnmount() {
		this.props.onSearchDeco([]);
	}

	render() {
		const { title, datas, filterData } = this.state;

		return (
			<Fragment>
				<div style={{ height: '100%' }}>
					<Card
						title={title}
						extra={
							<div style={{ width: 130 }}>
								정렬{' '}
								<AntdSelect
									size="small"
									onChange={this.handleChange}
									value={this.state.order}
									dropdownStyle={{ zIndex: 4000 }}>
									<AntOption value="1">빈도수</AntOption>
									<AntOption value="2">가나다</AntOption>
								</AntdSelect>
							</div>
						}
						style={{ height: '100%' }}>
						<div className={'decocontent'}>
							{filterData &&
								filterData.map(key => {
									if (datas[key] > 1) {
										return (
											<Badge
												key={key}
												className="ant-badge-custom "
												count={<p className="ant-badge-custom-count">{datas[key]}</p>}>
												<Button size="small" onClick={() => this.onClickDecoBtn(key)}>
													{key}
												</Button>
											</Badge>
										);
									} else {
										return (
											<Button
												key={key}
												size="small"
												style={{ margin: '8px' }}
												onClick={() => this.onClickDecoBtn(key)}>
												{key}
											</Button>
										);
									}
								})}
							{datas && Object.keys(datas).length <= 0 && (
								<EmptyData message="어휘 목록이 존재 하지 않습니다." />
							)}
							{!datas && <EmptyData message="어휘 목록이 존재 하지 않습니다." />}
						</div>
					</Card>
				</div>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DecoWords);
