import axios from 'axios';
import 'react-app-polyfill/ie11'; // For IE 11 support
import * as types from 'actions/ActionTypes';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';

//url ex) url: /api/pay errorHander: callback
export const api = (self, url, datas, callback) => {
	return axios
		.post(types.LOCAL_API_URL + url, { datas }, { withCredentials: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			if (err.status === 403 || (err.response !== undefined && err.response.status === 403)) {
				self.props.logout();
				self.props.history.push('/views/login');
			} else {
				Toastr.error('서버에 응답이 없습니다. 잠시 후 다시 접속해 주세요.');
				if (callback) callback();
			}
			return err.response;
		});
};

//주문서 작성
export const pay = datas => {
	return axios
		.post(types.LOCAL_API_URL + '/api/pay', { datas }, { withCredentials: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			return err.response;
		});
};

//테스트 모달 오픈
export const start = datas => {
	return axios
		.post(types.LOCAL_API_URL + '/api/start', { datas }, { withCredentials: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			return err.response;
		});
};

//문제 가져오기
export const post = datas => {
	return axios
		.post(types.LOCAL_API_URL + '/api/start/post', { datas }, { withCredentials: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			return err.response;
		});
};

//문항 히스토리 생성 및 가져오기
export const history = datas => {
	return axios
		.post(types.LOCAL_API_URL + '/api/start/history', { datas }, { withCredentials: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			return err.response;
		});
};

//문항 응시정보 업데이트
export const historyUpdate = datas => {
	return axios
		.post(types.LOCAL_API_URL + '/api/start/update', { datas }, { withCredentials: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			return err.response;
		});
};
