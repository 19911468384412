/* eslint-disable no-useless-escape */
import React from 'react';
import { CompositeDecorator } from 'draft-js';

const styles = {
	default: { backgroundColor: '#FFFF00' },
	menu2: { backgroundColor: 'rgba(82, 196, 26, 0.65)' },
	menu3: { backgroundColor: 'rgba(243, 171, 40, 0.65)' },
};

const SearchHighlight = props => {
	if (props.selectedMenu === '2') {
		return <span style={styles.menu2}>{props.children}</span>;
	} else if (props.selectedMenu === '3') {
		return <span style={styles.menu3}>{props.children}</span>;
	} else {
		return <span style={styles.default}>{props.children}</span>;
	}
};

export const generateDecorator = (highlightTerm, selectedMenu) => {
	return new CompositeDecorator([
		{
			strategy: (contentBlock, callback) => {
				if (highlightTerm.length > 0) {
					const _orgStr = highlightTerm.map(i => '([^\uac00-\ud7a3])' + i + '(?=[^\uac00-\ud7a3])');
					const regex = new RegExp(_orgStr.join('|'), 'g');
					let matchArr, start;
					while (
						(matchArr = regex.exec(' ' + contentBlock.getText().replace(/[\(\)]/, ' ') + ' ')) !== null
					) {
						start = matchArr.index;
						callback(start, start + matchArr[0].trim().length);
					}
				}
			},
			component: SearchHighlight,
			props: { selectedMenu: selectedMenu },
		},
	]);
};
