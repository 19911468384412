import React, { Component, Fragment } from 'react';
import { Treemap, ResponsiveContainer } from 'recharts';

const COLORS = [
	'#ffadd2',
	'#ff9c6e',
	'#ffce54',
	'#fff566',
	'#a0d468',
	'#5cdbd3',
	'#91d5ff',
	'#597ef7',
	'#d4c3fa',
	'#d4380d',
];

class CustomizedContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			root: props.root,
			depth: props.depth,
			x: props.x,
			y: props.y,
			width: props.width,
			height: props.height,
			index: props.index,
			payload: props.payload,
			colors: props.colors,
			rank: props.rank,
			name: props.name,
			size: props.size,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			root: nextProps.root,
			depth: nextProps.depth,
			x: nextProps.x,
			y: nextProps.y,
			width: nextProps.width,
			height: nextProps.height,
			index: nextProps.index,
			payload: nextProps.payload,
			colors: nextProps.colors,
			rank: nextProps.rank,
			name: nextProps.name,
			size: nextProps.size,
		});
	}

	render() {
		const { root, depth, x, y, width, height, index, colors, name, size } = this.state;
		return (
			<g>
				<rect
					x={x}
					y={y}
					width={width}
					height={height}
					style={{
						fill:
							depth < 2
								? root.children != null
									? colors[Math.floor((index / root.children.length) * 9)]
									: 'none'
								: 'none',
						stroke: '#fff',
						//strokeWidth: 2 / (depth + 1e-10),
						//	strokeOpacity: 1 / (depth + 1e-10),
						strokeWidth: 1,
						strokeOpacity: 1,
					}}
				/>
				{depth === 1 && size !== 0 ? (
					<text x={x + width / 2} y={y + height / 2 + 3} textAnchor="middle" fontSize="10">
						{name} ({size})
					</text>
				) : null}
			</g>
		);
	}
}

class SimpleTreemap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			datas: props.datas,
			width: 0,
			dist: 0,
			children: <Fragment></Fragment>,
			//		height: props.height,
		};

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ datas: nextProps.datas });
	}

	updateWindowDimensions() {
		let width = window.innerWidth;
		let dist = 60;
		if (width > 768) {
			width = width / 2;
			dist = 45;
		}

		const children = <Treemap data={[]} dataKey="size" ratio={1} content={<CustomizedContent colors={COLORS} />} />;
		this.setState({ children });
		this.setState({ width, height: window.innerHeight, children, dist });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.width !== prevState.width) {
			const { datas } = this.state;
			const children = (
				<Treemap data={datas} dataKey="size" ratio={1} content={<CustomizedContent colors={COLORS} />} />
			);
			this.setState({ children });
		}
	}

	render() {
		const { width, children, dist } = this.state;
		return (
			<ResponsiveContainer width={width - dist} height={150}>
				{children}
			</ResponsiveContainer>
		);
	}
}

/*width={(width * 0.8) / 2 - 30} */

export default SimpleTreemap;
