import React, { Component } from 'react';
import './Header.css';

class Header extends Component {
	render() {
		const { title, color, detail, bg, icon } = this.props;

		//const _bg = bg ? bg + ' ms-hero-bg-dark-light ' : ' ms-hero-bg-info ';

		return (
			<header className={'ms-hero-page-override ' + bg + ' mt-0 pb-2 pt-4'}>
				<div className="container">
					<div className="deskContent">
						<div className="text-center ">
							<span className={'ms-icon ms-icon-circle ms-icon-xxlg ' + color}>
								<i className={icon} />
							</span>
							<span className={`subtit color-white ml-1`}>{title}</span>
							<p className={`lead lead-sm color-white text-center center-block mt-2 mw-800 fw-400`}>
								{detail}
							</p>
						</div>
					</div>
					<div className="mobileContent">
						<div className="text-center">
							<span className={`subtit color-white ml-1`}>{title}</span>
							<p className={`lead lead-sm color-white text-center center-block mt-2 mw-800 fw-400`}>
								{detail}
							</p>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default Header;
