//export const LOCAL_API_URL = 'http://localhost:5000';
//export const LOCAL_URL = 'http://localhost:5000';
export const LOCAL_API_URL = '';
export const LOCAL_URL = 'https://www.natmal.com';
export const SCHOOL_SUPPORT_URL = 'http://localhost:3001';
//export const LOCAL_API_URL = 'http://main.natmal.com:5000';
//export const LOCAL_URL = 'http://localhost:3000';
export const CAREER_URL_API =
  'https://www.career.go.kr/cnet/openapi/getOpenApi';
export const JUSO_URL_API = 'https://www.juso.go.kr/addrlink/addrLinkApi.do';

//export const LOCAL_API_URL = 'http://localhost:5000';
//export const LOCAL_API_URL = 'http://main.natmal.com:5000';
//export const LOCAL_URL = 'http://main.natmal.com';
//export const LOCAL_URL = 'http://localhost:5000';

export const AI_API_URL = 'https://main-api.natmal.com';
//export const AI_API_URL = 'http://developer-api.natmal.com';

//*********************** 문장검사기 액션 타입 *************************
export const SENT_SIDE_BAR = 'SENT_SIDE_BAR';

export const SPELL_DECO_SELECTED_ITEM = 'SPELL_DECO_SELECTED_ITEM';
export const SPELL_REPLACE_ITEM = 'SPELL_REPLACE_ITEM';
export const SPELL_DISABLE_ITEM = 'SPELL_DISABLE_ITEM';
export const SPELL_DECOS_ADD = 'SPELL_DECOS_ADD';
export const SPELL_DECOS_REMOVE = 'SPELL_DECOS_REMOVE';
export const SPELL_DECOS_REMOVE_ITEM = 'SPELL_DECOS_REMOVE_ITEM';

export const SPELL_DECODATA_CREATE = 'SPELL_DECODATA_CREATE';
export const SPELL_DECOS_UPDATE = 'SPELL_DECOS_UPDATE';

export const SELECT_RIGHT_MENU = 'SELECT_RIGHT_MENU';
export const DECOSVIEW = 'DECOSVIEW';
export const DECOS_OPEN = 'DECOS_OPEN';
export const DECOS_CLOSE = 'DECOS_CLOSE';

export const CHANGE_TEXT = 'CHANGETEXT';
export const CHANGE_LQINFOS_TEXT = 'CHANGE_LQINFOS_TEXT';
export const LQTAGGING = 'LQTAGGING';
export const SEARCH_DECO = 'SEARCH_DECO';
export const SPELL_DECO = 'SPELL_DECO';
export const SPELL_SEARCH_FILTER = 'SPELL_SEARCH_FILTER';
export const CHANGE_BLOCKKEY = 'CHANGE_BLOCKKEY';

export const BLOCKKEY_TEXT = 'BLOCKKEY_TEXT';

export const SYNO_DECO = 'SYNO_DECO';
export const SYNO_FILTERS = 'SYNO_FILTERS';

export const HANJAS_BIND = 'HANJAS_BIND';

export const DIALECT_DECO = 'DIALECT_DECO';
export const DIALECT_FILTERS = 'DIALECT_FILTERS';

export const MULTI_LANG = 'MULTI_LANG';
export const MULTI_LANG_OPTION = 'MULTI_LANG_OPTION';
export const MULTI_LANG_SELECTED_TEXT = 'MULTI_LANG_SELECTED_TEXT';

export const HANJA_MENU_CHANGE = 'HANJA_MENU_CHANGE';

export const BLOCK_HIGHTLIGHT = 'BLOCK_HIGHLIGHT';

export const DOCUMENTS_TITLE_CHANGE = 'DOCUMENTS_TITLE_CHANGE';

export const SHOW_MODAL_REPORT = 'SHOW_MODAL_REPORT';
export const BASE_INFO_SEND_REPORT = 'BASE_INFO_SEND_REPORT';

export const LQ_SCORE = 'LQ_SCORE';

export const CHECKED_LQ_PARA = 'CHECKED_LQ_PARA';
export const CHECKED_SPELL = 'CHECKED_SPELL';
export const CHECKED_SYNO = 'CHECKED_SYNO';
export const CHECKED_DIALECT = 'CHECKED_DIALECT';

//********************************************************** */

export const RELOADABLE = 'RELOADABLE';
export const BREADCRUMB_ADD = 'BREADCRUMB_ADD';
export const BREADCRUMB_REMOVE_ADD = 'BREADCRUMB_REMOVE_ADD';
export const BREADCRUMB_INIT = 'BREADCRUMB_INIT';

// BookSearch *********************************************************************** //
export const BOOK_SEARCH_KEYWORD = 'BOOK_SEARCH_KEYWORD';
export const BOOK_SEARCH_LQ_GRADE = 'BOOK_SEARCH_LQ_GRADE';
export const BOOK_SEARCH_SC_GRADE = 'BOOK_SEARCH_SC_GRADE';
export const BOOK_SEARCH_DETAIL = 'BOOK_SEARCH_DETAIL';
export const BOOK_SEARCH_DETAIL_TOGGLE = 'BOOK_SEARCH_DETAIL_TOGGLE';

// Puzzle *********************************************************************** //
export const PUZZLE_SEARCH_KEYWORD = 'PUZZLE_SEARCH_KEYWORD';

// DICTIONARY *********************************************************************** //
export const DICTIONARY_SHOW_OPTIONS = 'DICTIONARY_SHOW_OPTIONS';
export const DICTIONARY_SAVE_WORD = 'DICTIONARY_SAVE_WORD';
export const DICTIONARY_HISTORY_BACK = 'DICTIONARY_HISTORY_BACK';

// AUTH
export const REGISTER = 'REGISTER';
export const FIND_USER = 'FIND_USER';
export const CERTIFY_EMAIL = 'CERTIFY_EMAIL';
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATE_THE_USER = 'AUTHENTICATE_THE_USER';
export const NONAUTHENTICATE_THE_USER = 'NONAUTHENTICATE_THE_USER';
export const CHECK_USER = 'CHECK_USER';
export const CHANGE_PW = 'CHANGE_PW';

export const FACEBOOK = 'FACEBOOK';
export const NAVER = 'NAVER';
export const KAKAO = 'KAKAO';
export const GOOGLE = 'GOOGLE';

export const MOBILE_MENU_SHOW = 'MOBILE_MENU_SHOW';
export const MOBILE_MENU_HIDE = 'MOBILE_MENU_HIDE';

export const MOBILE_LINK_BTN = 'MOBILE_LINK_BTN';

export const GET_EDITOR_STATE = 'GET_EDITOR_STATE';
export const GET_EDITOR_STATE_OK = 'GET_EDITOR_STATE_OK';

export const PROCESS_COMPLETE = 'PROCESS_COMPLETE';

export const POST_DATA_TEXT = 'sentence/POST_DATA_TEXT';

export const SENTENCE_INIT = 'sentence/INIT';
