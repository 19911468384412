export const TESTER_DIV = {
	NAMES: 1,
	IDS: 2,
	properties: {
		1: { name: 'NAMES', label: '명렬표', value: 1 },
		2: { name: 'IDS', label: '아이디/패스워드', value: 2 },
	},
};

export const STATUS = {
	READY: { name: 'READY', value: '완료', checked: true, color: 'blue' },
	STANDBY: { name: 'STANDBY', value: '대기', checked: false, color: 'grey' },
};

export const GROUPS_DIV = {
	SCHOOL: { name: 'SCHOOL', value: '학교' },
	COMMON: { name: 'COMMON', value: '일반' },
};

export const OPTIONS = {
	TESTS: [
		{ key: 'ALL', text: '검사종류 - 전체', value: 'ALL', style: { fontSize: '0.9em' } },
		{ key: 'readtest', text: 'LQ 독서력 검사', value: '1', style: { fontSize: '0.9em' } },
		{ key: 'readtestcat', text: 'LQ 독서력 검사 CAT', value: '2', style: { fontSize: '0.9em' } },
		{ key: 'wordtest', text: '우리말 어휘력 검사', value: '3', style: { fontSize: '0.9em' } },
		{ key: 'engreadtest', text: '영어 독서력 검사', value: '4', style: { fontSize: '0.9em' } },
	],
	DIVS: [
		{ key: 'ALL', text: '단체구분 - 전체', value: 'ALL', style: { fontSize: '0.9em' } },
		{ key: 'SCHOOL', text: '학교', value: 'SCHOOL', style: { fontSize: '0.9em' } },
		{ key: 'COMMON', text: '일반', value: 'COMMON', style: { fontSize: '0.9em' } },
	],
	STATUS: [
		{ key: 'ALL', text: '검사상태 - 전체', value: 'ALL', style: { fontSize: '0.9em' } },
		{ key: 'ING', text: '진행중', value: 'ING', style: { fontSize: '0.9em' } },
		{ key: 'EXPIRED', text: '종료', value: 'EXPIRED', style: { fontSize: '0.9em' } },
	],
	TESTS_LEVELS: [
		{
			key: 'ALL',
			text: '검사종류 - 전체',
			value: 'ALL',
			style: { fontSize: '0.9em' },
			levels: [{ key: 'ALL', text: '검사레벨 - 전체', target: 'ALL', value: 'ALL' }],
		},
		{
			key: 'readtest',
			text: 'LQ 독서력 검사',
			value: '1',
			style: { fontSize: '0.9em' },
			levels: [
				{ key: 'ALL', text: '검사레벨 - 전체', target: 'ALL', value: 'ALL' },
				{ key: 'level7', text: '7급', target: '초등1~2학년', value: 'L7' },
				{ key: 'level6', text: '6급', target: '초등3~4학년', value: 'L6' },
				{ key: 'level5', text: '5급', target: '초등5~6학년', value: 'L5' },
				{ key: 'level4', text: '4급', target: '중등1~2학년', value: 'L4' },
				{ key: 'level3', text: '3급', target: '중등3~고등1학년', value: 'L3' },
				{ key: 'level2', text: '2급', target: '고등2~3학년', value: 'L2' },
				{ key: 'level1', text: '1급', target: '대학생·일반인', value: 'L1' },
			],
		},
		{
			key: 'readtestcat',
			text: 'LQ 독서력 검사 CAT',
			value: '2',
			style: { fontSize: '0.9em' },
			levels: [
				{ key: 'ALL', text: '검사레벨 - 전체', target: 'ALL', value: 'ALL' },
				{ key: 'level7', text: '7급', target: '초등1~2학년', value: 'L7' },
				{ key: 'level6', text: '6급', target: '초등3~4학년', value: 'L6' },
				{ key: 'level5', text: '5급', target: '초등5~6학년', value: 'L5' },
				{ key: 'level4', text: '4급', target: '중등1~2학년', value: 'L4' },
				{ key: 'level3', text: '3급', target: '중등3~고등1학년', value: 'L3' },
				{ key: 'level2', text: '2급', target: '고등2~3학년', value: 'L2' },
				{ key: 'level1', text: '1급', target: '대학생·일반인', value: 'L1' },
			],
		},
		{
			key: 'wordtest',
			text: '우리말 어휘력 검사',
			value: '3',
			style: { fontSize: '0.9em' },
			levels: [
				{ key: 'ALL', text: '검사레벨 - 전체', target: 'ALL', value: 'ALL' },
				{ key: 'level7', text: '7급', target: '초등1~2학년', value: 'L7' },
				{ key: 'level6', text: '6급', target: '초등3~4학년', value: 'L6' },
				{ key: 'level5', text: '5급', target: '초등5~6학년', value: 'L5' },
				{ key: 'level4', text: '4급', target: '중등1~2학년', value: 'L4' },
				{ key: 'level3', text: '3급', target: '중등3~고등1학년', value: 'L3' },
				{ key: 'level2', text: '2급', target: '고등2~3학년', value: 'L2' },
				{ key: 'level1', text: '1급', target: '대학생·일반인', value: 'L1' },
			],
		},
		{
			key: 'engreadtest',
			text: '영어 독서력 검사',
			value: '4',
			style: { fontSize: '0.9em' },
			levels: [
				{ key: 'ALL', text: '검사레벨 - 전체', target: 'ALL', value: 'ALL' },
				{ key: 'level5', text: '초급', target: '초등5~6학년', value: 'E5' },
				{ key: 'level4', text: '초중급', target: '중등1~2학년', value: 'E4' },
				{ key: 'level3', text: '중급', target: '중등3~고등1학년', value: 'E3' },
				{ key: 'level2', text: '중상급', target: '고등2~3학년', value: 'E2' },
				{ key: 'level1', text: '상급', target: '대학생·일반인', value: 'E1' },
			],
		},
	],
};

export const FIND_STATUS_VALUE = (status) => STATUS[status.trim()].value;
export const FIND_GROUP_DIV_VALUE = (groupdiv) => GROUPS_DIV[groupdiv.trim()].value;
export const FIND_LEVEL_OPTION = (testValue) =>
	OPTIONS['TESTS_LEVELS'].filter((item) => item.value === testValue)[0].levels;
