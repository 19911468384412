/* eslint-disable no-useless-escape */
import React from 'react';
import { CompositeDecorator } from 'draft-js';
import { SynonymSpan } from 'sentence/components/decorators/synonyms';

const synoStrategy = (contentBlock, callback, synos) => {
	return new Promise(resolve => {
		if (synos.length > 0) {
			const _synos = synos.filter(syno => syno.blockKey === contentBlock.getKey()).flatMap(x => x.synos);
			const _orgStr = _synos
				.filter(x => x.synoymsFilter.length > 0)
				.map(
					d =>
						'([^\uac00-\ud7a3])' +
						d.name.replace(/[\[\]\/\{\}\(\)]/g, '\\$&').trim() +
						'(?=[^\uac00-\ud7a3])'
				)
				.reduce(function(a, b) {
					if (a.indexOf(b) < 0) a.push(b);
					return a;
				}, []);

			if (_orgStr.length > 0) {
				const regex = new RegExp(_orgStr.join('|'), 'g');
				let matchArr, start;
				while ((matchArr = regex.exec(' ' + contentBlock.getText().replace(/[\(\)]/, ' ') + ' ')) !== null) {
					start = matchArr.index;
					resolve(callback(start, start + matchArr[0].trim().length));
				}
				resolve();
			} else {
				resolve();
			}
		} else {
			resolve();
		}
	});
};

export const generateDecorator = synos => {
	return new CompositeDecorator([
		{
			strategy: async (contentBlock, callback) => await synoStrategy(contentBlock, callback, synos),
			component: SynonymSpan,
			props: { synos },
		},
	]);
};
