import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import Toastr from 'modern-toastr';
import * as utils from 'utils/groups';
import * as types from 'types/OrderTypes';
import axios from 'axios';
import * as service from 'services/groups';

class KyowonService extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      pageId: 'start',
      tabs: 'lq1',
      title: 'LQ 독서력 검사',
      detail:
        'LQ 독서력 검사는 나의 독서능력 향상을 위해 현재의 독서력 수준을 측정하는 도구입니다.\n 정확한 측정을 통한 진단만이 독서에 흥미를	더하고 독서능력을 향상시키는 데 도움이 됩니다.',
      type: 1,
      orderItem: {
        code: props.match.params.code,
        DIV: 'COMMON',
      },
    };
  }

  fetchCerted = async (datas, resultData) => {
    const { tabs, title, detail } = this.state;
    const data = {
      ...this.state,
      pageId: 'psnCommons',
      tabs,
      title,
      detail,
      currentPage: 1,
      orderItem: { ...this.state.orderItem, ...resultData },
    };

    this.props.history.push({
      pathname: '/views/groups/etests',
      post: data,
    });
    return true;
  };

  componentDidMount() {
    const code = this.state.orderItem.code;
    async function getGroupInfo(props, state, fetchCerted) {
      try {
        const tempURL = `/api/etest/kyowon/${code}`;
        const result = await axios.get(tempURL);

        if (result.status === 200) {
          const {
            ORDER_GROUP_ID,
            SERVICE_START_DATE,
            SERVICE_END_DATE,
          } = result.data;

          const serviceStartDate = new Date(SERVICE_START_DATE);
          const serviceEndDate = new Date(SERVICE_END_DATE);
          const now = Date.now();
          if (
            serviceStartDate.getTime() <= now &&
            now <= serviceEndDate.getTime()
          ) {
            fetchCerted({ orderGroupId: ORDER_GROUP_ID, code }, result.data);
          } else throw new Error('서비스 기간이 아닙니다.');
        } else if (result.status === 204) {
          throw new Error('인증코드를 다시 확인해 주세요.');
        } else throw new Error('오류가 발생했습니다.');
      } catch (e) {
        console.error(e);
        alert(e.message);
        props.history.push('/');
      }
    }
    getGroupInfo(this.props, this.state, this.fetchCerted);
  }
  render() {
    return (
      <div id="fuse-splash-screen">
        <div className="center">
          <div className="logo">
            <img width="128" src="/assets/img/logo/Logo_50_C.png" alt="logo" />
          </div>
          <br />
          <div className="loading" />
        </div>
      </div>
    );
  }
}

export default withRouter(KyowonService);
