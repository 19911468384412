import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';

const styles = {
	root: {},
	title: {
		padding: '2px',
		fontSize: '16px',
		fontWeight: 500,
		color: '#000',
		direction: 'ltr',
		unicodeBidi: 'bidi-override',
		borderBottom: '4px solid #0070C0',
	},
	subTitle: {
		padding: '2px',
		fontSize: '13px',
		direction: 'ltr',
		unicodeBidi: 'bidi-override',
		borderBottom: '4px solid #0070C0',
	},
	line: {},
};

class Header extends Component {
	state = {
		color: '#0070C0',
		title: null,
		subTitle: null,
	};

	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			color: props.color,
			title: props.title,
			subTitle: props.subTitle,
		};
	}

	render() {
		const { color, title, subTitle } = this.state;
		return (
			<Fragment>
				<Row type="flex" align="bottom">
					<Col style={Object.assign({}, styles.title, { borderBottom: '4px solid ' + color })}>
						<span>{title}</span>
					</Col>
					<Col style={Object.assign({}, styles.subTitle, { borderBottom: '4px solid ' + color })}>
						<span>{subTitle}</span>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default Header;
