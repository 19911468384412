import * as types from 'actions/ActionTypes';

const initialState = {
	hanjas: [],
	div: false,
};

const hanjas = (state = initialState, action) => {
	switch (action.type) {
		case types.HANJAS_BIND:
			return Object.assign({}, state, { hanjas: action.hanjas });
		case types.HANJA_MENU_CHANGE:
			return Object.assign({}, state, { div: action.div });
		default:
			return state;
	}
};

export default hanjas;
