import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Form, Grid, Label, Input, Button, Segment, Message } from 'semantic-ui-react';
import * as service from 'services/groups';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';

const state = {
	password: '',
	confirm: '',
};

class ModifyPass extends Component {
	fetchModifyPassword = async datas => {
		const result = await service.api(this, '/api/groups/tests/modifyPass', datas, null);

		if (result && result.status === 200) {
			Toastr.info(result.data.message);
			const { close = f => f } = this.props;
			this.setState(() => close(this.state.testerItem));
		}
	};

	constructor(props) {
		super(props);

		let v = {
			password: {
				error: false,
				field: '새 암호',
				message: '새 암호를 입력해 주세요.',
				maxLength: 8,
				minLength: 4,
			},
			confirm: {
				error: false,
				field: '암호',
				message: '새 암호 확인란을 입력해 주세요.',
				cond: () => this.state.password !== this.state.confirm,
			},
		};
		this.state = {
			testerItem: props.testerItem,
			v,
			...state,
			isWarning: false,
		};
	}

	validateHandler = () => {
		let v = this.state.v;
		Object.keys(v).map(key => {
			if (this.state.hasOwnProperty(key) && this.state[key] && this.state[key].toString().trim().length > 0) {
				v[key].error = false;
				if (v[key].hasOwnProperty('cond')) {
					v[key].error = v[key].cond(this.state[key].toString());
					if (v[key].error) {
						v[key].message = '확인란에 새암호와 동일한 암호를 입력해 주세요.';
					}
				}

				if (v[key].hasOwnProperty('maxLength')) {
					if (
						this.state[key].toString().length > v[key].maxLength ||
						this.state[key].toString().length < v[key].minLength
					) {
						v[key].error = true;
						v[key].message =
							v[key].field +
							'의 길이는 최소 ' +
							v[key].minLength +
							'자에서 최대 ' +
							v[key].maxLength +
							'자 입니다.';
					}
				}
			} else {
				v[key].error = true;
			}

			return v[key];
		});
		let messages = Object.keys(v)
			.filter(key => v[key].error)
			.map(key => {
				return v[key].message;
			});

		if (messages.length > 0) {
			this.setState({ v, isWarning: true, errors: messages });
			return false;
		} else {
			this.setState({ v, isWarning: false, errors: null });
			return true;
		}
	};

	dataHandler = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
	};

	onSumbit = e => {
		e.preventDefault();
		if (this.validateHandler()) {
			const { testerItem, password } = this.state;
			this.fetchModifyPassword({ id: testerItem.PSN_ID, password });
		}
	};

	render() {
		const { testerItem, v, errors, isWarning, password, confirm } = this.state;

		return (
			<Container fluid className="m-0 p-0">
				<Form warning={isWarning} key="large" size="large">
					<Message warning header="아래 사항을 확인해 주세요." list={errors} />
					<Segment textAlign="center" size="large">
						<Form.Group inline>
							<Label color="grey" children="응시자" />
							&nbsp;
							<Form.Field>
								<Input readOnly value={testerItem.TEST_ID} />
							</Form.Field>
						</Form.Group>
						<Form.Group inline>
							<Label color="grey" children="새암호" />
							&nbsp;
							<Form.Field error={v.password.error}>
								<Input
									name="password"
									type="password"
									placeholder="새 암호를 입력해 주세요."
									onChange={this.dataHandler}
									value={password}
								/>
							</Form.Field>
						</Form.Group>
						<Form.Group inline>
							<Label color="grey" children="&nbsp;확&nbsp;인&nbsp;" />
							&nbsp;
							<Form.Field error={v.confirm.error}>
								<Input
									name="confirm"
									type="password"
									placeholder="새 암호를 다시 한번 입력해 주세요."
									onChange={this.dataHandler}
									value={confirm}
								/>
							</Form.Field>
						</Form.Group>
					</Segment>
					<Grid>
						<Grid.Row centered columns={3} style={{ marginLeft: '0px' }}>
							<Grid.Column>
								<Button fluid onClick={this.onSumbit}>
									수정
								</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			</Container>
		);
	}
}

ModifyPass.propTypes = {
	eventHander: PropTypes.func,
	close: PropTypes.func,
};

export default ModifyPass;
