import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import styles from './TopMenu.css';
import { connect } from 'react-redux';
import { SubNavBar } from 'components';
import { PropTypes } from 'prop-types';
import menu from 'components/menu/datas/menu.json';
import { Icon } from 'semantic-ui-react';

import Toastr from 'modern-toastr';
import TopRightMenu from './TopRightMenu.js';

import * as actions from 'actions';
import * as commonUtil from 'utils/common';
import * as service from 'services/user';

const cx = classNames.bind(styles);

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logout()),
  onReloadable: (isReload) => dispatch(actions.getReloadable(isReload)),
  onBreadcrumbInit: () => dispatch(actions.breadcrumbInit()),
  onBreadcrumbRemoveAdd: (breadcrumb, depth) =>
    dispatch(actions.breadcrumbRemoveAdd(breadcrumb, depth)),
});

class TopMenu extends Component {
  initialState() {
    return menu.menus;
  }

  constructor(props) {
    super(props);
    //새로 고침 시
    let links = this.initialState();
    links.map((link, i) => {
      if (props.location.pathname.indexOf(link.path) >= 0) {
        link.isActive = true;
        this.props.onBreadcrumbRemoveAdd(
          { path: link.path, title: link.text },
          0
        );
        link.sublinks.map((sublink, k) => {
          if (props.location.pathname === sublink.path) {
            sublink.isActive = true;
            this.props.onBreadcrumbRemoveAdd(
              { path: sublink.path, title: sublink.text },
              1
            );
          } else {
            sublink.isActive = false;
          }
          return sublink;
        });
      } else {
        link.isActive = false;
      }
      return link;
    });
    const lk = links.filter((link) => {
      return link.isActive;
    })[0];
    this.state = {
      isReload: true,
      links: links,
      link: lk != null ? lk : { path: '', text: '', subtext: '', sublinks: [] },
    };
  }

  componentDidUpdate(prevProps) {
    const user = this.props.user;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (user !== undefined && user !== null) {
        //console.log('*** topmenu ***');
        const authKey = commonUtil.getAuthKey();
        this.fetchCheckUserAuthKey({
          userId: user.id,
          userAuthKey: authKey,
        });
      } else {
        this.setMenuLink();
      }
    }
  }

  fetchCheckUserAuthKey = async (params) => {
    let connectInfo = await service.checkUserAuthKeyInfo(params);

    if (connectInfo !== null) {
      if (connectInfo.status === 200) {
        commonUtil.setAuthKey(connectInfo.data.authKey);
        //console.log(connectInfo.data.authKey);
        this.setMenuLink();
      } else {
        Toastr.error(connectInfo.data.message);
        if (connectInfo.data.authKeyError) {
          this.props.logout();
        }
      }
    } else {
      Toastr.error(
        '중복 로그인 확인중에 오류가 발생하였습니다. 다시 시도해 주세요.'
      );
    }
  };

  setMenuLink() {
    let links = this.initialState();
    links = links.map((link, i) => {
      if (this.props.location.pathname.indexOf(link.path) > -1) {
        link.isActive = true;
        this.props.onBreadcrumbRemoveAdd(
          { path: link.path, title: link.text },
          0
        );
        link.sublinks = link.sublinks.map((sublink, k) => {
          if (this.props.location.pathname === sublink.path) {
            sublink.isActive = true;
            this.props.onBreadcrumbRemoveAdd(
              { path: sublink.path, title: sublink.text },
              1
            );
          } else {
            sublink.isActive = false;
          }
          return sublink;
        });
      } else {
        link.isActive = false;
      }
      return link;
    });
    const lk = links.filter((link) => {
      return link.isActive;
    })[0];
    this.setState({
      isReload: true,
      links: links,
      link: lk != null ? lk : { path: '', text: '', subtext: '', sublinks: [] },
    });
  }

  handleClick = (e, i) => {
    e.preventDefault();
    let link = this.state.links[i];
    let links = this.state.links;
    const _links = links.map((link, i) => {
      link.isActive = false;
      link.sublinks.map((sublink) => {
        sublink.isActive = false;
        return sublink;
      });
      return link;
    });
    _links[i].isActive = true;
    this.setState({ links: _links, link });
    if (link != null) {
      this.props.onBreadcrumbInit();
      this.props.onBreadcrumbRemoveAdd(
        { path: link.path, title: link.text },
        0
      );
    }
    this.props.history.push({ pathname: link.path });
  };

  subHandleClick = (e) => {
    e.preventDefault();
    const links = this.state.links.slice();

    const lk = links.filter((link) => {
      return link.isActive;
    })[0];

    //초기화
    for (const idx in lk.sublinks) {
      lk.sublinks[idx].isActive = false;
    }

    this.setState({
      links: links,
      link: lk != null ? lk : { path: '', text: '', subtext: '', sublinks: [] },
    });

    if (lk != null) {
      this.props.onBreadcrumbRemoveAdd({ path: lk.path, title: lk.text }, 0);
    }
    this.props.history.push({ pathname: lk.path });
  };

  onMouseOver = (e, menu) => {
    if (menu) {
      this.setState({ [menu]: true });
    }
  };

  onMouseOut = (e, menu) => {
    if (menu) {
      this.setState({ [menu]: false });
    }
  };

  render() {
    return (
      <Fragment>
        <div className={cx('ms-header', 'top-menu', 'ms-header-white')}>
          <div className="container container-full">
            <div className="row">
              <div className="col text-left mt-1 mb-1 ">
                {this.state.links.map((link, i) => {
                  if (link.isHidden) return null;
                  const isActive = this.state['menu_' + i] || link.isActive;
                  return (
                    <Link
                      key={'menu_' + i}
                      to="#0"
                      name={'menu_' + i}
                      onMouseOver={(e) => this.onMouseOver(e, 'menu_' + i)}
                      onMouseOut={(e) => this.onMouseOut(e, 'menu_' + i)}
                      className={
                        'left-menu-item link-gray animated zoomInDown animation-delay-' +
                        (i + 1) * 2
                      }
                      onClick={(e) => this.handleClick(e, i)}
                    >
                      <Icon
                        circular
                        //inverted={isActive}
                        //color={isActive ? link.color : 'grey'}
                        //color={isActive ? '': 'gray'}
                        className={
                          isActive
                            ? 'primary-color-' +
                              link.icon +
                              ' plat-icon ' +
                              link.icon
                            : 'plat-icon ' + link.icon
                        }
                      />
                      <label
                        className={
                          this.state['menu_' + i]
                            ? 'color-dark small'
                            : 'color-dark small'
                        }
                      >
                        &nbsp;{link.text}&nbsp;&nbsp;
                      </label>
                    </Link>
                  );
                })}
              </div>
              <div className="col text-right">
                <Link
                  to="#"
                  title="트위터"
                  onClick={() => {
                    window.open('https://twitter.com/NatmalMaster');
                  }}
                  className="menu-item4"
                >
                  <Icon name="twitter" size="large" />
                </Link>
                <Link
                  to="#"
                  onClick={() => {
                    window.open('https://www.instagram.com/koreanword_natmal');
                  }}
                  title="인스타그램"
                  className="menu-item3"
                >
                  <Icon name="instagram" size="large" />
                </Link>
                <Link
                  to="#"
                  className="menu-item2"
                  title="페이스북"
                  onClick={() => {
                    window.open(
                      'https://www.facebook.com/Koreanword_natmal-114795973405603'
                    );
                  }}
                >
                  <Icon name="facebook" size="large" />
                </Link>
                <Link
                  to="#"
                  className="menu-item"
                  title="유튜브"
                  onClick={() => {
                    window.open(
                      'https://www.youtube.com/channel/UCfg3Yv8w8dg3b7iVhRBduHQ/featured'
                    );
                  }}
                >
                  <Icon name="youtube" size="large" />
                  &nbsp;
                </Link>
                <Link
                  to="#"
                  className="menu-item5"
                  onClick={() => {
                    window.open('http://blog.naver.com/wordnet21');
                  }}
                >
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </Link>
                <br />
                <TopRightMenu handleClick={this.handleClick} />
              </div>
            </div>
          </div>
        </div>
        <SubNavBar
          links={this.state.links}
          link={this.state.link}
          onClick={this.subHandleClick}
        />
      </Fragment>
    );
  }
}

TopMenu.contextTypes = {
  router: PropTypes.object,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TopMenu)
);
