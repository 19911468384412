import * as types from 'actions/ActionTypes';

const initalState = {
	decoVisible: false,
	decoType: null,
	post: null,
};

const decos = (state = initalState, action) => {
	switch (action.type) {
		case types.DECOS_OPEN:
			return Object.assign({}, state, {
				decoVisible: action.decoVisible,
				decoType: action.decoType,
				post: action.post,
			});
		case types.DECOS_CLOSE:
			return Object.assign({}, state, { decoVisible: action.decoVisible });
		default:
			return state;
	}
};

export default decos;
