import React, { Component, Fragment } from 'react';
import { Menu, Badge, Row, Col, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'actions';
import XLSX from 'xlsx';
import './SpellsSetup.css';

const mapStateToProps = state => ({
	decos: state.spell.decos,
});

const mapDispatchToProps = dispatch => ({
	//onSpellDeco: decos => dispatch(actions.onSpellDeco(decos)),
	onSpellSearchFilter: searchFilter => dispatch(actions.onSpellSearchFilter(searchFilter)),
	onSelectedItem: selectedItem => dispatch(actions.onSelectedItem(selectedItem)),
});

const initialData = decos => {
	let count = 0;
	const spells = decos.flatMap(x =>
		x.filterSpells.flatMap(s => s.spells.map(t => Object.assign({}, t, { lineNum: x.lineNum })))
	);
	const allDatas = spells.map(x => {
		return {
			줄번호: x.lineNum,
			표제어: x.orgStr,
			대치어: x.candWord.join('|'),
			도움말: x.help,
		};
	});
	count = spells.length;
	let spaceCount = 0,
		standardCount = 0,
		spellCount = 0,
		specialCharCount = 0,
		wordSelectCount = 0,
		etcCount = 0;
	const spaceData = spells
		.filter(a => a.helpcate.SPACE === 1)
		.map(x => {
			return {
				줄번호: x.lineNum,
				표제어: x.orgStr,
				대치어: x.candWord.join('|'),
				도움말: x.help,
			};
		});
	const standard = spells
		.filter(a => a.helpcate.STANDARD === 1)
		.map(x => {
			return {
				줄번호: x.lineNum,
				표제어: x.orgStr,
				대치어: x.candWord.join('|'),
				도움말: x.help,
			};
		});
	const spellData = spells
		.filter(a => a.helpcate.SPELL === 1)
		.map(x => {
			return {
				줄번호: x.lineNum,
				표제어: x.orgStr,
				대치어: x.candWord.join('|'),
				도움말: x.help,
			};
		});
	const specialChar = spells
		.filter(a => a.helpcate.SPECIAL_CHAR === 1)
		.map(x => {
			return {
				줄번호: x.lineNum,
				표제어: x.orgStr,
				대치어: x.candWord.join('|'),
				도움말: x.help,
			};
		});
	const wordSelect = spells
		.filter(a => a.helpcate.WORD_SELECT === 1)
		.map(x => {
			return {
				줄번호: x.lineNum,
				표제어: x.orgStr,
				대치어: x.candWord.join('|'),
				도움말: x.help,
			};
		});
	const etc = spells
		.filter(
			a =>
				a.helpcate.SPACE !== 1 &&
				a.helpcate.STANDARD !== 1 &&
				a.helpcate.SPELL !== 1 &&
				a.helpcate.SPECIAL_CHAR !== 1 &&
				a.helpcate.WORD_SELECT !== 1
		)
		.map(x => {
			return {
				줄번호: x.lineNum,
				표제어: x.orgStr,
				대치어: x.candWord.join('|'),
				도움말: x.help,
			};
		});
	spaceCount = spaceData.length;
	standardCount = standard.length;
	spellCount = spellData.length;
	specialCharCount = specialChar.length;
	wordSelectCount = wordSelect.length;
	etcCount = etc.length;

	return {
		count,
		spaceCount,
		standardCount,
		spellCount,
		specialCharCount,
		wordSelectCount,
		etcCount,
		allDatas,
		spaceData,
		standard,
		spellData,
		specialChar,
		wordSelect,
		etc,
	};
};

const exportClick = (e, data) => {
	e.preventDefault();
	const wb = XLSX.utils.book_new();
	const ws = XLSX.utils.json_to_sheet(data);
	XLSX.utils.book_append_sheet(wb, ws, 'export');
	XLSX.writeFile(wb, 'export.csv');
};

class SpellsSetup extends Component {
	constructor(props) {
		super(props);

		if (props.decos != null && props.decos.length > 0) {
			const datas = initialData(props.decos);

			this.state = {
				...datas,
			};
		}
	}

	getTitle = helpcate => {
		switch (helpcate) {
			case 'ALL':
				return '전체';
			case 'SPACE':
				return '띄어쓰기 오류';
			case 'STANDARD':
				return '표준어 오류';
			case 'SPELL':
				return '철자 오류';
			case 'SPECIAL_CHAR':
				return '문장 부호 오류';
			case 'WORD_SELECT':
				return '어휘 선택 오류';
			case 'ETC':
				return '기타 오류';
			default:
				return '';
		}
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.decos != null && nextProps.decos.length > 0) {
			const datas = initialData(nextProps.decos);
			this.setState({
				...datas,
			});
		}
	}

	onClickDecos = (e, helpcate) => {
		//const { decos } = this.props;
		this.props.onSpellSearchFilter(helpcate);
		this.props.onSelectedItem(null);
		/*
		let pathname = '';
		if (this.props.location.pathname.indexOf('/decoinfo') > -1) {
			pathname = this.props.location.pathname;
		} else {
			pathname = this.props.match.url + '/decos/decoinfo';
		}
		this.props.history.push({
			pathname: pathname,
			post: {
				title: this.getTitle(helpcate),
				//		helpcate,
			},
		});
		*/
	};

	render() {
		const {
			count,
			spaceCount,
			standardCount,
			spellCount,
			specialCharCount,
			wordSelectCount,
			etcCount,
			allDatas,
			spaceData,
			standard,
			spellData,
			specialChar,
			wordSelect,
			etc,
		} = this.state;

		return (
			<Fragment>
				<div
					style={{
						padding: '8px 10px',
						margin: '0',
						background: '#fff',
					}}>
					<Menu>
						<Menu.Item key="menu1" onClick={e => this.onClickDecos(e, 'ALL')} style={{ margin: 0 }}>
							<Row type="flex" justify="space-between">
								<Col>전체보기</Col>
								<Col>
									<Badge count={count} overflowCount={999} />
									&nbsp;&nbsp; &nbsp;&nbsp;
									<Icon type="download" onClick={e => exportClick(e, allDatas)} />
								</Col>
							</Row>
						</Menu.Item>
						<Menu.Item key="menu2" onClick={e => this.onClickDecos(e, 'SPACE')} style={{ margin: 0 }}>
							<Row type="flex" justify="space-between">
								<Col>띄어쓰기 오류</Col>
								<Col>
									<Badge count={spaceCount} overflowCount={999} />
									&nbsp;&nbsp; &nbsp;&nbsp;
									<Icon type="download" onClick={e => exportClick(e, spaceData)} />
								</Col>
							</Row>
						</Menu.Item>
						<Menu.Item key="menu3" onClick={e => this.onClickDecos(e, 'STANDARD')} style={{ margin: 0 }}>
							<Row type="flex" justify="space-between">
								<Col>표준어 오류</Col>
								<Col>
									<Badge count={standardCount} overflowCount={999} />
									&nbsp;&nbsp; &nbsp;&nbsp;
									<Icon type="download" onClick={e => exportClick(e, standard)} />
								</Col>
							</Row>
						</Menu.Item>
						<Menu.Item key="menu4" onClick={e => this.onClickDecos(e, 'SPELL')} style={{ margin: 0 }}>
							<Row type="flex" justify="space-between">
								<Col>철자 오류</Col>
								<Col>
									<Badge count={spellCount} overflowCount={999} />
									&nbsp;&nbsp; &nbsp;&nbsp;
									<Icon type="download" onClick={e => exportClick(e, spellData)} />
								</Col>
							</Row>
						</Menu.Item>
						<Menu.Item
							key="menu5"
							onClick={e => this.onClickDecos(e, 'SPECIAL_CHAR')}
							style={{ margin: 0 }}>
							<Row type="flex" justify="space-between">
								<Col>문장 부호 오류</Col>
								<Col>
									<Badge count={specialCharCount} overflowCount={999} />
									&nbsp;&nbsp; &nbsp;&nbsp;
									<Icon type="download" onClick={e => exportClick(e, specialChar)} />
								</Col>
							</Row>
						</Menu.Item>
						<Menu.Item key="menu6" onClick={e => this.onClickDecos(e, 'WORD_SELECT')} style={{ margin: 0 }}>
							<Row type="flex" justify="space-between">
								<Col>어휘 선택 오류</Col>
								<Col>
									<Badge count={wordSelectCount} overflowCount={999} />
									&nbsp;&nbsp; &nbsp;&nbsp;
									<Icon type="download" onClick={e => exportClick(e, wordSelect)} />
								</Col>
							</Row>
						</Menu.Item>
						<Menu.Item key="menu7" onClick={e => this.onClickDecos(e, 'ETC')} style={{ margin: 0 }}>
							<Row type="flex" justify="space-between">
								<Col>기타 오류</Col>
								<Col>
									<Badge count={etcCount} overflowCount={999} />
									&nbsp;&nbsp; &nbsp;&nbsp;
									<Icon type="download" onClick={e => exportClick(e, etc)} />
								</Col>
							</Row>
						</Menu.Item>
					</Menu>
				</div>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpellsSetup));
