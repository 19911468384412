// 외부 스크립트 로드 후 함수 실행

export default (url, cb) => { 
 
  var scr = document.createElement('script'); 
  scr.type = 'text/javascript'; 
  if (scr.readyState) { // IE 
    scr.onreadystatechange = function() { 
      // eslint-disable-next-line
    if (scr.readyState =='loaded' || scr.readyState ==='complete') { 
        scr.onreadystatechange = null;
        cb(); 
    } 
    }; 
  } else { // Others 
    scr.onload = cb; 
  } 
  scr.src = url; 
  document.getElementsByTagName('head')[0].appendChild(scr); 
 
};