import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as service from 'services/groups';
import { Container, Input, Modal, Grid, Table, Responsive, Pagination, Icon } from 'semantic-ui-react';
import 'modern-toastr/dist/modern-toastr.css';
import './index.css';
const loading = {
	addr: false,
};

const search = {
	addr: {
		currentPage: 1,
		countPerPage: 8,
		keyword: '',
	},
};

class SearchAddr extends Component {
	fetchAddrLists = async datas => {
		let result = await service.jusoApi(this, datas, null);
		if (result && result.status === 200) {
			let datas = result.data.results;
			let common = datas.common;
			if (common.totalCount > 0) {
				let totalPage = parseInt(parseInt(common.totalCount) / search.addr.countPerPage) + 1;
				this.setState({
					addrs: { totalPage, datas: datas.juso },
				});
			} else {
				this.setState({
					addrs: { message: '검색하신 내용이 없습니다.' },
				});
			}
		}
		let { loading } = this.state;
		loading['addr'] = false;
		this.setState({ loading });
	};

	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			loading,
			search,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open !== nextProps.open) {
			this.setState({ ...nextProps });
			return true;
		}
		return false;
	}

	searchOnChange = (e, { name, value }) => {
		let { search } = this.state;
		if (search.addr.hasOwnProperty(name)) {
			search.addr[name] = value;
			this.setState({ search });
		}
	};

	searchOnKeyPress = e => {
		if (e.key === 'Enter') {
			this.searchOnClick(e);
		}
	};

	searchOnClick = e => {
		e.preventDefault();
		let { search } = this.state;
		search.addr['currentPage'] = 1;
		this.setState({ search });
		this.searchHandler();
	};

	searchHandler = () => {
		let { search, loading } = this.state;

		loading['addr'] = true;
		this.setState({ loading });

		let data = search.addr;
		this.fetchAddrLists(data);
	};

	searchPaginationChange = (e, { activePage }) => {
		let { search } = this.state;
		search.addr['currentPage'] = activePage;
		this.setState({ search });
		this.searchHandler();
	};

	selectedItem = item => {
		const { getSelectedItem = f => f } = this.props;
		this.setState(() => getSelectedItem(item));
	};

	render() {
		const { open, addrs, loading } = this.state;

		return (
			<Modal size="small" open={open} onClose={this.props.close}>
				<Modal.Header>
					주소찾기 <i aria-hidden="true" className="close icon" onClick={this.props.close} />
				</Modal.Header>
				<Modal.Content>
					<Container style={{ fontSize: '1.2em' }}>
						<Grid columns={16}>
							<Grid.Column width={16}>
								<Input
									fluid
									name="keyword"
									action={{
										size: 'big',
										color: 'teal',
										children: '검색',
										secondary: true,
										onClick: this.searchOnClick,
									}}
									onKeyPress={this.searchOnKeyPress}
									onChange={this.searchOnChange}
									icon="search"
									loading={loading.addr}
									iconPosition="left"
									placeholder="도로명 또는 건물명 또는 동이름 또는 학교명을 입력해 주세요."
								/>
							</Grid.Column>
							<Grid.Column width={16}>
								<i className="fas fa-info-circle" />
								&nbsp;검색하신 주소를 선택해 주세요.
								<Table color="black" key="black" selectable>
									<Responsive as={Table.Header} {...Responsive.onlyComputer}>
										<Table.Row>
											<Table.HeaderCell>우편번호</Table.HeaderCell>
											<Table.HeaderCell>주소</Table.HeaderCell>
										</Table.Row>
									</Responsive>
									<Table.Body>
										{addrs &&
											addrs.datas &&
											addrs.datas.map((addr, index) => {
												return (
													<Table.Row
														key={'addr_' + index}
														onClick={() => this.selectedItem(addr)}>
														<Table.Cell className="cursor-pointer">{addr.zipNo}</Table.Cell>
														<Table.Cell className="cursor-pointer">
															{addr.roadAddr}
														</Table.Cell>
													</Table.Row>
												);
											})}
										{addrs && addrs.message && (
											<Table.Row colSpan="2">
												<Table.Cell>{addrs.message}</Table.Cell>
											</Table.Row>
										)}
										<Table.Row>
											<Table.Cell colSpan="2" className="text-center">
												{addrs && addrs.datas && (
													<Pagination
														activePage={search.addr.currentPage}
														ellipsisItem={{
															content: <Icon name="ellipsis horizontal" />,
															icon: true,
														}}
														firstItem={{
															content: <Icon name="angle double left" />,
															icon: true,
														}}
														lastItem={{
															content: <Icon name="angle double right" />,
															icon: true,
														}}
														prevItem={{ content: <Icon name="angle left" />, icon: true }}
														nextItem={{ content: <Icon name="angle right" />, icon: true }}
														boundaryRange={0}
														siblingRange={2}
														pointing
														secondary
														onPageChange={this.searchPaginationChange}
														totalPages={addrs.totalPage}
													/>
												)}
											</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</Grid.Column>
						</Grid>
					</Container>
				</Modal.Content>
			</Modal>
		);
	}
}

SearchAddr.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func,
	getSelectedItem: PropTypes.func,
};

export default SearchAddr;
