import * as types from 'actions/ActionTypes';

const initialState = {
    payload: null
};

const quickPanel = function(state = initialState, action) {
    switch (action.type) {
        case types.BOOK_SEARCH_DETAIL_TOGGLE: {
            return {
                state: action.payload
            };
        }
        default : {
            return state;
        }
    }
};

export default quickPanel;
