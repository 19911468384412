import * as types from 'actions/ActionTypes';

const initialState = {
	isComplete: false,
};

const process = (state = initialState, action) => {
	switch (action.type) {
		case types.PROCESS_COMPLETE:
			return Object.assign({}, state, { isComplete: action.isComplete });
		default:
			return state;
	}
};

export default process;
