import * as types from 'actions/ActionTypes';

const initalState = {
	isReportVisible: false,
	baseInfos: {},
	spellsInfos: {},
};

const report = (state = initalState, action) => {
	switch (action.type) {
		case types.SHOW_MODAL_REPORT:
			return Object.assign({}, state, { isReportVisible: action.isReportVisible });
		case types.BASE_INFO_SEND_REPORT:
			return Object.assign({}, state, { baseInfos: action.baseInfos });
		default:
			return state;
	}
};

export default report;
