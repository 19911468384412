import React from 'react';
import { Component } from 'react';
import './LqTest.css';
import { withRouter } from 'react-router-dom';
import * as service from 'services/etests';
import * as actions from 'actions';
import { connect } from 'react-redux';
import LimitTimer from './LimitTimer';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Form, Radio } from 'semantic-ui-react';
import { Button } from 'antd';
import './LqTestCat.css';

const START = 'START';
const STOP = 'STOP';
const WHAT_TIME = 'WHAT_TIME';
const INIT = 'INIT';

const RESET_COUNT = 'RESET_COUNT';
const WHAT_COUNT = 'WHAT_COUNT';

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logout()),
});

class LqTestCat extends Component {
  fetchPostInfo = async (datas) => {
    let req_data = { datas };
    //console.log(datas);
    const post = await service.api(
      this,
      '/api/start/post/catFirst',
      req_data,
      this.props.handleClose
    );
    if (post.status === 200) {
      let postdata = post.data.results;
      const testbankItems = postdata.testbankItems;
      this.setState({ currentPage: 1, testbankItems });

      var testHistorys = [];
      for (var i = 0; i < this.state.sumCount; i++) {
        let qno = 0;
        if (i === 0) {
          qno = testbankItems[i].CAT_ID.toString();
        }
        let answer = 0;
        let time = 0;
        testHistorys.push({ qno, answer, time });
      }

      //답안지 생성
      this.fetchHistroy({
        testInfoId: this.state.testInfo.ID,
        testHistorys: testHistorys,
        sumTime: this.state.limitTime,
      });
    }
  };

  fetchPostCatInfo = async (datas) => {
    let req_data = { datas };
    const post = await service.api(
      this,
      '/api/start/post/catNext',
      req_data,
      null
    );
    if (post.status === 200) {
      let postdata = post.data.results;
      const testbankItems = postdata.testbankItems;
      var qnos = this.state.qnos;
      var num = this.state.currentPage + 1;
      qnos[num - 1] = testbankItems[0].CAT_ID.toString();
      this.setState({ currentPage: num, testbankItems, qnos });
    }
  };

  fetchHistroy = async (datas) => {
    let req_data = { datas };
    const post = await service.api(
      this,
      '/api/start/history',
      req_data,
      this.props.handleClose
    );
    if (post.status === 200) {
      //답안지 로드
      let postdata = post.data.results;

      //정상로드체크
      let qnos = postdata.Q_NO.split('|');
      let answers = postdata.ANSWER.split('|');
      let times = postdata.TIME.split('|');
      let sumTime = postdata.SUM_TIME;
      let remainCount = answers.filter((item) => item === '0').length;
      let ids = qnos.filter((item) => item > 0);

      //시험 시작
      this.setState({
        timerStatus: START,
        limitTime: sumTime,
        remainCount: remainCount,
        currentPage: ids.length,
        qnos,
        answers,
        times,
      });
    }
  };

  fetchHistroyUpdate = async (datas) => {
    let req_data = { datas };
    const post = await service.api(
      this,
      '/api/start/history/update',
      req_data,
      this.props.handleClose
    );
    if (post.status === 200) {
      //업데이트 성공여부
    }
  };

  fetchStatusUpdate = async (datas) => {
    let req_data = { datas };
    const post = await service.api(
      this,
      '/api/start/status/update',
      req_data,
      null
    );
    if (post.status === 200) {
      //업데이트 성공여부
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      qnos: [],
      answers: [],
      times: [],
      allTestBanks: [],
      testbankItems: [],
      currentPage: null,
      totalPages: null,
      limitTime: parseInt(props.levelInfo.time) * 60,
      sumCount: props.levelInfo.count,
      remainCount: props.levelInfo.count,
      timeCount: 0,
      timerStatus: STOP,
      timeCountStatus: INIT,
    };
  }

  timerCount = (count) => {
    this.setState({ timeCount: count, timeCountStatus: RESET_COUNT });
  };

  componentDidMount() {
    if (this.state.isOpen) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';

      let testInfoId = this.props.testInfo.ID;

      //문항 및 히스토리 로드
      this.fetchPostInfo({
        testInfoId,
        testConfig: this.props.testConfig,
        testLevel: this.props.levelInfo.value,
      });
    }
  }

  /*
	componentWillReceiveProps(nextProps) {
		if (this.props.isOpen !== nextProps.isOpen) {
			if (nextProps.isOpen) {
				this.setState({ ...nextProps });
				let testInfoId = nextProps.testInfo.ID;

				//문항 및 히스토리 로드
				this.fetchPostInfo({
					testInfoId,
					testConfig: nextProps.testConfig,
					testLevel: nextProps.levelInfo.value,
				});
			} else {
				this.setState({ timerStatus: STOP, timeCount: 0 });
			}
			return true;
		}
		return true;
	}
*/
  componentWillUnmount() {
    document.body.style.overflow = 'visible';
    this.setState({ timerStatus: STOP });
  }

  convertTestBankItem(source) {
    return source
      .replace(/</g, '『')
      .replace(/>/g, '』')
      .replace(/\/\^/g, "'")
      .replace(/\^\//g, "'")
      .replace(/\\n/g, '<br/>')
      .replace(/ /g, '&nbsp;')
      .replace(/@/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
      .replace(/\/\$/g, '<span class="underline-custom">')
      .replace(/\$\//g, '</span>');
  }

  handleChange = (e, { name, value }) => {
    const { answers, times, timeCount } = this.state;
    answers[name] = value;
    times[name] = (parseInt(times[name]) + timeCount).toString();
    this.setState({ answers, times });
  };

  onClickAnswers = (e, index) => {
    const selectedIndex = this.state.answers[index];
    if (!parseInt(selectedIndex)) {
      this.setState({
        remainCount: this.state.remainCount - 1,
      });
    }
    this.setState({ timerStatus: WHAT_TIME, timeCountStatus: WHAT_COUNT });
  };

  getCurrentTime = (currentTime) => {
    this.setState({ currentTime: currentTime, timerStatus: INIT });
    let sumTime = currentTime;
    let qnos = this.state.qnos.join('|');
    let answers = this.state.answers.join('|');
    let times = this.state.times.join('|');
    let testInfoId = this.state.testInfo.ID;
    //히스토리 저장
    this.fetchHistroyUpdate({ testInfoId, qnos, answers, times, sumTime });
  };

  timeOver = (currentTime) => {
    let sumTime = currentTime;
    let qnos = this.state.qnos.join('|');
    let answers = this.state.answers.join('|');
    let times = this.state.times.join('|');
    let testInfoId = this.state.testInfo.ID;
    const div = this.state.testInfo.DIV;
    //히스토리 저장
    this.fetchHistroyUpdate({ testInfoId, qnos, answers, times, sumTime });
    this.fetchStatusUpdate({ testInfoId, status: 'TIME_OVER' });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="lqtest-custom-ui">
            <h3>검사 종료</h3>
            <p>시간이 초과되었습니다.</p>
            {div.trim() === 'USER' && (
              <p>결과는 마이페이지의 보관함에서 확인할 수 있습니다. </p>
            )}
            <button
              onClick={() => {
                this.props.handleClose('timeover');
                onClose();
              }}
            >
              확인
            </button>
          </div>
        );
      },
    });
  };

  nextTestBankItems(e) {
    e.preventDefault();
    if (this.state.remainCount > 0) {
      let testInfo = this.state.testInfo.ID;
      let testLevel = this.props.levelInfo.value;
      let currentPage = this.state.currentPage;
      let catId = this.state.qnos[currentPage - 1];
      let qno = currentPage;
      let checkAnswer = this.state.answers[currentPage - 1];

      if (checkAnswer > 0) {
        this.fetchPostCatInfo({ testInfo, testLevel, catId, qno, checkAnswer });
      } else {
        Toastr.info('답을 체크해 주세요.');
      }
    } else {
      this.onClickClose(e);
    }
  }

  onClickClose(e) {
    e.preventDefault();
    // reamin count 완료
    const testInfo = this.state.testInfo;

    if (testInfo) {
      const div = testInfo.DIV;

      if (this.state.remainCount > 0) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="lqtest-custom-stop-ui">
                <h3>검사를 중지 하시겠습니까?</h3>
                {div.trim() === 'GROUP' && <p>남은 문항은 재접속 시</p>}
                {div.trim() === 'USER' && (
                  <p>남은 문항은 마이페이지의 보관함에서 </p>
                )}
                <p>이어서 검사하실 수 있습니다.</p>
                <div>
                  <Button onClick={onClose}>계속 응시</Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      const testInfoId = testInfo.TEST_INFO_ID;
                      this.setState({
                        timerStatus: WHAT_TIME,
                        timeCountStatus: WHAT_COUNT,
                      });
                      this.fetchStatusUpdate({ testInfoId, status: 'PAUSE' });
                      this.props.handleClose('pause');
                      onClose();
                    }}
                  >
                    중지
                  </Button>
                </div>
              </div>
            );
          },
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="lqtest-custom-ui">
                <h3>검사 완료</h3>
                <p>수고하셨습니다. 검사가 완료되었습니다.</p>
                {div.trim() === 'USER' && (
                  <p>결과는 마이페이지의 보관함에서 확인할 수 있습니다. </p>
                )}
                <button
                  onClick={() => {
                    const testInfoId = testInfo.ID;
                    this.fetchStatusUpdate({ testInfoId, status: 'FINISH' });
                    this.props.handleClose('finish');
                    onClose();
                  }}
                >
                  확인
                </button>
              </div>
            );
          },
        });
      }
    } else {
      this.props.handleClose();
    }
  }

  render() {
    const {
      title,
      limitTime,
      remainCount,
      testbankItems,
      currentPage,
      timerStatus,
      timeCountStatus,
      timeCount,
    } = this.state;

    const { isOpen } = this.props;
    const showHideClassName = isOpen
      ? 'modal modal-custom modal-dark display-block animated fadeIn '
      : 'modal modal-custom modal-dark';
    const answer =
      this.state.answers.length > 0 ? this.state.answers[currentPage - 1] : '0';

    return (
      <div className={showHideClassName} tabIndex="-1" role="dialog">
        <div
          className="modal-dialog-custom"
          role="document"
          style={{ margin: '0px!important' }}
        >
          <div className="modal-content modal-content-custom">
            <div className="modal-header modal-header-custom bg-dark">
              <div className="container color-white">
                <div className="row">
                  <div className="col-12 col-md-5" style={{ margin: 'auto' }}>
                    <h3>{title}</h3>
                  </div>
                  <div className="col-12 col-md-7" style={{ margin: 'auto' }}>
                    <div className="row">
                      <div className="col-md-3" />
                      <div className="col-10 col-md-7">
                        <div className="row">
                          <div
                            className="col-12 col-md-6 p-1"
                            style={{ margin: 'auto' }}
                          />
                          <div
                            className="col-12 col-md-6 p-1"
                            style={{ margin: 'auto' }}
                          >
                            <h3 className="m-0">
                              <small>
                                <span className="badge mr-1">남은시간</span>
                              </small>
                              <LimitTimer
                                limitTime={limitTime}
                                status={timerStatus}
                                countStatus={timeCountStatus}
                                timerCount={this.timerCount}
                                getCurrentTime={this.getCurrentTime}
                                timeOver={this.timeOver}
                                count={timeCount}
                              />
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 col-md-2">
                        <button
                          type="button"
                          className="btn-circle btn-circle-white"
                          data-dismiss="modal"
                          onClick={(e) => this.onClickClose(e)}
                        >
                          <i className="fas fa-times" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body modal-body-custom-desktop">
              <div className="container">
                <div className="row">
                  <div className="col-12 mt-2 mb-4">
                    {currentPage && parseInt(currentPage) > 25 && (
                      <div
                        className="alert alert-success alert-light alert-dismissible ml-36 mr-36"
                        role="alert"
                      >
                        <div className="row justify-content-center align-items-center">
                          <div className="col-12 col-md-6 text-center">
                            <strong>
                              <i className="zmdi zmdi-check" />
                            </strong>{' '}
                            남은 문항이 없습니다. <strong>평가 완료</strong>{' '}
                            버튼을 클릭해 주세요.
                          </div>
                          <div className="col-12 col-md-6 text-center">
                            <button
                              type="button"
                              className="btn btn-raised btn-success"
                              onClick={(e) => this.onClickClose(e)}
                            >
                              평가완료
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {testbankItems.map((item, index) => (
                      <div key={'desk_' + index} className="card">
                        <div className="card-header">
                          <h2>
                            {currentPage}.&nbsp;
                            <span
                              dangerouslySetInnerHTML={{
                                __html: this.convertTestBankItem(item.QUE),
                              }}
                            />
                          </h2>
                        </div>
                        {item.TestBankQue ? (
                          <div className="card-body">
                            <p
                              className="well well-lg"
                              dangerouslySetInnerHTML={{
                                __html: this.convertTestBankItem(
                                  item.TestBankQue.QUE_TEXT
                                ),
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="card-footer bg-white">
                          <Form size="big" key="big">
                            {item.TestBankExams.map((item, key) => {
                              return (
                                <Form.Field key={'item_' + key}>
                                  <Radio
                                    name={(currentPage - 1).toString()}
                                    value={item.EX_NO.toString()}
                                    checked={answer === item.EX_NO.toString()}
                                    onChange={this.handleChange}
                                    onClick={(e) =>
                                      this.onClickAnswers(e, currentPage - 1)
                                    }
                                    label={{
                                      style: {
                                        fontSize: '1.4em',
                                      },
                                      dangerouslySetInnerHTML: {
                                        __html:
                                          item.EX_NO.toString() +
                                          '.&nbsp;' +
                                          this.convertTestBankItem(
                                            item.EX_TEXT
                                          ),
                                      },
                                    }}
                                  />
                                </Form.Field>
                              );
                            })}
                          </Form>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer modal-footer-custom-desktop bg-dark">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <span
                      href="#0"
                      className="btn-circle btn-circle-lg btn-circle-success color-white btn-circle-raised LqTestCat-next-btn"
                      role="button"
                      onClick={(e) => this.nextTestBankItems(e)}
                    >
                      다음
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LqTestCat.defaultProps = {
  classes: false,
};

export default connect(null, mapDispatchToProps)(withRouter(LqTestCat));
