import * as types from './ActionTypes';

export const mobileMenu = (isShow) => {
  if (isShow) {
    return {
      type: types.MOBILE_MENU_SHOW,
      isShow,
    };
  } else {
    return {
      type: types.MOBILE_MENU_HIDE,
      isShow,
    };
  }
};

export const mobileLinkBtn = (isShow) => {
  return {
    type: types.MOBILE_LINK_BTN,
    isShow,
  };
};
