export const nCorrectMethod = {
	0: { title: '에러가 없을 떄', dp: '오류없음', dpcode: 0 },
	1: { title: '형태소 분석이 안될 때', dp: '철자오류', dpcode: 1 },
	2: { title: '오용어로 분석될 때', dp: '철자오류', dpcode: 1 },
	3: { title: '다수어절 오류', dp: '다수어절 오류', dpcode: 3 },
	4: { title: '의미문체 오류', dp: '의미문체 오류', dpcode: 2 },
	5: { title: '문장 부호 오류', dp: '철자오류 - 문장부호', dpcode: 1 },
	6: { title: '통계정보를 이용한 붙여쓰기', dp: '철자오류 - 붙여쓰기', dpcode: 1 },
	7: { title: '영어 오용어로 분석될 때', dp: '철자오류', dpcode: 1 },
	8: { title: '태깅 오류', dp: '철자오류', dpcode: 1 },
	9: { title: '복합명사 언더바 오류', dp: '철자오류 - 복합명사', dpcode: 1 },
	10: { title: '오류 형태에 따라 붙여쓰기', dp: '철자오류 - 붙여쓰기', dpcode: 1 },
};

export const SPELL_EVENT_STATUS = {
	SELECT: 'SELECT',
	DISABLE: 'DISABLE',
	REPLACE: 'REPLACE',
	DECO_ADD: 'DECO_ADD',
	REMOVE: 'REMOVE',
	REMOVE_ITEM: 'REMOVE_ITEM',
	DECO_UPDATE: 'DECO_UPDATE',
};
