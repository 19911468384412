import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

const START = 'START';
const STOP = 'STOP';
const RESTART = 'RESTART';
const WHAT_TIME = 'WHAT_TIME';
const INIT = 'INIT';
const TIMEOVER = 'TIMEOVER';

const RESET_COUNT = 'RESET_COUNT';
const WHAT_COUNT = 'WHAT_COUNT';

class LimitTimer extends Component {
	initTimeLabel(limitTime) {
		const initLimit = limitTime;
		var minutes = parseInt((initLimit / 60) % 60, 10);
		var seconds = parseInt(initLimit % 60, 10);

		minutes = minutes < 10 ? '0' + minutes : minutes;
		seconds = seconds < 10 ? '0' + seconds : seconds;

		return minutes + ':' + seconds;
	}

	constructor(props) {
		super(props);
		const limitTimeLabel = this.initTimeLabel(props.limitTime);
		this.state = {
			currentTime: props.limitTime,
			limitTimeLabel: limitTimeLabel,
			status: props.status,
			countStatus: props.countStatus,
			count: props.count,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.status !== nextProps.status) {
			let limitIntervalId = this.state.limitTimeLabel;
			switch (nextProps.status) {
				case START: {
					const limitTimeLabel = this.initTimeLabel(nextProps.limitTime);
					if (limitIntervalId) clearInterval(limitTimeLabel);
					limitIntervalId = setInterval(this.limitTimer.bind(this), 1000);
					this.setState({
						currentTime: nextProps.limitTime,
						limitTimeLabel,
						limitIntervalId,
					});
					break;
				}
				case STOP: {
					this.isCurrentTime();
					if (limitIntervalId) clearInterval(limitIntervalId);
					break;
				}
				case RESTART: {
					if (limitIntervalId) clearInterval(limitIntervalId);
					const limitTimeLabel = this.initTimeLabel(nextProps.limitTime);
					limitIntervalId = setInterval(this.limitTimer.bind(this), 1000);
					this.setState({
						currentTime: nextProps.limitTime,
						limitTimeLabel,
						limitIntervalId,
					});
					break;
				}
				case WHAT_TIME: {
					this.isCurrentTime();
					break;
				}
				default: {
					break;
				}
			}
		}
		if (this.props.countStatus !== nextProps.countStatus) {
			switch (nextProps.countStatus) {
				case RESET_COUNT:
					this.setState({ count: 0 });
					this.isTimerCount();
					break;
				case WHAT_COUNT:
					this.isTimerCount();
					break;
				default:
					break;
			}
		}
		return false;
	}

	isCurrentTime() {
		const { getCurrentTime = f => f } = this.props;
		this.setState(() => getCurrentTime(this.state.currentTime));
	}

	timeOver() {
		const { timeOver = f => f } = this.props;
		this.setState(() => timeOver(this.state.currentTime));
	}

	isTimerCount() {
		const { timerCount = f => f } = this.props;
		this.setState(() => timerCount(this.state.count));
	}

	componentWillUnmount() {
		const { limitIntervalId } = this.state;
		if (limitIntervalId) clearInterval(limitIntervalId);
	}
	/*
	componentDidUpdate(prevProps, prevState) {
		if (this.state.currentTime !== prevState.currentTime) {
			this.timerCount();
		}
	}
*/
	limitTimer() {
		var count = this.state.count + 1;
		var remainTime = this.state.currentTime - 1;
		var minutes = parseInt((remainTime / 60) % 60, 10);
		var seconds = parseInt(remainTime % 60, 10);

		minutes = minutes < 10 ? '0' + minutes : minutes;
		seconds = seconds < 10 ? '0' + seconds : seconds;

		if (remainTime > 0) {
			this.setState({ currentTime: remainTime, limitTimeLabel: minutes + ':' + seconds, count });
		} else {
			this.setState({ currentTime: remainTime, limitTimeLabel: minutes + ':' + seconds, count });
			this.timeOver();
			const { limitIntervalId } = this.state;
			if (limitIntervalId) clearInterval(limitIntervalId);
		}
	}

	render() {
		const { limitTimeLabel } = this.state;

		return <Fragment>{limitTimeLabel}</Fragment>;
	}
}

LimitTimer.propTypes = {
	limitTime: PropTypes.number.isRequired,
	status: PropTypes.oneOf([START, STOP, RESTART, WHAT_TIME, INIT, TIMEOVER]),
	countStatus: PropTypes.oneOf([RESET_COUNT, WHAT_COUNT, INIT]),
	getCurrentTime: PropTypes.func,
	timerCount: PropTypes.func,
	timeOver: PropTypes.func,
	count: PropTypes.number.isRequired,
};

export default LimitTimer;
