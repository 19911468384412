/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import { Card, Icon } from 'antd';
import { connect } from 'react-redux';
import * as services from 'services/sentence';
import './HanjaConvert.css';
import { Loading } from 'sentence/components/utils';

const mapStateToProps = state => ({
	div: state.hanjas.div,
	lqInfosText: state.info.lqInfosText,
	blockKeys: state.blocks.blockKeys,
	blockTexts: state.blocks.blockTexts,
});

class HanjaConvert extends Component {
	state = {
		hanjas: [],
		hanjaByKey: [],
		changeHanja: false,
		isLoading: false,
	};

	fetchHanja = async (block, loading) => {
		if (block.blockText == null || (block.blockText != null && block.blockText.trim() === '')) {
			const { hanjaByKey } = this.state;
			hanjaByKey[block.blockKey] = null;
			this.setState({ hanjaByKey, changeHanja: loading });
			return;
		}
		const WORD_REGEX = new RegExp(/\([^\)|^\(]+\)/, 'g');
		let matchArr;
		let data = block.blockText;
		while ((matchArr = WORD_REGEX.exec(data)) !== null) {
			const pattern = /[\u2e80-\u2eff\u31c0-\u31ef\u3200-\u32ff\u3400-\u4dbf\u4e00-\u9fbf\uf900-\ufaff]/;
			if (pattern.test(matchArr[0])) {
				const pattern2 = new RegExp(matchArr[0], 'g');
				data = data.replace(pattern2, '');
			}
		}
		if (data != null) {
			data = data.replace(/\(\)/g, '');
		}
		const post = await services.postApi(this, '/api/sentence-unlimit/hanja', data);
		if (post && post.status === 200) {
			if (post.data.hanja != null) {
				let _hanja = this.state.hanjaByKey;
				_hanja[block.blockKey] = post.data.hanja;
				//const { blockTexts } = this.state;
				//	blockTexts[block.blockKey] = Object.assign({}, blockTexts[block.blockKey], { isHanja: false });
				this.setState({ hanjaByKey: _hanja, changeHanja: loading });
			}
		}
	};

	fetchHanjaText = async (text, loading) => {
		const post = await services.postApi(this, '/api/sentence-unlimit/hanja', text);
		if (post && post.status === 200) {
			if (post.data.hanja != null) {
				//let _hanja = this.state.hanjaByKey;
				//_hanja[block.blockKey] = post.data.hanja;
				//const { blockTexts } = this.state;
				//	blockTexts[block.blockKey] = Object.assign({}, blockTexts[block.blockKey], { isHanja: false });
				//his.setState({ hanjas: post.data.hanja, changeHanja: loading });
				return post.data.hanja;
			} else {
				return '';
			}
		} else {
			return '';
		}
	};

	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.state, {
			div: props.div,
			text: props.lqInfosText,
			blockKeys: props.blockKeys,
			blockTexts: props.blockTexts,
		});
	}

	async componentDidMount() {
		if (this.state.text != null && this.state.text.trim() !== '') {
			if (this.state.div) {
				this.setState({ isLoading: true });
				const texts = this.state.text.split('\n');
				const hanjas = await Promise.all(
					texts.map(async x => {
						return await this.fetchHanjaText(x);
					})
				);
				this.setState({ hanjas, isLoading: false });
			} else {
				this.setState({ hanjas: this.state.text.split('\n'), isLoading: false });
			}
		} else {
			this.setState({ hanjas: [], isLoading: false });
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			div: nextProps.div,
			text: nextProps.lqInfosText,
			blockKeys: nextProps.blockKeys,
			blockTexts: nextProps.blockTexts,
		});
		/*
		if (this.props.div !== nextProps.div) {
			this.setState({ div: nextProps.div });
		}

		if (this.props.text !== nextProps.text) {
			this.setState({ text: nextProps.text });
		}

		if (this.props.blockKeys !== nextProps.blockKeys) {
			this.setState({ blockKeys: nextProps.blockKeys });
		}

		if (this.props.blockTexts !== nextProps.blockTexts) {
			this.setState({ blockTexts: nextProps.blockTexts });
		}
		*/
	}

	async componentDidUpdate(prevProps, prevState) {
		if (this.state.text !== prevState.text) {
			if (this.state.text != null && this.state.text.trim() !== '') {
				if (this.state.div) {
					this.setState({ isLoading: true });
					const texts = this.state.text.split('\n');
					const hanjas = await Promise.all(
						texts.map(async x => {
							return await this.fetchHanjaText(x);
						})
					);
					this.setState({ hanjas, isLoading: false });
				} else {
					this.setState({ hanjas: this.state.text.split('\n'), isLoading: false });
				}
			} else {
				this.setState({ hanjas: [], isLoading: false });
			}
		}
		if (this.state.div !== prevState.div) {
			if (this.state.div) {
				this.setState({ isLoading: true });
				const texts = this.state.text.split('\n');
				const hanjas = await Promise.all(
					texts.map(async x => {
						return await this.fetchHanjaText(x);
					})
				);
				this.setState({ hanjas, isLoading: false });
			} else {
				this.setState({ hanjas: this.state.text.split('\n'), isLoading: false });
			}
		}
	}

	render() {
		const { div, hanjas, isLoading } = this.state;
		return (
			<Fragment>
				<div style={{ height: '100%', padding: '15px' }}>
					<Card
						title={
							div ? (
								<div>
									한글 <Icon type="arrow-right" /> 한자 병기
								</div>
							) : (
								<div>
									{' '}
									한자 병기 <Icon type="arrow-right" /> 한글
								</div>
							)
						}
						style={{ height: '100%', margin: '5px 0px', bottom: '5px' }}>
						<div className="deco_hanja_content">
							{isLoading && <Loading />}
							{!isLoading && hanjas.map((x, i) => <p key={i}>{x}</p>)}
						</div>
					</Card>
				</div>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, null)(HanjaConvert);
