import React, { Component } from 'react';
import { Layout, Icon, Button, Row, Col } from 'antd';
import { LeftMenu, RightMenu } from 'sentence/components/menu';
import MainContaienr from 'sentence/containers/main';
import DecoContainer from 'sentence/containers/deco';
import { Reports } from 'sentence/containers/modal';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { BaseInfo } from 'sentence/components/menu/RightMenu/informations';
import { ReactComponent as Logo } from 'sentence/components/logos/logo.svg';
import 'draft-js/dist/Draft.css';
import './index.css';

const LogoIcon = (props) => <Icon component={Logo} {...props} />;
const mapStateToProps = (state) => ({
	text: state.postData.text,
	sideBarOption: state.sidebar.sideBarOption,
});

const { Header, Sider } = Layout;
class SentenceApp extends Component {
	state = {
		collapsed: true,
		rightCollapsed: true,
		text: '',
		editorState: null,
	};

	breakTwoPoint = (broken) => {
		this.setState({ broken2: broken });
	};

	toggle = () => {
		this.setState({
			collapsed: !this.state.collapsed,
			rightCollapsed: true,
		});
	};

	rightToggle = () => {
		this.setState({
			collapsed: true,
			rightCollapsed: !this.state.rightCollapsed,
		});
	};

	constructor(props) {
		super(props);

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.state = {
			...this.state,
			width: 0,
		};
	}

	componentWillReceiveProps(nextProps) {
		const { decoVisible, decoType, post, text, sideBarOption } = nextProps;
		if (this.state.decoVisible !== decoVisible) {
			this.setState({ decoVisible, decoType, post });
		}
		if (this.state.text !== text) {
			this.setState({ text });
		}
		if (this.state.sideBarOption !== sideBarOption) {
			this.setState({ sideBarOption });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { sideBarOption } = this.state;
		if (sideBarOption !== prevState.sideBarOption) {
			if (sideBarOption.left === 'hide' && sideBarOption.right === 'hide') {
				this.setState({ collapsed: true, rightCollapsed: true });
			}
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	getEditorState = (editorState) => {
		this.setState({ editorState });
	};

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	render() {
		const { text, editorState, width } = this.state;
		return (
			<Layout style={{ padding: '0px 0px 0px 0px' }}>
				<Sider
					collapsedWidth="0"
					collapsible
					width={125}
					collapsed={this.state.collapsed}
					theme="light"
					style={{ padding: '0px' }}>
					<LeftMenu onClose={this.props.onClose} text={text} />
				</Sider>
				<Layout style={{ padding: '0px' }}>
					<Header style={{ background: 'rgb(50, 50, 50)', padding: '5px', height: '40px', lineHeight: '0' }}>
						<Row>
							<Col span={12}>
								<Button
									className="trigger"
									ghost
									icon={this.state.collapsed ? 'menu-fold' : 'menu-unfold'}
									onClick={this.toggle}
								/>
							</Col>
							<Col style={{ position: 'fixed', left: width / 2 - 11 }}>
								<LogoIcon style={{ fontSize: '32px', zIndex: '-1', color: 'darkorange' }} />
							</Col>
							<Col span={12} style={{ textAlign: 'right' }}>
								<Button
									className="trigger"
									ghost
									icon={this.state.rightCollapsed ? 'menu-unfold' : 'menu-fold'}
									onClick={this.rightToggle}
								/>
							</Col>
						</Row>
					</Header>
					<Layout style={{ padding: '0px', overflowY: 'hidden' }}>
						<MainContaienr text={text} getEditorState={this.getEditorState} />
					</Layout>
				</Layout>
				<Sider
					collapsedWidth="0"
					collapsible
					collapsed={this.state.rightCollapsed}
					width={250}
					theme="light"
					reverseArrow={true}
					style={{
						background: '#fff',
						padding: '0px',
						borderLeft: '1px solid rgb(232, 232, 232)',
						zIndex: '3001',
					}}>
					<RightMenu text={text} editorState={editorState} />
				</Sider>
				<Reports isReportVisible={false} />
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	null
)(withRouter(SentenceApp));
