import React, { Component, createRef, ReactDOM } from 'react';
import { Layout, Affix, Drawer, Spin } from 'antd';
import { CustomEditors } from 'sentence/components';
import { withRouter } from 'react-router-dom';
import './index.css';
import { connect } from 'react-redux';
import * as services from 'services/sentence';
import * as actions from 'actions';
import PropTypes from 'prop-types';
import DecoContainer from 'sentence/containers/deco';
import { BaseInfo } from 'sentence/components/menu/RightMenu/informations';
import {
	DecoWords,
	DecosList,
	HanjaConvert,
	DecoMultiLang,
	DecoSynonym,
	DecoDictionary,
} from 'sentence/components/decorators';

const { Sider, Content } = Layout;

const mapStateToProps = (state) => ({
	authenticated: state.auth.authenticated,
	selectMenu: state.menu.selectMenu,
	decoVisible: state.decos.decoVisible,
	decoType: state.decos.decoType,
	post: state.decos.post,
	blockTexts: state.blocks.blockTexts,
});

const mapDispatchToProps = (dispatch) => ({
	validate: (user) => dispatch(actions.validate(user)),
	onDecosClose: (decoVisible) => dispatch(actions.onDecosClose({ decoVisible })),
});

const DecoByType = (type, post) => {
	switch (type) {
		case 'wordinfo':
			return <DecoWords post={post} />;
		case 'decoinfo':
			return <DecosList post={post} />;
		case 'hanja':
			return <HanjaConvert post={post} />;
		case 'multilang':
			return <DecoMultiLang post={post} />;
		case 'synonyms':
			return <DecoSynonym post={post} />;
		case 'dictionary':
			return <DecoDictionary word_no={post.word_no} />;
		default:
			return '';
	}
};

class MainContainer extends Component {
	state = {
		isFocus: false,
		placement: 'left',
		currentPage: 0,
		editorState: null,
		loading: false,
		visible: true,
	};

	fetchSummary1 = async (block) => {
		if (block.blockText == null || (block.blockText != null && block.blockText.replace('\n', '').trim() === '')) {
			return { blockKey: block.blockKey, lqInfos: null };
		}
		const post = await services.postApi(this, '/api/lq/book/analysis', block.blockText);
		if (post && post.status === 200) {
			let lqInfos = post.data.results;
			if (lqInfos != null) {
				return { blockKey: block.blockKey, lqInfos: Object.assign({}, lqInfos, { blockKey: block.blockKey }) };
			}
		}
		return { blockKey: block.blockKey, lqInfos: null };
	};

	constructor(props) {
		super(props);

		const USER = JSON.parse(window.localStorage.getItem('user'));
		if (USER != null) {
			props.validate(USER);
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

		this.state = {
			...this.state,
			width: window.innerWidth,
			selectMenu: props.selectMenu,
			text: props.text,
			decoVisible: props.decoVisible,
			title: props.post && props.post.title,
			type: props.decoType,
			post: props.post,
			blockTexts: props.blockTexts,
		};
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	componentDidMount() {
		this.updateWindowDimensions();
		//setInterval(this.timerTick, TEXTS_INTERVAL);
		//setInterval(this.timerTickWords, WORDS_INTERVAL);
		//setInterval(this.timerTickLqs, LQINFOS_INTERVAL);
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	componentWillReceiveProps(nextProps) {
		const { authenticated, text, selectMenu, decoVisible, post, decoType, blockTexts } = nextProps;
		const loadedBlockTexts = blockTexts
			.filter((x) => x.status === 'COMPLETE')
			.map((x) => x.blockText)
			.join('\n');
		this.setState({
			selectMenu,
			decoVisible,
			title: post && post.title,
			post,
			type: decoType,
			blockTexts,
			loadedBlockTexts,
		});

		if (this.state.authenticated !== authenticated) {
			this.setState({ authenticated });
		}

		if (this.state.text !== text && text != null) {
			const maxLength = 2000;
			const textLen = text.length;
			const loopCnt = textLen / maxLength + 1;
			let rssTitles = '';
			let entityList = [];
			for (let i = 0; i < loopCnt; i++) {
				let lastIndex = (i + 1) * maxLength;

				if (textLen > lastIndex) {
					rssTitles = text.substring(i * maxLength, lastIndex);
				} else {
					rssTitles = text.substring(i * maxLength);
				}

				entityList = entityList.concat(rssTitles);
			}
			this.setState({ text, entityList, loadcount: 1, data: [entityList[0]] });
		}
	}

	getEditorState = (editorState) => {
		this.setState({ editorState });
		this.props.getEditorState(editorState);
	};

	showDrawer = (word_no) => {
		this.setState({
			visible: true,
			word_no,
		});
	};

	onCloseDrawer = () => {
		this.setState({
			visible: false,
		});
	};

	onChangeDrawer = (e) => {
		this.setState({
			placement: e.target.value,
		});
	};

	onClose = () => {
		this.props.onDecosClose(false);
		this.setState({
			decoVisible: false,
		});
	};

	render() {
		const {
			title,
			text,
			width,
			height,
			selectMenu,
			loading,
			animation,
			dimmed,
			direction,
			visible,
			decoVisible,
			type,
			post,
			loadedBlockTexts,
		} = this.state;
		let _width = width;
		let drawWidth = 250;
		/*
		if (selectMenu === '6' || selectMenu === '8') {
			_width = width - 500;
		} else {
			_width = width - 350;
		}
*/
		let bodyStyle = {};
		let drawTitle = title;
		if (type === 'synonyms') {
			drawTitle = null;
			bodyStyle = Object.assign({}, bodyStyle, { padding: '0px' });
			drawWidth = 500;
		}

		if (type === 'wordinfo') {
			drawTitle = null;
			bodyStyle = Object.assign({}, bodyStyle, { padding: '0px' });
			drawWidth = 250;
		}

		return (
			<Content>
				<div className="caixa">
					<div
						style={
							{
								/*margin: '15px',*/
								/*width: _width,*/
							}
						}>
						<div
							className="maincontent"
							style={{
								/*width: _width - 30*/
								width: _width,
							}}>
							<CustomEditors
								titleContents={title}
								textContents={text}
								getEditorState={this.getEditorState}
							/>
						</div>
						<Drawer
							title={drawTitle}
							placement="right"
							onClose={this.onClose}
							visible={decoVisible}
							mask={false}
							getContainer={false}
							closable={true}
							width={drawWidth}
							bodyStyle={bodyStyle}
							style={{ position: 'absolute', zIndex: 3005 }}>
							{DecoByType(type, post)}
						</Drawer>
					</div>
				</div>
			</Content>
		);
	}
}

MainContainer.propTypes = {
	getEditorState: PropTypes.func,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(MainContainer));
