/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { PriceList } from 'components';
import { Link, withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="ms-service">
          <div className="container p-1">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                      <div className="fs-18 mb-1">낱말 서비스 이용안내</div>
                      <p className="fs-12 text-justify mx-auto min-w-320 max-w-sm">
                        사전, 독서, 낱말퍼즐, 영어, 문장검사 각 서비스의 기능과
                        분류에 대한 자세한 정보를 보실 수 있습니다. 회원별
                        이용가능한 서비스와 이용권 구매시 가능한 서비스를
                        안내합니다.
                      </p>
                    </div>
                    <div className="col-lg-3 text-center pt-2">
                      <p>
                        <span
                          className="main-box-btn-orange"
                          data-toggle="modal"
                          data-target="#modalPriceList">
                          유료 서비스 안내
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="ms-footer">
          <div className="container">
            <div className="row">
              <div className="col">
                <small>
                  <a href="/views/company" target="_self">
                    <span>회사소개</span>
                  </a>{' '}
                  |{' '}
                  <a href="/views/member" target="_self">
                    <span>이용약관</span>
                  </a>{' '}
                  |{' '}
                  <a href="/views/member/policy" target="_self">
                    <span>개인정보취급방침</span>
                  </a>{' '}
                  |{' '}
                  <a
                    href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208716688"
                    target="_blank">
                    <span>사업자정보확인</span>
                  </a>{' '}
                  |{' '}
                  <a href="mailto:master@natmal.com">
                    <span>문의메일</span>
                  </a>{' '}
                  |{' '}
                  <a href="https://blog.naver.com/wordnet21" target="_blank">
                    <img
                      src="/assets/imgs/home/blog_footer-w.png"
                      width="80px"
                    />
                  </a>
                </small>
                <br />
                <small>
                  상호/대표자명: (주)낱말/김기형 사업자등록번호: 220-87-16688
                  통신판매업신고번호: 제 2018-서울송파-0463
                </small>
                <br />
                <small>
                  주소: 서울특별시 송파구 법원로 114 엠스테이트 B동
                  1102호(문정동)
                </small>
                <br />
                <small>
                  전화번호/이메일: 02-3463-2321/mail@natmal.com 팩스:
                  02-3463-6123
                </small>
                <br />
                <small>Copyright &copy; Natmal Corp. 2018</small>
                <br />
                <div>
                  <Link
                    to="#"
                    onClick={() => {
                      window.open('https://twitter.com/NatmalMaster');
                    }}
                    className="menu-item3">
                    <Icon name="twitter" size="big" />
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      window.open(
                        'https://www.instagram.com/koreanword_natmal'
                      );
                    }}
                    className="menu-item3">
                    <Icon name="instagram" size="big" />
                  </Link>
                  <Link
                    to="#"
                    className="menu-item2"
                    onClick={() => {
                      window.open(
                        'https://www.facebook.com/Koreanword_natmal-114795973405603'
                      );
                    }}>
                    <Icon name="facebook" size="big" />
                  </Link>
                  <Link
                    to="#"
                    className="menu-item"
                    onClick={() => {
                      window.open(
                        'https://www.youtube.com/channel/UCfg3Yv8w8dg3b7iVhRBduHQ/featured'
                      );
                    }}>
                    <Icon name="youtube" size="big" />
                    &nbsp;
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-back-top">
            <a
              href="#"
              data-scroll
              id="back-top"
              className="btn-circle btn-circle-warning btn-circle-sm btn-circle-raised ">
              <i className="zmdi zmdi-long-arrow-up" />
            </a>
          </div>
        </footer>
        <PriceList />
      </Fragment>
    );
  }
}

export default withRouter(Footer);
