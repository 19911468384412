import * as types from './ActionTypes';
import * as commonUtil from 'utils/common';
import request from 'superagent';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';
import * as status from 'sentence/types';

Toastr.setDefaultConfig({
  timeOut: 5000,
  closeButton: true,
});

export const getReloadable = (isReload) => ({
  type: types.RELOADABLE,
  isReload: isReload,
});

//하위 추가
export const breadcrumbAdd = (breadcrumbs) => ({
  type: types.BREADCRUMB_ADD,
  breadcrumbs: breadcrumbs,
});

//뎁스에 추가
export const breadcrumbRemoveAdd = (breadcrumb, depth) => ({
  type: types.BREADCRUMB_REMOVE_ADD,
  breadcrumb: breadcrumb,
  depth: depth,
});

//초기화
export const breadcrumbInit = () => ({
  type: types.BREADCRUMB_INIT,
});

// Puzzle Search *********************************************************  2019.01.25  //
export const getSearchPuzzleInfo = (keyword) => ({
  type: types.PUZZLE_SEARCH_KEYWORD,
  keyword: keyword,
});
// *********************************************************************

// Book Search *********************************************************  2018.11.20  //
export const getSearchBookInfo = (keyword) => ({
  type: types.BOOK_SEARCH_KEYWORD,
  searchText: keyword,
});

export const toggleQuickPanel = (value) => ({
  type: types.BOOK_SEARCH_DETAIL_TOGGLE,
  payload: value,
});

export const getSearchBookInfoForLqGrade = (minLq, maxLq) => ({
  type: types.BOOK_SEARCH_LQ_GRADE,
  minLq: minLq,
  maxLq: maxLq,
});

export const getSearchBookInfoForScGrade = (targetGrade, minLq, maxLq) => ({
  type: types.BOOK_SEARCH_SC_GRADE,
  targetGrade: targetGrade,
  minLq: minLq,
  maxLq: maxLq,
});

export const getSearchBookInfoForDetail = (
  fromResults,
  minLq,
  maxLq,
  targetGrade,
  isbnMain,
  isbnSub
) => ({
  type: types.BOOK_SEARCH_DETAIL,
  fromResults: fromResults,
  detailMinLq: minLq,
  detailMmaxLq: maxLq,
  detailTargetGrade: targetGrade,
  detailIsbnMain: isbnMain,
  detailIsbnSub: isbnSub,
});
// *********************************************************************

// Dictionary *********************************************************  2019.03.19  //
export const toggleShowOptions = (value) => ({
  type: types.DICTIONARY_SHOW_OPTIONS,
  showOptions: value,
});

export const saveDicWordBookItem = (userId, dicType, selectedItem) => ({
  type: types.DICTIONARY_SAVE_WORD,
  userId: userId,
  dicType: dicType,
  selectedItem: selectedItem,
});

export const setHistorySelectedItem = (historySelectedItem) => ({
  type: types.DICTIONARY_HISTORY_BACK,
  historySelectedItem: historySelectedItem,
});
// *********************************************************************

export const loginLocal = (user) => {
  return (dispatch) =>
    request('POST', types.LOCAL_API_URL + '/api/login')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .send({ email: user.email, password: user.password })
      .type('json')
      .withCredentials()
      .end(function(err, res) {
        if (res && res.ok) {
          // 성공적으로 로그인 됨. token 저장
          const data = res.body;
          const user = data.user;
          //console.log('loginLocal', user);
          window.parent.postMessage({ loginStatus: true, user }, '*');
          window.sessionStorage.setItem('user', JSON.stringify(user));
          window.localStorage.setItem('user', JSON.stringify(user));
          commonUtil.setLoginEmail(user.userEmail);

          dispatch({
            type: types.AUTHENTICATE_THE_USER,
            authenticated: true,
            user: {
              ...user,
              status: user.status,
              item: user.item,
            },
          });
        } else {
          window.sessionStorage.removeItem('user');
          window.localStorage.removeItem('user');
          window.parent.postMessage({ loginStatus: false }, '*');
          dispatch({
            type: types.NONAUTHENTICATE_THE_USER,
            authenticated: false,
          });
          if (res.body && res.body.error) {
            Toastr.error(res.body.message);
          }
        }
      });
};

export const checkUser = (user) => {
  return (dispatch) =>
    request('POST', types.LOCAL_API_URL + '/api/checkUser')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .send({ email: user.email, password: user.password })
      .type('json')
      .withCredentials()
      .end(function(err, res) {
        //console.log(res);
        if (res.ok) {
          dispatch({ type: types.CHECK_USER, checkUser: true });
        } else {
          dispatch({ type: types.CHECK_USER, checkUser: false });
        }
      });
};

export const validate = (user) => {
  return (dispatch) =>
    request('POST', types.LOCAL_API_URL + '/api/validate')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .send(user)
      .type('json')
      .withCredentials()
      .end(function(err, res) {
        if (res && res.ok) {
          const data = res.body;
          // 성공적으로 로그인 됨. token 저장
          const re_user = data.user;
          //console.log(data);
          if (re_user) {
            dispatch({
              type: types.AUTHENTICATE_THE_USER,
              authenticated: true,
              user: {
                ...re_user,
                status: data.status,
                item: data.item,
              },
            });
          } else {
            window.sessionStorage.removeItem('user');
            window.localStorage.removeItem('user');
            window.parent.postMessage({ loginStatus: false }, '*');
            dispatch({
              type: types.NONAUTHENTICATE_THE_USER,
              authenticated: false,
            });
          }
        } else {
          window.sessionStorage.removeItem('user');
          window.localStorage.removeItem('user');
          window.parent.postMessage({ loginStatus: false }, '*');
          dispatch({
            type: types.NONAUTHENTICATE_THE_USER,
            authenticated: false,
          });
        }
      });
};

export const join = (user) => {
  return (dispatch) =>
    request('POST', types.LOCAL_API_URL + '/api/join')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .send({
        name: user.name,
        phone: user.phone,
        email: user.email,
        password: user.password,
      })
      .type('json')
      .withCredentials()
      .end(function(err, res) {
        const data = res.body;
        if (res.ok) {
          //성공적으로 로그인 됨. token 저장
          Toastr.success(
            '가입하신 메일로 인증메일을 보내드렸습니다. 확인 후 로그인해 주세요.'
          );
          //Toastr.success('가입처리가 정상적으로 완료되었습니다. 로그인 후 서비스를 이용해 주세요.');
          dispatch({ type: types.REGISTER, registered: true });
        } else {
          dispatch({
            type: types.NONAUTHENTICATE_THE_USER,
            authenticated: false,
          });
          if (data.error) {
            Toastr.error(data.message);
          }
        }
      });
};

export const setRegistered = (registered) => ({
  type: types.REGISTER,
  registered: registered,
});

export const find = (user) => {
  return (dispatch) =>
    request('POST', types.LOCAL_API_URL + '/api/find')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .send({ email: user.email })
      .type('json')
      .withCredentials()
      .end(function(err, res) {
        const data = res.body;
        if (res.ok) {
          Toastr.info(
            user.email +
              ' 로 비밀번호 변경을 위한 접속주소를 보냈습니다. 해당 주소 클릭 후 비밀번호를 변경해 주세요.'
          );
          dispatch({
            type: types.NONAUTHENTICATE_THE_USER,
            authenticated: false,
          });
        } else {
          dispatch({
            type: types.NONAUTHENTICATE_THE_USER,
            authenticated: false,
          });
          if (data.error) {
            Toastr.error(data.message);
          }
        }
      });
};

export const certify = (user) => {
  return (dispatch) =>
    request('POST', types.LOCAL_API_URL + '/api/certify')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .send({ email: user.email })
      .type('json')
      .withCredentials()
      .end(function(err, res) {
        const data = res.body;
        if (res.ok) {
          Toastr.info(user.email + ' 로 이메일 인증을 위한 메일을 보냈습니다.');
          dispatch({
            type: types.NONAUTHENTICATE_THE_USER,
            authenticated: false,
          });
        } else {
          dispatch({
            type: types.NONAUTHENTICATE_THE_USER,
            authenticated: false,
          });
          if (data.error) {
            Toastr.error(data.message);
          }
        }
      });
};

export const setChangedPw = (changed) => ({
  type: types.CHANGE_PW,
  changed: changed,
});

export const change = (user) => {
  return (dispatch) =>
    request('POST', types.LOCAL_API_URL + '/api/change')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .send({
        secretId: user.secretId,
        secretKey: user.secretKey,
        password: user.password,
      })
      .type('json')
      .withCredentials()
      .end(function(err, res) {
        const data = res.body;
        if (res.ok) {
          Toastr.info(
            '비밀번호 변경이 완료되었습니다. 다시 로그인해 주시기 바랍니다.'
          );
          dispatch({ type: types.CHANGE_PW, changed: true });
        } else {
          dispatch({ type: types.CHANGE_PW, changed: false });
          if (data.error) {
            Toastr.error(data.message);
          }
        }
      });
};

export const snsLogin = (snsInfo) => {
  return (dispatch) =>
    request('POST', types.LOCAL_API_URL + '/api/sns')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .send({ snsId: snsInfo.token, snsFlag: snsInfo.type })
      .type('json')
      .withCredentials()
      .end(function(err, res) {
        if (res && res.ok) {
          const data = res.body;
          // 성공적으로 로그인 됨. token 저장
          const re_user = data.user;
          if (re_user) {
            window.parent.postMessage(
              { loginStatus: true, user: re_user },
              '*'
            );
            window.sessionStorage.setItem('user', JSON.stringify(re_user));
            window.localStorage.setItem('user', JSON.stringify(re_user));
            dispatch({
              type: types.AUTHENTICATE_THE_USER,
              authenticated: true,
              user: { ...re_user },
            });
          }
        } else {
          dispatch({
            type: types.NONAUTHENTICATE_THE_USER,
            authenticated: false,
          });
          if (res.body.error) {
            Toastr.error(res.body.message);
          }
        }
      });
};

export const loginKakao = () => {
  return (dispatch) =>
    request('GET', types.LOCAL_API_URL + '/api/auth/kakao')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .end(function(err, res) {});
};

//유효하지 않은 접근
export const nonAccess = () => {
  // window.localStorage.removeItem('TOKEN');
  // window.localStorage.removeItem('REFRESH_TOKEN');
  // window.localStorage.removeItem('ID');
  return {
    type: types.NONAUTHENTICATE_THE_USER,
    authenticated: false,
    code: 403,
  };
};

//로그아웃
export const logout = () => {
  return (dispatch) =>
    request('GET', types.LOCAL_API_URL + '/api/logout')
      .set('baseURL', types.LOCAL_API_URL)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .type('json')
      .withCredentials()
      .end(function(err, res) {
        //if (res.ok) {
        window.sessionStorage.removeItem('user');
        window.localStorage.removeItem('user');
        window.parent.postMessage({ loginStatus: false }, '*');
        dispatch({
          type: types.NONAUTHENTICATE_THE_USER,
          authenticated: false,
        });
        //}
      });
};

//문장검사기 액션부
export const onSideBarView = (sideBarOption) => ({
  type: types.SENT_SIDE_BAR,
  sideBarOption,
});

export const onSelectedItem = (selectedItem) => ({
  type: types.SPELL_DECO_SELECTED_ITEM,
  selectedItem: selectedItem,
  status: status.SPELL_EVENT_STATUS.SELECT,
});

export const onReplaceItem = (replaceState, replaceText) => ({
  type: types.SPELL_REPLACE_ITEM,
  replaceItem: replaceState,
  replaceText: replaceText,
  status: status.SPELL_EVENT_STATUS.REPLACE,
});

export const onDisableItem = (disableItem) => ({
  type: types.SPELL_DISABLE_ITEM,
  disableItem: disableItem,
  status: status.SPELL_EVENT_STATUS.DISABLE,
});

export const onAddItem = (decoItem) => ({
  type: types.SPELL_DECOS_ADD,
  decoItem: decoItem,
  status: status.SPELL_EVENT_STATUS.DECO_ADD,
});

export const onRemoveAll = () => ({
  type: types.SPELL_DECOS_REMOVE,
  status: status.SPELL_EVENT_STATUS.REMOVE,
});

export const onRemoveItem = (decoItem) => ({
  type: types.SPELL_DECOS_REMOVE_ITEM,
  decoItem: decoItem,
  status: status.SPELL_EVENT_STATUS.REMOVE_ITEM,
});

export const onDecoDataCreate = (decoDataItem) => ({
  type: types.SPELL_DECODATA_CREATE,
  decoDataItem: decoDataItem,
});

export const onStatusUpdate = () => ({
  type: types.SPELL_DECOS_UPDATE,
  status: status.SPELL_EVENT_STATUS.DECO_UPDATE,
});

export const onChangeText = (text) => ({
  type: types.CHANGE_TEXT,
  text,
});

export const onChangeLqInfosText = (lqInfosText) => ({
  type: types.CHANGE_LQINFOS_TEXT,
  lqInfosText,
});

export const onTaggedText = (lqInfos) => ({
  type: types.LQTAGGING,
  lqInfos,
});

export const onSearchDeco = (highlights) => ({
  type: types.SEARCH_DECO,
  highlights,
});

export const onSpellDeco = (decos) => ({
  type: types.SPELL_DECO,
  decos,
});

export const onSpellSearchFilter = (searchFilter) => ({
  type: types.SPELL_SEARCH_FILTER,
  searchFilter,
});

export const onChangeBlockKey = (blockKeys) => ({
  type: types.CHANGE_BLOCKKEY,
  blockKeys,
});

export const onBlockKeyText = (blockTexts, flag) => ({
  type: types.BLOCKKEY_TEXT,
  blockTexts,
  flag,
});

export const onSelectRightMenu = (selectMenu) => ({
  type: types.SELECT_RIGHT_MENU,
  selectMenu,
});

export const onSynoDeco = (synos) => ({
  type: types.SYNO_DECO,
  synos,
});

export const onSynoFilters = (filters) => ({
  type: types.SYNO_FILTERS,
  filters,
});

export const onHanjasBind = (hanjas) => ({
  type: types.HANJAS_BIND,
  hanjas,
});

export const onDialectDeco = (dialects) => ({
  type: types.DIALECT_DECO,
  dialects,
});

export const onDialectFilters = (dialectFilters) => ({
  type: types.DIALECT_FILTERS,
  dialectFilters,
});

export const onMultiLang = (multilangs) => ({
  type: types.MULTI_LANG,
  multilangs,
});

export const onMultiLangOption = (multioptions) => ({
  type: types.MULTI_LANG_OPTION,
  multioptions,
});

export const onMultiLangSelectedText = (selectedText) => ({
  type: types.MULTI_LANG_SELECTED_TEXT,
  selectedText,
});

export const onHanjaMenuChange = (div) => ({
  type: types.HANJA_MENU_CHANGE,
  div,
});

export const onBlockHighlight = (blocks) => ({
  type: types.BLOCK_HIGHTLIGHT,
  blocks,
});

export const onDocumentsTitleChange = (title) => ({
  type: types.DOCUMENTS_TITLE_CHANGE,
  title,
});

export const onShowModalReport = (visible) => ({
  type: types.SHOW_MODAL_REPORT,
  isReportVisible: visible,
});

export const onBaseInfoSendReport = (datas) => ({
  type: types.BASE_INFO_SEND_REPORT,
  baseInfos: datas,
});

export const onLQScore = (lqscore) => ({
  type: types.LQ_SCORE,
  lqscore: lqscore,
});

export const onCheckLqByPara = (checked) => ({
  type: types.CHECKED_LQ_PARA,
  checked,
});

export const onCheckSpell = (checked) => ({
  type: types.CHECKED_SPELL,
  checked,
});

export const onCheckSyno = (checked) => ({
  type: types.CHECKED_SYNO,
  checked,
});

export const onCheckDialect = (checked) => ({
  type: types.CHECKED_DIALECT,
  checked,
});

export const onDecosOpen = ({ decoVisible, decoType, post }) => ({
  type: types.DECOS_OPEN,
  decoVisible,
  decoType,
  post,
});

export const onDecosClose = ({ decoVisible }) => ({
  type: types.DECOS_CLOSE,
  decoVisible,
});

export const onGetEditorState = () => ({
  type: types.GET_EDITOR_STATE,
});

export const onGetEditorStateOK = () => ({
  type: types.GET_EDITOR_STATE_OK,
});

export const onProcessComplete = (isComplete) => ({
  type: types.PROCESS_COMPLETE,
  isComplete,
});

export const onSentenceSubmit = (text, activeKey) => ({
  type: types.POST_DATA_TEXT,
  text,
  activeKey,
});

export const onSentenceInit = () => ({
  type: types.SENTENCE_INIT,
});
