import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

class BreadCrumb extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      className: props.className
    };
  }

  render(){

    const {className} = this.state;
    return(
      <header className={className?className:'bg-white'}>
        <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-slash pt-1 pb-0">
                    <li className="breadcrumb-item"><Link to="/" style={{color:'#fd7e14'}}>홈</Link></li>
                    {
                      this.props.breadcrumbs.map((breadcrumb,i)=>
                        i === this.props.breadcrumbs.length-1?
                          <li key={'bc'+i} className="breadcrumb-item active"  aria-current="page">{breadcrumb.title}</li>:
                          <li key={'bc'+i} className="breadcrumb-item"><Link style={{color:'#fd7e14'}} to={breadcrumb.path}>{breadcrumb.title}</Link></li>
                      )
                    }
                </ol>
            </nav>
          </div>
          </div>
        </div>
      </header>
    );
  }
  
}

let mapStateToProps = (state) => {
  return {
    breadcrumbs: state.breadcrumb.breadcrumbs
  };
};

export default withRouter(connect(mapStateToProps)(BreadCrumb));