// SessionStorage -----------------------------------------------------//
export const getUserInfo = () => {
	const jsonData = window.localStorage.getItem('user');
	if (jsonData !== undefined && jsonData !== null) {
		const userInfo = JSON.parse(window.localStorage.getItem('user'));
		if (userInfo !== undefined && userInfo !== null) {
			return userInfo;
		} else {
			return null;
		}
	} else {
		return null;
	}
};

export const getUserId = () => {
	const jsonData = window.localStorage.getItem('user');
	if (jsonData !== undefined && jsonData !== null && jsonData !== '') {
		const userInfo = JSON.parse(window.localStorage.getItem('user'));
		if (userInfo !== undefined && userInfo !== null && userInfo.id !== undefined && userInfo.id !== null) {
			return userInfo.id;
		} else {
			return null;
		}
	} else {
		return null;
	}
};

export const hideStrOverLimitCount = (strTxt, limitCount) => {
	let returnStr = '';

	if (strTxt.length > limitCount) {
		returnStr = strTxt.substring(0, limitCount) + '...';
	} else {
		returnStr = strTxt;
	}

	return returnStr;
};

export const isMobile = {
	Android: function() {
		return navigator.userAgent.match(/Android/i) == null ? false : true;
	},
	BlackBerry: function() {
		return navigator.userAgent.match(/BlackBerry/i) == null ? false : true;
	},
	IOS: function() {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null ? false : true;
	},
	Opera: function() {
		return navigator.userAgent.match(/Opera Mini/i) == null ? false : true;
	},
	Windows: function() {
		return navigator.userAgent.match(/IEMobile/i) == null ? false : true;
	},
	any: function() {
		return isMobile.Android() || isMobile.BlackBerry() || isMobile.IOS() || isMobile.Opera() || isMobile.Windows();
	},
};

export const setLoginEmail = (email) => {
	window.localStorage.setItem('loginEmailForNatmal', email);
}

export const getLoginEmail = () => {
	const emailData = window.localStorage.getItem('loginEmailForNatmal');
	if (emailData !== undefined && emailData !== null && emailData !== '') {
		return emailData;
	} else {
		return '';
	}
}

export const setAuthKey = (authKey) => {
	window.localStorage.setItem('natmalUserAuthkey', authKey);
}

export const getAuthKey = () => {
	const authKey = window.localStorage.getItem('natmalUserAuthkey');
	if (authKey !== undefined && authKey !== null && authKey !== '') {
		return authKey;
	} else {
		return '';
	}
}

// PopUp ******************************************************************//
export const getSystemCheckingPopupState = () => {
	const jsonData = window.localStorage.getItem('showSystemCheckingPopup');
	if (jsonData !== undefined && jsonData !== null) {
		return false;
	} else {
		return true;
	}
};

export const setSystemCheckingPopupState = () => {
	window.localStorage.setItem('showSystemCheckingPopup', 'T');
};


export const getEventPopupState = () => {
	const jsonData = window.localStorage.getItem('showOpenEventPopup');
	if (jsonData !== undefined && jsonData !== null) {
		return false;
	} else {
		return true;
	}
};

export const setEventPopupState = () => {
	window.localStorage.setItem('showOpenEventPopup', 'T');
};

export const getCombinePopupState = () => {
	const jsonData = window.localStorage.getItem('ShowCombinePopup');
	if (jsonData !== undefined && jsonData !== null) {
		return false;
	} else {
		return true;
	}
};

export const setCombinePopupState = () => {
	window.localStorage.setItem('ShowCombinePopup', 'T');
};

export const getCongratulationPopupState = () => {
	const jsonData = window.localStorage.getItem('ShowWordnetOpenTempPopup');
	if (jsonData !== undefined && jsonData !== null) {
		return false;
	} else {
		return true;
	}
};

export const setCongratulationPopupState = () => {
	window.localStorage.setItem('ShowWordnetOpenTempPopup', 'T');
};


