import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as service from 'services/groups';
import { Container, Input, Select, Modal, Grid, Table, Responsive, Pagination, Icon } from 'semantic-ui-react';
import * as types from 'types/EtestTypes.js';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';
import './index.css';
const loading = {
	school: false,
};

const search = {
	school: {
		thisPage: 1,
		pageSize: 8,
		gubun: '',
		region: '',
		searchSchulNm: '',
	},
};

class SearchSchool extends Component {
	fetchSchoolLists = async datas => {
		let result = await service.careerApi(this, datas, null);
		if (result && result.status === 200) {
			let datas = result.data.dataSearch.content;
			if (datas.length > 0) {
				let totalPage = parseInt(parseInt(datas[0].totalCount) / search.school.pageSize) + 1;

				this.setState({
					schools: { totalPage, datas },
				});
			} else {
				this.setState({
					schools: { message: '검색하신 내용이 없습니다.' },
				});
			}
		}
		let { loading } = this.state;
		loading['school'] = false;
		this.setState({ loading });
	};

	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			loading,
			search,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open !== nextProps.open) {
			this.setState({ ...nextProps });
			return true;
		}
		return false;
	}

	searchSchoolOnChange = (e, { name, value }) => {
		let { search } = this.state;
		if (search.school.hasOwnProperty(name)) {
			search.school[name] = value;
			this.setState({ search });
		}
	};

	searchOneKeyPress = e => {
		if (e.key === 'Enter') {
			this.searchSchoolOnClick(e);
		}
	};

	searchSchoolOnClick = e => {
		e.preventDefault();

		let { search } = this.state;
		search.school['thisPage'] = 1;
		this.setState({ search });
		this.searchSchoolHandler();
	};

	searchSchoolHandler = () => {
		let { search, loading } = this.state;
		if (!search.school.gubun) {
			Toastr.info('학교구분을 먼저 선택해 주세요.');
			return false;
		}

		loading['school'] = true;
		this.setState({ loading });

		let data = search.school;
		this.fetchSchoolLists(data);
	};

	searchSchoolPaginationChange = (e, { activePage }) => {
		let { search } = this.state;
		search.school['thisPage'] = activePage;
		this.setState({ search });
		this.searchSchoolHandler();
	};

	selectedSchoolItem = schoolItem => {
		const { getSelectedItem = f => f } = this.props;
		this.setState(() => getSelectedItem({ ...schoolItem, schoolGubun: this.state.search.school.gubun }));
	};

	render() {
		const { open, schools, loading } = this.state;

		return (
			<Modal size="small" open={open} onClose={this.props.close}>
				<Modal.Header>
					학교검색 <i aria-hidden="true" className="close icon" onClick={this.props.close} />
				</Modal.Header>
				<Modal.Content>
					<Container style={{ fontSize: '1.2em' }}>
						<Grid columns={16}>
							<Grid.Column width={16}>
								<Select
									name="gubun"
									fluid
									options={types.SCHOOL.GUBUN}
									placeholder="학교구분"
									onChange={this.searchSchoolOnChange}
								/>
							</Grid.Column>
							<Grid.Column width={16}>
								<Select
									name="region"
									fluid
									options={types.SCHOOL.REGION}
									placeholder="지역구분"
									onChange={this.searchSchoolOnChange}
								/>
							</Grid.Column>
							<Grid.Column width={16}>
								<Input
									fluid
									name="searchSchulNm"
									action={{
										size: 'big',
										color: 'teal',
										children: '검색',
										secondary: true,
										onClick: this.searchSchoolOnClick,
									}}
									onKeyPress={this.searchOneKeyPress}
									onChange={this.searchSchoolOnChange}
									icon="search"
									loading={loading.school}
									iconPosition="left"
									placeholder="학교검색"
								/>
							</Grid.Column>
							<Grid.Column width={16}>
								<i className="fas fa-info-circle" />
								&nbsp;사용하실 학교명을 선택해 주세요.
								<Table color="black" key="black" selectable>
									<Responsive as={Table.Header} {...Responsive.onlyComputer}>
										<Table.Row>
											<Table.HeaderCell>학교명</Table.HeaderCell>
											<Table.HeaderCell>주소</Table.HeaderCell>
										</Table.Row>
									</Responsive>
									<Table.Body>
										{schools &&
											schools.datas &&
											schools.datas.map((school, index) => {
												return (
													<Table.Row
														key={'school_' + index}
														onClick={() => this.selectedSchoolItem(school)}>
														<Table.Cell className="cursor-pointer">
															{school.schoolName}
														</Table.Cell>
														<Table.Cell className="cursor-pointer">
															{school.adres}
														</Table.Cell>
													</Table.Row>
												);
											})}
										{schools && schools.message && (
											<Table.Row colSpan="2">
												<Table.Cell>{schools.message}</Table.Cell>
											</Table.Row>
										)}
										<Table.Row>
											<Table.Cell colSpan="2" className="text-center">
												{schools && schools.datas && (
													<Pagination
														activePage={search.school.thisPage}
														ellipsisItem={{
															content: <Icon name="ellipsis horizontal" />,
															icon: true,
														}}
														firstItem={{
															content: <Icon name="angle double left" />,
															icon: true,
														}}
														lastItem={{
															content: <Icon name="angle double right" />,
															icon: true,
														}}
														prevItem={{ content: <Icon name="angle left" />, icon: true }}
														nextItem={{ content: <Icon name="angle right" />, icon: true }}
														boundaryRange={0}
														siblingRange={2}
														pointing
														secondary
														onPageChange={this.searchSchoolPaginationChange}
														totalPages={schools.totalPage}
													/>
												)}
											</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</Grid.Column>
						</Grid>
					</Container>
				</Modal.Content>
			</Modal>
		);
	}
}

SearchSchool.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func,
	getSelectedItem: PropTypes.func,
};

export default SearchSchool;
