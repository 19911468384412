import * as types from 'actions/ActionTypes';

const initalState = {
	decos: [],
	selectedItem: { blockKey: '', start: 0, end: 0, text: '', offsetKey: '', scrollNo: false },
	replaceItem: { blockKey: '', start: 0, end: 0, orgStr: '', text: '' },
	searchFilter: '',
	disableItems: [],
};

const spell = (state = initalState, action) => {
	switch (action.type) {
		case types.SPELL_DECO:
			return Object.assign({}, state, { decos: action.decos });
		case types.SPELL_DECO_SELECTED_ITEM:
			return Object.assign({}, state, { selectedItem: action.selectedItem, status: action.status });
		case types.SPELL_SEARCH_FILTER:
			return Object.assign({}, state, { searchFilter: action.searchFilter });
		case types.SPELL_DISABLE_ITEM:
			if (state.disableItems) {
				const isExist = state.disableItems.indexOf(action.disableItem) !== -1;
				if (!isExist) {
					state.disableItems.push(action.disableItem);
					return Object.assign({}, state, { disableItems: [...state.disableItems], status: action.status });
				} else {
					return Object.assign({}, state, { disableItems: [...state.disableItems], status: action.status });
				}
			} else {
				return Object.assign({}, state, { disableItems: [action.disableItem], status: action.status });
			}
		case types.SPELL_REPLACE_ITEM:
			return Object.assign({}, state, { replaceItem: action.replaceItem, status: action.status });
		default:
			return state;
	}
};

export default spell;
