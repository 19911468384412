import * as types from 'actions/ActionTypes';

const initalState = {
	text: null,
	activeKey: 'tab1',
};

const postData = (state = initalState, action) => {
	switch (action.type) {
		case types.POST_DATA_TEXT:
			return Object.assign({}, state, { text: action.text, activeKey: action.activeKey });
		default:
			return state;
	}
};

export default postData;
