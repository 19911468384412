import * as types from 'actions/ActionTypes';

const initialState = {
	isShow: false,
	isMobileBtnShow: true
};

const mobilemenu = (state = initialState, action) => {
	switch (action.type) {
		case types.MOBILE_MENU_SHOW:
			return { isShow: action.isShow };
		case types.MOBILE_MENU_HIDE:
			return { isSHow: action.isShow };
		case types.MOBILE_LINK_BTN:
			return { isMobileBtnShow: action.isShow };
		default:
			return state;
	}
};

export default mobilemenu;
