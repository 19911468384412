import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import './SubLink.css';

class SubLink extends Component {
	render() {
		return (
			<li className={'nav-item custom ' + (this.props.isActive ? 'active' : '')}>
				{this.props.isOpen && !this.props.isPop && (
					<Link to={this.props.path} className="nav-link s-item-title" onClick={() => this.props.onClick()}>
						<small>{this.props.text}</small>
						{/*<i className="zmdi zmdi-chevron-down"></i> */}
					</Link>
				)}
				{this.props.isPop && (
					<a href={this.props.path} className="nav-link s-item-title">
						<small>{this.props.text}</small>
						{/*<i className="zmdi zmdi-chevron-down"></i> */}
					</a>
				)}
				{!this.props.isOpen && (
					<Link to={'#'} className="nav-link s-item-title" onClick={() => this.props.onClick()}>
						<small>{this.props.text}</small>
						<Label className="main-menu-floating-label" color="orange">
							준비중
						</Label>
						{/*<i className="zmdi zmdi-chevron-down"></i> */}
					</Link>
				)}
			</li>
		);
	}
}

export default withRouter(SubLink);
