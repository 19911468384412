import * as types from 'actions/ActionTypes';

const initalState = {
	selectMenu: '',
};

const menu = (state = initalState, action) => {
	switch (action.type) {
		case types.SELECT_RIGHT_MENU:
			return Object.assign({}, state, { selectMenu: action.selectMenu });
		default:
			return state;
	}
};

export default menu;
