import React, { Component, Fragment } from 'react';
import { Radio, Divider, Checkbox, Row, Tree, Tag } from 'antd';
import treeData from './datas/treeData.json';
import { connect } from 'react-redux';
import * as actions from 'actions';
import './SynoSetup.css';

const mapDispatchToProps = dispatch => ({
	onSynoFilters: filters => dispatch(actions.onSynoFilters(filters)),
	onSelectedItem: selectedItem => dispatch(actions.onSelectedItem(selectedItem)),
});

const { TreeNode } = Tree;

const renderTreeNodes = data =>
	data.map(item => {
		if (item.children) {
			return (
				<TreeNode title={item.title} key={item.key} dataRef={item}>
					{renderTreeNodes(item.children)}
				</TreeNode>
			);
		}
		return <TreeNode title={item.title} key={item.title} dataRef={item} />;
	});

class SynoSetup extends Component {
	state = {
		level: 2,
		base1: false,
		base2: false,
		base3: false,
		checkedKeys: [],
	};

	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			isLoading: props.isLoading,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ isLoading: nextProps.isLoading });
	}

	onRadioChange = e => {
		this.props.onSynoFilters(Object.assign({}, this.state, { [e.target.name]: e.target.value }));
		this.props.onSelectedItem(null);
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	onBaseChange = e => {
		this.props.onSynoFilters(Object.assign({}, this.state, { [e.target.name]: e.target.checked }));
		this.props.onSelectedItem(null);
		this.setState({
			[e.target.name]: e.target.checked,
		});
	};

	onExpand = expandedKeys => {
		// if not set autoExpandParent to false, if children expanded, parent can not collapse.
		// or, you can remove all expanded children keys.
		this.setState({
			expandedKeys,
			autoExpandParent: false,
		});
	};

	onCheck = checkedKeys => {
		this.props.onSynoFilters(Object.assign({}, this.state, { checkedKeys }));
		this.props.onSelectedItem(null);
		this.setState({ checkedKeys });
	};

	render() {
		return (
			<Fragment>
				<div
					style={{
						padding: '8px 10px',
						margin: '0',
						background: '#fff',
					}}>
					<table style={{ padding: '0px', lineHeight: '1.5em', width: '100%' }}>
						<tbody>
							<tr>
								<td colSpan="2">
									<Tag color="" style={{ fontSize: '10px', lineHeight: '12px' }}>
										수준별 추천
									</Tag>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<Radio.Group
										name="level"
										onChange={this.onRadioChange}
										value={this.state.level}
										size="small"
										disabled={this.state.isLoading}>
										<Radio value={2} style={{ marginRight: '0px' }}>
											전체
										</Radio>
										<Radio value={0} style={{ marginRight: '0px' }}>
											어렵게
										</Radio>
										<Radio value={1} style={{ marginRight: '0px' }}>
											쉽게
										</Radio>
									</Radio.Group>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<Divider>AND</Divider>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<Checkbox
										size="small"
										name="base1"
										onChange={this.onBaseChange}
										disabled={this.state.isLoading}>
										일상 어휘
									</Checkbox>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<Checkbox
										size="small"
										name="base2"
										onChange={this.onBaseChange}
										disabled={this.state.isLoading}>
										정서 어휘
									</Checkbox>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<Checkbox
										size="small"
										name="base3"
										onChange={this.onBaseChange}
										disabled={this.state.isLoading}>
										학술 기본 어휘
									</Checkbox>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<Divider>AND</Divider>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<Row gutter={8}>
										<Tree
											checkable
											onExpand={this.onExpand}
											expandedKeys={this.state.expandedKeys}
											autoExpandParent={this.state.autoExpandParent}
											onCheck={this.onCheck}
											checkedKeys={this.state.checkedKeys}
											disabled={this.state.isLoading}>
											{renderTreeNodes(treeData)}
										</Tree>
									</Row>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(SynoSetup);
