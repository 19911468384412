import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as service from 'services/groups';
import { Container, Input, Modal, Grid, Table, Responsive, Pagination, Icon } from 'semantic-ui-react';
import * as types from 'types/OrderTypes';
import './index.css';
const moment = require('moment');
const loading = {
	group: false,
};

const state = {
	total: 0,
	activePage: 1,
	perPage: 8,
	searchName: '',
	selectedItem: 0,
};

class SearchGroup extends Component {
	_isMounted = false;

	fetchGroupLists = async datas => {
		const result = await service.api(this, '/api/groups/myList', datas, null);
		if (result && result.status === 200) {
			const data = result.data;
			const totalPages = Math.ceil(parseInt(data.total) / this.state.perPage);
			if (this._isMounted) {
				this.setState({ total: data.total, totalPages, groups: data.datas });
			}
		}
		let { loading } = this.state;
		loading['group'] = false;
		this.setState({ loading });
	};

	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			loading,
			...state,
		};
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentWillMount() {
		this.fetchGroupLists({ pageSize: state.perPage, offset: state.activePage, searchName: state.searchName });
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open !== nextProps.open) {
			this.setState({ ...nextProps });
			return true;
		}
		return false;
	}

	dataHandler = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
	};

	paginationChange = (e, { activePage }) => {
		const { searchName } = this.state;
		this.setState({ activePage: activePage });
		this.fetchGroupLists({ pageSize: this.state.perPage, offset: activePage, searchName });
	};

	searchOnKeyPress = e => {
		if (e.key === 'Enter') {
			this.searchGroupOnClick(e);
		}
	};

	searchGroupOnClick = e => {
		e.preventDefault();

		let { search } = this.state;
		this.setState({ search });
		this.searchGroupHandler();
	};

	searchGroupHandler = () => {
		const { searchName, loading, activePage } = this.state;

		loading['group'] = true;
		this.setState({ loading });

		this.fetchGroupLists({ pageSize: this.state.perPage, offset: activePage, searchName });
	};

	selectedGroupItem = groupItem => {
		const { getSelectedItem = f => f } = this.props;
		this.setState(() => getSelectedItem({ ...groupItem }));
	};

	render() {
		const { open, groups, loading, activePage, totalPages, searchName } = this.state;

		return (
			<Modal size="small" open={open} onClose={this.props.close}>
				<Modal.Header>
					단체검색 <i aria-hidden="true" className="close icon" onClick={this.props.close} />
				</Modal.Header>
				<Modal.Content>
					<Container style={{ fontSize: '1.2em' }}>
						<Grid columns={16}>
							<Grid.Column width={16}>
								<Input
									fluid
									name="searchName"
									action={{
										size: 'big',
										color: 'teal',
										children: '검색',
										secondary: true,
										onClick: this.searchGroupOnClick,
									}}
									value={searchName}
									onKeyPress={this.searchOnKeyPress}
									onChange={this.dataHandler}
									icon="search"
									loading={loading.group}
									iconPosition="left"
									placeholder="단체명 검색"
								/>
							</Grid.Column>
							<Grid.Column width={16}>
								<i className="fas fa-info-circle" />
								&nbsp;사용하실 단체명을 검색 후 선택해 주세요.
								<Table
									color="black"
									key="black"
									fixed
									selectable
									textAlign="center"
									style={{ position: 'relative', padding: '0px', margin: '0px' }}>
									<Responsive as={Table.Header} minWidth={768}>
										<Table.Row>
											<Table.HeaderCell>No.</Table.HeaderCell>
											<Table.HeaderCell>단체구분</Table.HeaderCell>
											<Table.HeaderCell>단체명</Table.HeaderCell>
											<Table.HeaderCell>등록일</Table.HeaderCell>
										</Table.Row>
									</Responsive>
									<Table.Body>
										{groups &&
											groups.map((group, index) => {
												return (
													<Table.Row
														key={'group_' + index}
														onClick={() => this.selectedGroupItem(group)}>
														<Table.Cell>{group.GROUP_ID}</Table.Cell>
														<Table.Cell>
															{types.GROUPS_DIV[group.DIV.trim()].value}
														</Table.Cell>
														<Table.Cell singleLine>{group.NAME}</Table.Cell>
														<Table.Cell>
															{moment(group.INSERT_DATE).format('YYYY-MM-DD')}
														</Table.Cell>
													</Table.Row>
												);
											})}
										{!groups && (
											<Table.Row>
												<Table.Cell colSpan="4">등록된 단체가 없습니다. </Table.Cell>
											</Table.Row>
										)}
										<Table.Row>
											<Table.Cell colSpan="4" className="text-center">
												{groups && (
													<Pagination
														activePage={activePage}
														ellipsisItem={{
															content: <Icon name="ellipsis horizontal" />,
															icon: true,
														}}
														firstItem={{
															content: <Icon name="angle double left" />,
															icon: true,
														}}
														lastItem={{
															content: <Icon name="angle double right" />,
															icon: true,
														}}
														prevItem={{ content: <Icon name="angle left" />, icon: true }}
														nextItem={{ content: <Icon name="angle right" />, icon: true }}
														boundaryRange={0}
														siblingRange={2}
														pointing
														secondary
														onPageChange={this.paginationChange}
														totalPages={totalPages}
													/>
												)}
											</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</Grid.Column>
						</Grid>
					</Container>
				</Modal.Content>
			</Modal>
		);
	}
}

SearchGroup.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func,
	getSelectedItem: PropTypes.func,
};

export default SearchGroup;
