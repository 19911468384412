import axios from 'axios';
import 'react-app-polyfill/ie11'; // For IE 11 support
import { notification } from 'antd';
import * as types from 'actions/ActionTypes';

export function callSpacePost(contents) {
	//axios.defaults.withCredentials = true;
	return axios.post('http://ai.natmal.com:1025/api/spacechk', { contents: contents });
}

export function callSpellPost(contents) {
	//axios.defaults.withCredentials = true;
	return axios({
		method: 'post',
		url: 'http://spell.natmal.com:8080/api/speller/textbyline',
		contentType: 'application/json',
		dataType: 'json',
		data: { contents: contents },
		headers: {
			Authorization:
				'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJqc3BhcmtAbmF0bWFsLmNvbSIsInNjb3BlcyI6WyJBRE1JTiJdLCJpc3MiOiJodHRwOi8vd3d3Lm5hdG1hbC5jb20iLCJpYXQiOjE1MzcxNTk2MjEsImV4cCI6MTU2ODY5NTYyMX0.2QA-DvRPoT2ngeYFBKxTHYSfBV712cng-XM9bNae4KOAySslBFOv2pIh51c1H2OJ9hunqFgFsyqHNsep4bdJaA',
		},
	});
}

export function callLqAnalysisPost(contents) {
	//axios.defaults.withCredentials = true;
	return axios.post('http://ai.natmal.com:1026/api/lq_analysis', { contents: contents });
}

const openNotificationWithIcon = (type, message, desc) => {
	notification[type]({
		message: message,
		description: desc,
	});
};

export const api = (self, url, datas, callback) => {
	return axios
		.post(types.AI_API_URL + url, {
			params: {
				...datas,
			},
		})
		.then(res => {
			return res;
		})
		.catch(err => {
			if (err.response) {
				const userMessage = err.response.data.userMessage;
				//openNotificationWithIcon('error', '에러', userMessage);
			} else {
				openNotificationWithIcon('error', '에러', '서버에 응답이 없습니다. 잠시 후 다시 접속해 주세요.');
			}
		});
};

export const postApi = (self, url, datas, callback) => {
	return axios
		.post(types.LOCAL_API_URL + url, { contents: datas }, { withCredentials: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			if (err.response) {
				const userMessage = err.response.data.userMessage;
				//openNotificationWithIcon('error', '에러', userMessage);
			} else {
				openNotificationWithIcon('error', '에러', '서버에 응답이 없습니다. 잠시 후 다시 접속해 주세요.');
			}
			if (callback) callback();
		});
};

export const isUsedValidate = () => {
	return new Promise((resolve, reject) => {
		axios.get(types.LOCAL_API_URL + '/api/used/validate', { withCredentials: true }).then(response => {
			if (response.data) {
				resolve(response.data);
			} else {
				reject(response.data);
			}
		});
	});
};
