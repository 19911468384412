import client from '../client';
import axios from 'axios';
import 'react-app-polyfill/ie11'; // For IE 11 support
import * as types from 'actions/ActionTypes';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';

export const careerApi = (self, datas, callback) => {
	return axios
		.get(
			types.CAREER_URL_API,
			{
				params: {
					apiKey: '598656c6c78a14616c50ca0f666a61dd',
					svcType: 'api',
					svcCode: 'SCHOOL',
					contentType: 'json',
					...datas,
				},
			},
			{
				withCredentials: false,
			}
		)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
			Toastr.error('서버에 응답이 없습니다. 잠시 후 다시 접속해 주세요.');
		});
};

export const jusoApi = (self, datas, callback) => {
	return axios
		.get(
			types.JUSO_URL_API,
			{
				params: {
					confmKey: 'U01TX0FVVEgyMDE3MDgzMTA5MTcxNDEwNzI5OTQ=',
					resultType: 'json',
					...datas,
				},
			},
			{
				withCredentials: false,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
			Toastr.error('서버에 응답이 없습니다. 잠시 후 다시 접속해 주세요.');
		});
};

export const apiTest = (self, url, datas, callback) => {
	return axios
		.post(
			types.LOCAL_API_URL + url,
			{
				datas: {
					...datas,
				},
			},
			{
				withCredentials: true,
			}
		)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
			if (err.response) {
				const userMessage = err.response.data.userMessage;
				Toastr.error(userMessage);
			} else {
				Toastr.error('서버에 응답이 없습니다. 잠시 후 다시 접속해 주세요.');
			}
		});
};

export const api = (self, url, datas, callback) => {
	return axios
		.post(
			types.LOCAL_API_URL + url,
			{
				params: {
					...datas,
				},
			},
			{
				withCredentials: true,
			}
		)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
			if (err.response) {
				const userMessage = err.response.data.userMessage;
				Toastr.error(userMessage);
			} else {
				Toastr.error('서버에 응답이 없습니다. 잠시 후 다시 접속해 주세요.');
			}
		});
};

//엑셀양식 다운로드
export const downLoadFile = (filename) => {
	return axios(types.LOCAL_API_URL + '/downloads?filename=' + filename, {
		method: 'GET',
		responseType: 'blob',
	})
		.then((res) => {
			const file = new Blob([res.data], { type: 'application/vnd.ms-excel' });
			const fileURL = URL.createObjectURL(file);
			const link = document.createElement('a');
			link.href = fileURL;
			link.setAttribute('download', 'sample.xlsx');
			document.body.appendChild(link);
			link.click();
		})
		.catch((err) => {
			console.log(err);
		});
};

export const getUpdateBanner = (groupId, bannerStatus) =>
	client.post('/api/groups/bannerUpdate', { groupId, bannerStatus });
