import * as types from 'actions/ActionTypes';

const initialState = {
    searchText: '',
    searchAction: ''
};

const puzzleSearch = function(state = initialState, action) {
    switch(action.type) {
        case types.PUZZLE_SEARCH_KEYWORD:
            return {
                ...state,
                searchText: action.keyword,
                searchAction: 'T'
            }
        default:
            return state;
    }
}

export default puzzleSearch;