/* eslint-disable no-useless-escape */
import { CompositeDecorator } from 'draft-js';
import { DialectSpan } from 'sentence/components/decorators/dialects';

const dialectStrategy = (contentBlock, callback, dialects) => {
	return new Promise(resolve => {
		if (dialects.length > 0) {
			const _dialects = dialects.filter(x => x.blockKey === contentBlock.getKey()).flatMap(x => x.dialect);
			const _orgStr = _dialects
				.filter(x => x.dialectsFilter.length > 0)
				.map(
					d =>
						'([^\uac00-\ud7a3])' +
						d.name.replace(/[\[\]\/\{\}\(\)]/g, '\\$&').trim() +
						'(?=[^\uac00-\ud7a3])'
				)
				.reduce(function(a, b) {
					if (a.indexOf(b) < 0) a.push(b);
					return a;
				}, []);

			if (_orgStr.length > 0) {
				const regex = new RegExp(_orgStr.join('|'), 'g');
				let matchArr, start;
				while ((matchArr = regex.exec(' ' + contentBlock.getText().replace(/[\(\)]/, ' ') + ' ')) !== null) {
					start = matchArr.index;
					resolve(callback(start, start + matchArr[0].trim().length));
				}
				resolve();
			} else {
				resolve();
			}
		} else {
			resolve();
		}
	});
};

export const generateDecorator = dialects => {
	return new CompositeDecorator([
		{
			strategy: async (contentBlock, callback) => await dialectStrategy(contentBlock, callback, dialects),
			component: DialectSpan,
			props: { dialects },
		},
	]);
};
