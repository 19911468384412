import React, { Component, Fragment } from 'react';
import { Icon, Card, Row, Col, Tag, Popover, Button, Tabs } from 'antd';
import { connect } from 'react-redux';
import * as actions from 'actions';
var nlp = require('compromise');
const { TabPane } = Tabs;
const mapDispatchToProps = dispatch => ({
	onBaseInfoSendReport: datas => dispatch(actions.onBaseInfoSendReport(datas)),
});

const styles = {
	limit: { color: 'rgba(193,22,22,0.65)', textAlign: 'right', fontWeight: '500', fontSize: '13px' },
	default: { color: '#135bcd', textAlign: 'right', fontWeight: '500', fontSize: '13px' },
};

const content = (
	<div>
		<p>
			대한안과학회지(2016년 제 57권 제 4호)에 실린 논문을 기준으로 계산 하였으며, 이 연구 결과에 따르면 20~39세
			사이의 한국인이 초등학교 2힉년 교과서에서 선정한 63개의 문장을 읽는 속도는 202.3 WPM(Words per Minutes, 분당
			단어 수)로 추정하였습니다.
		</p>
	</div>
);

class BaseInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lqInfos: [],
			text: props.text,
			nsentence: 0,
			neojul: 0,
			neojul_per_sent: 0,
			read_time: 0,
			nchar: 0,
			nchar_ex: 0,
			npage: 0,
		};
	}

	componentDidMount() {
		const div = document
			.getElementById('custom-tabs')
			.getElementsByClassName('ant-tabs-ink-bar ant-tabs-ink-bar-animated');
		if (div) {
			while (div.length > 0) {
				div[0].parentNode.removeChild(div[0]);
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		const { text } = nextProps;

		if (text != null) {
			let nchar = 0,
				nchar_ex = 0,
				npage = 0,
				nparam = 0,
				nsentence = 0,
				neojul = 0,
				neojul_per_sent = 0,
				read_time = 0;

			//const source = nlp(text);

			nchar = text.length;
			nchar_ex = text.replace(/\s/g, '').length;
			npage = (text.length / 200).toFixed(1);
			//const param = text.match(/[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣|A-Z|a-z]+(\n)/g) || [];
			//nparam = (text.match(/\n/g) || []).length;
			nparam = text.split('\n').length;
			nsentence = text.split('\n').filter(x => {
				return x.trim() !== '' && (x.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|A-Z|a-z]+([\\.|\\!|\\?])/g) || []).length === 0;
			}).length;
			nsentence = nsentence + (text.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|A-Z|a-z]+([\\.|\\!|\\?])/g) || []).length;
			neojul = (text.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|(\w+)]+([\s])/g) || []).length;
			//nsentence = source.sentences().data().length;
			//neojul = source.terms().data().length;
			const n = nsentence === 0 ? 1 : nsentence;
			neojul_per_sent = neojul_per_sent = (neojul / n).toFixed(1);

			const wpm = 202.3; // 폰트사이즈 10 기준
			read_time = ((neojul * 60) / wpm).toFixed(1);

			const data = {
				text: nextProps.text,
				nchar,
				nchar_ex,
				npage,
				nparam,
				nsentence,
				neojul,
				neojul_per_sent,
				read_time,
			};

			this.props.onBaseInfoSendReport(data);
			this.setState(data);
		} else {
			const data = {
				text: nextProps.text,
				nchar: 0,
				nchar_ex: 0,
				npage: 0,
				nparam: 0,
				nsentence: 0,
				neojul: 0,
				neojul_per_sent: 0,
				read_time: 0,
			};
			this.props.onBaseInfoSendReport(data);
			this.setState(data);
		}
	}

	render() {
		const { nsentence, nparam, neojul, neojul_per_sent, read_time, nchar, nchar_ex, npage } = this.state;

		return (
			<Fragment>
				<div id="custom-tabs">
					<Tabs
						defaultActiveKey={[0, 1, 2, 3]}
						tabPosition={'top'}
						style={{ height: 45, backgroundColor: '#fff' }}
						tabBarGutter={0}>
						<TabPane
							tab={
								<div>
									<Tag>문장 수</Tag>
									<span style={styles.default}>{nsentence}</span>
								</div>
							}
							style={{ lineHeight: '0px' }}
							key={1}></TabPane>
						<TabPane
							tab={
								<div>
									<Tag>문단 수</Tag>
									<span style={styles.default}>{nparam}</span>
								</div>
							}
							key={2}></TabPane>
						<TabPane
							tab={
								<div>
									<Tag>글자 수(공백 포함)</Tag>
									<span style={styles.default}>{nchar}</span>
								</div>
							}
							key={3}></TabPane>
						<TabPane
							tab={
								<div>
									<Tag>글자 수(공백 제외)</Tag>
									<span style={styles.default}>{nchar_ex}</span>
								</div>
							}
							key={4}></TabPane>
						<TabPane
							tab={
								<div>
									<Tag>어절 수</Tag>
									<span style={styles.default}>{neojul}</span>
								</div>
							}
							key={5}></TabPane>
						<TabPane
							tab={
								<div>
									<Tag>문장당 평균 어절 수</Tag>
									<span style={neojul_per_sent > 20 ? styles.limit : styles.default}>
										{neojul_per_sent}
									</span>
								</div>
							}
							key={6}></TabPane>
						<TabPane
							tab={
								<div>
									<Tag>
										읽기 시간(초)&nbsp;
										<Popover placement="bottom" title="읽기 시간(초)" content={content}>
											<Icon type="question-circle" theme="filled" />
										</Popover>
									</Tag>
									<span style={styles.default}>{read_time}</span>
								</div>
							}
							key={7}></TabPane>
						<TabPane
							tab={
								<div>
									<Tag>200자 원고지(장)</Tag>
									<span style={styles.default}>{npage}</span>
								</div>
							}
							key={8}></TabPane>
					</Tabs>
				</div>
			</Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(BaseInfo);
