import React, { Component, Fragment } from 'react';
import { Header, Statistic as Stat, TreeMap } from 'sentence/components/reports';
import { Row, Col, Divider } from 'antd';

class Statistic extends Component {
	constructor(props) {
		super(props);

		this.state = {
			color: props.color,
			title: props.title,
			subTitle: props.subTitle,
			desc: props.desc,
			datas: props.datas,
			charts: props.charts,
			width: props.width,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			datas: nextProps.datas,
			charts: nextProps.charts,
			width: nextProps.width,
		});
	}

	render() {
		const { color, title, subTitle, desc, datas, charts, width } = this.state;
		let span = 0;
		if (datas && datas.length > 0) {
			span = parseInt(24 / datas.length);
		}

		return (
			<Fragment>
				<Header color={color} title={title} subTitle={subTitle} />
				{desc && (
					<Row style={{ marginTop: '10px' }}>
						<Col>
							<div
								style={{
									background: '#DDEBF7',
									borderRadius: 5,
									padding: 10,
									verticalAlign: 'middle',
								}}>
								{desc}
							</div>
						</Col>
					</Row>
				)}
				{datas != null && datas.filter(x => x.frequency == null).length > 0 && (
					<Row gutter={8} type="flex" className="flex-container" style={{ marginTop: '10px' }}>
						{datas.map((x, i) => {
							return (
								<Col key={x.name} span={span} className="flex-item" style={{ minWidth: '120px' }}>
									<Stat
										title={x.title}
										subTitle={x.subTitle}
										value={x.value}
										limit={x.limit}
										color={color}
									/>
								</Col>
							);
						})}
					</Row>
				)}
				{datas != null && datas.filter(x => x.frequency != null && x.frequency.length > 0).length > 0 && (
					<Row type="flex" className="flex-container">
						<Col className="flex-item">
							<Row gutter={8} type="flex" style={{ marginTop: '10px' }}>
								{datas.map((x, i) => {
									return (
										<Col key={x.name} span={span} style={{ minWidth: '120px' }}>
											<Stat
												title={x.title}
												subTitle={x.subTitle}
												value={x.value}
												limit={x.limit}
												color={color}
											/>
										</Col>
									);
								})}
							</Row>
							<Row>
								<Col>
									<div style={{ marginTop: '5px' }}>
										<Divider>
											<span
												style={{
													color: 'rgba(0,0,0,1)',
													fontWeight: 500,
												}}>
												상위 5개 다빈도 어휘
											</span>
										</Divider>
									</div>
								</Col>
							</Row>
							<Row gutter={8} type="flex" style={{ marginTop: '10px' }}>
								{datas.map((x, i) => {
									return (
										<Col
											key={x.name}
											span={span}
											className="flex-item"
											style={{ minWidth: '120px' }}>
											{x.frequency &&
												x.frequency.map(y => {
													return (
														<Row key={y.name}>
															<Col>
																{y.name}({y.value})
															</Col>
														</Row>
													);
												})}
										</Col>
									);
								})}
							</Row>
						</Col>
					</Row>
				)}
				{charts != null && charts.length > 0 && (
					<Row style={{ marginTop: '10px' }}>
						<Col>
							<div style={{ height: 150 }}>
								<TreeMap datas={charts} height={150} />
							</div>
						</Col>
					</Row>
				)}
			</Fragment>
		);
	}
}

export default Statistic;
