import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as service from 'services/groups';
import { Container, Input, Modal, Grid, Table, Responsive, Pagination, Icon } from 'semantic-ui-react';
import './index.css';
const loading = {
	user: false,
};

const search = {
	user: {
		thisPage: 1,
		perPage: 8,
		searchName: '',
	},
};

class SearchUser extends Component {
	fetchUserLists = async (datas) => {
		const result = await service.api(this, '/api/user/search', datas, null);
		if (result && result.status === 200) {
			if (result.data.total > 0) {
				const totalPage = Math.ceil(result.data.total / search.user.perPage);
				const datas = result.data.datas;
				this.setState({
					users: { totalPage, datas },
				});
			} else {
				this.setState({
					users: { message: '검색하신 내용이 없습니다.' },
				});
			}
		}
		let { loading } = this.state;
		loading['user'] = false;
		this.setState({ loading });
	};

	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			loading,
			search,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open !== nextProps.open) {
			this.setState({ ...nextProps });
			return true;
		}
		return false;
	}

	searchUserOnChange = (e, { name, value }) => {
		let { search } = this.state;
		if (search.user.hasOwnProperty(name)) {
			search.user[name] = value;
			this.setState({ search });
		}
	};

	searchOneKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.searchUserOnClick(e);
		}
	};

	searchUserOnClick = (e) => {
		e.preventDefault();

		let { search } = this.state;
		this.setState({ search });
		this.searchUserHandler();
	};

	searchUserHandler = () => {
		let { search, loading } = this.state;

		loading['user'] = true;
		this.setState({ loading });

		let data = search.user;
		this.fetchUserLists(data);
	};

	searchUserPaginationChange = (e, { activePage }) => {
		let { search } = this.state;
		search.user['thisPage'] = activePage;
		this.setState({ search });
		this.searchUserHandler();
	};

	selectedUserItem = (userItem) => {
		const { getSelectedItem = (f) => f } = this.props;
		this.setState(() => getSelectedItem({ ...userItem }));
	};

	render() {
		const { open, users, loading } = this.state;

		return (
			<Modal size="small" open={open} onClose={this.props.close}>
				<Modal.Header>
					ID 조회 <i aria-hidden="true" className="close icon" onClick={this.props.close} />
				</Modal.Header>
				<Modal.Content>
					<Container style={{ fontSize: '1.2em' }}>
						<Grid columns={16}>
							<Grid.Column width={16}>
								<Input
									fluid
									name="searchName"
									action={{
										size: 'big',
										color: 'teal',
										children: '검색',
										secondary: true,
										onClick: this.searchUserOnClick,
									}}
									onKeyPress={this.searchOneKeyPress}
									onChange={this.searchUserOnChange}
									icon="search"
									loading={loading.user}
									iconPosition="left"
									placeholder="관리자명 검색"
								/>
							</Grid.Column>
							<Grid.Column width={16}>
								<i className="fas fa-info-circle" />
								&nbsp;사용하실 관리자명을 검색 후 선택해 주세요.
								<Table color="black" key="black" selectable>
									<Responsive as={Table.Header} {...Responsive.onlyComputer}>
										<Table.Row>
											<Table.HeaderCell>사용자명</Table.HeaderCell>
											<Table.HeaderCell>이메일</Table.HeaderCell>
										</Table.Row>
									</Responsive>
									<Table.Body>
										{users &&
											users.datas &&
											users.datas.map((user, index) => {
												return (
													<Table.Row
														key={'user_' + index}
														onClick={() => this.selectedUserItem(user)}>
														<Table.Cell className="cursor-pointer">
															{user.userName}
														</Table.Cell>
														<Table.Cell className="cursor-pointer">
															{user.userEmail}
														</Table.Cell>
													</Table.Row>
												);
											})}
										{users && users.message && (
											<Table.Row colSpan="2">
												<Table.Cell>{users.message}</Table.Cell>
											</Table.Row>
										)}
										<Table.Row>
											<Table.Cell colSpan="2" className="text-center">
												{users && users.datas && (
													<Pagination
														activePage={search.user.thisPage}
														ellipsisItem={{
															content: <Icon name="ellipsis horizontal" />,
															icon: true,
														}}
														firstItem={{
															content: <Icon name="angle double left" />,
															icon: true,
														}}
														lastItem={{
															content: <Icon name="angle double right" />,
															icon: true,
														}}
														prevItem={{ content: <Icon name="angle left" />, icon: true }}
														nextItem={{ content: <Icon name="angle right" />, icon: true }}
														boundaryRange={0}
														siblingRange={2}
														pointing
														secondary
														onPageChange={this.searchUserPaginationChange}
														totalPages={users.totalPage}
													/>
												)}
											</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</Grid.Column>
						</Grid>
					</Container>
				</Modal.Content>
			</Modal>
		);
	}
}

SearchUser.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func,
	getSelectedItem: PropTypes.func,
};

export default SearchUser;
