import React, { Fragment, Component } from 'react';
import { Row, Col } from 'antd';

const styles = {
	limit: { color: 'rgba(193,22,22,0.65)', textAlign: 'right', fontWeight: '600', fontSize: '30px' },
	default: { color: '#135bcd', textAlign: 'right', fontWeight: '600', fontSize: '30px' },
	title: { fontWeight: 500, fontSize: '13px', color: '#000' },
	subTitle: { fontSize: '11px', color: 'rgba(0,0,0,0.65)' },
};

class Statistic extends Component {
	constructor(props) {
		super(props);
		this.state = {
			style: props.style,
			value: props.value,
			limit: props.limit,
			color: props.color,
			title: props.title,
			subTitle: props.subTitle,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			value: nextProps.value,
			limit: nextProps.limit,
		});
	}

	render() {
		const { style, value, limit, color, title, subTitle } = this.state;
		return (
			<Fragment>
				<div style={style}>
					<Row>
						<Col>
							<span
								style={
									value > limit
										? styles.limit
										: color != null
										? Object.assign({}, styles.default, { color: color })
										: styles.default
								}
							>
								{value}
							</span>
						</Col>
					</Row>
					<Row>
						<Col>
							<span style={styles.title}>{title}</span>
						</Col>
					</Row>
					<Row>
						<Col>
							<span style={styles.subTitle}>{subTitle}</span>
						</Col>
					</Row>
				</div>
			</Fragment>
		);
	}
}

export default Statistic;
