import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import 'modern-toastr/dist/modern-toastr.css';
import Main from './main';
import ModifyPass from './modify';

class SearchPassword extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			event: 'main',
		};
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open !== nextProps.open) {
			this.setState({ event: 'main', ...nextProps });
			return true;
		}
		return false;
	}

	onEventHander = (event, selectedItem) => {
		this.setState({ event, selectedItem });
	};

	selectedUserItem = userItem => {
		const { getSelectedItem = f => f } = this.props;
		this.setState(() => getSelectedItem({ ...userItem }));
	};

	onCloseClick = e => {
		const { close = f => f } = this.props;
		this.setState(() => close());
	};

	render() {
		const { open, event } = this.state;

		return (
			<Modal size="tiny" open={open} onClose={this.onCloseClick}>
				{event === 'main' && (
					<Fragment>
						<Modal.Header>
							암호찾기 <i aria-hidden="true" className="close icon" onClick={this.onCloseClick} />
						</Modal.Header>
						<Modal.Content>
							<Main eventHander={this.onEventHander} testerItem={this.props.testerItem} />
						</Modal.Content>
					</Fragment>
				)}
				{event === 'modify' && (
					<Fragment>
						<Modal.Header>
							암호 변경
							<i aria-hidden="true" className="close icon" onClick={this.onCloseClick} />
						</Modal.Header>
						<Modal.Content>
							<ModifyPass
								eventHander={this.onEventHander}
								testerItem={this.props.testerItem}
								close={this.onCloseClick}
							/>
						</Modal.Content>
					</Fragment>
				)}
			</Modal>
		);
	}
}

SearchPassword.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func,
};

export default SearchPassword;
