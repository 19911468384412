import * as types from 'actions/ActionTypes';

const initialState = {
	dialects: [],
	dialectFilters: [],
};

const dialect = (state = initialState, action) => {
	switch (action.type) {
		case types.DIALECT_DECO:
			return Object.assign({}, state, { dialects: action.dialects });
		case types.DIALECT_FILTERS:
			return Object.assign({}, state, { dialectFilters: action.dialectFilters });
		default:
			return state;
	}
};

export default dialect;
