/* eslint-disable no-useless-escape */
import { CompositeDecorator } from 'draft-js';
import { HandleSpan } from 'sentence/components/decorators/spellchecks';

const division = (arr, n) => {
	const len = arr.length;
	const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
	const tmp = [];
	for (var i = 0; i < cnt; i++) {
		tmp.push(arr.splice(0, n));
	}
	return tmp;
};

const findRegex = (lineStart, _decos, lines, x, callback) => {
	return new Promise(resolve => {
		const x_decos = _decos.filter(z => z.key === x.key).flatMap(z => z.spells);
		if (x_decos.length > 0) {
			const regex = new RegExp(
				x_decos.map(d => d.orgStr.replace(/[\[\]?*+|{}\\()@.\n\r]/g, '\\$&').trim()).join('|'),
				'g'
			);
			let matchArr, start;
			while ((matchArr = regex.exec(x.text)) !== null) {
				start = lineStart + matchArr.index;
				resolve(callback(start, start + matchArr[0].trim().length));
			}
		} else {
			resolve();
		}
	});
};

const spellStrategy = (contentBlock, callback, decos) => {
	return new Promise(resolve => {
		if (decos.length > 0) {
			const texts = contentBlock.getText();
			const words = texts.split(' ');
			const lines = division(words, 20).map((x, i) => {
				return { key: i, text: x.join(' ') };
			});
			const _decos = decos.filter(z => z.blockKey === contentBlock.getKey()).flatMap(z => z.filterSpells);
			if (_decos.length > 0) {
				let lineStart;
				resolve(
					Promise.all(
						lines.map(async (x, i) => {
							lineStart = i === 0 ? 0 : lineStart + lines[i - 1].text.length + 1;
							return await findRegex(lineStart, _decos, lines, x, callback);
						})
					)
				);
			}
		} else {
			resolve();
		}
	});
};

export const generateDecorator = decos => {
	return new CompositeDecorator([
		{
			strategy: async (contentBlock, callback) => await spellStrategy(contentBlock, callback, decos),
			component: HandleSpan,
			props: { decos },
		},
	]);
};
