/* eslint-disable no-useless-escape */
import React, { Component, Fragment, createRef } from 'react';
import {
  Input,
  Affix,
  Row,
  Col,
  message,
  Button,
  notification,
  Tag,
  Drawer,
  Spin,
} from 'antd';
import { Map } from 'immutable';
import 'draft-js/dist/Draft.css';
import {
  Editor,
  EditorState,
  EditorBlock,
  getDefaultKeyBinding,
  ContentState,
  Modifier,
  Entity,
  SelectionState,
} from 'draft-js';
import { DecoDictionary } from 'sentence/components/decorators';
import { DecosFunc } from 'sentence/components/utils';
import { connect } from 'react-redux';
import { BaseInfo } from 'sentence/components/menu/RightMenu/informations';
import { withRouter } from 'react-router-dom';
import * as services from 'services/sentence';
import * as actions from 'actions';
import * as gens from 'sentence/components/decorators/generators';
import { sample1, sample2, sample3, sample4 } from './sample';
import PropTypes from 'prop-types';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import { timeThursdays } from 'd3';

const WORDS_DELAY = 1200;
const LQINFOS_DELAY = 5000;
const TEXTS_DELAY = 1200;

let wordstimer, textstimer, lqinfostimer;
let root;

const innerEditor = createRef();

const mapStateToProps = (state) => ({
  div: state.hanjas.div,
  highlights: state.searchDeco.highlights,
  decos: state.spell.decos,
  synos: state.syno.synos,
  dialects: state.dialect.dialects,
  searchFilter: state.spell.searchFilter,
  selectMenu: state.menu.selectMenu,
  replaceItem: state.spell.replaceItem,
  status: state.spell.status,
  filters: state.syno.filters,
  dialectFilters: state.dialect.dialectFilters,
  multioptions: state.multilang.multioptions,
  activeIndex: state.difficulty.activeIndex,
  blocks: state.difficulty.blocks,
  selectedItem: state.spell.selectedItem,
  lqInfos: state.info.lqInfos,
  user: state.auth.user,
  editorStatus: state.editor.editorStatus,
  isComplete: state.process.isComplete,
  decoVisible: state.decos.decoVisible,
});

message.config({
  top: 100,
  duration: 2,
  maxCount: 3,
});

const info = () => {
  message.info('문서를 분석 중입니다.');
};

const info2 = () => {
  message.info(
    '단어를 더블 클릭하시면 단어의 뜻풀이 정보를 확인하실 수 있습니다.'
  );
};

const close = () => {
  //console.log('Notification was closed. Either the close button was clicked or duration time elapsed.');
};

const gotoStdDictionary = (word_no, key) => {
  if (word_no != null && root != null) {
    root.props.onDecosOpen(true, 'synonyms', { dicCode: 'dic-std', word_no });
  }
  notification.close(key);
};

const fetchStdInfos = async (datas) => {
  const sendDatas = datas.map((x) => {
    return { word_no: x.WORD_NO, sup_no: x.WEB_SUP };
  })[0];
  const post = await services.postApi(this, '/api/sentence/stdInfo', sendDatas);
  if (post && post.status === 200) {
    return post.data;
  }
  return null;
};

const openNotification = async (datas) => {
  const results = await fetchStdInfos(datas);
  if (
    results.datas == null ||
    (results.datas != null && results.datas.length === 0)
  )
    return;
  const key = 'noti';
  const btn = window.innerWidth > 500 && (
    <Button
      type="primary"
      size="small"
      onClick={() => gotoStdDictionary(results.datas[0].WORD_NO, key)}
    >
      꼬마사전 더보기
    </Button>
  );
  notification.open({
    message: (
      <div>
        {datas[0].CD_WORD}
        {datas[0].ORG_LANGUAGE && '(' + datas[0].ORG_LANGUAGE + ')'}
      </div>
    ),
    description: (
      <div>
        <Row type="flex">
          <Col>
            <Tag>
              {results.datas[0].PART_SPEECH && results.datas[0].PART_SPEECH}
            </Tag>
          </Col>
          <Col>
            {results.datas[0].DEFINITION && results.datas[0].DEFINITION}
          </Col>
        </Row>
      </div>
    ),
    btn,
    key,
    onClose: close,
    duration: 0,
  });
};

const mapDispatchToProps = (dispatch) => ({
  onChangeText: (text) => dispatch(actions.onChangeText(text)),
  onChangeBlockKey: (blockKeys) =>
    dispatch(actions.onChangeBlockKey(blockKeys)),
  onBlockKeyText: (blockTexts, flag) =>
    dispatch(actions.onBlockKeyText(blockTexts, flag)),
  onDisableItem: (disableItem) => dispatch(actions.onDisableItem(disableItem)),
  onSelectedItem: (selectedItem) =>
    dispatch(actions.onSelectedItem(selectedItem)),
  onMultiLangSelectedText: (selectedText) =>
    dispatch(actions.onMultiLangSelectedText(selectedText)),
  onDocumentsTitleChange: (title) =>
    dispatch(actions.onDocumentsTitleChange(title)),
  onChangeLqInfosText: (lqInfosText) =>
    dispatch(actions.onChangeLqInfosText(lqInfosText)),
  onGetEditorStateOK: () => dispatch(actions.onGetEditorStateOK()),
  onProcessComplete: (isComplete) =>
    dispatch(actions.onProcessComplete(isComplete)),
  onSideBarView: (sideBarOption) =>
    dispatch(actions.onSideBarView(sideBarOption)),
  onDecosOpen: (decoVisible, decoType, post) =>
    dispatch(actions.onDecosOpen({ decoVisible, decoType, post })),
  onDecosClose: (decoVisible) =>
    dispatch(actions.onDecosClose({ decoVisible })),
});

const styles = {
  root: {
    fontSize: 14,
    padding: 20,
    width: 600,
    color: 'black!important',
  },
  editor: {
    cursor: 'text',
    fontSize: 20,
    padding: 0,
    marginTop: 15,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
  },
  controls: {
    fontSize: 25,
    marginBottom: 10,
    userSelect: 'none',
    borderStyle: 'dashed',
    color: '#000',
  },
  styleButton: {
    color: '#999',
    cursor: 'pointer',
    marginRight: 16,
    padding: '2px 0',
  },
};

const customRenderMap = Map({
  unstyled: {
    element: 'div',
    // will be used in convertFromHTMLtoContentBlocks
    aliasedElements: ['p'],
  },
});

const findOne = (datas, key) => {
  const data = datas.filter((item) => item.getKey() === key);
  if (data) return data[0];
  return null;
};

const editor = React.createRef();

const GET_EDITOR_STATE = 'GET_EDITOR_STATE';
const GET_EDITOR_STATE_OK = 'GET_EDITOR_STATE_OK';

class Line extends React.Component {
  render() {
    const { block, contentState } = this.props;
    const lineNumber =
      contentState
        .getBlockMap()
        .toList()
        .findIndex((item) => item.key === block.key) + 1;
    return (
      <div className="line" data-line-number={lineNumber}>
        <div className="line-text">
          <EditorBlock {...this.props} />
        </div>
      </div>
    );
  }
}

const wrapperRef = createRef();

const blockRendererFn = () => ({
  component: Line,
});

class CustomEditors extends Component {
  state = {
    width: 0,
    visible: false,
    placement: 'left',
  };

  fetchLogSave = async (datas) => {
    await services.postApi(this, '/api/sentence-unlimit/spell', datas);
    return true;
  };

  fetchOrderUpdate = async () => {
    await services.postApi(this, '/api/used/usedOk', null);
    return true;
  };

  constructor(props) {
    super(props);

    const editorState = EditorState.createWithContent(
      ContentState.createFromText(props.textContents)
    );
    this.state = {
      editorState,
      width: 0,
      height: 0,
      selectedItem: props.selectedItem,
      text: props.textContents,
      titleContents: '',
      textContents: props.textContents,
      isPasted: false,
      isReplaced: false,
      changeText: false,
      enterChangeText: false,
      isLoading: false,
      isChangeText: false,
      isChangeLqText: false,
      isChangeWords: false,
      start: false,
      selection: null,
      newText: '',
      change: false,
      isLock: false,
      status: props.status,
      loading: false,
      currentPage: 0,
      isComplete: props.isComplete,
      initLoad: false,
      scrollNo: false,
      direction: '',
      lastScrollPos: 0,
      blockTexts: [],
      loadedBlockTexts: [],
      limit: 0,
    };

    //this.myRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onChange = (editorState) => {
      try {
        const content = editorState.getCurrentContent();
        const newText = content.getPlainText();
        this.setState({ editorState, text: newText });
      } catch (e) {
        //console.log(e);
      }
    };

    this.setEditor = (editor) => {
      this.editor = editor;
    };

    /*
		this.focusEditor = () => {
			try {
				if (editor) {
					editor.current.focus();
					//editor.current.editor.addEventListener('compositionend', e => this.handleEvent(e, this));
				}
			} catch (e) {
				//console.log(e);
			}
		};
		*/
    root = this;
  }

  handleEvent(e, callback) {
    if (callback.state.editorState.isInCompositionMode()) {
      this.setState({ compareText: editor.current.editor.innerText });
      if (wordstimer) clearTimeout(wordstimer);
      wordstimer = setTimeout(this.timerTickWords, WORDS_DELAY);
      callback.setState({ text: editor.current.editor.innerText.trim() });
    }
  }

  onChangeTitle = (e) => {
    if (e.target.value.length < 100) {
      this.props.onDocumentsTitleChange(e.target.value);
      this.setState({ title: e.target.value });
    } else {
      message.error('글 제목은 100자 내로 입력해 주세요.');
    }
  };

  onReChange = (editorState) => {
    const content = editorState.getCurrentContent();
    const newText = content.getPlainText();
    this.props.onChangeText(newText);

    this.setState({
      editorState,
      text: newText,
    });
  };

  onHandlePastedText = (text, html) => {
    const { editorState } = this.state;
    if (text != null) {
      const blockMap = ContentState.createFromText(text.trim()).blockMap;
      const newState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        blockMap
      );

      const _editorState = EditorState.push(
        editorState,
        newState,
        'insert-fragment'
      );

      this.onChange(_editorState);
      //if (textstimer) clearTimeout(textstimer);
      //textstimer = setTimeout(this.timerTick, TEXTS_DELAY);
      //if (lqinfostimer) clearTimeout(lqinfostimer);
      //lqinfostimer = setTimeout(this.timerTickLqs, LQINFOS_DELAY);
      return true;
    }
  };

  onKeyBindingFn = (e) => {
    if (e.keyCode === 32) {
      this.setState({ keyStatus: 'SPACE' });
      //if (textstimer) clearTimeout(textstimer);
      //textstimer = setTimeout(this.timerTick, TEXTS_DELAY);
      //if (lqinfostimer) clearTimeout(lqinfostimer);
      //lqinfostimer = setTimeout(this.timerTickLqs, LQINFOS_DELAY);
      return getDefaultKeyBinding(e);
    } else if (e.keyCode === 46) {
      this.setState({ keyStatus: 'DELETE' });
      //if (textstimer) clearTimeout(textstimer);
      //textstimer = setTimeout(this.timerTick, TEXTS_DELAY);
      //if (lqinfostimer) clearTimeout(lqinfostimer);
      //lqinfostimer = setTimeout(this.timerTickLqs, LQINFOS_DELAY);
      return getDefaultKeyBinding(e);
    } else if (e.keyCode === 8) {
      this.setState({ keyStatus: 'BACKSPACE', change: true });
      return getDefaultKeyBinding(e);
    } else if (e.keyCode === 13) {
      this.setState({ keyStatus: 'ENTER', change: true });
      return getDefaultKeyBinding(e);
    } else if (e.keyCode > 189) {
      this.setState({ keyStatus: 'SPECIAL', change: true });
      return getDefaultKeyBinding(e);
    } else {
      this.setState({ keyStatus: 'ETC', change: true });
      return getDefaultKeyBinding(e);
    }
  };

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    //this.focusEditor();

    //setInterval(this.timerTick, TEXTS_INTERVAL);
    //setInterval(this.timerTickWords, WORDS_INTERVAL);
    //setInterval(this.timerTickLqs, LQINFOS_INTERVAL);
    window.addEventListener('resize', this.updateWindowDimensions);
    const selection = this.state.editorState.getSelection();
    this.setState({ selection });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {
    const {
      div,
      highlights,
      decos,
      searchFilter,
      selectMenu,
      synos,
      dialects,
      filters,
      multioptions,
      blocks,
      lqInfos,
      selectedItem,
      titleContents,
      textContents,
      editorStatus,
      isComplete,
    } = nextProps;

    this.setState({
      titleContents,
      textContents,
      text: textContents,
      isComplete,
      searchFilter,
    });

    if (
      this.props.div !== div ||
      (this.props.multioptions !== multioptions && selectMenu === '9') ||
      (this.props.selectMenu !== selectMenu && selectMenu === '7')
    ) {
      const contentBlocks = this.state.editorState
        .getCurrentContent()
        .getBlocksAsArray();
      const blockTexts = [];
      const blockKeys = contentBlocks
        .map((contentBlock) => {
          blockTexts.push({
            blockKey: contentBlock.getKey(),
            blockText: contentBlock.getText(),
          });
          return { blockKey: contentBlock.getKey() };
        })
        .filter((x) => x != null);
      this.props.onBlockKeyText(blockTexts, true);
      this.props.onChangeBlockKey(blockKeys);
      if (this.props.filters !== filters) {
        this.setState({ isLoading: true });
      }
    }

    if (selectMenu === '2' || selectMenu === '3' || selectMenu === '9') {
      if (highlights != null) {
        const _highlight = highlights.map((i) =>
          i.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
        );
        this.setState({
          editorState: EditorState.set(this.state.editorState, {
            decorator: gens.highlights.generateDecorator(
              _highlight,
              selectMenu
            ),
          }),
        });
      } else {
        this.setState({
          editorState: EditorState.set(this.state.editorState, {
            decorator: null,
          }),
        });
      }
    }

    if (selectMenu === '1') {
      if (blocks != null && blocks.length > 0) {
        //if (this.props.blocks !== blocks) {
        this.setState({
          editorState: EditorState.set(this.state.editorState, {
            //decorator: gens.blockHighlights.generateDecorator(blocks, activeIndex),
            decorator: gens.blockHighlights.generateDecorator(blocks),
          }),
          blocks,
          isLoading: false,
        });
        //}
      }
    }

    if (selectMenu === '4' || selectMenu === '7') {
      this.setState({
        editorState: EditorState.set(this.state.editorState, {
          decorator: null,
        }),
      });
    }

    if (selectMenu === '5') {
      if (decos != null && decos.length > 0) {
        this.setState({
          editorState: EditorState.set(this.state.editorState, {
            decorator: gens.spellchecks.generateDecorator(decos),
          }),
          selectedItem,
          decos,
          isLoading: false,
          isChangeSelectedItem: true,
        });
      }
    }

    if (selectMenu === '6') {
      if (synos != null && synos.length > 0) {
        this.setState({
          editorState: EditorState.set(this.state.editorState, {
            decorator: gens.synonyms.generateDecorator(synos),
          }),
          isLoading: false,
          synos,
        });
      }
    }

    if (selectMenu === '8') {
      if (dialects != null && dialects.length > 0) {
        this.setState({
          editorState: EditorState.set(this.state.editorState, {
            decorator: gens.dialects.generateDecorator(dialects),
          }),
          isLoading: false,
          dialects,
        });
      }
    }

    if (this.props.editorStatus !== editorStatus) {
      switch (nextProps.editorStatus) {
        case GET_EDITOR_STATE: {
          this.isEditorState();
          break;
        }
        default:
          break;
      }
    }
    this.setState({ selectMenu, lqInfos, isComplete });
  }

  isEditorState() {
    const { getEditorState = (f) => f } = this.props;
    this.setState(
      () => getEditorState(this.state.editorState),
      () => {
        this.props.onGetEditorStateOK();
      }
    );
  }

  onLoad = () => {
    this.props.onDecosClose(false);
    const { currentPage, blockTexts } = this.state;
    if (!this.state.loading && wrapperRef.current && blockTexts != null) {
      //스크롤 다운 시

      this.props.onProcessComplete(false);
      this.setState({ loading: true, scrollTop: wrapperRef.current.scrollTop });
      //let height = 0;
      const _blockTexts = blockTexts.map((x, i) => {
        //const div = editor.current.editor.children[0].children[i];
        //div.style.display = 'block';
        //height = height + div.clientHeight;
        if (
          //wrapperRef.current.clientHeight * currentPage < height &&
          //height < wrapperRef.current.clientHeight * (currentPage + 1)
          this.state.limit * currentPage <= i &&
          i < this.state.limit * (currentPage + 1)
        ) {
          if (x.status === 'INIT') {
            editor.current.editor.children[0].children[i].style.display =
              'block';
          }
          return Object.assign({}, x, { status: 'REQ' });
        } else {
          if (x.status === 'INIT') {
            editor.current.editor.children[0].children[i].style.display =
              'none';
          }
          return x;
        }
      });
      this.setState(
        {
          blockTexts: _blockTexts,
          currentPage: currentPage + 1,
          direction: 'INIT',
          scrollNo: false,
        },
        () => {
          this.props.onBlockKeyText(_blockTexts, false);
        }
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.textContents !== prevState.textContents) {
      if (
        this.state.textContents != null &&
        this.state.textContents.trim() !== ''
      ) {
        const editorState = EditorState.createWithContent(
          ContentState.createFromText(this.state.textContents)
        );

        const contentBlocks = editorState
          .getCurrentContent()
          .getBlocksAsArray();
        const blockTexts = contentBlocks.map((contentBlock, i) => {
          return {
            lineNum: i + 1,
            blockKey: contentBlock.getKey(),
            blockText: contentBlock.getText(),
            status: 'INIT',
          };
        });

        this.setState({
          editorState,
          text: this.state.textContents,
          blockTexts,
          initLoad: true,
        });
      }
    }

    if (this.state.blockTexts !== prevState.blockTexts) {
      if (
        this.state.blockTexts != null &&
        this.state.blockTexts.length > 0 &&
        editor.current != null &&
        this.state.initLoad
      ) {
        //초기 로드
        this.props.onProcessComplete(false);
        this.setState({ loading: true });
        let height = 0;

        const _blockTexts = this.state.blockTexts.map((x, i) => {
          const div = editor.current.editor.children[0].children[i];
          height = height + div.clientHeight;
          if (height < wrapperRef.current.clientHeight || i === 0) {
            editor.current.editor.children[0].children[i].style.display =
              'block';
            return Object.assign({}, x, { status: 'REQ' });
          } else {
            editor.current.editor.children[0].children[i].style.display =
              'none';
            return x;
          }
        });

        this.setState(
          {
            blockTexts: _blockTexts,
            initLoad: false,
            scrollNo: true,
            currentPage: 1,
            limit: _blockTexts.filter((x) => x.status === 'REQ').length,
          },
          () => {
            this.props.onBlockKeyText(_blockTexts, false);
          }
        );
      }
    }

    if (this.state.isComplete !== prevState.isComplete) {
      if (this.state.isComplete) {
        const { blockTexts } = this.state;
        const _blockTexts = blockTexts.map((x, i) => {
          if (x.status === 'REQ') {
            return Object.assign({}, x, {
              status: 'COMPLETE',
            });
          } else {
            return x;
          }
        });
        const loadedBlockTexts = _blockTexts
          .filter((x) => x.status === 'COMPLETE')
          .map((x) => x.blockText)
          .join('\n');
        this.setState(
          { blockTexts: _blockTexts, loading: false, loadedBlockTexts },
          () => {
            this.props.onBlockKeyText(_blockTexts, false);
          }
        );
      } else {
        this.setState({ loading: true });
      }
    }
    //스크롤 페이지 변경 체크
    if (this.state.direction !== prevState.direction) {
      if (this.state.direction === 'DOWN') {
        this.onLoad();
      }
    }

    if (this.state.isChangeSelectedItem) {
      const { selectedItem, decos } = this.state;
      const _keys = decos
        .map((item, index) => {
          if (
            selectedItem != null &&
            item.orgStr === selectedItem.text &&
            parseInt(item.start) === parseInt(selectedItem.start)
          ) {
            return index;
          } else {
            return null;
          }
        })
        .filter((x) => x != null);

      //if (_keys != null && _keys.length > 0) {
      //	if (this.myRef.current.scrollTop != null) {
      //this.myRef.current.scrollTop = 35 * _keys[0];
      //	}
      //}

      this.setState({ isChangeSelectedItem: false });
    }

    var selectionState = this.state.editorState.getSelection();
    var anchorKey = selectionState.getAnchorKey();
    var currentContent = this.state.editorState.getCurrentContent();
    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var start = selectionState.getStartOffset();
    var end = selectionState.getEndOffset();
    var selectedText = currentContentBlock.getText().slice(start, end);
    var prev_selectionState = prevState.editorState.getSelection();
    var prev_anchorKey = prev_selectionState.getAnchorKey();
    var prev_currentContent = prevState.editorState.getCurrentContent();
    var prev_currentContentBlock =
      prev_currentContent.getBlockForKey(prev_anchorKey);
    var prev_start = prev_selectionState.getStartOffset();
    var prev_end = prev_selectionState.getEndOffset();
    var prev_selectedText = prev_currentContentBlock
      .getText()
      .slice(prev_start, prev_end);
    if (selectedText !== prev_selectedText) {
      if (
        selectedText.trim() !== '' &&
        selectedText.trim().indexOf(' ') === -1 &&
        selectedText.trim() !== this.state.text.trim()
      ) {
        const { lqInfos } = this.state;
        if (lqInfos && lqInfos.length > 0) {
          const datas = lqInfos
            .filter((x) => x.blockKey === anchorKey)
            .flatMap((x) =>
              x.strRateandHomos.filter(
                (i) => i.name.indexOf(selectedText.trim()) >= 0
              )
            );
          if (datas != null && datas.length > 0) {
            notification.destroy();
            openNotification(datas);
          }
        } else {
          info();
        }
      } else {
        notification.destroy();
      }
    }

    /*
		if (this.state.selectMenu !== prevState.selectMenu) {
			this.props.onSelectedItem(null);
			const content = this.state.editorState.getCurrentContent();
			const blockKeys = content
				.getBlocksAsArray()
				.map(contentBlock => {
					if (contentBlock.getText().trim() !== '') {
						return { blockKey: contentBlock.getKey() };
					} else {
						return null;
					}
				})
				.filter(x => x != null);

			this.props.onChangeBlockKey(blockKeys);
		}
*/
    if (prevProps.replaceItem.text !== this.props.replaceItem.text) {
      var line_selectionState = this.state.editorState.getSelection();
      var line_anchorKey = line_selectionState.getAnchorKey();
      var line_currentContent = this.state.editorState.getCurrentContent();
      var line_currentContentBlock =
        line_currentContent.getBlockForKey(line_anchorKey);
      var line = line_currentContentBlock
        .getText()
        .split('.')
        .filter((x) => x.indexOf(this.props.replaceItem.orgStr));

      const USER = JSON.parse(window.sessionStorage.getItem('user'));
      let datas = {
        status: '교체',
        source: this.props.replaceItem.orgStr,
        target: this.props.replaceItem.text,
      };

      if (USER != null) {
        datas = Object.assign({}, datas, { userId: USER.id });
      }
      if (line[0] != null) {
        datas = Object.assign({}, datas, { line: line[0] });
      }
      this.fetchLogSave(datas);
      let content = this.state.editorState.getCurrentContent();
      const entityKey = Entity.create('MENTION', 'IMMUTABLE', {
        name: this.props.replaceItem.text,
      });

      let replaced = Modifier.replaceText(
        content,
        new SelectionState({
          anchorKey: this.props.replaceItem.blockKey,
          anchorOffset: this.props.replaceItem.start,
          focusKey: this.props.replaceItem.blockKey,
          focusOffset: this.props.replaceItem.end,
        }),
        this.props.replaceItem.text,
        null,
        entityKey
      );

      this.setState({
        editorState: EditorState.push(
          this.state.editorState,
          replaced,
          'replace-text'
        ),
        isReplaced: true,
      });
    }

    if (this.state.isReplaced) {
      //교체 저장
      //this.fetchLogSave({})
      this.props.onDisableItem(this.props.replaceItem.orgStr);
      this.props.onSelectedItem(null);
      //	if (textstimer) clearTimeout(textstimer);
      //	textstimer = setTimeout(this.timerTick, TEXTS_DELAY);
      //	if (lqinfostimer) clearTimeout(lqinfostimer);
      //	lqinfostimer = setTimeout(this.timerTickLqs, LQINFOS_DELAY);

      this.setState({ isReplaced: false });
    }
  }

  onHandleClick = (e) => {
    e.preventDefault();
    this.onLoad();
  };

  //스크롤 시 페이지 단위로 조회 하는 곳
  handleScroll = (e) => {
    //const pageNumber = Math.ceil(e.currentTarget.scrollTop / wrapperRef.current.clientHeight);
    if (this.state.lastScrollPos > e.currentTarget.scrollTop) {
      this.setState({
        direction: 'UP',
        lastScrollPos: e.currentTarget.scrollTop,
        /*currentPage: pageNumber,*/
      });
    } else if (this.state.lastScrollPos < e.currentTarget.scrollTop) {
      if (
        e.currentTarget.scrollTop ===
        e.currentTarget.scrollHeight -
          e.currentTarget.clientHeight -
          e.currentTarget.clientTop
      ) {
        this.setState({
          direction: 'DOWN',
          lastScrollPos: e.currentTarget.scrollTop,
          /*currentPage: pageNumber,*/
        });
      }
    }
  };

  onFocus = (e) => {
    e.preventDefault();
    this.props.onSideBarView({ left: 'hide', right: 'hide' });
    if (this.props.decoVisible) {
      this.props.onDecosClose(false);
    }
  };

  render() {
    const { editorState, loading, blockTexts, width, height, scrollNo } =
      this.state;
    const more =
      blockTexts &&
      blockTexts.filter((x) => x.status !== 'COMPLETE').length > 0;
    return (
      <Fragment>
        <div>
          <BaseInfo text={this.state.loadedBlockTexts} />
        </div>
        <Spin tip="처리중..." spinning={loading}>
          <div
            className="mainwraper"
            ref={wrapperRef}
            /*onScroll={this.handleScroll}*/
            onClick={this.onFocus}
            style={{ height: height - 85, overflow: 'auto' }}
          >
            <div className="innertube" style={styles.editor} ref={innerEditor}>
              <Editor
                ref={editor}
                editorState={editorState}
                onFocus={this.onFocus}
                onChange={this.onChange}
                keyBindingFn={this.onKeyBindingFn}
                handlePastedText={this.onHandlePastedText}
                blockRenderMap={customRenderMap}
                blockRendererFn={blockRendererFn}
                textAlignment="justify"
                readOnly={width > 768 ? false : true}
              />
            </div>
          </div>
        </Spin>
        {more && !loading && (
          <div
            style={{
              position: 'fixed',
              zIndex: 3000,
              bottom: 55,
              left: width / 2 - 30,
            }}
          >
            <Button
              type="primary"
              shape="round"
              onClick={(e) => this.onHandleClick(e)}
            >
              더보기
            </Button>
          </div>
        )}
      </Fragment>
    );
  }
}

/*
<div className="framecontentBottom" style={{ width: _width }}>
					<div className="innertube">
						<BaseInfo />
					</div>
				</div>
*/

CustomEditors.propTypes = {
  editorStatus: PropTypes.oneOf([GET_EDITOR_STATE, GET_EDITOR_STATE_OK]),
  getEditorState: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomEditors));
