import React, { Component } from 'react';
import { Home, Main } from './pages';
import KyowonService from './pages/kyowon/index';
import { Link, Route, Switch } from 'react-router-dom';
import { MobileMenu } from 'components';
import ScrollToTop from 'react-router-scroll-top';

import RequireAuth from 'components/auth/require_auth';

import SetenceApp from 'sentence';
import './index.css';
import './App.css';

class App extends Component {
  render() {
    return (
      <ScrollToTop>
        <div className="ms-site-container">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/sentence/modal"
              component={RequireAuth(SetenceApp)}
            />
            <Route path="/views" component={Main} />
            <Route path="/etest/kyowon/:code" component={KyowonService} />
            <Route>
              <div className="w-full h-screen flex flex-col justify-center items-center">
                <div>
                  <img src="/assets/imgs/Logo_40_C.png" />
                </div>
                <h2 className="font-semibold text-2xl mb-3">
                  페이지를 찾을 수 없습니다.
                </h2>
                <h4 className="font-medium text-base mb-5">
                  찾고자 하는 페이지가 존재하지 않거나 삭제되었습니다.
                </h4>
                <Link to="/" className="hover:underline text-lime-600">
                  홈으로 &rarr;
                </Link>
              </div>
            </Route>
          </Switch>
        </div>
        <MobileMenu />
      </ScrollToTop>
    );
  }
}

export default App;
