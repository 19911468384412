import React, { Component, Fragment } from 'react';
import { Button, Tag, Badge, Row, Col, List, Typography, Popover } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'actions';
import * as services from 'services/sentence';
import { DecosFunc } from 'sentence/components/utils';
import './SynonymSpan.css';

const styles = {
	handle: {
		direction: 'ltr',
		unicodeBidi: 'bidi-override',
		color: 'black',
		fontWeight: 'bold',
	},
};

const convertPumsa = pumsa => {
	switch (pumsa) {
		case 'NC':
			return '명사';
		case 'NP':
			return '대명사';
		case 'VV':
			return '동사';
		case 'VA':
			return '형용사';
		case 'MA':
			return '부사';
		default:
			return '';
	}
};

const mapStateToProps = state => ({
	filters: state.syno.filters,
	selectedItem: state.spell.selectedItem,
});

const mapDispatchToProps = dispatch => ({
	onSelectedItem: selectedItem => dispatch(actions.onSelectedItem(selectedItem)),
	onDecosOpen: (decoVisible, decoType, post) => dispatch(actions.onDecosOpen({ decoVisible, decoType, post })),
	onDecosClose: decoVisible => dispatch(actions.onDecosClose({ decoVisible })),
});

const menu = (datas, props, isSelected, callback) => {
	return datas.synoymsFilter.map((x, idx) => {
		/*
		const results = x.synonyms.sort((a, b) => {
			if (a.MEAN == null || b.MEAN == null) return -1;
			return 0;
		});
		*/
		const grouped = DecosFunc.groupBy(x.synonyms, item => item.MEAN);
		const more = x.synonyms.length > 3 && window.innerWidth > 500 ? true : false;
		return (
			<div key={idx}>
				<List
					header={
						<div>
							<Row type="flex" justify="space-between">
								<Col style={{ marginRight: '5px' }}>
									<span>
										<Tag>{x.PUMSA}</Tag>
										<Typography.Text strong>
											{x.PYOJAE_NAME + (x.PYOJAE_HANJA ? '(' + x.PYOJAE_HANJA + ')' : '')}
										</Typography.Text>
									</span>
								</Col>
								<Col>
									<Badge
										style={{ backgroundColor: 'rgb(102, 204, 255)' }}
										count={x.synonyms.length}
									/>
								</Col>
							</Row>
						</div>
					}
					footer={
						more && (
							<Row type="flex" justify="center">
								<Col>
									<Button
										shape="round"
										onClick={e => callback.onClick(e, x.PYOJAE_NO)}
										size={'small'}>
										...더보기
									</Button>
								</Col>
							</Row>
						)
					}
					bordered={false}
					dataSource={grouped}
					locale={{
						emptyText: '조회된 유의어가 없습니다.',
					}}
					renderItem={item => {
						const key = item[0];
						const value = item[1];
						return (
							<div
								key={key ? key : 'NUL'}
								style={{
									marginTop: '10px',
									marginBottom: '10px',
								}}>
								<Row type="flex" justify="space-between">
									<Col>
										<Row type="flex" justify="start">
											<Col>
												<div className="arrow_box">
													<span
														style={{
															fontSize: '9px',
															marginLeft: '3px',
															marginRight: '3px',
														}}>
														의미 범주
													</span>
												</div>
											</Col>
											<Col
												style={{
													color: '#025798',
													marginLeft: '8px',
													marginRight: '5px',
													fontWeight: '500',
													fontStyle: 'italic',
													textAlign: 'left!important',
												}}>
												{key ? <b>{key}</b> : <b>없음</b>}
											</Col>
										</Row>
									</Col>
									<Col>
										<Badge
											size="small"
											count={value.length}
											style={{
												backgroundColor: '#fff',
												color: '#999',
												boxShadow: '0 0 0 1px #d9d9d9 inset',
											}}
										/>
									</Col>
								</Row>
								<Row>
									<Col>
										<div style={{ marginLeft: '8px', marginTop: '5px' }}>
											{value
												.filter((x, k) => k < 3)
												.map((x, k) => {
													return (
														<span key={k} style={{ fontWeight: '500', fontSize: '13' }}>
															{k > 0 ? ' | ' + x.SYN_NAME : x.SYN_NAME}
														</span>
													);
												})}
											{value.length > 3 && <span> | ...</span>}
										</div>
									</Col>
								</Row>
							</div>
						);
					}}
				/>
			</div>
		);
	});
};

class SynonymSpan extends Component {
	state = {
		title: '',
		org: '',
		count: 0,
		pyojae: null,
		more: false,
		visible: false,
		isSelected: false,
		postData: [],
	};

	onClick = (e, pyojae) => {
		e.preventDefault();
		const { filters } = this.state;
		this.props.onDecosOpen(true, 'synonyms', { dicCode: 'dic-syn', filters, pyojae });
		this.hide();
	};

	fetchSynonym = synoymsFilter => {
		const results = synoymsFilter.sort((a, b) => {
			if (a.MEAN == null || b.MEAN == null) return -1;
			return 0;
		});
		const grouped = DecosFunc.groupBy(results, result => result.MEAN);
		this.setState({
			postData: [...grouped.entries()].filter((i, key) => key < 3),
		});
	};

	constructor(props) {
		super(props);
		this.state = {
			...this.state,
		};
	}

	onDropDownClick = e => {
		const { filters, pyojae } = this.state;
		this.fetchSynonym(pyojae.wordno, filters);

		const blockKey = this.props.children[0].props.block.getKey();
		const start = this.props.children[0].props.start;
		const end = start + this.props.decoratedText.length;
		this.props.onSelectedItem({
			blockKey,
			start,
			end,
			text: this.props.decoratedText,
			offsetKey: this.props.offsetKey,
			scrollNo: false,
		});
	};

	componentDidMount() {
		this.setState({ synos: this.props.synos, decoratedText: this.props.decoratedText, changeSynos: true });
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.filters !== nextProps.filters) {
			this.setState({ filters: nextProps.filters });
		}
	}

	hide = () => {
		this.setState({
			visible: false,
		});
	};

	handleVisibleChange = visible => {
		if (visible) {
			const blockKey = this.props.children[0].props.block.getKey();
			const start = this.props.children[0].props.start;
			const end = start + this.props.decoratedText.length;
			this.props.onDecosClose(false);
			this.props.onSelectedItem({
				blockKey,
				start,
				end,
				text: this.props.decoratedText,
				offsetKey: this.props.offsetKey,
				scrollNo: false,
			});
		}
		this.setState({ visible });
	};

	render() {
		const blockKey = this.props.children[0].props.block.getKey();
		const selectedItem = this.props.selectedItem;
		let isSelected = false;
		if (selectedItem && selectedItem.blockKey === blockKey && selectedItem.text === this.props.decoratedText) {
			isSelected = true;
		} else {
			isSelected = false;
		}
		const _datas = this.props.synos
			.filter(z => z.blockKey === blockKey)
			.flatMap(z => z.synos)
			.filter(z => z.name === this.props.decoratedText)
			.filter(z => z.synoymsFilter.length > 0);

		const menuItems = _datas[0];

		if (menuItems != null) {
			return (
				<Fragment>
					<Popover
						trigger="click"
						placement="bottomLeft"
						visible={this.state.visible}
						onVisibleChange={this.handleVisibleChange}
						content={menu(menuItems, this.props, isSelected, this)}>
						<span
							style={styles.handle}
							className={isSelected ? 'selected' : ''}
							id={isSelected ? 'selected' : 'noselected'}>
							{this.props.children}
						</span>
					</Popover>
				</Fragment>
			);
		} else {
			return <span>{this.props.children}</span>;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SynonymSpan));
