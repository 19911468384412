import React, { Component, Fragment } from 'react';
import { Card, Collapse, Badge, List, Tag, Input, Row, Col, Icon } from 'antd';
import { EmptyData, Loading } from 'sentence/components/utils';
import { connect } from 'react-redux';
import * as actions from 'actions';
import * as services from 'services/sentence';
import { DecosFunc } from 'sentence/components/utils';

const Panel = Collapse.Panel;
const Search = Input.Search;

const customPanelStyle = {
	selected: {
		borderRadius: 4,
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: '0!important',
		border: '1px solid #596674',
		overflow: 'hidden',
	},
	unselected: {
		borderRadius: 4,
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: '0!important',
		border: '1px solid #e8e8e8',
		overflow: 'hidden',
	},
};

const mapStateToProps = state => ({
	//multilangs: state.multilang.multilangs,
	multioptions: state.multilang.multioptions,
	selectedText: state.multilang.selectedText,
	lqInfosText: state.info.lqInfosText,
	blockKeys: state.blocks.blockKeys,
	blockTexts: state.blocks.blockTexts,
	flag: state.blocks.flag,
});

const mapDispatchToProps = dispatch => ({
	onSearchDeco: highlights => dispatch(actions.onSearchDeco(highlights)),
});

const SelectedHeader = props => {
	return (
		<Card
			size="small"
			style={{ background: '#596674', border: 0, borderRadius: 0 }}
			bodyStyle={{ padding: 0, margin: 0 }}
			headStyle={{ borderBottom: 0 }}
			title={
				<Row type="flex" justify="start" align="middle" style={{ padding: '0px', margin: '0px' }}>
					<Col span={2}>
						<Badge color="#fff" />
					</Col>
					<Col span={14} style={{ padding: '0px' }}>
						<span style={{ fontWeight: '500', color: '#fff' }}>
							{props.data.SURF} <Icon type="arrow-right" />{' '}
							{props.data.MA.map(x => {
								if (x.ML[0].split(props.div)[0].trim() !== '') {
									return x.BSP[0] + '(' + x.ML[0].split(props.div)[0] + ')';
								} else {
									return x.BSP[0];
								}
							}).join('+')}{' '}
						</span>
					</Col>
				</Row>
			}
		/>
	);
};

const UnSelectedHeader = props => {
	return (
		<Card
			size="small"
			style={{ border: 0 }}
			bodyStyle={{ padding: 0 }}
			title={
				<Row type="flex" justify="start" align="middle" style={{ padding: '0px', margin: '0px' }}>
					<Col span={2}>
						<Badge status="default" />
					</Col>
					<Col span={14} style={{ padding: '0px' }}>
						<span style={{ fontWeight: '500' }}>
							{props.data.SURF} <Icon type="arrow-right" />{' '}
							{props.data.MA.map(x => {
								if (x.ML[0].split(props.div)[0].trim() !== '') {
									return x.BSP[0] + '(' + x.ML[0].split(props.div)[0] + ')';
								} else {
									return x.BSP[0];
								}
							}).join('+')}{' '}
						</span>
					</Col>
				</Row>
			}
		/>
	);
};

class DecoMultiLang extends Component {
	fetchMultiLang = async (block, option, loading) => {
		if (block.blockText == null || (block.blockText != null && block.blockText.trim() === '')) {
			const { multiByKey } = this.state;
			multiByKey[block.blockKey] = null;
			this.setState({ multiByKey, changeMultiLang: loading });
			return;
		}
		const params = { text: block.blockText, option };
		const post = await services.postApi(this, '/api/sentence/multilang', params, null);
		if (post && post.status === 200) {
			const results = post.data.multiLang;
			if (results != null && results.length > 0) {
				let _multilangs = this.state.multiByKey;
				_multilangs[block.blockKey] = results;
				this.setState({ multiByKey: _multilangs, changeMultiLang: loading });
			}
		}
	};

	state = {
		changeMultiLang: false,
		isSearch: false,
		isLoading: false,
		multilangs: [],
		multioptions: 0,
		selectedKey: '1',
		selectedText: '',
		searchName: '',
		multiByKey: [],
	};

	constructor(props) {
		super(props);
		console.log(props.post);
		this.state = Object.assign({}, this.state, {
			title: props.post.title,
			multioptions: props.post != null && props.post.option,
			text: props.lqInfosText,
			blockTexts: props.blockTexts,
			blockKeys: props.blockKeys,
		});
	}

	componentDidMount() {
		if (this.state.text != null && this.state.text.trim() !== '') {
		} else {
			this.setState({ multilangs: [] });
		}

		if (this.state.blockTexts !== null && this.state.blockTexts.length > 0) {
			if (this.state.multioptions != null) {
				this.setState({ isLoading: true });

				Object.keys(this.state.blockTexts).forEach(async (key, i) => {
					let loading = false;
					if (i === Object.keys(this.state.blockTexts).length - 1) loading = true;
					await this.fetchMultiLang(this.state.blockTexts[key], this.state.multioptions, loading);
				});
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		const { lqInfosText, blockKeys, blockTexts, selectedText, multioptions, post, flag } = nextProps;
		this.setState({
			title: post.title,
			text: lqInfosText,
			blockKeys,
			blockTexts,
			selectedText,
			multioptions: post && post.option,
			flag,
		});
		/*
		if (this.props.post.title !== post.title) {
			this.setState({ title: post.title });
		}

		if (this.props.text !== text) {
			this.setState({ text });
		}

		if (this.props.blockKeys !== blockKeys) {
			this.setState({ blockKeys });
		}

		if (this.props.blockTexts !== blockTexts) {
			this.setState({ blockTexts });
		}

		if (this.props.selectedText !== selectedText) {
			this.setState({ selectedText });
		}

		if (this.props.multioptions !== multioptions) {
			console.log(multioptions);
			this.setState({ multioptions });
		}
		*/
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.text !== prevState.text) {
			if (this.state.text != null && this.state.text.trim() !== '') {
			} else {
				this.setState({ multilangs: [] });
			}
		}

		if (this.state.multioptions !== prevState.multioptions) {
			if (this.state.flag) {
				this.setState({ isLoading: true, multilangs: [] });
				Object.keys(this.state.blockTexts).forEach(async (key, i) => {
					let loading = false;
					if (i === Object.keys(this.state.blockTexts).length - 1) loading = true;
					this.fetchMultiLang(this.state.blockTexts[key], this.state.multioptions, loading);
				});
			}
		}

		if (this.state.blockTexts !== prevState.blockTexts) {
			if (!this.state.flag) {
				if (this.state.multioptions != null) {
					if (this.state.blockTexts != null && this.state.blockTexts.length > 0) {
						this.setState({ isLoading: true });
					}

					Object.keys(this.state.blockTexts).forEach(async (key, i) => {
						let loading = false;
						if (i === Object.keys(this.state.blockTexts).length - 1) loading = true;
						this.fetchMultiLang(this.state.blockTexts[key], this.state.multioptions, loading);
					});
				}
			}
		}

		if (this.state.changeMultiLang !== prevState.changeMultiLang) {
			const { blockKeys, multiByKey } = this.state;
			const _multilangs = blockKeys.map(key => multiByKey[key.blockKey]).filter(x => x != null);
			let __multilangs = [].concat(..._multilangs);
			__multilangs = __multilangs.filter(
				items => items.MA.flatMap(x => x.ML).filter(x => x != null && x.trim() !== '').length > 0
			);

			__multilangs = DecosFunc.getUniqueObjectArray(__multilangs, 'SURF');

			if (this.state.searchName != null && this.state.searchName.trim() !== '') {
				this.setState({
					searchlangs: __multilangs.filter(x => x.SURF.trim().indexOf(this.state.searchName.trim()) > -1),
					isSearch: true,
					multilangs: __multilangs,
					changeMultiLang: false,
					isLoading: false,
				});
			} else {
				this.setState({ multilangs: __multilangs, changeMultiLang: false, isLoading: false, isSearch: false });
			}
		}

		if (this.state.selectedText !== prevState.selectedText) {
			this.onSearch(this.state.selectedText);
			this.setState({ searchName: this.state.selectedText });
		}

		if (this.state.searchName !== prevState.searchName) {
			this.onSearch(this.state.searchName);
		}
	}

	callback = key => {
		const { multilangs, isSearch, searchlangs } = this.state;
		let datas = [];
		if (isSearch) datas = searchlangs;
		else datas = multilangs;
		if (key != null && key !== 0) {
			const search = datas[key - 1].SURF;
			const searchs = [];
			searchs.push(search);
			this.props.onSearchDeco(searchs);
		}
		this.setState({ selectedKey: key });
	};

	onSearch = value => {
		const { multilangs } = this.state;
		if (value != null && value.trim() !== '') {
			this.setState({
				searchlangs: multilangs.filter(x => x.SURF.trim().indexOf(value.trim()) > -1),
				isSearch: true,
			});
		} else {
			this.setState({
				isSearch: false,
			});
		}
	};

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		const {
			title,
			multioptions,
			multilangs,
			changeMultiLang,
			isSearch,
			searchlangs,
			searchName,
			isLoading,
			selectedKey,
		} = this.state;
		let datas = [];
		if (isSearch) datas = searchlangs;
		else datas = multilangs;

		let div = ',';
		if (multioptions === 1) div = ';';
		if (multioptions === 2) div = '。';
		if (multioptions === 3) div = ',';
		if (multioptions === 10) div = ';';
		if (multioptions === 11) div = ';';

		return (
			<Fragment>
				<div style={{ height: '100%' }}>
					<Card
						title={
							<div style={{ width: 270 }}>
								<Search
									name="searchName"
									placeholder="검색(Search)"
									onSearch={this.onSearch}
									style={{ width: 270 }}
									value={searchName}
									onChange={e => this.onChange(e)}
								/>
							</div>
						}
						style={{ height: '100%' }}>
						<div className={'decocontent'}>
							{isLoading && <Loading />}
							{!isLoading && datas.length > 0 && (
								<Collapse
									accordion
									bordered={false}
									defaultActiveKey={[selectedKey]}
									onChange={this.callback}>
									{datas.map((multilang, index) => {
										return (
											<Panel
												showArrow={false}
												header={
													parseInt(selectedKey) === index + 1 ? (
														<SelectedHeader data={multilang} div={div} />
													) : (
														<UnSelectedHeader data={multilang} div={div} />
													)
												}
												key={index + 1}
												style={
													parseInt(selectedKey) === index + 1
														? customPanelStyle.selected
														: customPanelStyle.unselected
												}>
												<div
													style={{
														padding: '8px 10px',
														margin: '0',
														background: '#fff',
													}}>
													<List
														dataSource={multilang.MA}
														locale={{
															emptyText: '다국어가 없습니다.',
														}}
														renderItem={item => (
															<List.Item>
																<Card style={{ width: '100%' }}>
																	<div>
																		<Tag
																			color=""
																			style={{
																				fontSize: '10px',
																				lineHeight: '12px',
																			}}>
																			한국어
																		</Tag>
																		{item.BSP[0]}
																	</div>
																	{item.ML[0] && (
																		<div>
																			<Tag
																				color=""
																				style={{
																					fontSize: '10px',
																					lineHeight: '12px',
																				}}>
																				{title}
																			</Tag>
																			{item.ML[0]}
																		</div>
																	)}
																	{item.ML[1] && (
																		<div>
																			<Tag
																				color=""
																				style={{
																					fontSize: '10px',
																					lineHeight: '12px',
																				}}>
																				설명
																			</Tag>
																			{item.ML[1]}
																		</div>
																	)}
																</Card>
															</List.Item>
														)}
													/>
												</div>
											</Panel>
										);
									})}
								</Collapse>
							)}
							{!changeMultiLang && isSearch && searchlangs && searchlangs.length === 0 && (
								<EmptyData message={'검색된 다국어가 없습니다.'} />
							)}
						</div>
					</Card>
				</div>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DecoMultiLang);
