import * as types from 'actions/ActionTypes';

const initalState = {
	blockKeys: [],
	blockTexts: [],
	flag: false,
};

const blocks = (state = initalState, action) => {
	switch (action.type) {
		case types.CHANGE_BLOCKKEY:
			return Object.assign({}, state, { blockKeys: action.blockKeys });
		case types.BLOCKKEY_TEXT:
			return Object.assign({}, state, { blockTexts: action.blockTexts, flag: action.flag });
		default:
			return state;
	}
};

export default blocks;
