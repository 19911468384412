import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

class ExportExcel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			csvData: props.data,
			fileName: props.fileName,
			elements: props.elements,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			csvData: nextProps.data,
			fileName: nextProps.fileName,
			elements: nextProps.elements,
		});
	}

	exportToCSV = (csvData, fileName) => {
		const ws = XLSX.utils.json_to_sheet(csvData);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	};

	render() {
		const { csvData, fileName, elements } = this.state;
		return <button {...elements.props} onClick={e => this.exportToCSV(csvData, fileName)} />;
	}
}

export default ExportExcel;
