import * as types from 'actions/ActionTypes';

const initialBreadCrumbState = {
   breadcrumbs: []
};

const breadcrumb = (state = initialBreadCrumbState, action) => {
    switch(action.type) {
        //하위에 추가 하는 경우
        case types.BREADCRUMB_ADD:
            return {
              breadcrumbs: state.breadcrumbs.concat({...action.breadcrumbs})
            };
        // 같은 뎁스에 추가하는 경우
        case types.BREADCRUMB_REMOVE_ADD:
            if(state.breadcrumbs.length>0){
              state.breadcrumbs.splice(action.depth,1,action.breadcrumb);
              return {
                breadcrumbs: state.breadcrumbs
              };
            }else{
              return {
                breadcrumbs: state.breadcrumbs.concat({...action.breadcrumb})
              };
            }
        case types.BREADCRUMB_INIT:
            return {breadcrumbs:[]};
        default:
            return state;
    }
};

export default breadcrumb;
