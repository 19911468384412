import axios from 'axios';
import 'react-app-polyfill/ie11';
import * as types from 'actions/ActionTypes';

export const getCreatedPuzzle = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/views/puzzleCreate',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getCreatedPuzzleEmpty = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/views/puzzleCreateEmpty',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getWordsearchPuzzleInfo = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/views/wordsearch',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getWordsearchPuzzleResultInfoForFull = () => {
    return axios.post(
        types.LOCAL_API_URL + '/views/puzzleResultForFull',
        { }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getCrosswordPuzzleResultInfoForFull = () => {
    return axios.post(
        types.LOCAL_API_URL + '/views/puzzleResultForFull',
        { }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getCrosswordPuzzleInfo = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/views/crossword',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getPuzzleFinalCategory = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/search/category',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getSearchRandomPuzzleItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/search/randomPuzzle',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getSearchPuzzleListForCategoryId = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/search/categoryId',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getSearchPuzzleListForBook = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/search/book',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getSearchPuzzleListForBookPuzzle = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/search/bookPuzzle',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getSearchPuzzleListForUserPuzzle = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/search/userPuzzle',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getSearchPuzzleListForKeyword = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/search/keyword',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getSearchPuzzleItemsForKeyword = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/search/keywordItems',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export function getPuzzleResultEjsFrame() {
    return axios.post(
        types.LOCAL_API_URL + '/views/getPuzzleResultEjsFrame',
        {},{withCredentials:true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getPuzzleRatingList = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/rating',
        { params },{ withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export function getBookPuzzleResultInfoForStandard() {
    return axios.post(
        types.LOCAL_API_URL + '/views/puzzleResult',
        {}, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getBookPuzzleHistoryItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/history/bookPuzzleItem',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
};

export const getBookPuzzleHistoryList = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/history/bookPuzzleItems',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
};

export const insertPuzzleCrosswordResult = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/history/insertCrosswordResult',
        { params }, { withCredentials: true}        
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
};

export const insertPuzzleWordsearchResult = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/history/insertWordsearchResult',
        { params }, { withCredentials: true}        
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
};

export const getUserPuzzleResultData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/history/resultItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getLastUserPuzzleItems = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/lastUserPuzzleItems',
        { params }, { withCredentials: true}        
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const insertUserPuzzleExpectedQuestionData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/insertQuestion',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const insertUserPuzzleData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/insertUserPuzzle',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserPuzzleExpectedQuestionData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/expectedItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getDeleteUserPuzzleExpectedItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/deleteItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserPuzzleExpectedQuestionItems = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/questionItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserPuzzleExpectedQuestionAllItems = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/questionAllItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getInsertUserPuzzleExpectedQuestionItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/insertQuestionItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getDeleteUserPuzzleExpectedQuestionItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/deleteQuestionItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUpdatedUserPuzzleExpectedQuestionItems = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/updateQuestionItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getDeleteUserPuzzleExpectedQuestionItems = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/deleteQuestionItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUpdatedUserPuzzleExpectedQuestionData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/updateExpectedItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserCreatedPuzzle = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/createPuzzle',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserCreatedPuzzleItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/userPuzzleItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getDeleteUserCreatedPuzzleItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/deleteUserPuzzleItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserCreatedPuzzleData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/userPuzzleItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUpdateUserCreatedPuzzleData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/create/updateUserPuzzleItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserPuzzleResultItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/history/resultItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getDeletePuzzleResultItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/history/deleteResultItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

// English ------------------------------------------------------------------ //

export const getSearchEngPuzzleListForKeyword = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/eng/search/keyword',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getSearchEngPuzzleListForUserPuzzle = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/eng/search/userPuzzle',
        { params }, { withCredentials: true }
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserEngPuzzleExpectedQuestionData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/eng/create/expectedItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUpdatedUserEngPuzzleExpectedQuestionData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/eng/create/updateExpectedItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const insertUserEngPuzzleExpectedQuestionData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/eng/create/insertQuestion',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const insertUserEngPuzzleData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/eng/create/insertUserPuzzle',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserEngCreatedPuzzleData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/eng/create/userPuzzleItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUpdateUserEngCreatedPuzzleData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/eng/create/updateUserPuzzleItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getUserEngPuzzleResultData = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/puzzle/history/resultItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}