import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, Icon, Collapse, Tag, List, Row, Col, Divider, Input, Button } from 'antd';
import { EmptyData, DecosFunc } from 'sentence/components/utils';
import { connect } from 'react-redux';
import * as actions from 'actions';
import './DecosList.css';
import * as services from 'services/sentence';
import { notification } from 'sentence/components/utils';

const Panel = Collapse.Panel;

const customPanelStyle = {
	selected: {
		borderRadius: 4,
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: '0!important',
		border: '1px solid #596674',
		overflow: 'hidden',
	},
	unselected: {
		borderRadius: 4,
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: '0!important',
		border: '1px solid #e8e8e8',
		overflow: 'hidden',
	},
};

const mapDispatchToProps = dispatch => ({
	onReplaceItem: replaceItem => dispatch(actions.onReplaceItem(replaceItem)),
	onDisableItem: disableItem => dispatch(actions.onDisableItem(disableItem)),
	onDecosClose: decoVisible => dispatch(actions.onDecosClose({ decoVisible })),
});

class DecosList extends Component {
	state = {
		decos: [],
	};

	constructor(props) {
		super(props);
		this.state = {
			error: '',
			height: 0,
			post: props.post,
		};
		this.myRef = React.createRef();
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	onClickDisableItem = (e, key) => {
		this.props.onDisableItem(key);
		this.props.onDecosClose(false);
	};

	onChangeError = e => {
		if (e.target.value.length < 100) {
			this.setState({ error: e.target.value });
		} else {
			notification.openNotificationWithIcon('error', '100자 내로 입력해 주세요.', null);
		}
	};

	onReplaceClickItem = (e, item, text) => {
		if (item) {
			this.props.onReplaceItem({
				blockKey: item.blockKey,
				start: item.start,
				end: item.end,
				text: text,
				orgStr: item.orgStr,
			});
			this.props.onDecosClose(false);
		}
	};

	onScroll = e => {};

	componentWillReceiveProps(nextProps) {
		const { post } = nextProps;

		this.setState({ post: post });
	}

	render() {
		const { post, selectedKey } = this.state;

		return (
			<Fragment>
				<div style={{ height: '100%' }}>
					{post && post.datas && (
						<div
							style={{
								margin: '0',
							}}>
							<List
								dataSource={post.datas.candWord}
								locale={{
									emptyText: '대치어가 없습니다.',
								}}
								renderItem={cand => (
									<List.Item onClick={e => this.onReplaceClickItem(e, post.orgData, cand)}>
										<Tag color="" style={{ fontSize: '10px', lineHeight: '12px' }}>
											대치어
										</Tag>
										<b>
											<span style={{ cursor: 'pointer' }}>{cand}</span>
										</b>
									</List.Item>
								)}
							/>
							<Divider />
							<Tag color="" style={{ fontSize: '10px', lineHeight: '12px' }}>
								도움말
							</Tag>
							<span
								dangerouslySetInnerHTML={{
									__html: post.datas.help,
								}}
							/>
							<Divider />
							<Row span={16} type="flex" justify="start" align="middle">
								<Col span={7}>
									<Button
										size="small"
										onClick={e => this.onClickDisableItem(e, post.datas.orgStr)}
										style={{ borderColor: 'green' }}>
										<Icon type="delete" />
										무시하기
									</Button>
								</Col>
								<Col span={1}>&nbsp;</Col>
								<Col span={15}>&nbsp;</Col>
							</Row>
						</div>
					)}
					{!post && (
						<EmptyData message={(post.title === '전체' ? '맞춤법 오류' : post.title) + '가 없습니다.'} />
					)}
				</div>
			</Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(DecosList);
