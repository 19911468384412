import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, Form, Checkbox, Radio, Row, Col, Input } from 'antd';
import { connect } from 'react-redux';
import service from 'services/survey';
const { TextArea } = Input;

let mapStateToProps = state => {
	return {
		authenticated: state.auth.authenticated,
		user: state.auth.user,
	};
};

const Survey = props => {
	const [open, setOpen] = useState(false);
	const [desc, setDesc] = useState();
	const [status, setStatus] = useState('');

	const { getFieldDecorator } = props.form;

	useEffect(() => {
		setStatus('');
	}, [open]);

	const fetchSubmit = datas => {
		service
			.surveyCreate(datas)
			.then(data => {
				if (data.status.trim() === 'success') {
					setStatus(data.status);
				}
			})
			.catch(e => {
				console.log('fail', e);
			});
	};

	const setModalVisible = (e, value) => {
		setOpen(value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				fetchSubmit(values);
			}
		});
	};

	const onChange = ({ target: { value } }) => {
		setDesc(desc);
	};

	if (props.authenticated) {
		return (
			<Fragment>
				<div
					style={{
						textAlign: 'left',
						position: 'fixed',
						width: '40px',
						top: '160px',
						left: '-5px',
						padding: 0,
						margin: 0,
						zIndex: 1000,
					}}>
					<Button type="primary" className="animated rubberBand" onClick={e => setModalVisible(e, true)}>
						소감보내기
					</Button>
				</div>
				<Modal
					title="소감보내기"
					centered
					visible={open}
					width={600}
					onCancel={e => setModalVisible(e, false)}
					footer={null}>
					{status !== 'success' && (
						<article>
							<div className="alert alert-info alert-light alert-dismissible" role="alert">
								<i className="color-info zmdi zmdi-info" /> 회원별 서비스를 위해 아래의 간단한 설문에
								응해 주시면 감사하겠습니다.
							</div>
							<Form onSubmit={handleSubmit}>
								<Form.Item label="낱말 사이트에서 관심이 가는 것을 모두 골라 주세요." colon={false}>
									{getFieldDecorator('interests', {
										rules: [{ required: true, message: '* 하나 이상 선택하세요.', type: 'array' }],
									})(
										<Checkbox.Group style={{ width: '100%' }}>
											<Row>
												<Col span={8}>
													<Checkbox value="0">사전</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox value="1">문장검사</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox value="2">낱말퍼즐</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox value="3">독서</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox value="4">영어</Checkbox>
												</Col>
											</Row>
										</Checkbox.Group>
									)}
								</Form.Item>
								<Form.Item label="개선 요구/문의/의견" colon={false}>
									{getFieldDecorator('desc', {
										rules: [
											{ required: true, message: '* 필수 요소입니다.' },
											{ min: 10, message: '* 최소 10글자 이상 입력하세요.' },
										],
									})(<TextArea onChange={onChange} autosize={{ minRows: 8, maxRows: 8 }} />)}
								</Form.Item>
								<Form.Item label="학생 구분" colon={false}>
									{getFieldDecorator('schooldiv', {
										rules: [{ required: true, message: '* 필수 선택 요소입니다.' }],
									})(
										<Radio.Group style={{ width: '100%' }}>
											<Row>
												<Col span={4}>
													<Radio value="0">초등학생</Radio>
												</Col>
												<Col span={4}>
													<Radio value="1">중학생</Radio>
												</Col>
												<Col span={4}>
													<Radio value="2">고등학생</Radio>
												</Col>
												<Col span={4}>
													<Radio value="3">대학생</Radio>
												</Col>
												<Col span={4}>
													<Radio value="4">일반인</Radio>
												</Col>
											</Row>
										</Radio.Group>
									)}
								</Form.Item>
								<Form.Item label="내/외국인 구분" colon={false}>
									{getFieldDecorator('nation', {
										rules: [{ required: true, message: '* 필수 선택입니다.' }],
									})(
										<Radio.Group style={{ width: '100%' }}>
											<Row>
												<Col span={4}>
													<Radio value="0">내국인</Radio>
												</Col>
												<Col span={4}>
													<Radio value="1">외국인</Radio>
												</Col>
											</Row>
										</Radio.Group>
									)}
								</Form.Item>
								<Form.Item
									label="직업이나 직종은 어떻게 되나요? 해당되는 것을 모두 골라 주세요."
									colon={false}>
									{getFieldDecorator('jobs', {
										rules: [{ required: true, message: '* 하나 이상 선택하세요.', type: 'array' }],
									})(
										<Checkbox.Group style={{ width: '100%' }}>
											<Row>
												<Col span={16}>
													<Checkbox value="0">학생</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="1">인문 및 사회과학 관련직</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="2">법률/언론 관련직</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="3">
														언어 관련 전문직(언어교육 콘텐츠 기획 등)
													</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="4">교육 관련직(교수/교사/강사 등)</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="5">작가 관련직</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="6">기획/영업 관련직</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="7">연구직</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="8">방송인, 기자, 블로거</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="9">학원 운영(독서, 논술 지도등)</Checkbox>
												</Col>
												<Col span={16}>
													<Checkbox value="10">기타</Checkbox>
												</Col>
											</Row>
										</Checkbox.Group>
									)}
								</Form.Item>
								<Form.Item style={{ textAlign: 'center' }}>
									<Button type="primary" htmlType="submit">
										보내기
									</Button>
								</Form.Item>
							</Form>
						</article>
					)}
					{status === 'success' && (
						<article>
							<div className="alert alert-info alert-light alert-dismissible" role="alert">
								<i className="color-info zmdi zmdi-info" /> 소감이 정상적으로 접수되었습니다.
							</div>
							<div style={{ textAlign: 'center' }}>
								<Button type="primary" onClick={e => setModalVisible(e, false)}>
									닫기
								</Button>
							</div>
						</article>
					)}
				</Modal>
			</Fragment>
		);
	} else {
		return null;
	}
};

export default connect(
	mapStateToProps,
	null
)(Form.create({ name: 'validate_other' })(Survey));
