import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Container, Grid, Label, Segment, Dropdown, Input, Form, Message } from 'semantic-ui-react';
import * as service from 'services/groups';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';

const state = {
	testId: '',
	grade: null,
	classes: '',
	num: '',
	name: '',
	password: '',
	confirm: '',
	email: '',
};

class Modify extends Component {
	fetchTesterModify = async datas => {
		const result = await service.api(this, '/api/groups/tests/groupSchool/modify', datas, null);
		if (result && result.status === 200) {
			Toastr.info(result.data.message);
			this.onClickList();
		}
	};

	fetchCheckId = async datas => {
		const result = await service.api(this, '/api/groups/tests/groupSchool/checkId', datas, null);
		if (result && result.status === 200) {
			Toastr.info(result.data.message);
			this.setState({ checkId: true });
		}
		this.setState({ isWarning: false });
	};

	constructor(props) {
		super(props);

		let v = {
			grade: { error: false, message: '학년을 선택해 주세요.' },
			classes: { error: false, message: '반을 입력해 주세요.' },
			num: { error: false, message: '번호를 입력해 주세요.' },
			name: {
				error: false,
				field: '이름',
				message: '이름을 입력해 주세요.',
				maxLength: 6,
				minLength: 2,
			},
			password: {
				error: false,
				field: '암호',
				message: '암호를 입력해 주세요.',
				maxLength: 8,
				minLength: 4,
			},
			confirm: {
				error: false,
				field: '암호',
				message: '암호 확인란을 입력해 주세요.',
				cond: () => this.state.password !== this.state.confirm,
			},
			email: {
				error: false,
				field: '이메일',
				message: '이메일을 입력해 주세요.',
				checkEmail: this.checkEmail,
			},
		};

		const orderItem = props.orderItem;

		let gradeOption = [];
		if (orderItem.SCHOOL_GUBUN.trim() === 'elem_list') {
			gradeOption.push({ key: '1', value: 1, text: '1학년' });
			gradeOption.push({ key: '2', value: 2, text: '2학년' });
			gradeOption.push({ key: '3', value: 3, text: '3학년' });
			gradeOption.push({ key: '4', value: 4, text: '4학년' });
			gradeOption.push({ key: '5', value: 5, text: '5학년' });
			gradeOption.push({ key: '6', value: 6, text: '6학년' });
		} else {
			gradeOption.push({ key: '1', value: 1, text: '1학년' });
			gradeOption.push({ key: '2', value: 2, text: '2학년' });
			gradeOption.push({ key: '3', value: 3, text: '3학년' });
		}

		state.testId = props.selectedItem.TEST_ID;

		const school = props.selectedItem.TestGroupSchools[0];

		state.grade = school.GRADE;
		state.classes = school.CLASS;
		state.num = school.NUM;
		state.name = props.selectedItem.NAME;
		state.email = props.selectedItem.EMAIL;

		this.state = {
			orderItem: props.orderItem,
			v,
			gradeOption,
			isWarning: false,
			isIdWarning: false,
			user: props.selectedItem,
			...state,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open !== nextProps.open) {
			this.setState({ ...nextProps });
			return true;
		}
		return false;
	}

	isHangle = name => {
		// eslint-disable-next-line
		const regexp = /[0-9ㄱ-ㅎㅏ-ㅣ]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;
		return regexp.test(name);
	};

	checkEmail = email => {
		// eslint-disable-next-line
		const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

		if (email.match(regExp) != null) {
			return false;
		} else {
			return true;
		}
	};

	checkId = id => {
		const idReg = /^[a-z]+[a-z0-9]{5,19}$/g;
		return !idReg.test(id);
	};

	dataHandler = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
	};

	changeIdHandler = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
			this.setState({ checkId: false });
		}
		this.setState({ isWarning: false, isIdWarning: false });
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.isWarning !== prevState.isWarning) {
			this.validateHandler();
		}
	}

	validateHandler = () => {
		let v = this.state.v;
		Object.keys(v).map(key => {
			if (this.state.hasOwnProperty(key) && this.state[key] && this.state[key].toString().trim().length > 0) {
				v[key].error = false;
				if (v[key].hasOwnProperty('cond')) {
					v[key].error = v[key].cond(this.state[key].toString());
					if (v[key].error) {
						v[key].message = v[key].field + '와 동일한 ' + v[key].field + '를 입력해 주세요.';
					}
				}
				if (v[key].hasOwnProperty('checkEmail')) {
					v[key].error = v[key].checkEmail(this.state[key].toString());
					if (v[key].error) {
						v[key].message = '알려진 이메일 형식이 아닙니다. 올바른 이메일 주소를 기입해 주세요.';
					}
				}
				if (v[key].hasOwnProperty('checkId')) {
					v[key].error = v[key].checkId(this.state[key].toString());
					if (v[key].error) {
						v[key].message = '아이디는 영문자로 시작하는 6~20자 영문자 또는 숫자이어야 합니다.';
					}
				}
				if (v[key].hasOwnProperty('maxLength')) {
					if (
						this.state[key].toString().length > v[key].maxLength ||
						this.state[key].toString().length < v[key].minLength
					) {
						v[key].error = true;
						v[key].message =
							v[key].field +
							'의 길이는 최소 ' +
							v[key].minLength +
							'자에서 최대 ' +
							v[key].maxLength +
							'자 입니다.';
					}
				}
			} else {
				v[key].error = true;
			}

			return v[key];
		});
		let messages = Object.keys(v)
			.filter(key => v[key].error)
			.map(key => {
				return v[key].message;
			});

		if (messages.length > 0) {
			this.setState({ v, isWarning: true, errors: messages });
			return false;
		} else {
			this.setState({ v, isWarning: false, errors: null });
			return true;
		}
	};

	validateHandlerById = () => {
		let p = this.state.p;
		Object.keys(p).map(key => {
			if (this.state.hasOwnProperty(key) && this.state[key] && this.state[key].toString().trim().length > 0) {
				p[key].error = false;
				if (p[key].hasOwnProperty('checkId')) {
					p[key].error = p[key].checkId(this.state[key].toString());
					if (p[key].error) {
						p[key].message = '아이디는 영문자로 시작하는 6~20자 영문자 또는 숫자이어야 합니다.';
					}
				}
				if (p[key].hasOwnProperty('maxLength')) {
					if (
						this.state[key].toString().length > p[key].maxLength ||
						this.state[key].toString().length < p[key].minLength
					) {
						p[key].error = true;
						p[key].message =
							p[key].field +
							'의 길이는 최소 ' +
							p[key].minLength +
							'자에서 최대 ' +
							p[key].maxLength +
							'자 입니다.';
					}
				}
			} else {
				p[key].error = true;
			}

			return p[key];
		});
		let messages = Object.keys(p)
			.filter(key => p[key].error)
			.map(key => {
				return p[key].message;
			});

		if (messages.length > 0) {
			this.setState({ p, isIdWarning: true, errors: messages });
			return false;
		} else {
			this.setState({ p, isIdWarning: false, errors: null });
			return true;
		}
	};

	previousOnClick = e => {
		e.preventDefault();
		let data = { pageId: 'auths', tabs: 'lq1', currentPage: 1, orderItem: this.state.orderItem };
		this.props.history.push({ pathname: '/views/groups/etests', post: data });
	};

	onSumbit = e => {
		e.preventDefault();
		if (this.validateHandler()) {
			this.fetchTesterModify({ ...this.state });
		}
	};

	onClickList = () => {
		const { eventHander = f => f } = this.props;
		this.setState(() => eventHander('lists'));
	};

	onClickCheckId = e => {
		e.preventDefault();
		const { testId, orderItem } = this.state;

		if (this.validateHandlerById()) {
			this.fetchCheckId({ testId, groupId: orderItem.GROUP_ID });
		}
	};

	render() {
		const {
			testId,
			v,
			errors,
			isWarning,
			grade,
			classes,
			num,
			name,
			confirm,
			gradeOption,
			password,
			email,
		} = this.state;
		return (
			<Container fluid className="m-0 p-0">
				<Grid>
					<Grid.Row style={{ marginLeft: '0px' }}>
						<Grid.Column>
							<Segment>
								<Form warning={isWarning} key="large" size="large">
									<Message warning header="아래 사항을 확인해 주세요." list={errors} />
									<Form.Group inline>
										<Label color="grey">아이디</Label>
										&nbsp;
										<Form.Field width="6">
											<Input name="testId" fluid placeholder="아이디" value={testId} readOnly />
										</Form.Field>
									</Form.Group>
									<Form.Group inline>
										<Label color="grey">&nbsp;암&nbsp;호&nbsp;</Label>
										&nbsp;
										<Form.Field error={v.password.error} width={6}>
											<Input
												name="password"
												type="password"
											placeholder="암호를 입력해 주세요."
												onChange={this.dataHandler}
												value={password}
											/>
										</Form.Field>
									</Form.Group>
									<Form.Group inline>
										<Label color="grey">&nbsp;확&nbsp;인&nbsp;</Label>
										&nbsp;
										<Form.Field error={v.confirm.error} width={6}>
											<Input
												name="confirm"
												type="password"
												placeholder="암호를 다시 한번 입력해 주세요."
												onChange={this.dataHandler}
												value={confirm}
											/>
										</Form.Field>
									</Form.Group>
									<Form.Group inline>
										<Label color="grey">학년</Label>
										&nbsp;
										<Form.Field error={v.grade.error} width={2}>
											<Dropdown
												name="grade"
												options={gradeOption}
												onChange={this.dataHandler}
												value={grade}
												selection
												compact
												placeholder="학년"
											/>
										</Form.Field>
									</Form.Group>
									<Form.Group inline>
										<Label color="grey">&nbsp;&nbsp;반&nbsp;&nbsp;</Label>
										&nbsp;
										<Form.Field error={v.classes.error} width={2}>
											<Input
												name="classes"
												type="number"
												min="1"
												max="30"
												maxLength="2"
												onChange={this.dataHandler}
												value={classes}
												placeholder="반"
											/>
										</Form.Field>
									</Form.Group>
									<Form.Group inline>
										<Label color="grey">번호</Label>
										&nbsp;
										<Form.Field error={v.num.error} width={2}>
											<Input
												name="num"
												type="number"
												min="1"
												max="60"
												maxLength="2"
												placeholder="번호"
												onChange={this.dataHandler}
												value={num}
											/>
										</Form.Field>
									</Form.Group>
									<Form.Group inline>
										<Label color="grey">성명</Label>
										&nbsp;
										<Form.Field error={v.name.error}>
											<Input
												name="name"
												placeholder="이름을 입력해 주세요."
												onChange={this.dataHandler}
												value={name}
												maxLength="5"
											/>
										</Form.Field>
									</Form.Group>

									<Form.Group inline>
										<Label color="grey">이메일</Label>
										&nbsp;
										<Form.Field error={v.email.error} width={6}>
											<Input
												name="email"
												placeholder="이메일을 입력해 주세요."
												onChange={this.dataHandler}
												value={email}
											/>
										</Form.Field>
										<i className="fas fa-info-circle" />
										&nbsp;본인 확인을 위한 이메일 주소를 등록해 주세요.
									</Form.Group>
								</Form>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{ marginLeft: '0px' }}>
						<Grid.Column textAlign="center">
							<Button size="large" onClick={this.onClickList}>
								취소
							</Button>
							<Button size="large" color="twitter" onClick={this.onSumbit}>
								수정
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid />
			</Container>
		);
	}
}

Modify.propTypes = {
	eventHander: PropTypes.func,
};

export default withRouter(Modify);
