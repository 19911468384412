import React, { Component } from 'react';
import { Container, Grid, Form, Label, Message, Input, Accordion, Icon, Segment } from 'semantic-ui-react';
import * as service from 'services/groups';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';
/**
 * 패스워드 초기화 모달
 *
 * @class SearchPassword
 * @extends {Component}
 */
class Main extends Component {
	fetchStatusPass = async datas => {
		const result = await service.api(this, '/api/groups/tests/statusPass', datas, null);
		if (result && result.status === 200) {
			this.setState({ btnCode: result.data.code });
		}
	};

	fetchSendCertedNum = async datas => {
		const result = await service.api(this, '/api/groups/tests/certedCode', datas, null);

		if (result && result.status === 200) {
			Toastr.info(result.data.message);
			this.setState({ certedCode: result.data.code });
		}
	};

	fetchInitPass = async datas => {
		const result = await service.api(this, '/api/groups/tests/initPass', datas, null);
		if (result && result.status === 200) {
			this.setState({ isMessage: true, messages: result.data.messages, btnCode: 2 });
		}
	};

	constructor(props) {
		super(props);

		const v = { authnum: { error: false, message: '인증번호를 입력해 주세요.' } };

		this.state = {
			testerItem: props.testerItem,
			activeIndex: 0,
			v,
			certedCode: 0,
			authnum: '',
			isWarning: false,
			isMessage: false,
			btnCode: 0,
		};
	}

	componentDidMount() {
		const { testerItem } = this.state;
		this.fetchStatusPass({ id: testerItem.PSN_ID });
	}

	validateHandler = () => {
		let v = this.state.v;
		Object.keys(v).map(key => {
			if (this.state.hasOwnProperty(key) && this.state[key] && this.state[key].toString().trim().length > 0) {
				v[key].error = false;
			} else {
				if (v[key].hasOwnProperty('cond')) {
					v[key].error = v[key].cond();
				} else {
					v[key].error = true;
				}
			}

			return v[key];
		});
		let messages = Object.keys(v)
			.filter(key => v[key].error)
			.map(key => {
				return v[key].message;
			});

		if (messages.length > 0) {
			this.setState({ v, isWarning: true, errors: messages });
			return false;
		} else {
			this.setState({ v, isWarning: false, errors: null });
			return true;
		}
	};

	dataHandler = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
	};

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	onKeyPress = e => {
		if (e.key === 'Enter') {
			this.onClickDoCheckCode(e);
		}
	};

	onClickMailSubmit = e => {
		e.preventDefault();
		this.fetchSendCertedNum({ user: this.state.testerItem });
	};

	onClickDoCheckCode = e => {
		e.preventDefault();
		if (this.validateHandler()) {
			if (this.state.authnum === this.state.certedCode) {
				Toastr.info('본인 인증이 확인되었습니다.');
				const { eventHander = f => f } = this.props;
				this.setState(() => eventHander('modify'));
			} else {
				Toastr.warn('인증번호가 맞지 않습니다. 인증번호를 다시 확인해 주세요.');
			}
		}
	};

	onClickInit = e => {
		e.preventDefault();
		const { testerItem } = this.state;
		this.fetchInitPass({ id: testerItem.PSN_ID });
	};

	onClickPassChange = e => {
		e.preventDefault();
		const { eventHander = f => f } = this.props;
		this.setState(() => eventHander('modify'));
	};

	render() {
		const { testerItem, activeIndex, isWarning, errors, authnum, v, isMessage, messages, btnCode } = this.state;
		if (!testerItem) return '';
		return (
			<Container style={{ fontSize: '1.2em' }}>
				<Form warning={isWarning} size="large">
					<Message warning header="아래 사항을 확인해 주세요." list={errors} />
					<Message color="green" hidden={!isMessage} header="아래 사항을 확인해 주세요." list={messages} />
					<Grid widths={16}>
						<Grid.Row style={{ marginLeft: '0px' }}>
							<Grid.Column>
								<Accordion fluid>
									<Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
										<Icon name="dropdown" />
										<Label size="big" color="blue" content="암호가 기억나지 않으세요?" />
									</Accordion.Title>
									<Accordion.Content active={activeIndex === 0}>
										<Segment>
											<Message
												info
												list={[
													'발송 버튼을 클릭하시면 등록하신 이메일로 인증번호가 발송됩니다.',
													'발급받은 인증번호로 본인인증 후 암호를 변경하실 수 있습니다.',
												]}
											/>
											<Form.Group inline>
												<Label color="grey">&nbsp;응&nbsp;시&nbsp;자&nbsp;</Label>
												<Form.Field>&nbsp;</Form.Field>
												<Form.Field>
													<Input
														readOnly
														value={testerItem.TEST_ID}
														action={{
															size: 'large',
															children: '발송',
															onClick: this.onClickMailSubmit,
														}}
													/>
												</Form.Field>
											</Form.Group>
											<Form.Group inline>
												<Label color="grey">인증&nbsp;번호</Label>
												<Form.Field>&nbsp;</Form.Field>
												<Form.Field error={v.authnum.error}>
													<Input
														name="authnum"
														type="number"
														placeholder="인증번호"
														maxLength="6"
														value={authnum}
														action={{
															size: 'large',
															children: '인증하기',
															onClick: this.onClickDoCheckCode,
														}}
														onChange={this.dataHandler}
														onKeyPress={this.onKeyPress}
													/>
												</Form.Field>
											</Form.Group>
										</Segment>
									</Accordion.Content>
									<Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
										<Icon name="dropdown" />
										<Label
											size="big"
											color="blue"
											content="등록한 이메일로 인증번호를 발송하실 수 없나요?"
										/>
									</Accordion.Title>
									<Accordion.Content active={activeIndex === 1}>
										<Segment>
											<Message
												info
												list={[
													'초기화 버튼을 클릭하여 담당자에게 암호 초기화 요청을 해주세요.',
													'담장자가 초기화 수락 후 암호를 변경 할 수 있습니다.',
												]}
											/>
											<Form.Group inline>
												<Label color="grey">&nbsp;응&nbsp;시&nbsp;자&nbsp;</Label>
												<Form.Field>&nbsp;</Form.Field>
												<Form.Field>
													{btnCode === 0 && (
														<Input
															readOnly
															value={testerItem.TEST_ID}
															action={{
																size: 'large',
																children: '초기화',
																onClick: this.onClickInit,
															}}
														/>
													)}
													{btnCode === 2 && (
														<Input
															readOnly
															value={testerItem.TEST_ID}
															action={{
																size: 'large',
																children: '요청중',
															}}
														/>
													)}
													{btnCode === 1 && (
														<Input
															readOnly
															value={testerItem.TEST_ID}
															action={{
																size: 'large',
																color: 'red',
																children: '암호수정',
																onClick: this.onClickPassChange,
															}}
														/>
													)}
												</Form.Field>
											</Form.Group>
										</Segment>
									</Accordion.Content>
								</Accordion>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			</Container>
		);
	}
}

export default Main;
