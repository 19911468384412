import withSplitting from '../withSplitting';
import React from 'react';
import Loadable from 'react-loadable';
const Loading = () => {
  return (
    <div id="fuse-splash-screen">
      <div className="center">
        <div className="logo">
          <img width="128" src="/assets/img/logo/Logo_50_C.png" alt="logo" />
        </div>
        <br />
        <div className="loading" />
      </div>
    </div>
  );
};

export const Home = Loadable({
  loader: () => import('./home'),
  loading: Loading,
});

export const Main = Loadable({
  loader: () => import('./main'),
  loading: Loading,
});

export const KyowonService = Loadable({
  loader: () => import('./kyowon'),
  loading: Loading,
});

export const Lq = withSplitting(() => import('./lq/index'));
export const Puzzle = withSplitting(() => import('./puzzle/index'));
