import * as types from 'actions/ActionTypes';

const initialState = {
    isReload: false
};

const reloadable = (state = initialState, action) => {
    switch(action.type) {
        case types.RELOADABLE:
            return {
              isReload: action.isReload
            };
        default:
            return state;
    }
};

export default reloadable;
