import * as types from 'actions/ActionTypes';

const initalState = {
	text: null,
	title: null,
	lqInfosText: null,
	lqInfos: null,
	lqscore: 0,
	type: null,
};

const info = (state = initalState, action) => {
	switch (action.type) {
		case types.CHANGE_TEXT:
			return Object.assign({}, state, { text: action.text, type: types.CHANGE_TEXT });
		case types.CHANGE_LQINFOS_TEXT:
			return Object.assign({}, state, { lqInfosText: action.lqInfosText, type: types.CHANGE_LQINFOS_TEXT });
		case types.LQTAGGING:
			return Object.assign({}, state, { lqInfos: action.lqInfos, type: types.LQTAGGING });
		case types.LQ_SCORE:
			return Object.assign({}, state, { lqscore: action.lqscore });
		case types.DOCUMENTS_TITLE_CHANGE:
			return Object.assign({}, state, { title: action.title });
		default:
			return state;
	}
};

export default info;
