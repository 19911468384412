import React from 'react';
import { CompositeDecorator } from 'draft-js';
const styles = [
	{ direction: 'ltr', unicodeBidi: 'bidi-override', borderBottom: '2px dashed #1E88E5' },
	{ direction: 'ltr', unicodeBidi: 'bidi-override', borderBottom: '2px dashed #669933' },
	{ direction: 'ltr', unicodeBidi: 'bidi-override', borderBottom: '2px dashed #cc6633' },
];

const findWithRegex = (blocks, contentBlock, callback) => {
	if (blocks.filter(x => x.blockKey === contentBlock.getKey()).length > 0) {
		const text = contentBlock.getText();
		callback(0, text.length);
	}
};

const SearchHighlight = props => {
	const blockKey = props.children[0].props.block.getKey();
	const searchs = props.blocks.filter(x => x.blockKey === blockKey);
	if (searchs != null && searchs.length > 0) {
		return <span style={styles[searchs[0].index]}>{props.children}</span>;
	} else {
		return <span>{props.children}</span>;
	}
};

/*
export const generateDecorator = (blocks, selectedIndex) => {
	return new CompositeDecorator([
		{
			strategy: (contentBlock, callback) => {
				if (blocks.length > 0) {
					findWithRegex(blocks, contentBlock, callback);
				}
			},
			component: SearchHighlight,
			props: { selectedIndex: selectedIndex },
		},
	]);
};
*/
export const generateDecorator = blocks => {
	return new CompositeDecorator([
		{
			strategy: (contentBlock, callback) => {
				if (blocks.length > 0) {
					findWithRegex(blocks, contentBlock, callback);
				}
			},
			component: SearchHighlight,
			props: { blocks },
		},
	]);
};
