import React, { Component } from 'react';
import {
	DecoWords,
	DecosList,
	HanjaConvert,
	DecoMultiLang,
	DecoSynonym,
	DecoDictionary,
} from 'sentence/components/decorators';
import { withRouter } from 'react-router-dom';
import { Layout, Button } from 'antd';
import { connect } from 'react-redux';
import * as actions from 'actions';
import './index.css';

const mapDispatchToProps = (dispatch) => ({
	onDecosClose: (decoVisible) => dispatch(actions.onDecosClose({ decoVisible })),
});

const { Sider, Content } = Layout;

const positions = [
	{ position: 'absolute', top: 15, right: 15, zIndex: 1009 },
	{ position: 'absolute', top: 30, right: 30, zIndex: 1009 },
	{ position: 'absolute', top: 10, right: 15, zIndex: 1009 },
];

const styles = (type) => {
	switch (type) {
		case 'wordinfo':
			return positions[0];
		case 'decoinfo':
			return positions[0];
		case 'hanja':
			return positions[1];
		case 'multilang':
			return positions[0];
		case 'synonyms':
			return positions[2];
		case 'dictionary':
			return positions[2];
		default:
			return '';
	}
};

const DecoByType = (type, post) => {
	switch (type) {
		case 'wordinfo':
			return <DecoWords post={post} />;
		case 'decoinfo':
			return <DecosList post={post} />;
		case 'hanja':
			return <HanjaConvert post={post} />;
		case 'multilang':
			return <DecoMultiLang post={post} />;
		case 'synonyms':
			return <DecoSynonym post={post} />;
		case 'dictionary':
			return <DecoDictionary word_no={post.word_no} />;
		default:
			return '';
	}
};

class DecoContainer extends Component {
	constructor(props) {
		super(props);
		if (props.type != null) {
			this.state = { type: props.type, post: props.data };
		} else {
			this.state = { type: 'baseinfo' };
		}

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.type !== 'multilang') {
			if (nextProps.type != null) {
				let wsize = 350;
				if (nextProps.type === 'synonyms' || nextProps.type === 'dictionary') wsize = 500;
				this.setState({ type: nextProps.type, post: nextProps.data, wsize });
			}
		} else {
			if (nextProps.data != null && nextProps.data.option !== this.state.post.option) {
				console.log(nextProps.data.option);
				let wsize = 350;
				this.setState({ type: nextProps.type, post: nextProps.data, wsize });
			} else {
				let wsize = 350;
				this.setState({ wsize });
			}
		}
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	render() {
		const { type, post, width, wsize } = this.state;
		if (post) {
			return (
				<Sider
					breakpoint="xl"
					trigger={null}
					collapsedWidth="0"
					onBreakpoint={(broken) => {
						//this.props.onDecosView(broken);
					}}
					onCollapse={(collapsed, type) => {}}
					width={type !== 'hanja' ? wsize : (width - 250 - 80) / 2}
					style={{ background: '#fafafa' }}>
					<Content style={{ height: '100%' }}>{DecoByType(type, post)}</Content>
				</Sider>
			);
		} else {
			return '';
		}
	}
}

export default connect(
	null,
	mapDispatchToProps
)(withRouter(DecoContainer));
