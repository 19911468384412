import React, { Component } from 'react';
import { Slide, Dialog, AppBar } from '@material-ui/core';
import { Button, Grid, Container } from 'semantic-ui-react';

import * as service from 'services/puzzle';
import { loadScript } from 'components';

import './Crossword.css';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Crossword extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    window.CrosswordComponent = this;

    this.state = {
      crosswordData: '',
      extractedScript: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    if (
      nextProps.puzzleOpen !== false &&
      this.props.isOpen !== nextProps.puzzleOpen
    ) {
      if (
        nextProps.puzzleId !== undefined &&
        nextProps.puzzleId !== '' &&
        nextProps.puzzleId !== 0
      ) {
        this._isMounted &&
          this.fetchPuzzleInfo(
            parseInt(nextProps.puzzleGubn),
            parseInt(nextProps.puzzleSubGubn),
            parseInt(nextProps.puzzleId),
            nextProps.finishAction,
            nextProps.puzzleLanguage,
            nextProps.puzzleSize
          );
      }

      if (
        nextProps.analysisId !== undefined &&
        nextProps.analysisId !== '' &&
        nextProps.analysisId !== 0
      ) {
        this._isMounted &&
          this.fetchPuzzleInfo(
            parseInt(nextProps.puzzleGubn),
            parseInt(nextProps.puzzleSubGubn),
            parseInt(nextProps.analysisId),
            nextProps.finishAction,
            nextProps.puzzleLanguage,
            nextProps.puzzleSize
          );
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.extractedScript !== prevState.extractedScript) {
      let scriptToRun = this.state.extractedScript;

      if (scriptToRun !== undefined) {
        let scriptLines = scriptToRun.split('\n');
        scriptLines.pop();
        scriptLines.shift();
        let cleanScript = scriptLines.join('\n');

        //console.log(cleanScript);

        loadScript('/assets/js/custom/createjs.min.js', () => {
          loadScript('/assets/js/custom/hangul.min.js', () => {
            loadScript('/assets/js/custom/crosswordKR.js', () => {
              // eslint-disable-next-line
              window.eval(cleanScript);
            });
          });
        });
      }
    }
  }

  fetchPuzzleInfo = async (
    puzzleGubn,
    puzzleSubGubn,
    puzzleId,
    finishAction,
    puzzleLanguage,
    puzzleSize
  ) => {
    let puzzleInfo = await service.getCrosswordPuzzleInfo({
      puzzleGubn: puzzleGubn,
      puzzleSubGubn: puzzleSubGubn,
      puzzleId: puzzleId,
      finishAction: finishAction,
      puzzleLanguage: puzzleLanguage,
      puzzleSize: puzzleSize,
    });
    let htmlResponse = puzzleInfo.data;
    //console.log(htmlResponse);
    // eslint-disable-next-line
    let extractedScript = /<script myscript[^<]*>([^\/]*)<\/script>/g.exec(
      htmlResponse
    )[0];

    this.setState({
      extractedScript: extractedScript,
      crosswordData: puzzleInfo.data,
    });
  };

  hidePuzzle = () => {
    this.setState({
      crosswordData: '',
      extractedScript: '',
    });
  };

  render() {
    const {
      puzzleOpen,
      handleClose,
      puzzleTitle,
      btnBackLabelText,
    } = this.props;

    return (
      <Dialog
        fullScreen
        open={puzzleOpen}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar className="puzzle-appbar-bg">
          <Container className="puzzle-top-bar">
            <Grid columns="equal">
              <Grid.Column>
                <Button id="btn_go_back" size="large">
                  {btnBackLabelText}
                </Button>
              </Grid.Column>
              <Grid.Column className="text-center">
                <span className="text-20 font-500">{puzzleTitle}</span>
              </Grid.Column>
              <Grid.Column className="text-right">
                <Button
                  size="large"
                  data-toggle="modal"
                  data-target="#modalPuzzleFinish">
                  결과확인
                </Button>
              </Grid.Column>
            </Grid>
          </Container>
        </AppBar>
        <div className="mt-72">
          <div dangerouslySetInnerHTML={{ __html: this.state.crosswordData }} />
        </div>
      </Dialog>
    );
  }
}

export default Crossword;
