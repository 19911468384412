import * as types from 'actions/ActionTypes';

const initalState = {
	//activeIndex: 0,
	blocks: [],
};

const difficulty = (state = initalState, action) => {
	switch (action.type) {
		case types.BLOCK_HIGHTLIGHT:
			//return Object.assign({}, state, { activeIndex: action.activeIndex, blocks: action.blocks });
			return Object.assign({}, state, { blocks: action.blocks });
		default:
			return state;
	}
};

export default difficulty;
