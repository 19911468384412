export const INTRO = 'intro';
export const READTEST = 'readtest';
export const READTEST_CAT = 'readtestcat';
export const WORDTEST = 'wordtest';
export const ENGREADTEST = 'engreadtest';
export const PAYMENTS = 'payments';
export const START = 'start';

export const METHOD = {
	0: 'ONLINE',
	1: 'OFFLINE',
};

export const TEST_TYPES = {
	KOR_READ_CBT: 1,
	KOR_READ_CAT: 2,
	KOR_WORD_CBT: 3,
	ENG_READ_CBT: 4,
	properties: {
		1: { name: 'KOR_READ_CBT', label: 'LQ 독서력 검사', value: 1 },
		2: { name: 'KOR_READ_CAT', label: 'LQ 독서력 검사 CAT', value: 2 },
		3: { name: 'KOR_WORD_CBT', label: '우리말 어휘력 검사', value: 3 },
		4: { name: 'ENG_READ_CBT', label: '영어 독서력 검사', value: 4 },
	},
};

export const TEST_SET_OFF = {
	A: 1,
	B: 2,
	C: 3,
	properties: {
		1: { name: 'A', label: 'A세트', value: 1 },
		2: { name: 'B', label: 'B세트', value: 2 },
		3: { name: 'C', label: 'C세트', value: 3 },
	},
};

export const KOR_READ_CBT = {
	id: 'readtest',
	title: 'LQ 독서력 검사',
	div: 'KOR_READ',
	method: 'CBT',
	value: 1,
	payments: '20,000원',
	tables: {
		7: { id: 'level7', label: '7급', value: '7', target: '초등1~2학년', time: '40', count: '25', type: '온라인' },
		6: { id: 'level6', label: '6급', value: '6', target: '초등3~4학년', time: '40', count: '30', type: '온라인' },
		5: { id: 'level5', label: '5급', value: '5', target: '초등5~6학년', time: '50', count: '30', type: '온라인' },
		4: { id: 'level4', label: '4급', value: '4', target: '중등1~2학년', time: '50', count: '35', type: '온라인' },
		3: {
			id: 'level3',
			label: '3급',
			value: '3',
			target: '중등3~고등1학년',
			time: '50',
			count: '35',
			type: '온라인',
		},
		2: { id: 'level2', label: '2급', value: '2', target: '고등2~3학년', time: '60', count: '40', type: '온라인' },
		1: { id: 'level1', label: '1급', value: '1', target: '대학생/일반인', time: '60', count: '40', type: '온라인' },
	},
};
export const KOR_READ_CAT = {
	id: 'readtestcat',
	title: 'LQ 독서력 검사 CAT',
	div: 'KOR_READ',
	method: 'CAT',
	value: 2,
	payments: '20,000원',
	tables: {
		7: { id: 'level7', label: '', value: '7', target: '초등1~2학년', time: '40', count: '25', type: '온라인' },
		6: { id: 'level6', label: '', value: '6', target: '초등3~4학년', time: '40', count: '25', type: '온라인' },
		5: { id: 'level5', label: '', value: '5', target: '초등5~6학년', time: '40', count: '25', type: '온라인' },
		4: { id: 'level4', label: '', value: '4', target: '중등1~2학년', time: '40', count: '25', type: '온라인' },
		3: { id: 'level3', label: '', value: '3', target: '중등3~고등1학년', time: '40', count: '25', type: '온라인' },
		2: { id: 'level2', label: '', value: '2', target: '고등2~3학년', time: '40', count: '25', type: '온라인' },
		1: { id: 'level1', label: '', value: '1', target: '대학생/일반인', time: '40', count: '25', type: '온라인' },
	},
};
export const KOR_WORD_CBT = {
	id: 'wordtest',
	title: '우리말 어휘력 검사',
	div: 'KOR_WORD',
	method: 'CBT',
	value: 3,
	payments: '20,000원',
	tables: {
		7: { id: 'level7', label: '7급', value: '7', target: '초등1~2학년', time: '25', count: '25', type: '온라인' },
		6: { id: 'level6', label: '6급', value: '6', target: '초등3~4학년', time: '30', count: '33', type: '온라인' },
		5: { id: 'level5', label: '5급', value: '5', target: '초등5~6학년', time: '30', count: '50', type: '온라인' },
		4: { id: 'level4', label: '4급', value: '4', target: '중등1~2학년', time: '40', count: '60', type: '온라인' },
		3: {
			id: 'level3',
			label: '3급',
			value: '3',
			target: '중등3~고등1학년',
			time: '40',
			count: '60',
			type: '온라인',
		},
		2: { id: 'level2', label: '2급', value: '2', target: '고등2~3학년', time: '40', count: '60', type: '온라인' },
		1: { id: 'level1', label: '1급', value: '1', target: '대학생/일반인', time: '40', count: '70', type: '온라인' },
	},
};
export const ENG_READ_CBT = {
	id: 'engreadtest',
	title: '영어 독서력 검사',
	div: 'ENG_READ',
	method: 'CBT',
	value: 4,
	payments: '20,000원',
	tables: {
		5: {
			id: 'level5',
			label: '초급',
			value: '5',
			target: '초등5~6학년',
			time: '40',
			count: '36',
			type: '온라인',
		},
		4: {
			id: 'level4',
			label: '초중급',
			value: '4',
			target: '중등1~2학년',
			time: '45',
			count: '36',
			type: '온라인',
		},
		3: {
			id: 'level3',
			label: '중급',
			value: '3',
			target: '중등3~고등1학년',
			time: '50',
			count: '40',
			type: '온라인',
		},
		2: {
			id: 'level2',
			label: '중상급',
			value: '2',
			target: '고등2~3학년',
			time: '50',
			count: '40',
			type: '온라인',
		},
		1: {
			id: 'level1',
			label: '상급',
			value: '1',
			target: '대학생/일반인',
			time: '50',
			count: '40',
			type: '온라인',
		},
	},
};

export const LEVEL7 = { id: 'level7', title: '7급', target: '초등1~2학년', value: 'L7' };
export const LEVEL6 = { id: 'level6', title: '6급', target: '초등3~4학년', value: 'L6' };
export const LEVEL5 = { id: 'level5', title: '5급', target: '초등5~6학년', value: 'L5' };
export const LEVEL4 = { id: 'level4', title: '4급', target: '중등1~2학년', value: 'L4' };
export const LEVEL3 = { id: 'level3', title: '3급', target: '중등3~고등1학년', value: 'L3' };
export const LEVEL2 = { id: 'level2', title: '2급', target: '고등2~3학년', value: 'L2' };
export const LEVEL1 = { id: 'level1', title: '1급', target: '대학생·일반인', value: 'L1' };

export const ENG_LEVEL5 = { id: 'level5', title: '초급', target: '초등5~6학년', value: 'E5' };
export const ENG_LEVEL4 = { id: 'level4', title: '초중급', target: '중등1~2학년', value: 'E4' };
export const ENG_LEVEL3 = { id: 'level3', title: '중급', target: '중등3~고등1학년', value: 'E3' };
export const ENG_LEVEL2 = { id: 'level2', title: '중상급', target: '고등2~3학년', value: 'E2' };
export const ENG_LEVEL1 = { id: 'level1', title: '상급', target: '대학생·일반인', value: 'E1' };

export const TESTS = [KOR_READ_CBT, KOR_READ_CAT, KOR_WORD_CBT, ENG_READ_CBT];
export const LEVELS = [LEVEL1, LEVEL2, LEVEL3, LEVEL4, LEVEL5, LEVEL6, LEVEL7];
export const ENG_LEVELS = [ENG_LEVEL1, ENG_LEVEL2, ENG_LEVEL3, ENG_LEVEL4, ENG_LEVEL5];

export const ENG_LEVELS_OFF = [ENG_LEVEL5, ENG_LEVEL4, ENG_LEVEL3, ENG_LEVEL2, ENG_LEVEL1];

export const CERTED = {
	APPROVE: 1,
	REQUEST: 2,
	COMPLEATE: 0,
	properties: {
		0: { name: 'compleate', label: '사용가능', value: 0, code: '' },
		2: { name: 'request', label: '암호 초기화 요청', value: 0, code: '' },
		1: { name: 'approve', label: '암호 초기화 수락', value: 0, code: '' },
	},
	options: [
		{ key: 'compleate', text: '전체', value: 0 },
		{ key: 'approve', text: '초기화 수락', value: 1 },
		{ key: 'request', text: '초기화 요청', value: 2 },
	],
};

export const STATUS = {
	READY: { name: 'READY', value: '결제완료' },
	STANDBY: { name: 'STANDBY', value: '결제완료' },

	FINISH: { name: 'FINISH', value: '검사완료' },
	TIME_OVER: { name: 'TIME_OVER', value: '검사완료' },

	RESTART: { name: 'RESTART', value: '재검사' },
	START: { name: 'START', value: '검사시작' },
	PAUSE: { name: 'PAUSE', value: '검사중지' },

	REPORT: { name: 'REPORT', value: '검사완료' },
	FAIL: { name: 'FAIL', value: '채점에러' },
};

export const GROUP_ORDER_STATUS = {
	properties: {
		START: { name: 'start', label: '검사시작', value: 0, code: '' },
		PAUSE: { name: 'pause', label: '검사중지', value: 0, code: '' },
		REPORT: { name: 'report', label: '검사완료', value: 0, code: '' },
	},
	options: [
		{ key: 'all', text: '전체', value: 'ALL' },
		{ key: 'START', text: '검사시작', value: 'START' },
		{ key: 'PAUSE', text: '검사중지', value: 'PAUSE' },
		{ key: 'REPORT', text: '검사완료', value: 'REPORT' },
	],
};

export const SCHOOL = {
	GUBUN: [
		{
			key: 'elem_list',
			text: '초등학교',
			value: 'elem_list',
			style: { fontSize: '1.1em' },
			gradeOption: [
				{ key: 'grade0', text: '학년 - 전체', value: 0 },
				{ key: 'grade1', text: '1학년', value: 1 },
				{ key: 'grade2', text: '2학년', value: 2 },
				{ key: 'grade3', text: '3학년', value: 3 },
				{ key: 'grade4', text: '4학년', value: 4 },
				{ key: 'grade5', text: '5학년', value: 5 },
				{ key: 'grade6', text: '6학년', value: 6 },
			],
		},
		{
			key: 'midd_list',
			text: '중학교',
			value: 'midd_list',
			style: { fontSize: '1.1em' },
			gradeOption: [
				{ key: 'grade0', text: '학년 - 전체', value: 0 },
				{ key: 'grade1', text: '1학년', value: 1 },
				{ key: 'grade2', text: '2학년', value: 2 },
				{ key: 'grade3', text: '3학년', value: 3 },
			],
		},
		{
			key: 'high_list',
			text: '고등학교',
			value: 'high_list',
			style: { fontSize: '1.1em' },
			gradeOption: [
				{ key: 'grade0', text: '학년 - 전체', value: 0 },
				{ key: 'grade1', text: '1학년', value: 1 },
				{ key: 'grade2', text: '2학년', value: 2 },
				{ key: 'grade3', text: '3학년', value: 3 },
			],
		},
		{ key: 'univ_list', text: '대학교', value: 'univ_list', style: { fontSize: '1.1em' } },
		{ key: 'seet_list', text: '특수/기타학교', value: 'seet_list', style: { fontSize: '1.1em' } },
		{ key: 'alte_list', text: '대안학교', value: 'alte_list', style: { fontSize: '1.1em' } },
	],
	REGION: [
		{ key: '100260', text: '서울특별시', value: '100260', style: { fontSize: '1.1em' } },
		{ key: '100267', text: '부산광역시', value: '100267', style: { fontSize: '1.1em' } },
		{ key: '100269', text: '인천광역시', value: '100269', style: { fontSize: '1.1em' } },
		{ key: '100271', text: '대전광역시', value: '100271', style: { fontSize: '1.1em' } },
		{ key: '100272', text: '대구광역시', value: '100272', style: { fontSize: '1.1em' } },
		{ key: '100273', text: '울산광역시', value: '100273', style: { fontSize: '1.1em' } },
		{ key: '100275', text: '광주광역시', value: '100275', style: { fontSize: '1.1em' } },
		{ key: '100276', text: '경기도', value: '100276', style: { fontSize: '1.1em' } },
		{ key: '100278', text: '강원도', value: '100278', style: { fontSize: '1.1em' } },
		{ key: '100280', text: '충청북도', value: '100280', style: { fontSize: '1.1em' } },
		{ key: '100281', text: '충청남도', value: '100281', style: { fontSize: '1.1em' } },
		{ key: '100282', text: '전라북도', value: '100282', style: { fontSize: '1.1em' } },
		{ key: '100283', text: '전라남도', value: '100283', style: { fontSize: '1.1em' } },
		{ key: '100285', text: '경상북도', value: '100285', style: { fontSize: '1.1em' } },
		{ key: '100291', text: '경상남도', value: '100291', style: { fontSize: '1.1em' } },
		{ key: '100292', text: '제주도', value: '100292', style: { fontSize: '1.1em' } },
	],
};

export const PAGE_SIZE = [
	{ key: 'page-10', text: '10', value: 10 },
	{ key: 'page-20', text: '20', value: 20 },
	{ key: 'page-40', text: '40', value: 40 },
	{ key: 'page-60', text: '60', value: 60 },
];

export const FIND_TEST_ID = (test_id) => TESTS.filter((item) => item.id === test_id)[0];
export const FIND_TEST_VALUE = (test_value) => TESTS.filter((item) => item.value === test_value)[0];
export const FIND_LEVEL_ID = (level_id) => LEVELS.filter((item) => item.id === level_id)[0];
export const FIND_LEVEL_TITLE = (level_value) => LEVELS.filter((item) => item.value === level_value)[0];
export const FIND_ENG_LEVEL_ID = (level_id) => ENG_LEVELS.filter((item) => item.id === level_id)[0];
export const FIND_ENG_LEVEL_TITLE = (level_value) => ENG_LEVELS.filter((item) => item.value === level_value)[0];
export const FIND_STATUS_VALUE = (status) => STATUS[status].value;
