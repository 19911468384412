import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import 'modern-toastr/dist/modern-toastr.css';
import './index.css';
import Lists from './list';
import Create from './create';
import Modify from './modify';
import ModifyPass from '../../searchPassword/modify';

class SearchTesterCommon extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			event: 'lists',
		};
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.open !== nextProps.open) {
			this.setState({ event: 'lists', ...nextProps });
			return true;
		}
		return false;
	}

	onEventHander = (event, selectedItem) => {
		this.setState({ event, selectedItem });
	};

	selectedUserItem = userItem => {
		const { getSelectedItem = f => f } = this.props;
		this.setState(() => getSelectedItem({ ...userItem }));
	};

	onCloseClick = e => {
		const { close = f => f } = this.props;
		this.setState(() => close());
	};

	render() {
		const { open, event } = this.state;

		return (
			<Modal size="small" open={open} onClose={this.onCloseClick}>
				{event === 'lists' && (
					<Fragment>
						<Modal.Header>
							응시자 조회 <i aria-hidden="true" className="close icon" onClick={this.props.close} />
						</Modal.Header>
						<Modal.Content>
							<Lists
								eventHander={this.onEventHander}
								orderItem={this.props.orderItem}
								getSelectedItem={this.selectedUserItem}
							/>
						</Modal.Content>
					</Fragment>
				)}
				{event === 'create' && (
					<Fragment>
						<Modal.Header>
							응시자 등록
							<i aria-hidden="true" className="close icon" onClick={this.onCloseClick} />
						</Modal.Header>
						<Modal.Content>
							<Create eventHander={this.onEventHander} orderItem={this.props.orderItem} />
						</Modal.Content>
					</Fragment>
				)}
				{event === 'modify' && (
					<Fragment>
						<Modal.Header>
							응시자 수정
							<i aria-hidden="true" className="close icon" onClick={this.onCloseClick} />
						</Modal.Header>
						<Modal.Content>
							<Modify
								eventHander={this.onEventHander}
								orderItem={this.props.orderItem}
								selectedItem={this.state.selectedItem}
							/>
						</Modal.Content>
					</Fragment>
				)}
				{event === 'modifyPass' && (
					<Fragment>
						<Modal.Header>
							암호 변경
							<i aria-hidden="true" className="close icon" onClick={this.onCloseClick} />
						</Modal.Header>
						<Modal.Content>
							<ModifyPass
								eventHander={this.onEventHander}
								orderItem={this.props.orderItem}
								testerItem={this.state.selectedItem}
								close={this.selectedUserItem}
							/>
						</Modal.Content>
					</Fragment>
				)}
			</Modal>
		);
	}
}

SearchTesterCommon.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func,
	getSelectedItem: PropTypes.func,
};

export default SearchTesterCommon;
