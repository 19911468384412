import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as service from 'services/groups';
import {
  Container,
  Input,
  Grid,
  Table,
  Responsive,
  Pagination,
  Button,
  Icon,
  Checkbox,
  Message,
  Form,
} from 'semantic-ui-react';
import * as types from 'types/EtestTypes';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';
import './index.css';

const moment = require('moment');

const loading = {
  user: false,
};

const search = {
  user: {
    thisPage: 1,
    perPage: 8,
    searchName: '',
  },
};

const state = {
  selectedItem: null,
  checkedItem: 0,
  password: '',
  v: {
    checkedItem: { error: false, message: '응시자명을 선택해 주세요.' },
  },
  p: {
    checkedItem: { error: false, message: '응시자명을 선택해 주세요.' },
    password: { error: false, message: '암호를 입력해 주세요.' },
  },
};

class Lists extends Component {
  fetchUserLists = async (datas) => {
    //console.log(datas);
    const result = await service.api(
      this,
      '/api/groups/tests/groupSchool',
      datas,
      null
    );
    if (result && result.status === 200) {
      if (result.data.total > 0) {
        const totalPage = Math.ceil(result.data.total / search.user.perPage);
        const datas = result.data.datas;
        this.setState({
          users: { totalPage, datas },
        });
      } else {
        this.setState({
          users: { message: '검색하신 내용이 없습니다.' },
        });
      }
    }
    let { loading } = this.state;
    loading['user'] = false;
    this.setState({ loading });
  };

  fetchUserValidate = async (datas) => {
    const result = await service.api(
      this,
      '/api/groups/tests/groupSchool/validate',
      datas,
      null
    );
    if (result && result.status === 200) {
      const { eventHander = (f) => f } = this.props;
      this.setState(() => eventHander('modify', this.state.selectedItem));
    }
  };

  fetchUserDelete = async (datas) => {
    const result = await service.api(
      this,
      '/api/groups/tests/groupSchool/delete',
      datas,
      null
    );
    if (result && result.status === 200) {
      Toastr.info(result.data.message);
      this.searchUserHandler();
    }
  };

  fetchDateCheck = async (datas) => {
    const result = await service.api(
      this,
      '/api/groups/tests/groupSchool/checkDate',
      datas,
      null
    );
    if (result && result.status === 200) {
      if (result.data.code === 1) {
        this.selectedUserItem(this.state.selectedItem);
      } else {
        this.setState({
          checkDateInfo: true,
          checkDateMessages: result.data.messages,
        });
      }
    }
  };

  fetchStatusPass = async (datas) => {
    const result = await service.api(
      this,
      '/api/groups/tests/statusPass',
      datas,
      null
    );
    if (result && result.status === 200) {
      if (result.data.code === types.CERTED.APPROVE) {
        Toastr.info(
          '담당자가 암호 초기화를 수락했습니다. 암호를 변경해 주세요.'
        );
        const { eventHander = (f) => f } = this.props;
        this.setState(() => eventHander('modifyPass', this.state.selectedItem));
      } else {
        //행정년월 체크
        this.fetchDateCheck({ user: this.state.selectedItem });
      }
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      loading,
      search,
      ...state,
      orderItem: props.orderItem,
      isWarning: false,
      checkDateInfo: false,
      checkDateMessages: [],
    };
  }

  componentDidMount() {
    this.searchUserHandler();
  }

  validateHandler = () => {
    let v = this.state.v;
    Object.keys(v).map((key) => {
      if (
        this.state.hasOwnProperty(key) &&
        this.state[key] &&
        this.state[key].toString().trim().length > 0
      ) {
        v[key].error = false;
      } else {
        v[key].error = true;
      }
      return v[key];
    });
    let messages = Object.keys(v)
      .filter((key) => v[key].error)
      .map((key) => {
        return v[key].message;
      });

    if (messages.length > 0) {
      this.setState({ v, isWarning: true, errors: messages });
      return false;
    } else {
      this.setState({ v, isWarning: false, errors: null });
      return true;
    }
  };

  validatePasswordHandler = () => {
    let p = this.state.p;
    Object.keys(p).map((key) => {
      if (
        this.state.hasOwnProperty(key) &&
        this.state[key] &&
        this.state[key].toString().trim().length > 0
      ) {
        p[key].error = false;
      } else {
        p[key].error = true;
      }
      return p[key];
    });
    let messages = Object.keys(p)
      .filter((key) => p[key].error)
      .map((key) => {
        return p[key].message;
      });

    if (messages.length > 0) {
      this.setState({ p, isWarning: true, errors: messages });
      return false;
    } else {
      this.setState({ p, isWarning: false, errors: null });
      return true;
    }
  };

  dataHandler = (e, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
    this.setState({ isWarning: false });
  };

  searchUserOnChange = (e, { name, value }) => {
    let { search } = this.state;
    if (search.user.hasOwnProperty(name)) {
      search.user[name] = value;
      this.setState({ search });
    }
  };

  searchOneKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.searchUserOnClick(e);
    }
  };

  searchUserOnClick = (e) => {
    e.preventDefault();

    let { search } = this.state;
    this.setState({ search });
    this.searchUserHandler();
  };

  searchUserHandler = () => {
    let { search, loading } = this.state;

    loading['user'] = true;
    this.setState({ loading });

    let data = search.user;
    this.fetchUserLists({ ...data, ...this.props.orderItem });
  };

  searchUserPaginationChange = (e, { activePage }) => {
    let { search } = this.state;
    search.user['thisPage'] = activePage;
    this.setState({ search });
    this.searchUserHandler();
  };

  selectedUserItem = (userItem) => {
    const { getSelectedItem = (f) => f } = this.props;
    this.setState(() => getSelectedItem({ ...userItem }));
  };

  onClickCreate = () => {
    const { eventHander = (f) => f } = this.props;
    this.setState(() => eventHander('create'));
  };

  onClickUsed = (e) => {
    this.setState({
      v: {
        checkedItem: { error: false, message: '응시자명을 선택해 주세요.' },
      },
      p: {
        checkedItem: { error: false, message: '응시자명을 선택해 주세요.' },
        password: { error: false, message: '암호를 입력해 주세요.' },
      },
    });
    if (this.validateHandler()) {
      this.fetchStatusPass({ id: this.state.selectedItem.PSN_ID });
    }
  };

  onClickedDelete = (e) => {
    this.setState({
      v: {
        checkedItem: { error: false, message: '응시자명을 선택해 주세요.' },
      },
      p: {
        checkedItem: { error: false, message: '응시자명을 선택해 주세요.' },
        password: { error: false, message: '암호를 입력해 주세요.' },
      },
    });
    if (this.validatePasswordHandler()) {
      const testId = this.state.selectedItem.TEST_ID;
      const groupId = this.state.selectedItem.GROUP_ID;
      this.fetchUserDelete({
        testId,
        groupId,
        user: this.state.selectedItem,
        password: this.state.password,
      });
    }
  };

  onClickedModify = (e) => {
    this.setState({
      v: {
        checkedItem: { error: false, message: '응시자명을 선택해 주세요.' },
      },
      p: {
        checkedItem: { error: false, message: '응시자명을 선택해 주세요.' },
        password: { error: false, message: '암호를 입력해 주세요.' },
      },
    });
    if (this.validatePasswordHandler()) {
      const testId = this.state.selectedItem.TEST_ID;
      const groupId = this.state.selectedItem.GROUP_ID;
      this.fetchUserValidate({
        testId,
        groupId,
        user: this.state.selectedItem,
        password: this.state.password,
      });
    }
  };

  onClickItem = (e, item) => {
    this.dataHandler(e, {
      name: 'checkedItem',
      value: item.PSN_ID,
    });

    this.setState({ selectedItem: item });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.p.password.error &&
      this.state.isWarning !== prevState.isWarning
    ) {
      this.validatePasswordHandler();
    }
    if (
      this.state.p.checkedItem.error &&
      this.state.isWarning !== prevState.isWarning
    ) {
      this.validatePasswordHandler();
    }
    if (
      this.state.v.checkedItem.error &&
      this.state.isWarning !== prevState.isWarning
    ) {
      this.validateHandler();
    }
  }

  render() {
    const {
      users,
      loading,
      checkedItem,
      p,
      errors,
      isWarning,
      password,
      checkDateInfo,
      checkDateMessages,
    } = this.state;

    return (
      <Container style={{ fontSize: '1.2em' }}>
        <Form warning={isWarning} size="large">
          <Grid widths={16}>
            <Grid.Row style={{ marginLeft: '0px' }}>
              <Grid.Column>
                <i className="fas fa-info-circle" />
                &nbsp;사용하실 응시자명을 검색 후 사용하기 버튼을 클릭해 주세요.
                <br />
                <i className="fas fa-info-circle" />
                &nbsp;사용할 응시자명이 없다면 등록하기 버튼을 클릭하여 등록 후
                이용해 주세요.
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginLeft: '0px' }}>
              <Grid.Column>
                <Message
                  warning
                  header="아래 사항을 확인해 주세요."
                  list={errors}
                />
                <Message
                  color="orange"
                  hidden={!checkDateInfo}
                  header="아래 사항을 확인해 주세요."
                  list={checkDateMessages}
                />
                <Form.Group inline>
                  <Form.Field width={13} style={{ padding: '0px' }}>
                    <Input
                      name="searchName"
                      fluid
                      action={{
                        size: 'large',
                        color: 'twitter',
                        children: '검색',
                        onClick: this.searchUserOnClick,
                      }}
                      onKeyPress={this.searchOneKeyPress}
                      onChange={this.searchUserOnChange}
                      icon="search"
                      loading={loading.user}
                      iconPosition="left"
                      placeholder="응시자명"
                    />
                  </Form.Field>
                  &nbsp;
                  <Form.Field width={3} style={{ padding: '0px' }}>
                    <Button
                      fluid
                      size="large"
                      color="grey"
                      onClick={this.onClickCreate}
                      children="등록하기"
                    />
                  </Form.Field>
                </Form.Group>
                <Table
                  color="black"
                  key="black"
                  compact="very"
                  style={{ padding: '0px!important' }}
                  selectable>
                  <Responsive as={Table.Header} {...Responsive.onlyComputer}>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        선택
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        아이디
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        응시자명
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        학년
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">반</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        번호
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        수정일
                      </Table.HeaderCell>
                    </Table.Row>
                  </Responsive>
                  <Table.Body>
                    {users &&
                      users.datas &&
                      users.datas.map((user, index) => {
                        return (
                          <Table.Row
                            key={'user_' + index}
                            active={checkedItem === user.PSN_ID}
                            onClick={(e) => this.onClickItem(e, user)}>
                            <Table.Cell textAlign="center">
                              <Checkbox
                                name="checkedItem"
                                radio
                                value={user.PSN_ID}
                                checked={checkedItem === user.PSN_ID}
                                onChange={this.dataHandler}
                              />
                            </Table.Cell>
                            <Table.Cell
                              className="cursor-pointer"
                              textAlign="center">
                              {user.TEST_ID}
                            </Table.Cell>
                            <Table.Cell
                              className="cursor-pointer"
                              textAlign="center">
                              {user.NAME}
                            </Table.Cell>
                            <Table.Cell
                              className="cursor-pointer"
                              textAlign="center">
                              {user.TestGroupSchools[0].GRADE} 학년
                            </Table.Cell>
                            <Table.Cell
                              className="cursor-pointer"
                              textAlign="center">
                              {user.TestGroupSchools[0].CLASS} 반
                            </Table.Cell>
                            <Table.Cell
                              className="cursor-pointer"
                              textAlign="center">
                              {user.TestGroupSchools[0].NUM} 번
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {moment(
                                user.TestGroupSchools[0].UPDATE_DATE
                              ).format('YYYY-MM-DD')}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    {users && users.message && (
                      <Table.Row>
                        <Table.Cell colSpan="7">{users.message}</Table.Cell>
                      </Table.Row>
                    )}
                    <Table.Row>
                      <Table.Cell colSpan="7" className="text-center">
                        {users && users.datas && (
                          <Pagination
                            activePage={search.user.thisPage}
                            ellipsisItem={{
                              content: <Icon name="ellipsis horizontal" />,
                              icon: true,
                            }}
                            firstItem={{
                              content: <Icon name="angle double left" />,
                              icon: true,
                            }}
                            lastItem={{
                              content: <Icon name="angle double right" />,
                              icon: true,
                            }}
                            prevItem={{
                              content: <Icon name="angle left" />,
                              icon: true,
                            }}
                            nextItem={{
                              content: <Icon name="angle right" />,
                              icon: true,
                            }}
                            boundaryRange={0}
                            siblingRange={2}
                            pointing
                            secondary
                            onPageChange={this.searchUserPaginationChange}
                            totalPages={users.totalPage}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginLeft: '0px' }}>
              <Grid.Column>
                <Form.Group inline>
                  <Form.Field width={3} style={{ padding: '0px' }}>
                    <Button
                      fluid
                      size="large"
                      children="사용하기"
                      color="grey"
                      onClick={this.onClickUsed}
                    />
                  </Form.Field>
                  <Form.Field width={5} />
                  &nbsp;
                  <Form.Field
                    width={4}
                    style={{ padding: '0px' }}
                    error={p.password.error}>
                    <Input
                      name="password"
                      fluid
                      size="small"
                      type="password"
                      placeholder="암호"
                      value={password}
                      onChange={this.dataHandler}
                    />
                  </Form.Field>
                  &nbsp;
                  <Form.Field width={2} style={{ padding: '0px' }}>
                    <Button
                      fluid
                      size="small"
                      children="삭제"
                      onClick={this.onClickedDelete}
                    />
                  </Form.Field>
                  &nbsp;
                  <Form.Field width={2} style={{ padding: '0px' }}>
                    <Button
                      fluid
                      size="small"
                      children="수정"
                      onClick={this.onClickedModify}
                    />
                  </Form.Field>
                </Form.Group>
                &nbsp;
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }
}

Lists.propTypes = {
  eventHander: PropTypes.func,
  getSelectedItem: PropTypes.func,
};

export default Lists;
