import React, { Component, Fragment } from 'react';
import SubLink from './SubLink.js';
import { withRouter, Link, Redirect } from 'react-router-dom';
import * as actions from 'actions';
import * as mobile from 'actions/mobile';
import { connect } from 'react-redux';
import Toastr from 'modern-toastr';

import classNames from 'classnames';
import styles from './SubNavBar.css';

const cx = classNames.bind(styles);

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  isShow: state.mobilemenu.isShow,
  isMobileBtnShow: state.mobilemenu.isMobileBtnShow,
});

const mapDispatchToProps = (dispatch) => ({
  onBreadcrumbRemoveAdd: (breadcrumb, depth) =>
    dispatch(actions.breadcrumbRemoveAdd(breadcrumb, depth)),
  onMobileMenu: (isShow) => dispatch(mobile.mobileMenu(isShow)),
  onMobileLinkBtn: (isShow) => dispatch(mobile.mobileLinkBtn(isShow)),
});

class SubNavBar extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    //window.addEventListener('scroll', this.onScroll);

    const USER = JSON.parse(window.localStorage.getItem('user'));
    let grade = null;
    if (props.user && !USER) {
      window.localStorage.setItem('user', JSON.stringify(props.user));
      grade = props.user.grade;
    } else if (USER) {
      grade = USER.grade;
    }

    this.state = {
      link: this.props.link,
      reflash: false,
      target: '/',
      isSticky: false,
      grade,
    };
  }

  componentDidMount() {
    const mainapp = document.getElementById('mainapp');
    if (!mainapp) {
      const scriptmainapp = document.createElement('script');
      scriptmainapp.id = 'mainapp';
      scriptmainapp.src = process.env.PUBLIC_URL + '/assets/js/app.min.js';
      document.body.appendChild(scriptmainapp);
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps.link);
    if (this.props.link !== nextProps.link) {
      this.setState({ link: nextProps.link, links: nextProps.links });
      return true;
    } else {
      return false;
    }
  }

  componentWillUnmount() {
    //window.removeEventListener('scroll', this.onScroll);
    const mainapp = document.getElementById('mainapp');
    if (mainapp) {
      mainapp.parentNode.removeChild(mainapp);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //example 특정컴포넌트의 최상단(top)이 스크롤하여 가려져서 안보이게 되면(top<0) 특정 액션 실행하는 메소드
    if (this.state.scrollTop !== prevState.scrollTop) {
      if (this.state.scrollTop > 65) {
        this.setState({ isSticky: true });
      } else {
        this.setState({ isSticky: false });
      }
    }

    if (this.props.authenticated !== prevProps.authenticated) {
      let grade = null;
      if (this.props.authenticated) {
        grade = this.props.user.grade;
      }
      this.setState({ grade });
    }

    return true;
  }

  handleClick(i, isOpen) {
    if (isOpen) {
      const sublinks = this.state.link.sublinks.slice();
      for (const idx in sublinks) {
        if (i === parseInt(idx)) {
          sublinks[idx].isActive = true;
        } else {
          sublinks[idx].isActive = false;
        }
      }

      const lk = sublinks.filter((link) => {
        return link.isActive;
      })[0];

      this.props.onBreadcrumbRemoveAdd({ path: lk.path, title: lk.text }, 1);
      this.forceUpdate();
    } else {
      Toastr.info(
        '해당 서비스는 현재 준비중입니다. 다른 서비스를 이용해 주세요.'
      );
    }
  }

  handleClickMobileDic = (e) => {
    e.preventDefault();
    const { link, history, location } = this.props;
    if (link.path === '/views/dictionary') {
      this.props.onMobileLinkBtn(false);

      if (
        location.pathname !== undefined &&
        location.pathname !== null &&
        location.pathname !== ''
      ) {
        var arrPath = location.pathname.split('/');
        var moveDic = arrPath[3];
        switch (moveDic) {
          case 'syn':
          case 'ant':
          case 'dial':
          case 'rev':
          case 'std':
            history.push('/views/mobile/dictionary/' + arrPath[3]);
            break;
          case 'han':
            Toastr.info(
              '한자어휘집은 현재 모바일 페이지 서비스를 제공하고 있지 않습니다.'
            );
            break;
          case 'onom':
            Toastr.info(
              '의성.의태어는 현재 모바일 페이지 서비스를 제공하고 있지 않습니다.'
            );
            break;
          default:
            history.push('/views/mobile/dictionary/syn');
            break;
        }
      } else {
        history.push('/views/mobile/dictionary/syn');
      }
    } else {
      Toastr.info('현재 모바일 페이지는 사전 서비스만 제공되고 있습니다.');
      history.push('/views/dictionary');
    }
  };

  handleClickPcDic = (e) => {
    e.preventDefault();
    const { history, location } = this.props;
    this.props.onMobileLinkBtn(true);

    if (
      location.pathname !== undefined &&
      location.pathname !== null &&
      location.pathname !== ''
    ) {
      var arrPath = location.pathname.split('/');
      var moveDic = arrPath[4];
      switch (moveDic) {
        case 'syn':
        case 'ant':
        case 'dial':
        case 'rev':
        case 'std':
          history.push('/views/dictionary/' + arrPath[4]);
          break;
        default:
          history.push('/views/dictionary/syn');
          break;
      }
    } else {
      history.push('/views/dictionary');
    }
  };

  goUrl(e) {
    e.preventDefault();
    //this.setState({ reflash: true, target: '/' });
    window.location.href = '/';
    //this.props.history.push({ pathname: '/' });
  }
  /*
	onScroll = e => {
		// 스크롤 할때마다 state에 scroll한 만큼 scrollTop 값 증가하므로 이를 업데이트해줌,
		//따라서 스크롤 시점에 따라 특정액션을 추후에 state를 활용하여 구현 가능
		const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop);
		if (scrollTop) {
			this.setState({ scrollTop: scrollTop });
		}
	};
*/
  onMobileOpen = (e) => {
    e.preventDefault();
    const { isShow } = this.props;
    if (isShow) {
      this.props.onMobileMenu(false);
    } else {
      this.props.onMobileMenu(true);
    }
  };

  render() {
    const { link, reflash, target, isSticky, grade } = this.state;
    const { isMobileBtnShow } = this.props;
    if (reflash) return <Redirect to={target} />;
    const css = isSticky ? 'shrink fixed-top' : '';

    return (
      <nav
        className={
          'navbar navbar-expand-md navbar-static ms-navbar ms-navbar-dark navbar-mode ' +
          css
        }
      >
        <div
          className="container container-full"
          style={{ height: '100%!important' }}
        >
          <div className={cx('navbar-header', 'custom-header')}>
            <div className="navbar-brand" style={{ marginLeft: '-15px' }}>
              <Link to="/" onClick={(e) => this.goUrl(e)}>
                <img
                  src={process.env.PUBLIC_URL + '/assets/imgs/Logo_40_m_w.png'}
                  alt=""
                />
              </Link>
              <Link
                to={link.path}
                className={cx('s-title', 'ml-1', 'color-white')}
                onClick={(e) => this.props.onClick(e)}
              >
                <h4 className="custom-h4">
                  {link.text}
                  {link.subtext}
                </h4>
              </Link>
            </div>
          </div>
          <div
            className={cx('collapse', 'navbar-collapse', 'custom-collapse')}
            id="ms-navbar"
          >
            <ul className="nav navbar-nav">
              {link.sublinks.map((sublink, i) => {
                if (sublink.grade !== undefined && grade === sublink.grade) {
                  return (
                    <SubLink
                      path={sublink.path}
                      text={sublink.text}
                      isActive={sublink.isActive}
                      isOpen={sublink.isOpen}
                      isPop={sublink.isPop}
                      key={'sublinks_' + i}
                      dropDown={sublink.dropdown}
                      onClick={() => this.handleClick(i, sublink.isOpen)}
                    />
                  );
                } else if (sublink.grade == null) {
                  return (
                    <SubLink
                      path={sublink.path}
                      text={sublink.text}
                      isActive={sublink.isActive}
                      isOpen={sublink.isOpen}
                      isPop={sublink.isPop}
                      key={'sublinks_' + i}
                      dropDown={sublink.dropdown}
                      onClick={() => this.handleClick(i, sublink.isOpen)}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          </div>
          {
            // eslint-disable-next-line
            <Fragment>
              {isMobileBtnShow && (
                <a
                  href="#"
                  className="ms-toggle-left btn-navbar-menu show-pc"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="zmdi zmdi-menu" />
                </a>
              )}

              {!isMobileBtnShow && (
                <div className="show-pc">
                  <div>
                    <a
                      href="#"
                      className="ms-toggle-left btn-navbar-menu"
                      onClick={(e) => e.preventDefault()}
                      style={{ marginRight: '40px' }}
                    >
                      <i className="zmdi zmdi-menu" />
                    </a>
                  </div>
                  <div>
                    <Link
                      to="#"
                      className="btn-navbar-menu"
                      onClick={this.handleClickPcDic}
                    >
                      <i className="zmdi zmdi-desktop-mac" />
                    </Link>
                  </div>
                </div>
              )}

              <div className="show-mobile">
                <div>
                  <a
                    href="#"
                    className="ms-toggle-left btn-navbar-menu"
                    onClick={(e) => e.preventDefault()}
                    style={{ marginRight: '40px' }}
                  >
                    <i className="zmdi zmdi-menu" />
                  </a>
                </div>
                <div className="btn-mobile-dic">
                  {isMobileBtnShow && (
                    <Link
                      to="#"
                      className="btn-navbar-menu"
                      onClick={this.handleClickMobileDic}
                    >
                      <i className="zmdi zmdi-smartphone" />
                    </Link>
                  )}
                  {!isMobileBtnShow && (
                    <Link
                      to="#"
                      className="btn-navbar-menu"
                      onClick={this.handleClickPcDic}
                    >
                      <i className="zmdi zmdi-desktop-mac" />
                    </Link>
                  )}
                </div>
              </div>
            </Fragment>
          }
        </div>
      </nav>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubNavBar)
);
