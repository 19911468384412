/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Radio, Row, Col, List, Badge, Divider, Button } from 'antd';
import { connect } from 'react-redux';
import * as actions from 'actions';
var nlp = require('compromise');

/*
const mapStateToProps = state => ({
	lqInfosText: state.info.lqInfosText,
});
*/
const mapDispatchToProps = dispatch => ({
	onSearchDeco: highlights => dispatch(actions.onSearchDeco(highlights)),
});

const division = (arr, n) => {
	const len = arr.length;
	const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
	const tmp = [];
	for (let i = 0; i < cnt; i++) {
		tmp.push(arr.splice(0, n));
	}
	return tmp;
};

const styles = {
	scrollContainer: {
		border: '1px solid rgb(243, 171, 40)',
		borderRadius: '4px',
		overflow: 'auto',
		padding: '8px 10px',
		margin: '10px',
		height: '300px',
	},
	antListItem: {
		display: 'flex',
		alignItems: 'center',
		padding: '0px',
		cursor: 'pointer',
		borderBottom: '1px solid #e8e8e8',
	},
	badge: {
		backgroundColor: 'rgb(243, 171, 40)',
		color: '#fff',
		boxShadow: '0 0 0 1px #f79f00 inset',
	},
};

const getUniqueObjectArray = (array, key) => {
	return array.filter((item, i) => {
		return (
			array.findIndex((item2, j) => {
				return item[key] === item2[key];
			}) === i
		);
	});
};

class DistinctInfo extends Component {
	state = {
		eojel: '1',
		data: [],
		filterDatas: [],
		list: [],
		t1: 0,
		t2: 0,
		t3: 0,
		initLoading: true,
		loading: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			text: props.lqInfosText,
			selectedKey: props.selectedKey,
		};
	}

	getData = text => {
		return new Promise(resolve => {
			const _text = text.replace(/,/g, '#q#');
			var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
			const t = nlp(_text)
				.ngrams({ max: 3 })
				.data()
				.filter(i => i.count > 1 && i.normal.length > 1 && !regExp.test(i.normal))
				.map(i => {
					return { ...i, normal: i.normal.replace(/#q#/g, ',') };
				});
			return resolve(t);
		});
	};

	onLoadMore = () => {
		const { filterDatas, currentPage } = this.state;
		if (filterDatas.length > currentPage + 1) {
			this.setState(
				{
					loading: false,
					list: this.state.list.concat(filterDatas[currentPage + 1]),
					currentPage: currentPage + 1,
					initLoading: false,
				},
				() => {
					// Resetting window's offsetTop so as to display react-virtualized demo underfloor.
					// In real scene, you can using public method of react-virtualized:
					// https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
					window.dispatchEvent(new Event('resize'));
				}
			);
		}
	};

	async componentDidMount() {
		if (this.state.text != null) {
			const t = await this.getData(this.state.text);
			const _t = t.filter(i => i.size === parseInt(this.state.eojel));
			const _t1 = t.filter(i => i.size === 1);
			const _t2 = t.filter(i => i.size === 2);
			const _t3 = t.filter(i => i.size === 3);
			const itemCount = 10;
			const filterDatas = division(_t, itemCount);
			this.setState({
				datas: t,
				filterDatas,
				list: filterDatas[0],
				t1: _t1.length,
				t2: _t2.length,
				t3: _t3.length,
				initLoading: false,
				currentPage: 0,
			});
		}
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	componentWillReceiveProps(nextProps) {
		const { lqInfosText, selectedKey } = nextProps;
		this.setState({ text: lqInfosText, selectedKey });
	}

	async componentDidUpdate(prevProps, prevState) {
		if (this.state.text !== prevState.text) {
			const t = await this.getData(this.state.text);
			const results = [];
			this.state.datas.concat(t).reduce((acc, obj) => {
				if (!acc[obj.normal]) {
					acc[obj.normal] = { ...obj };
					results.push(acc[obj.normal]);
				} else {
					acc[obj.normal].count += obj.count;
				}
				return acc;
			}, {});
			results.sort((a, b) => {
				if (a.count < b.count) {
					return 1;
				}
				if (a.count > b.count) {
					return -1;
				}
				// a must be equal to b
				return 0;
			});
			const _t = results.filter(i => i.size === parseInt(this.state.eojel));
			const _t1 = results.filter(i => i.size === 1);
			const _t2 = results.filter(i => i.size === 2);
			const _t3 = results.filter(i => i.size === 3);
			const itemCount = 10;
			const filterDatas = division(_t, itemCount);
			this.setState({
				datas: results,
				filterDatas,
				list: filterDatas[0],
				t1: _t1.length,
				t2: _t2.length,
				t3: _t3.length,
				initLoading: false,
				currentPage: 0,
			});
		}

		if (this.state.eojel !== prevState.eojel) {
			if (this.state.datas != null) {
				const _t = this.state.datas.filter(i => i.size === parseInt(this.state.eojel));
				const itemCount = 10;
				const filterDatas = division(_t, itemCount);
				this.setState({ filterDatas, list: filterDatas[0], initLoading: false, currentPage: 0 });
			}
		}
		if (this.state.selectedKey !== prevState.selectedKey) {
			if (this.state.selectedKey !== '3') {
				this.props.onSearchDeco([]);
			}
		}
	}

	onClick = (e, search) => {
		e.preventDefault();
		const searchs = [];
		searchs.push(search);
		this.props.onSearchDeco(searchs);
	};

	render() {
		const { t1, t2, t3, initLoading, loading, list, datas, filterDatas, currentPage } = this.state;

		const loadMore =
			!initLoading && !loading && datas.length > 10 && filterDatas[currentPage + 1] != null ? (
				<div
					style={{
						textAlign: 'center',
						marginTop: 12,
						height: 32,
						lineHeight: '32px',
					}}>
					<Button onClick={this.onLoadMore}>더보기</Button>
				</div>
			) : null;

		return (
			<Fragment>
				<div
					style={{
						padding: '8px 10px',
						margin: '0',
						background: '#fff',
					}}>
					<Row>
						<Col style={{ textAlign: 'center' }}>
							<Radio.Group
								name="eojel"
								value={this.state.eojel}
								size="small"
								onChange={this.onChange}
								style={{ marginTop: '10px' }}>
								<Badge count={t1} showZero style={{ backgroundColor: 'rgb(243, 171, 40)' }}>
									<Radio.Button value="1" className="head-example">
										1어절
									</Radio.Button>
								</Badge>
								<Badge count={t2} showZero style={{ backgroundColor: 'rgb(243, 171, 40)' }}>
									<Radio.Button value="2" className="head-example">
										2어절
									</Radio.Button>
								</Badge>
								<Badge count={t3} showZero style={{ backgroundColor: 'rgb(243, 171, 40)' }}>
									<Radio.Button value="3" className="head-example">
										3어절
									</Radio.Button>
								</Badge>
							</Radio.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<div style={styles.scrollContainer}>
								<List
									loading={initLoading}
									itemLayout="horizontal"
									dataSource={list}
									loadMore={loadMore}
									renderItem={item => (
										<List.Item
											key={item.normal}
											style={styles.antListItem}
											onClick={e => this.onClick(e, item.normal)}>
											<List.Item.Meta title={item.normal} />
											<Badge count={item.count} style={styles.badge} />
										</List.Item>
									)}
								/>
							</div>
						</Col>
					</Row>
				</div>
			</Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(DistinctInfo);
