import * as types from 'actions/ActionTypes';

const initalState = {
	editorStatus: null,
};

const editor = (state = initalState, action) => {
	switch (action.type) {
		case types.GET_EDITOR_STATE:
			return Object.assign({}, state, { editorStatus: types.GET_EDITOR_STATE });
		case types.GET_EDITOR_STATE_OK:
			return Object.assign({}, state, { editorStatus: types.GET_EDITOR_STATE_OK });
		default:
			return state;
	}
};

export default editor;
