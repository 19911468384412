import React from 'react';
import { Component } from 'react';
import './LexileTest.css';
import { withRouter } from 'react-router-dom';
import * as service from 'services/etests';
import * as actions from 'actions';
import { connect } from 'react-redux';
import LimitTimer from './LimitTimer';
import 'modern-toastr/dist/modern-toastr.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import classNames from 'classnames/bind';
import css from './LexileTest.css';
import { Form, Radio, Responsive } from 'semantic-ui-react';
import { Button } from 'antd';

const cx = classNames.bind(css);

const START = 'START';
const STOP = 'STOP';
const WHAT_TIME = 'WHAT_TIME';
const INIT = 'INIT';

const RESET_COUNT = 'RESET_COUNT';
const WHAT_COUNT = 'WHAT_COUNT';

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logout()),
});

class LexileTest extends Component {
  fetchPostInfo = async (datas) => {
    let req_data = { datas };
    const post = await service.api(
      this,
      '/api/start/post/eng',
      req_data,
      this.props.handleClose
    );
    if (post.status === 200) {
      let postdata = post.data.results;
      const allTestBanks = postdata.testbankItems;
      this.setState({ allTestBanks });

      //답안지 초기화
      let testHistorys = allTestBanks.map((testBank) => {
        let qno = testBank.ID.toString();
        let answer = 0;
        let time = 0;
        return { qno, answer, time };
      });

      this.setState({ timerStatus: STOP, qnos: [], answers: [], times: [] });

      //답안지 생성
      this.fetchHistroy({
        testInfoId: this.state.testInfo.ID,
        testHistorys: testHistorys,
        sumTime: this.state.limitTime,
      });
    }
  };

  fetchHistroy = async (datas) => {
    let req_data = { datas };
    const post = await service.api(
      this,
      '/api/start/history',
      req_data,
      this.props.handleClose
    );
    if (post.status === 200) {
      //답안지 로드
      let postdata = post.data.results;

      //정상로드체크
      let qnos = postdata.Q_NO.split('|');
      let answers = postdata.ANSWER.split('|');
      let times = postdata.TIME.split('|');
      let sumTime = postdata.SUM_TIME;
      let remainCount = answers.filter((item) => item === '0').length;

      //시험 시작
      this.setState({
        timerStatus: START,
        limitTime: sumTime,
        remainCount: remainCount,
        qnos,
        answers,
        times,
      });
    }
  };

  fetchHistroyUpdate = async (datas) => {
    let req_data = { datas };
    const post = await service.api(
      this,
      '/api/start/history/update',
      req_data,
      this.props.handleClose
    );
    if (post.status === 200) {
      //업데이트 성공여부
      //let postdata = post.data;
    }
  };

  fetchStatusUpdate = async (datas) => {
    let req_data = { datas };
    const post = await service.api(
      this,
      '/api/start/status/update',
      req_data,
      null
    );
    if (post.status === 200) {
      //업데이트 성공여부
      //let postdata = post.data;
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      ...props,
      qnos: [],
      answers: [],
      times: [],
      allTestBanks: [],
      currentTestBanks: [],
      currentPage: null,
      totalPages: null,
      limitTime: parseInt(props.levelInfo.time) * 60,
      sumCount: props.levelInfo.count,
      remainCount: props.levelInfo.count,
      timeCount: 0,
      timerStatus: STOP,
      timeCountStatus: INIT,
    };
  }

  timerCount = (count) => {
    this.setState({ timeCount: count, timeCountStatus: RESET_COUNT });
  };

  onPageChanged = (data) => {
    const { allTestBanks } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTestBanks = allTestBanks.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentTestBanks, totalPages, timeCount: 0 });
  };

  componentDidMount() {
    if (this.state.isOpen) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
      this.fetchPostInfo({ testConfig: this.props.testConfig });
    }
  }

  /*
	componentWillReceiveProps(nextProps) {
		if (this.props.isOpen !== nextProps.isOpen) {
			if (nextProps.isOpen) {
				this.setState({ ...nextProps });
				//문항 및 히스토리 로드
				this.fetchPostInfo({ testConfig: nextProps.testConfig });
			} else {
				this.setState({
					qnos: [],
					answers: [],
					times: [],
					allTestBanks: [],
					currentTestBanks: [],
					currentPage: null,
					totalPages: null,
					limitTime: parseInt(nextProps.levelInfo.time) * 60,
					sumCount: nextProps.levelInfo.count,
					remainCount: nextProps.levelInfo.count,
					timeCount: 0,
					timerStatus: STOP,
				});
			}
			return true;
		}
		return true;
	}
*/

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
    this.setState({ timerStatus: STOP });
  }

  convertTestBankItem(source) {
    return source
      .replace(/</g, '『')
      .replace(/>/g, '』')
      .replace(/『i』/g, '<i>')
      .replace(/『\/i』/g, '</i>')
      .replace(/\/\^/g, "'")
      .replace(/\^\//g, "'")
      .replace(/\\n/g, '<br/>')
      .replace(/ /g, '&nbsp;')
      .replace(/@/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
      .replace(/\/\$/g, '<span class="underline-custom-eng">')
      .replace(/\$\//g, '</span>');
  }

  handleChange = (e, { name, value }) => {
    const { answers, times, timeCount } = this.state;
    answers[name] = value;
    times[name] = (parseInt(times[name]) + timeCount).toString();
    this.setState({ answers, times });
  };

  onClickAnswers = (e, index) => {
    const selectedIndex = this.state.answers[index];
    if (!parseInt(selectedIndex)) {
      this.setState({
        remainCount: this.state.remainCount - 1,
      });
    }
    this.setState({ timerStatus: WHAT_TIME, timeCountStatus: WHAT_COUNT });
  };

  getCurrentTime = (currentTime) => {
    this.setState({ currentTime: currentTime, timerStatus: INIT });
    let sumTime = currentTime;
    let qnos = this.state.qnos.join('|');
    let answers = this.state.answers.join('|');
    let times = this.state.times.join('|');
    let testInfoId = this.state.testInfo.ID;
    //히스토리 저장
    this.fetchHistroyUpdate({ testInfoId, qnos, answers, times, sumTime });
  };

  timeOver = (currentTime) => {
    let sumTime = currentTime;
    let qnos = this.state.qnos.join('|');
    let answers = this.state.answers.join('|');
    let times = this.state.times.join('|');
    let testInfoId = this.state.testInfo.ID;
    const div = this.state.testInfo.DIV;
    //히스토리 저장
    this.fetchHistroyUpdate({ testInfoId, qnos, answers, times, sumTime });
    this.fetchStatusUpdate({ testInfoId, status: 'TIME_OVER' });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="lqtest-custom-ui">
            <h3>검사 종료</h3>
            <p>시간이 초과되었습니다.</p>
            {div.trim() === 'USER' && (
              <p>결과는 마이페이지의 보관함에서 확인할 수 있습니다. </p>
            )}
            <button
              onClick={() => {
                this.props.handleClose('timeover');
                onClose();
              }}
            >
              확인
            </button>
          </div>
        );
      },
    });
  };

  onClickClose(e) {
    e.preventDefault();
    // reamin count 완료
    //console.log(this.state.remainCount);
    const testInfo = this.state.testInfo;
    if (testInfo) {
      const div = testInfo.DIV;

      if (this.state.remainCount > 0) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="lqtest-custom-stop-ui">
                <h3>검사를 중지 하시겠습니까?</h3>
                {div.trim() === 'GROUP' && <p>남은 문항은 재접속 시</p>}
                {div.trim() === 'USER' && (
                  <p>남은 문항은 마이페이지의 보관함에서 </p>
                )}
                <p>이어서 검사하실 수 있습니다.</p>
                <div>
                  <Button onClick={onClose}>계속 응시</Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      const testInfoId = testInfo.ID;
                      this.fetchStatusUpdate({ testInfoId, status: 'PAUSE' });
                      this.props.handleClose('pause');
                      onClose();
                    }}
                  >
                    중지
                  </Button>
                </div>
              </div>
            );
          },
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="lqtest-custom-ui">
                <h3>검사 완료</h3>
                <p>수고하셨습니다. 검사가 완료되었습니다.</p>
                {div.trim() === 'USER' && (
                  <p>결과는 마이페이지의 보관함에서 확인할 수 있습니다. </p>
                )}
                <button
                  onClick={() => {
                    const testInfoId = testInfo.ID;
                    this.fetchStatusUpdate({ testInfoId, status: 'FINISH' });
                    this.props.handleClose('finish');
                    onClose();
                  }}
                >
                  확인
                </button>
              </div>
            );
          },
        });
      }
    }
  }

  render() {
    const {
      title,
      subLabel,
      limitTime,
      remainCount,
      allTestBanks,
      timerStatus,
      answers,
      timeCountStatus,
      timeCount,
    } = this.state;
    const { isOpen } = this.props;
    const showHideClassName = isOpen
      ? 'modal modal-custom-eng modal-dark display-block animated fadeIn '
      : 'modal modal-custom-eng modal-dark';

    return (
      <div className={showHideClassName} tabIndex="-1" role="dialog">
        <div
          style={{ margin: '0px!important' }}
          className="modal-dialog-custom-eng"
          role="document"
        >
          <div
            id="carousel-example-generic"
            className={cx(
              'modal-content',
              'modal-content-custom-eng',
              'ms-carousel',
              'carousel',
              'slide'
            )}
            data-ride="carousel"
            data-interval="false"
          >
            <div className="modal-header modal-header-custom-eng bg-dark">
              <div className="container color-white">
                <div className="row">
                  <div className="col-12 col-md-5" style={{ margin: 'auto' }}>
                    <div className="row">
                      <div
                        className="col-12 col-md-6"
                        style={{ margin: 'auto', textAlign: 'right' }}
                      >
                        <h3>{title}</h3>
                      </div>
                      <div
                        className="col-12 col-md-6"
                        style={{ margin: 'auto', textAlign: 'left' }}
                      >
                        <span>{subLabel}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7" style={{ margin: 'auto' }}>
                    <div className="row">
                      <div className="col-md-3" />
                      <div className="col-10 col-md-7">
                        <div className="row">
                          <div
                            className="col-12 col-md-6 p-1"
                            style={{ margin: 'auto' }}
                          >
                            <h3 className="m-0">
                              <small>
                                <span className="badge mr-1">남은문항</span>
                              </small>
                              {remainCount}
                            </h3>
                          </div>
                          <div
                            className="col-12 col-md-6 p-1"
                            style={{ margin: 'auto' }}
                          >
                            <h3 className="m-0">
                              <small>
                                <span className="badge mr-1">남은시간</span>
                              </small>
                              <LimitTimer
                                limitTime={limitTime}
                                status={timerStatus}
                                countStatus={timeCountStatus}
                                timerCount={this.timerCount}
                                getCurrentTime={this.getCurrentTime}
                                timeOver={this.timeOver}
                                count={timeCount}
                              />
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 col-md-2">
                        <button
                          type="button"
                          className="btn-circle btn-circle-white"
                          data-dismiss="modal"
                          onClick={(e) => this.onClickClose(e)}
                        >
                          <i className="fas fa-times" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body modal-body-custom-eng-desktop">
              <div className="container">
                <div className="row">
                  <div className="col-12 mt-2 mb-4">
                    {remainCount === 0 && (
                      <div
                        className="alert alert-success alert-light alert-dismissible ml-36 mr-36"
                        role="alert"
                      >
                        <div className="row justify-content-center align-items-center">
                          <div className="col-12 col-md-6 text-center">
                            <strong>
                              <i className="zmdi zmdi-check" />
                            </strong>{' '}
                            남은 문항이 없습니다. <strong>평가 완료</strong>{' '}
                            버튼을 클릭해 주세요.
                          </div>
                          <div className="col-12 col-md-6 text-center">
                            <button
                              type="button"
                              className="btn btn-raised btn-success"
                              onClick={(e) => this.onClickClose(e)}
                            >
                              평가완료
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={cx(
                        'carousel-inner',
                        'carousel-inner-custom-eng',
                        'col-11'
                      )}
                      role="listbox"
                    >
                      {allTestBanks.map((item, index) => {
                        const answer =
                          this.state.answers.length > 0
                            ? this.state.answers[index]
                            : '0';

                        return (
                          <div
                            key={'desk_' + index}
                            className={
                              'carousel-item ' + (index === 0 ? 'active' : '')
                            }
                          >
                            <div className="card m-3">
                              <div className="card-header">
                                <h2>
                                  {item.Q_NO}.&nbsp;
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: this.convertTestBankItem(
                                        item.STEM
                                      ),
                                    }}
                                  />
                                </h2>
                              </div>
                              {item.ITEM_TEXT && (
                                <div className="card-body">
                                  <p
                                    className="well well-lg"
                                    dangerouslySetInnerHTML={{
                                      __html: this.convertTestBankItem(
                                        item.ITEM_TEXT
                                      ),
                                    }}
                                  />
                                </div>
                              )}
                              <div className="card-footer bg-white">
                                <Form size="big" key="big">
                                  <Form.Field key={'item_' + index}>
                                    <Radio
                                      name={index.toString()}
                                      value={'1'}
                                      checked={answer === '1'}
                                      onChange={this.handleChange}
                                      onClick={(e) =>
                                        this.onClickAnswers(e, index)
                                      }
                                      label={{
                                        style: {
                                          fontSize: '1.4em',
                                        },
                                        dangerouslySetInnerHTML: {
                                          __html:
                                            'A.&nbsp;' +
                                            this.convertTestBankItem(item.A),
                                        },
                                      }}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      name={index.toString()}
                                      value={'2'}
                                      checked={answer === '2'}
                                      onChange={this.handleChange}
                                      onClick={(e) =>
                                        this.onClickAnswers(e, index)
                                      }
                                      label={{
                                        style: {
                                          fontSize: '1.4em',
                                        },
                                        dangerouslySetInnerHTML: {
                                          __html:
                                            'B.&nbsp;' +
                                            this.convertTestBankItem(item.B),
                                        },
                                      }}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      name={index.toString()}
                                      value={'3'}
                                      checked={answer === '3'}
                                      onChange={this.handleChange}
                                      onClick={(e) =>
                                        this.onClickAnswers(e, index)
                                      }
                                      label={{
                                        style: {
                                          fontSize: '1.4em',
                                        },
                                        dangerouslySetInnerHTML: {
                                          __html:
                                            'C.&nbsp;' +
                                            this.convertTestBankItem(item.C),
                                        },
                                      }}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      name={index.toString()}
                                      value={'4'}
                                      checked={answer === '4'}
                                      onChange={this.handleChange}
                                      onClick={(e) =>
                                        this.onClickAnswers(e, index)
                                      }
                                      label={{
                                        style: {
                                          fontSize: '1.4em',
                                        },
                                        dangerouslySetInnerHTML: {
                                          __html:
                                            'D.&nbsp;' +
                                            this.convertTestBankItem(item.D),
                                        },
                                      }}
                                    />
                                  </Form.Field>
                                </Form>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer modal-footer-custom-eng-desktop bg-dark p-0">
              <div className="container mt-1">
                <div className="row">
                  <div className="col-1">
                    <Responsive {...Responsive.onlyComputer}>
                      <span
                        href="#carousel-example-generic"
                        className="btn-circle btn-circle-success color-white btn-circle-raised"
                        role="button"
                        data-slide="prev"
                      >
                        <i className="zmdi zmdi-chevron-left" />
                      </span>
                    </Responsive>
                  </div>
                  <div className="col-10">
                    <ol className="carousel-indicators carousel-indicators-tumbs carousel-indicators-tumbs-outside ">
                      {allTestBanks.map((item, index) => (
                        <li
                          key={'desk_item_' + index}
                          data-target="#carousel-example-generic"
                          data-slide-to={index}
                          className={
                            'btn-circle color-black ' +
                            (index === 0 ? 'active' : '')
                          }
                        >
                          <div
                            className={
                              answers[index] !== '0' ? 'checked-answer' : ''
                            }
                          >
                            {index + 1}
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>
                  <div className="col-1">
                    <Responsive {...Responsive.onlyComputer}>
                      <span
                        href="#carousel-example-generic"
                        className="btn-circle btn-circle-success color-white btn-circle-raised"
                        role="button"
                        data-slide="next"
                      >
                        <i className="zmdi zmdi-chevron-right" />
                      </span>
                    </Responsive>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LexileTest.defaultProps = {
  classes: false,
};

export default connect(null, mapDispatchToProps)(withRouter(LexileTest));
