import * as types from 'actions/ActionTypes';
/*
	 level(수준별): 0: 어렵게 1: 쉽게 2: 전체
	 base1: true  : 일상
	 base2: true : 정서
	 base3: true : 학술 기본
	 checkedKeys: [] 학술 전문1 array String
	 AND 조건
*/
const initialState = {
	synos: [],
	filters: { level: 2, base1: false, base2: false, base3: false, checkedKeys: [] },
};

const syno = (state = initialState, action) => {
	switch (action.type) {
		case types.SYNO_DECO:
			return Object.assign({}, state, { synos: action.synos });
		case types.SYNO_FILTERS:
			return Object.assign({}, state, { filters: action.filters });
		default:
			return state;
	}
};

export default syno;
