/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DecosFunc } from 'sentence/components/utils';

const Iframe = React.createRef();

const mapStateToProps = state => ({
	user: state.auth.user,
});

class DecoDictionary extends Component {
	state = { height: 800 };

	constructor(props) {
		super(props);
		//console.log(props);
		this.state = {
			...this.state,
			word_no: props.word_no,
			iframeSrc: DecosFunc.createMiniDicIframeSrc(this.props.user, 'dic-std', props.word_no)
		};

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.postMessage = this.postMessage.bind(this);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	postMessage() {
		const { user } = this.props;
		const { word_no } = this.state;
		if (Iframe != null) {
			this.setState({
				iframeSrc: DecosFunc.createMiniDicIframeSrc(user, 'dic-std', word_no)
			});
		}
		/*
		const { word_no } = this.props;
		if (Iframe != null) {
			var data = {
				dicCode: 'dic-std',
				wordNo: word_no,
			};
			Iframe.current.contentWindow.postMessage(data, '*');
		}
		*/
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		window.addEventListener('message', this.postMessage);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ word_no: nextProps.word_no });
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		window.removeEventListener('message', this.postMessage);
	}

	componentDidUpdate(prevProps, prevState) {
		const { user } = this.props;
		const { word_no } = this.state;
		if (prevState.word_no !== word_no) {
			if (Iframe != null) {
				this.setState({
					iframeSrc: DecosFunc.createMiniDicIframeSrc(user, 'dic-std', word_no)
				});
				/*
				Iframe.current.contentWindow.postMessage(
					{
						dicCode: 'dic-std',
						wordNo: this.state.word_no,
					},
					'*'
				);
				*/
			}
		}
	}

	render() {
		//const { userEmail } = this.props.user;
		const { iframeSrc } = this.state;
		return (			
			<Fragment>
				<div style={{ height: '100%', marginTop: '0', padding: '0' }}>
					{/*}
					<iframe
						ref={Iframe}
						height={this.state.height}
						src={
							'https://minidic.natmal.com/?linkType=SENTENCE&userId=' +
							(userEmail != null ? userEmail : '')
						}
						style={{ border: 'none', margin: '0', padding: '0', width: '500px' }}
					/>
					*/}
					<iframe
						ref={Iframe}
						height={this.state.height}
						src={iframeSrc}
						style={{ border: 'none', margin: '0', padding: '0', width: '500px' }}
					/>
				</div>
			</Fragment>
					
		);
	}
}

export default connect(
	mapStateToProps,
	null
)(DecoDictionary);
