import axios from 'axios';
import 'react-app-polyfill/ie11';
import * as types from 'actions/ActionTypes';

class survey {
	surveyCreate = datas => {
		return new Promise((resolve, reject) => {
			axios
				.post(types.LOCAL_API_URL + '/api/survey/create', { params: { ...datas } }, { withCredentials: true })
				.then(response => {
					if (response.data) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				});
		});
	};
}

const instance = new survey();
export default instance;
