import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Tooltip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import './PriceList.css';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    border: '1px solid #e8e8e8',
    borderRadius: '10px',
    background: 'linear-gradient(white, #e8e8e8);',
  },
  tabsIndicator: {
    backgroundColor: '#ff9800',
  },
  tabRoot: {
    borderRight: '1px solid #e8e8e8',
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    //marginRight: theme.spacing.unit * 4,
    fontSize: 16,
    fontFamily: ['Noto Sans KR', 'sans-serif'].join(','),
    '&:hover': {
      color: '#ff9800',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#ff9800',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#ff9800',
    },
  },
  tabSelected: {
    backgroundColor: 'white',
  },
  typography: {
    padding: theme.spacing.unit * 3,
  },
  iconLightTooltip: {
    padding: 0,
    color: '#ff9800',
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

class PriceList extends Component {
  state = {
    value: 0,
  };

  componentDidMount() {
    this.setState({ value: 0 });
  }

  componentWillUnmount() {
    this.setState({ value: 0 });
  }

  handleChange = (event, value) => {
    //console.log(value);
    this.setState({ value });
  };

  render() {
    // eslint-disable-next-line

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <Fragment>
        <div id="price-list">
          <button
            type="button"
            width="20px"
            className="ms-conf-btn ms-configurator-btn btn btn-raised btn-instagram"
            style={{
              padding: '15px',
              textAlign: 'center',
              width: '20px',
            }}
            data-toggle="modal"
            data-target="#modalPriceList"
          >
            {/*<i className="fas fa-question" />*/}
            <p
              style={{
                textOrientation: 'upright',
                writingMode: 'vertical-rl',
                padding: '0px',
                margin: '0px',
                lineHeight: '0px',
                textAlign: 'center',
              }}
            >
              {/* <RightOutlined /> */}
              유료 서비스 안내
            </p>
          </button>
          {/*
          <p
            style={{
              position: 'fixed',
              textOrientation: 'upright',
              writingMode: 'vertical-rl',
              right: '12px',
              top: '190px',
              backgroundColor: 'orange',
              padding: '30px 12px',
              borderRadius: '25px',
              color: 'white',
              fontSize: '1.5rem',
            }}>
            서비스 안내
          </p>
          */}
        </div>
        <div
          className="modal"
          id="modalPriceList"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalPriceListLabel"
        >
          <div
            className="modal-dialog modal-lg animated zoomIn animated-3x"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title color-dark" id="modalPriceListLabel">
                  <img src="/assets/imgs/Logo_40_s.png" alt="" /> 서비스
                  이용안내
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="zmdi zmdi-close" />
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="card card-flat">
                  <div className={classes.root}>
                    <Tabs
                      value={value}
                      onChange={this.handleChange}
                      classes={{
                        root: classes.tabsRoot,
                        indicator: classes.tabsIndicator,
                      }}
                      variant="fullWidth"
                    >
                      <Tab
                        disableRipple
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        style={{ background: '#cccccc!important' }}
                        label="사전"
                        icon={<i className="plat-icon icon icon-dic" />}
                      />
                      <Tab
                        disableRipple
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="독서"
                        icon={<i className="plat-icon icon icon-reading" />}
                      />
                      <Tab
                        disableRipple
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="낱말퍼즐"
                        icon={<i className="plat-icon icon icon-maze" />}
                      />
                      <Tab
                        disableRipple
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="영어"
                        icon={<i className="plat-icon icon icon-english" />}
                      />
                      <Tab
                        disableRipple
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="문장검사"
                        icon={<i className="plat-icon icon icon-analysis" />}
                      />
                    </Tabs>
                  </div>

                  <div className="card-body no-pl no-pr">
                    <div className="tab-content">
                      {/* -------------------------------------------------------------------- */}
                      {/* 사전요금 ------------------------------------------------------------- */}
                      {/* -------------------------------------------------------------------- */}

                      {value === 0 && (
                        <div>
                          <div className="text-center">
                            <h4>
                              <i className="plat-icon icon icon-dic" />
                              &nbsp; 사전 서비스 이용안내
                            </h4>
                          </div>
                          <br />
                          <div>
                            <table className="table">
                              <thead className="table-tx-head warning">
                                <tr className="text-center warning">
                                  <td className="column1">기능</td>
                                  <td className="column2">분류</td>
                                  <td className="column3">
                                    서비스
                                    <br />
                                    수준
                                  </td>
                                  <td className="column4">비회원</td>
                                  <td className="column4">
                                    회원
                                    <br />
                                    (무료)
                                  </td>
                                  <td className="column4">
                                    기본
                                    <br />
                                    이용권
                                  </td>
                                  <td className="column4">
                                    문장검사
                                    <br />
                                    이용권
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div>
                            <table className="table">
                              <tbody className="table-tx-body">
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-warning-bg"
                                    rowSpan="15"
                                  >
                                    사전
                                    <br />
                                    검색
                                  </td>
                                  <td className="column2 font-weight-bold">
                                    표준국어대사전
                                  </td>
                                  <td className="column3" />
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td rowSpan="3" className="font-weight-bold">
                                    유의어사전
                                  </td>
                                  <td>
                                    초등수준(1차)&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="1차 유의어"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td>
                                    초등수준(1,2차)&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="1,2차 유의어"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td>전체</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td rowSpan="2" className="font-weight-bold">
                                    반의어사전
                                  </td>
                                  <td>
                                    초등수준&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="반의어>유의어, 유의어>반의어"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    전체&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="반의어>유의어, 유의어>반의어"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td rowSpan="2" className="font-weight-bold">
                                    방언사전
                                  </td>
                                  <td>
                                    방언&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="해당 낱말의 방언만 검색 가능"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td>
                                    방언, 유의어&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="해당 낱말의 방언 및 유의어 검색 가능"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td rowSpan="3" className="font-weight-bold">
                                    역순사전
                                  </td>
                                  <td>초등수준</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>중, 고등수준</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>전체</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td rowSpan="2" className="font-weight-bold">
                                    의성의태어사전
                                  </td>
                                  <td>
                                    의성의태어만&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="의성의태어만 검색 가능"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td>
                                    핵심의미, 의미경로&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="의성의태어의 핵심의미 및 의미경로 검색 가능"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td rowSpan="2" className="font-weight-bold">
                                    한자어휘집
                                  </td>
                                  <td>초등수준</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>전체</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="text-center td-warning-bg font-weight-bold"
                                    rowSpan="5"
                                  >
                                    꼬마
                                    <br />
                                    사전
                                  </td>
                                  <td className="tb-bg font-weight-bold">
                                    표준국어대사전
                                  </td>
                                  <td className="tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td rowSpan="2" className="font-weight-bold">
                                    유의어사전
                                  </td>
                                  <td>초등수준(1차)</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>전체(1차)</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td rowSpan="2" className="font-weight-bold">
                                    반의어사전
                                  </td>
                                  <td>초등수준</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td>전체</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    rowSpan="2"
                                    className="text-center td-warning-bg font-weight-bold"
                                  >
                                    내 단어장
                                  </td>
                                  <td
                                    rowSpan="2"
                                    className="tb-bg font-weight-bold"
                                  >
                                    퍼즐
                                  </td>
                                  <td className="tb-bg">십자말 퍼즐</td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td>단어찾기 퍼즐</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="table-bottom" />
                          <div className="text-center mt-2 mb--50px">
                            <Link
                              to="#"
                              className="btn btn-raised btn-warning"
                              data-dismiss="modal"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: '/views/mypage/payment/step1',
                                });
                              }}
                            >
                              이용권 구매하기
                            </Link>
                          </div>
                        </div>
                      )}

                      {/* -------------------------------------------------------------------- */}
                      {/* 독서요금 ------------------------------------------------------------- */}
                      {/* -------------------------------------------------------------------- */}

                      {value === 1 && (
                        <div>
                          <div className="text-center">
                            <h4>
                              <i className="plat-icon icon icon-reading" />
                              &nbsp; 독서 서비스 이용안내
                            </h4>
                          </div>
                          <br />
                          <div>
                            <table className="table">
                              <thead className="table-tx-head royal">
                                <tr className="text-center royal">
                                  <td className="column1">기능</td>
                                  <td className="column2">분류</td>
                                  <td className="column3">
                                    서비스
                                    <br />
                                    수준
                                  </td>
                                  <td className="column4">비회원</td>
                                  <td className="column4">
                                    회원
                                    <br />
                                    (무료)
                                  </td>
                                  <td className="column4">
                                    기본
                                    <br />
                                    이용권
                                  </td>
                                  <td className="column4">&nbsp;</td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div>
                            <table className="table">
                              <tbody className="table-tx-body">
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-royal-bg"
                                    rowSpan="3"
                                  >
                                    LQ 독서력검사
                                  </td>
                                  <td className="column2 font-weight-bold">
                                    CBT 검사
                                  </td>
                                  <td className="column3">
                                    LQ 독서력검사&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="1~7급"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center column4">
                                    &nbsp;
                                  </td>
                                  <td className="text-center column4">
                                    &nbsp;
                                  </td>
                                  <td className="text-center column4">
                                    &nbsp;
                                  </td>
                                  <td className="text-center column4">
                                    <Link
                                      to="#"
                                      className="color-royal text-18"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: '/views/lq/etests/readtest',
                                        });
                                      }}
                                    >
                                      <i className="fas fa-arrow-alt-circle-right" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td className="font-weight-bold">CAT 검사</td>
                                  <td>
                                    LQ 독서력검사&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="1~7급"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <Link
                                      to="#"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: '/views/lq/etests/readtest',
                                        });
                                      }}
                                      className="color-royal text-18"
                                    >
                                      <i className="fas fa-arrow-alt-circle-right" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-weight-bold">
                                    단체용 검사 (On/Off-Line)
                                  </td>
                                  <td>
                                    LQ 독서력검사&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="1~7급"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <Link
                                      to="#"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: '/views/groups/etests',
                                        });
                                      }}
                                      className="color-royal text-18"
                                    >
                                      <i className="fas fa-arrow-alt-circle-right" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center td-royal-bg font-weight-bold">
                                    우리말 어휘력 검사
                                  </td>
                                  <td className="tb-bg font-weight-bold">
                                    <span className="column2 font-weight-bold">
                                      CBT 검사
                                    </span>
                                  </td>
                                  <td className="tb-bg">
                                    CBT 검사&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="1~7급"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="tb-bg text-center">
                                    <Link
                                      to="#"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: '/views/lq/etests/wordtest',
                                        });
                                      }}
                                      className="color-royal text-18"
                                    >
                                      <i className="fas fa-arrow-alt-circle-right" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    rowSpan="2"
                                    className="text-center td-royal-bg font-weight-bold"
                                  >
                                    LQ 텍스트/도서분석
                                  </td>
                                  <td className="font-weight-bold">
                                    텍스트 분석 맛보기
                                  </td>
                                  <td>
                                    LQ 텍스트 분석 맛보기&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="400자 이상 500자 이하"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center" />
                                </tr>
                                <tr>
                                  <td className="tb-bg font-weight-bold">
                                    LQ 도서 분석
                                  </td>
                                  <td className="tb-bg">
                                    LQ 도서 분석&nbsp;
                                    <LightTooltip
                                      placement="right"
                                      title="텍스트 업로드 후 분석"
                                    >
                                      <IconButton
                                        className={classes.iconLightTooltip}
                                      >
                                        <i className="fas fa-question-circle" />
                                      </IconButton>
                                    </LightTooltip>
                                  </td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="tb-bg text-center">
                                    <Link
                                      to="#"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: '/views/lq/bookAnalysis',
                                        });
                                      }}
                                      className="color-royal text-18"
                                    >
                                      <i className="fas fa-arrow-alt-circle-right" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    rowSpan="2"
                                    className="text-center td-royal-bg font-weight-bold"
                                  >
                                    LQ 도서 검색
                                  </td>
                                  <td
                                    rowSpan="2"
                                    className="tb-bg font-weight-bold"
                                  >
                                    LQ 도서 검색
                                  </td>
                                  <td className="tb-bg">기본</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="tb-bg text-center">
                                    <Link
                                      to="#"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: '/views/lq/bookSearch',
                                        });
                                      }}
                                      className="color-royal text-18"
                                    >
                                      <i className="fas fa-arrow-alt-circle-right" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tb-bg">보고서, 퍼즐 포함</td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg" />
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="table-bottom" />
                          <div className="text-center mt-2 mb--50px">
                            <Link
                              to="#"
                              data-dismiss="modal"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: '/views/mypage/payment/step1',
                                });
                              }}
                              className="btn btn-raised btn-royal"
                            >
                              이용권 구매하기
                            </Link>
                          </div>
                        </div>
                      )}

                      {/* -------------------------------------------------------------------- */}
                      {/* 퍼즐요금 ------------------------------------------------------------- */}
                      {/* -------------------------------------------------------------------- */}

                      {value === 2 && (
                        <div>
                          <div className="text-center">
                            <h4>
                              <i className="plat-icon icon icon-maze" />
                              &nbsp; 낱말퍼즐 서비스 이용안내
                            </h4>
                          </div>
                          <br />
                          <div>
                            <table className="table">
                              <thead className="table-tx-head info">
                                <tr className="text-center info">
                                  <td className="column1">기능</td>
                                  <td className="column2">분류</td>
                                  <td className="column3">
                                    서비스
                                    <br />
                                    수준
                                  </td>
                                  <td className="column4">비회원</td>
                                  <td className="column4">
                                    회원
                                    <br />
                                    (무료)
                                  </td>
                                  <td className="column4">
                                    기본
                                    <br />
                                    이용권
                                  </td>
                                  <td className="column4">
                                    문장검사
                                    <br />
                                    이용권
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div>
                            <table className="table">
                              <tbody className="table-tx-body">
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-info-bg"
                                    rowSpan="4"
                                  >
                                    낱말 퍼즐 풀기
                                  </td>
                                  <td
                                    rowSpan="3"
                                    className="column2 font-weight-bold"
                                  >
                                    십자말 퍼즐
                                    <br />
                                    단어찾기 퍼즐
                                  </td>
                                  <td className="column3">6x6</td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column3">8x8</td>
                                  <td className="text-center column4">
                                    &nbsp;
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column3">10x10</td>
                                  <td className="text-center column4">
                                    &nbsp;
                                  </td>
                                  <td className="text-center column4">
                                    &nbsp;
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td className="font-weight-bold">
                                    퍼즐 PDF 다운로드
                                  </td>
                                  <td>6x6, 8x8, 10x10</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    rowSpan="2"
                                    className="text-center td-info-bg font-weight-bold"
                                  >
                                    나의 퍼즐 만들기
                                  </td>
                                  <td className="font-weight-bold">
                                    십자말 퍼즐
                                  </td>
                                  <td>6x6, 8x8, 10x10</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-weight-bold">
                                    단어찾기 퍼즐
                                  </td>
                                  <td className="">6x6, 8x8, 10x10</td>
                                  <td className="text-center ">&nbsp;</td>
                                  <td className="text-center ">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center td-info-bg font-weight-bold">
                                    보관함
                                  </td>
                                  <td className="font-weight-bold">전체 </td>
                                  <td>전체 </td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="table-bottom" />
                          <div className="text-center mt-2 mb--50px">
                            <Link
                              to="#"
                              data-dismiss="modal"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: '/views/mypage/payment/step1',
                                });
                              }}
                              className="btn btn-raised btn-info"
                            >
                              이용권 구매하기
                            </Link>
                          </div>
                        </div>
                      )}

                      {/* -------------------------------------------------------------------- */}
                      {/* 영어요금 ------------------------------------------------------------- */}
                      {/* -------------------------------------------------------------------- */}
                      {value === 3 && (
                        <div>
                          <div className="text-center">
                            <h4>
                              <i className="plat-icon icon icon-english" />
                              &nbsp; 영어 서비스 이용안내
                            </h4>
                          </div>
                          <br />
                          <div>
                            <table className="table">
                              <thead className="table-tx-head success">
                                <tr className="text-center success">
                                  <td className="column1">기능</td>
                                  <td className="column2">분류</td>
                                  <td className="column3">
                                    서비스
                                    <br />
                                    수준
                                  </td>
                                  <td className="column4">비회원</td>
                                  <td className="column4">
                                    회원
                                    <br />
                                    (무료)
                                  </td>
                                  <td className="column4">
                                    기본
                                    <br />
                                    이용권
                                  </td>
                                  <td className="column4">
                                    문장검사
                                    <br />
                                    이용권
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div>
                            <table className="table">
                              <tbody className="table-tx-body">
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-success-bg"
                                    rowSpan="2"
                                  >
                                    영어 읽기능력 검사
                                    <br />
                                    LEXILE (English Readablity)
                                  </td>
                                  <td className="column2 font-weight-bold">
                                    CBT 검사
                                    <br />
                                  </td>
                                  <td className="column3">CBT 검사</td>
                                  <td className="text-center column4">
                                    &nbsp;
                                  </td>
                                  <td className="text-center column4">
                                    &nbsp;
                                  </td>
                                  <td className="text-center column4">
                                    &nbsp;
                                  </td>
                                  <td className="text-center column4">
                                    <Link
                                      to="#"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname:
                                            '/views/lexile/etests/engreadtest',
                                        });
                                      }}
                                      className="color-success text-18"
                                    >
                                      <i className="fas fa-arrow-alt-circle-right" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr className="tb-bg">
                                  <td className="font-weight-bold">
                                    <span className="column2 font-weight-bold">
                                      단체용 검사 (On/Off-Line)
                                    </span>
                                  </td>
                                  <td>단체용 검사 (On-Line, Off-Line)</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <Link
                                      to="#"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: '/views/groups/etests',
                                        });
                                      }}
                                      className="color-success text-18"
                                    >
                                      <i className="fas fa-arrow-alt-circle-right" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center td-success-bg font-weight-bold">
                                    영어 LEXILE 도서 검색
                                  </td>
                                  <td className="font-weight-bold">
                                    영어 LEXILE 도서 검색
                                  </td>
                                  <td>검색기본</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    rowSpan="4"
                                    className="text-center td-success-bg font-weight-bold"
                                  >
                                    사전 검색
                                  </td>
                                  <td
                                    rowSpan="2"
                                    className="tb-bg font-weight-bold"
                                  >
                                    한-영
                                  </td>
                                  <td className="tb-bg">초등수준</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tb-bg">전체</td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td rowSpan="2" className="font-weight-bold">
                                    영-한
                                  </td>
                                  <td>초등수준</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>전체</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    rowSpan="4"
                                    className="text-center td-success-bg font-weight-bold"
                                  >
                                    영어 퍼즐 풀기
                                  </td>
                                  <td
                                    rowSpan="3"
                                    className="tb-bg font-weight-bold"
                                  >
                                    십자말 퍼즐
                                    <br />
                                    단어찾기 퍼즐
                                  </td>
                                  <td className="tb-bg">6x6</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tb-bg">8x8</td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tb-bg">10x10</td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">&nbsp;</td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-weight-bold">
                                    퍼즐 PDF 다운로드
                                  </td>
                                  <td>6x6, 8x8, 10x10</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">&nbsp;</td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="table-bottom" />
                          <div className="text-center mt-2 mb--50px">
                            <Link
                              to="#"
                              data-dismiss="modal"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: '/views/mypage/payment/step1',
                                });
                              }}
                              className="btn btn-raised btn-success"
                            >
                              이용권 구매하기
                            </Link>
                          </div>
                        </div>
                      )}

                      {/* -------------------------------------------------------------------- */}
                      {/* 문장검사 ------------------------------------------------------------- */}
                      {/* -------------------------------------------------------------------- */}
                      {value === 4 && (
                        <div>
                          <div className="text-center">
                            <h4>
                              <i className="plat-icon icon icon-analysis" />
                              &nbsp; 문장검사 서비스 이용안내
                            </h4>
                          </div>
                          <br />
                          <div>
                            <table className="table">
                              <thead className="table-tx-head primary">
                                <tr className="text-center primary">
                                  <td
                                    className="column1"
                                    rowSpan="2"
                                    style={{ verticalAlign: 'middle' }}
                                  >
                                    기능
                                  </td>
                                  <td
                                    className="column2"
                                    rowSpan="2"
                                    style={{ verticalAlign: 'middle' }}
                                  >
                                    분류
                                  </td>
                                  <td className="column4">비회원</td>
                                  <td className="column4">무료회원</td>
                                  <td className="column4">문장검사 이용권</td>
                                </tr>
                                <tr className="text-center primary">
                                  <td>(100자 이하)</td>
                                  <td>(300자 이하)</td>
                                  <td>(2000자 이하)</td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div>
                            <table className="table">
                              <tbody className="table-tx-body">
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="3"
                                  >
                                    기본 정보
                                  </td>
                                  <td className="column2 font-weight-bold break-words">
                                    문장 수, 문단 수, 글자 수(공백 포함), 글자
                                    수(공백 제외), 어절 수, 문장당 평균 어절 수,
                                    읽기 시간, 200자 원고지
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 tb-bg font-weight-bold break-words">
                                    보관함
                                  </td>
                                  <td className="text-center tb-bg column4" />
                                  <td className="text-center tb-bg column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center tb-bg column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words">
                                    보고서, PDF
                                  </td>
                                  <td className="text-center column4" />
                                  <td className="text-center column4" />
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="3"
                                  >
                                    난이도
                                  </td>
                                  <td className="column2 font-weight-bold tb-bg break-words">
                                    문서의 난이도
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words">
                                    문단별 난이도
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    어휘의 난이도 분포
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="7"
                                  >
                                    맞춤법 검사
                                  </td>
                                  <td className="column2 font-weight-bold break-words">
                                    전체 보기
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    띄어쓰기 오류
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words">
                                    표준어 오류
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    철자 오류
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words">
                                    문장 부호 오류
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    어휘 선택 오류
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words">
                                    기타 오류
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="1"
                                  >
                                    문법 어법 검사(Beta)
                                  </td>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    준비 중
                                  </td>
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg" />
                                </tr>
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="3"
                                  >
                                    유의어 추천
                                  </td>
                                  <td className="column2 font-weight-bold break-words">
                                    수준별 추천(전체, 어렵게, 쉽게)
                                  </td>
                                  <td className="text-center column4" />
                                  <td className="text-center column4" />
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2  font-weight-bold break-words tb-bg ">
                                    일상 어휘, 정서 어휘, 학술 기본 어휘 추천
                                  </td>
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words">
                                    학술 전문 어휘 추천
                                  </td>
                                  <td className="text-center column4" />
                                  <td className="text-center column4" />
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="3"
                                  >
                                    중복 표현
                                  </td>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    1어절
                                  </td>
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words">
                                    2어절
                                  </td>
                                  <td className="text-center column4" />
                                  <td className="text-center column4" />
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    3어절
                                  </td>
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="2"
                                  >
                                    어휘 통계
                                  </td>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    명사, 대명사, 동사, 형용사, 부사, 접속어
                                  </td>
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words">
                                    고유어, 한자어, 외래어
                                  </td>
                                  <td className="text-center column4" />
                                  <td className="text-center column4" />
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="2"
                                  >
                                    한자
                                  </td>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    한글 -> 한자 병기
                                  </td>
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="column2 font-weight-bold break-words">
                                    한자 병기 -> 한자
                                  </td>
                                  <td className="text-center column4" />
                                  <td className="text-center column4" />
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="1"
                                  >
                                    방언
                                  </td>
                                  <td className="column2 font-weight-bold break-words tb-bg">
                                    강원도, 경기도, 경상도, 제주도, 전라도,
                                    충청도, 평안도, 함경도, 황해도
                                  </td>
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg" />
                                  <td className="text-center column4 tb-bg">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="text-center font-weight-bold column1 td-primary-bg"
                                    rowSpan="1"
                                  >
                                    다국어
                                  </td>
                                  <td className="column2 font-weight-bold break-words">
                                    영어, 러시아, 몽골, 베트남, 스페인, 아랍,
                                    인도네시아, 일본, 중국, 태국, 프랑스
                                  </td>
                                  <td className="text-center column4" />
                                  <td className="text-center column4" />
                                  <td className="text-center column4">
                                    <i className="fas fa-check" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="table-bottom" />
                          <div className="text-center mt-2 mb--50px">
                            <Link
                              to="#"
                              data-dismiss="modal"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: '/views/mypage/payment/step1',
                                });
                              }}
                              className="btn btn-raised btn-primary"
                            >
                              이용권 구매하기
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-c-dark"
                  data-dismiss="modal"
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(PriceList));
