import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from 'actions';
import { Drawer, Divider, Row, Col, Icon, List, Typography, Button } from 'antd';
import Statistic from './statistic';
import { Bar, XAxis, YAxis, CartesianGrid, BarChart, Cell, ResponsiveContainer } from 'recharts';
import './report.css';

var nlp = require('compromise');

const mapStateToProps = (state) => ({
	isReportVisible: state.report.isReportVisible,
	baseInfos: state.report.baseInfos,
	decos: state.spell.decos,
	synos: state.syno.synos,
	lqInfos: state.info.lqInfos,
	lqscore: state.info.lqscore,
	blockTexts: state.blocks.blockTexts,
	//text: state.info.text,
});

const mapDispatchToProps = (dispatch) => ({
	onShowModalReport: (visiable) => dispatch(actions.onShowModalReport(visiable)),
});

const sentenceLabels = [
	{ name: '1 (1320~1850)', label: '법조문이나 전문서적에 포함되는 글의 평균 수준' },
	{ name: '2 (1120~1450)', label: '신문 사설이나 대입 논술고사 지문에 포함되는 글의 평균 수준' },
	{ name: '3 (910~1190)', label: '고등학교 1~3학년 교과서에 포함되는 글의 평균 수준' },
	{ name: '4 (720~1000)', label: '초등학교 6~중학교 3학년 교과서에 포함되는 글의 평균 수준' },
	{ name: '5 (560~840)', label: '초등학교 3~5학년 교과서에 포함되는 글의 평균 수준' },
	{ name: '6 (350~630)', label: '초등학교 1~2학년 교과서에 포함되는 글의 평균 수준' },
	{ name: '7 (100~480)', label: '어린이 동화책에 포함되는 글의 평균 수준' },
];

const wordLabels = [
	{ name: '1', label: '기초어휘' },
	{ name: '2', label: '정규교육 이전' },
	{ name: '3', label: '정규교육 개시~사춘기 이전, 사고 도구어 일부 포함' },
	{ name: '4', label: '사춘기 이후~급격한 지적 성장 단계, 사고 도구어 포함' },
	{ name: '5', label: '전문화된 지적 성장 단계, 다량의 전문어 포함' },
	{ name: '6', label: '대학이상, 전문어 포함' },
	{ name: '7', label: '분야별 전문어 포함' },
];

const colors = ['#1E88E5', '#669933', '#cc6633'];
const sentsColors = ['#1E88E5', '#669933', '#669933', '#669933', '#cc6633'];

class CustomizedLabel extends PureComponent {
	render() {
		const { x, y, value, width, unit } = this.props;
		return (
			<text x={x + width / 2} y={y} dy={-2} fill="#777" fontSize="10" textAnchor="middle">
				{value.toFixed(0)} {unit != null && unit}
			</text>
		);
	}
}

class CustomizedAxisTick extends PureComponent {
	convertLabel = (value) => {
		switch (value) {
			case 100:
				return '';
			case 290:
				return '7';
			case 490:
				return '6';
			case 700:
				return '5';
			case 860:
				return '4';
			case 1050:
				return '3';
			case 1285:
				return '2';
			case 1585:
				return '1';
			default:
				return '';
		}
	};

	render() {
		const { x, y, payload } = this.props;

		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={3} fill="#666" fontSize="9px">
					{this.convertLabel(payload.value)}
				</text>
			</g>
		);
	}
}

const kdc = [
	//	{ name: '총류', code: '000' },
	{ name: '철학', code: '100' },
	{ name: '종교', code: '200' },
	{ name: '사회과학', code: '300' },
	{ name: '순수과학', code: '400' },
	{ name: '기술과학', code: '500' },
	{ name: '예술', code: '600' },
	{ name: '언어', code: '700' },
	{ name: '문학', code: '800' },
	{ name: '역사', code: '900' },
];

const initialData = (decos) => {
	let spaceCount = 0,
		standardCount = 0,
		spellCount = 0,
		specialCharCount = 0,
		wordSelectCount = 0,
		etcCount = 0;
	const spells = decos.flatMap((x) => x.filterSpells.flatMap((s) => s.spells));
	const spaceData = spells.filter((a) => a.helpcate.SPACE === 1);
	const standard = spells.filter((a) => a.helpcate.STANDARD === 1);
	const spellData = spells.filter((a) => a.helpcate.SPELL === 1);
	const specialChar = spells.filter((a) => a.helpcate.SPECIAL_CHAR === 1);
	const wordSelect = spells.filter((a) => a.helpcate.WORD_SELECT === 1);
	const etc = spells.filter(
		(a) =>
			a.helpcate.SPACE !== 1 &&
			a.helpcate.STANDARD !== 1 &&
			a.helpcate.SPELL !== 1 &&
			a.helpcate.SPECIAL_CHAR !== 1 &&
			a.helpcate.WORD_SELECT !== 1
	);
	spaceCount = spaceData.length;
	standardCount = standard.length;
	spellCount = spellData.length;
	specialCharCount = specialChar.length;
	wordSelectCount = wordSelect.length;
	etcCount = etc.length;

	return {
		space: spaceCount,
		standard: standardCount,
		spelling: spellCount,
		punc: specialCharCount,
		misused: wordSelectCount,
		others: etcCount,
	};
};

const datasByPumsa = (datas, pumsa, limit) => {
	let _datas = [];
	if (pumsa === 'OWN') {
		_datas = datas
			.filter((i) => i.OWN_YN === 1)
			.map((i) => i.CD_WORD)
			.reduce((count, word) => {
				count[word] = (count[word] || 0) + 1;
				return count;
			}, {});
	} else if (pumsa === 'CHN') {
		_datas = datas
			.filter((i) => i.CHN_YN === 1)
			.map((i) => i.CD_WORD + '(' + i.ORG_LANGUAGE + ')')
			.reduce((count, word) => {
				count[word] = (count[word] || 0) + 1;
				return count;
			}, {});
	} else {
		_datas = datas
			.filter((i) => i.PUMSA === pumsa)
			.map((i) => i.CD_WORD)
			.reduce((count, word) => {
				count[word] = (count[word] || 0) + 1;
				return count;
			}, {});
	}
	const __datas = Object.keys(_datas)
		.sort((key1, key2) => {
			if (_datas[key1] > _datas[key2]) return -1;
			if (_datas[key1] < _datas[key2]) return 1;
			return 0;
		})
		.map((key) => Object.assign({}, {}, { name: key, value: _datas[key] }))
		.slice(0, limit);

	return __datas;
};

const decimalClassificationCount = (datas, div) => {
	return datas.filter((x) => x != null && x.substr(0, 1) === div.toString()).length;
};

const lqInfosDatas = (lqInfos) => {
	let own_rate = 0,
		chn_rate = 0,
		nc_count = 0,
		np_count = 0,
		vv_count = 0,
		va_count = 0,
		ma_count = 0,
		mj_count = 0;

	const strRateandHomos_ex = lqInfos
		.flatMap((x) => x.strRateandHomos)
		.filter(
			(i) =>
				i.PUMSA === 'NC' ||
				i.PUMSA === 'NP' ||
				i.PUMSA === 'VV' ||
				i.PUMSA === 'VA' ||
				i.PUMSA === 'MA' ||
				i.PUMSA === 'MJ'
		)
		.filter((i) => i.DIFFICULTY !== 11);

	nc_count = strRateandHomos_ex.filter((i) => i.PUMSA === 'NC').length;
	np_count = strRateandHomos_ex.filter((i) => i.PUMSA === 'NP').length;
	vv_count = strRateandHomos_ex.filter((i) => i.PUMSA === 'VV').length;
	va_count = strRateandHomos_ex.filter((i) => i.PUMSA === 'VA').length;
	ma_count = strRateandHomos_ex.filter((i) => i.PUMSA === 'MA').length;
	mj_count = strRateandHomos_ex.filter((i) => i.PUMSA === 'MJ').length;

	const nEojul = lqInfos
		.flatMap((x) => x.nEojul)
		.reduce(function(p, c) {
			return p + c;
		}, 0);

	const own = strRateandHomos_ex.filter((i) => i.OWN_YN === 1).length;
	own_rate = (own * 100) / nEojul;
	if (!isNaN(own_rate)) own_rate = own_rate.toFixed(1);
	else own_rate = 0;
	const chn = strRateandHomos_ex.filter((i) => i.CHN_YN === 1).length;
	chn_rate = (chn * 100) / nEojul;
	if (!isNaN(chn_rate)) chn_rate = chn_rate.toFixed(1);
	else chn_rate = 0;

	return {
		count: {
			noun: nc_count,
			pronoun: np_count,
			verb: vv_count,
			adjective: va_count,
			adverb: ma_count,
			conjunction: mj_count,
			hangeul: own_rate + '%',
			sino: chn_rate + '%',
		},
		frequency: {
			noun: datasByPumsa(strRateandHomos_ex, 'NC', 5),
			pronoun: datasByPumsa(strRateandHomos_ex, 'NP', 5),
			verb: datasByPumsa(strRateandHomos_ex, 'VV', 5),
			adjective: datasByPumsa(strRateandHomos_ex, 'VA', 5),
			adverb: datasByPumsa(strRateandHomos_ex, 'MA', 5),
			conjunction: datasByPumsa(strRateandHomos_ex, 'MJ', 5),
			hangeul: datasByPumsa(strRateandHomos_ex, 'OWN', 5),
			sino: datasByPumsa(strRateandHomos_ex, 'CHN', 5),
		},
	};
};

let datas = {
	documents: [
		{ name: 'nsentence', title: '문장 수', subTitle: 'SENTENCE', value: 0 },
		{ name: 'nparam', title: '문단 수', subTitle: 'PARAGRAPHS', value: 0 },
		{ name: 'nchar', title: '글자 수(공백 포함)', subTitle: 'CHARACTERS (With Spaces)', value: 0 },
		{ name: 'nchar_ex', title: '글자 수(공백 제외)', subTitle: 'CHARACTERS (No Spaces)', value: 0 },
		{ name: 'neojul', title: '어절 수', subTitle: 'WORD COUNT', value: 0 },
		{
			name: 'neojul_per_sent',
			title: '문장당 평균 어절 수',
			subTitle: 'MEAN SENTENCE LENGTH',
			value: 0,
			limit: 20,
		},
		{ name: 'read_time', title: '읽기 시간(초)', subTitle: 'READING TIME', value: 0 },
		{ name: 'npage', title: '200자 원고지', subTitle: 'PAGES PER 200 CHARACTERS', value: 0 },
	],

	spellcheckers: [
		{ name: 'space', title: '띄어쓰기 오류', subTitle: 'SPACING ISSUES', value: 0 },
		{ name: 'standard', title: '표준어 오류', subTitle: 'STANDARD WORD ISSUES', value: 0 },
		{ name: 'spelling', title: '철자 오류', subTitle: 'SPELLING ISSUES', value: 0 },
		{ name: 'punc', title: '문장부호 오류', subTitle: 'PUNCTUATION ISSUES', value: 0 },
		{ name: 'misused', title: '어휘선택 오류', subTitle: 'MISUSED WORDS ISSUES', value: 0 },
		{ name: 'others', title: '기타 오류', subTitle: 'OTHERS', value: 0 },
	],

	words: [
		{ name: 'noun', title: '명사', subTitle: 'NOUN', value: 0 },
		{ name: 'pronoun', title: '대명사', subTitle: 'PRONOUN', value: 0 },
		{ name: 'verb', title: '동사', subTitle: 'VERB', value: 0 },
		{ name: 'adjective', title: '형용사', subTitle: 'ADJECTIVE', value: 0 },
		{ name: 'adverb', title: '부사', subTitle: 'ADVERB', value: 0 },
		{ name: 'conjunction', title: '접속어', subTitle: 'CONJUNCTION', value: 0 },
		{ name: 'hangeul', title: '고유어', subTitle: 'HANGEUL', value: '0%' },
		{ name: 'sino', title: '한자어', subTitle: 'SINO-KOREAN', value: '0%' },
	],
};

class Reports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: props.isReportVisible,
			baseInfos: props.baseInfos,
			decos: props.decos,
			synos: props.synos,
			lqInfos: props.lqInfos,
			sourceDatas: datas,
			text: props.text,
			lqscore: props.lqscore,
			blockTexts: props.blockTexts,
			dist: 0,
		};

		console.log(props.isReportVisible);

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	print(e) {
		const html = document.querySelector('html');
		const printContents = document.querySelector('.report-modal').innerHTML;
		const printDiv = document.createElement('DIV');
		printDiv.className = 'print-div';

		html.appendChild(printDiv);
		printDiv.innerHTML = printContents;
		document.body.style.display = 'none';
		window.print();
		document.body.style.display = 'block';
		printDiv.style.display = 'none';
	}

	updateWindowDimensions() {
		let width = window.innerWidth;
		let dist = 60;
		if (width > 768) {
			width = width / 2;
			dist = 45;
		}
		this.setState({ width, height: window.innerHeight, dist });
	}

	componentWillReceiveProps(nextProps) {
		const loadedBlockTexts = nextProps.blockTexts
			.filter((x) => x.status === 'COMPLETE')
			.map((x) => x.blockText)
			.join('\n');
		this.setState({
			show: nextProps.isReportVisible,
			baseInfos: nextProps.baseInfos,
			decos: nextProps.decos,
			synos: nextProps.synos,
			lqInfos: nextProps.lqInfos,
			text: nextProps.text,
			lqscore: nextProps.lqscore,
			blockTexts: nextProps.blockTexts,
			loadedBlockTexts,
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.baseInfos !== prevState.baseInfos) {
			const { baseInfos } = this.state;
			const statistic = datas.documents.map((x) => Object.assign({}, x, { value: baseInfos[x.name] }));
			datas = Object.assign({}, datas, { documents: statistic });
			this.setState({ sourceDatas: datas });
		}

		if (this.state.decos !== prevState.decos) {
			const decos = initialData(this.state.decos);
			const statistic = datas.spellcheckers.map((x) => Object.assign({}, x, { value: decos[x.name] }));
			datas = Object.assign({}, datas, { spellcheckers: statistic });
			this.setState({ sourceDatas: datas });
		}

		if (this.state.lqInfos !== prevState.lqInfos) {
			if (this.state.lqInfos != null) {
				const lqInfos = lqInfosDatas(this.state.lqInfos);
				const statistic = datas.words.map((x) =>
					Object.assign({}, x, { value: lqInfos.count[x.name], frequency: lqInfos.frequency[x.name] })
				);
				datas = Object.assign({}, datas, { words: statistic });

				const codes = this.state.lqInfos
					.flatMap((x) => x.strRateandHomos)
					.flatMap((x) => {
						if (x.CODE != null) {
							return x.CODE.split(',');
						} else {
							return null;
						}
					});

				const _kdc = kdc.map((x, i) => {
					//if (i === 0) {
					//	return Object.assign({}, x, {
					//			value: codes.filter(x => (x != null && x.length < 3) || x == null).length,
					//		});
					//	} else {
					return Object.assign({}, x, {
						size: decimalClassificationCount(codes, i + 1),
					});
					//	}
				});

				const rated1 = this.state.lqInfos
					.flatMap((x) => x.rated['1'])
					.filter((x) => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);
				const rated2 = this.state.lqInfos
					.flatMap((x) => x.rated['2'])
					.filter((x) => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);

				const rated3 = this.state.lqInfos
					.flatMap((x) => x.rated['3'])
					.filter((x) => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);

				const rated4 = this.state.lqInfos
					.flatMap((x) => x.rated['4'])
					.filter((x) => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);
				const rated5 = this.state.lqInfos
					.flatMap((x) => x.rated['5'])
					.filter((x) => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);
				const rated6 = this.state.lqInfos
					.flatMap((x) => x.rated['6'])
					.filter((x) => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);
				const rated7 = this.state.lqInfos
					.flatMap((x) => x.rated['7'])
					.filter((x) => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);

				const lq_min = this.state.lqInfos.filter((x) => x.lq < this.state.lqscore - 100);
				const lq_max = this.state.lqInfos.filter((x) => x.lq >= this.state.lqscore + 100);
				const lq_avg = this.state.lqInfos.filter(
					(x) => x.lq >= this.state.lqscore - 100 && x.lq < this.state.lqscore + 100
				);

				let paramsDatas = [];
				if (this.state.lqInfos.length > 0) {
					paramsDatas.push({
						name: '쉬움',
						value: (lq_min.length / this.state.lqInfos.length) * 100,
					});
					paramsDatas.push({
						name: '평균',
						value: (lq_avg.length / this.state.lqInfos.length) * 100,
					});
					paramsDatas.push({
						name: '어려움',
						value: (lq_max.length / this.state.lqInfos.length) * 100,
					});
				}

				this.setState({
					sourceDatas: datas,
					charts: _kdc,
					wordDatas: [
						{ name: 1, value: rated1 },
						{ name: 2, value: rated2 },
						{ name: 3, value: rated3 },
						{ name: 4, value: rated4 },
						{ name: 5, value: rated5 },
						{ name: 6, value: rated6 },
						{ name: 7, value: rated7 },
					],
					paramsDatas,
				});
			}
		}

		if (this.state.synos !== prevState.synos) {
			//console.log(this.state.synos);
		}

		if (this.state.loadedBlockTexts !== prevState.loadedBlockTexts) {
			const _text = this.state.loadedBlockTexts != null && this.state.loadedBlockTexts.replace(/,/g, '#q#');
			const t = nlp(_text)
				.ngrams({ max: 3 })
				.data()
				.filter((i) => i.count > 1)
				.map((i) => {
					return { ...i, normal: i.normal.replace(/#q#/g, ',') };
				});

			const _t1 = t.filter((i) => i.size === 1).splice(0, 5);
			const _t2 = t.filter((i) => i.size === 2).splice(0, 5);
			const _t3 = t.filter((i) => i.size === 3).splice(0, 5);

			this.setState({
				distinctDatas: [
					{ name: '1어절', datas: _t1 },
					{ name: '2어절', datas: _t2 },
					{ name: '3어절', datas: _t3 },
				],
			});

			const sentences = nlp(_text)
				.sentences()
				.data();
			/*
			const sent1 = sentences
				.filter((x, i) => i < 6)
				.flatMap(x =>
					nlp(x.normal)
						.ngrams({ max: 1 })
						.data()
				);
			const sent2 = sentences
				.filter((x, i) => i >= 6 && i < 9)
				.flatMap(x =>
					nlp(x.normal)
						.ngrams({ max: 1 })
						.data()
				);

			const sent3 = sentences
				.filter((x, i) => i >= 9 && i < 14)
				.flatMap(x =>
					nlp(x.normal)
						.ngrams({ max: 1 })
						.data()
				);
			const sent4 = sentences
				.filter((x, i) => i >= 14 && i < 20)
				.flatMap(x =>
					nlp(x.normal)
						.ngrams({ max: 1 })
						.data()
				);
			const sent5 = sentences
				.filter((x, i) => i >= 20)
				.flatMap(x =>
					nlp(x.normal)
						.ngrams({ max: 1 })
						.data()
				);
					*/

			const sents = sentences.map((x, i) => {
				let n = nlp(x.normal)
					.ngrams({ max: 1 })
					.data().length;
				return { name: i + 1, value: n };
			});

			const sent1 = sents.filter((x) => x.value < 6);
			const sent2 = sents.filter((x) => x.value >= 6 && x.value < 9);
			const sent3 = sents.filter((x) => x.value >= 9 && x.value < 14);
			const sent4 = sents.filter((x) => x.value >= 14 && x.value < 20);
			const sent5 = sents.filter((x) => x.value >= 20);

			const sentsByWords = [
				{ name: '<6 어절', value: sent1.length },
				{ name: '6~8 어절', value: sent2.length },
				{ name: '9~13 어절', value: sent3.length },
				{ name: '14~19 어절', value: sent4.length },
				{ name: '20+ 어절', value: sent5.length },
			];

			this.setState({ sentsByWords, sents });
		}

		if (this.state.lqscore !== prevState.lqscore) {
		}
	}

	showReport(visiable) {
		this.props.onShowModalReport(visiable);
		this.setState({ show: visiable });
	}

	hide = () => {
		this.props.onShowModalReport(false);
		this.setState({ show: false });
	};

	render() {
		const {
			sourceDatas,
			charts,
			distinctDatas,
			lqscore,
			wordDatas,
			paramsDatas,
			sentsByWords,
			sents,
			width,
			dist,
		} = this.state;
		return (
			<Drawer
				title={
					<Row type="flex">
						<Col style={{ textAlign: 'left' }} span={20}>
							<span style={{ fontSize: 25, fontWeight: 500 }}>문장검사 보고서</span>
						</Col>
						<Col style={{ textAlign: 'right' }} span={4}>
							<Button onClick={(e) => this.print(e)} icon="printer" style={{ marginRight: 30 }} />
						</Col>
					</Row>
				}
				placement="right"
				visible={this.state.show}
				closable={true}
				onClose={this.hide}
				width={window.innerWidth}
				zIndex={4001}
				bodyStyle={{ overflowY: 'auto', overflowX: 'hidden' }}>
				<div className="report-modal">
					<div
					//style={{ pageBreakBefore: 'avoid' }}
					>
						<Statistic
							color="#0070C0"
							title="문서 통계"
							subTitle="DOCUMENT STATISTICS"
							desc={
								<p style={{ marginBottom: 0 }}>
									글자 수가 제한된 글을 쓸 때, 글자의 수나 원고지 매수와 같은 문서의 통계를 확인할 수
									있습니다.
								</p>
							}
							datas={sourceDatas.documents}
						/>
						<Row style={{ marginTop: '5px', marginBottom: '10px' }}>
							<Col>
								<div style={{ width: '100%', height: '3px', background: 'rgba(0,0,0,0.65)' }} />
							</Col>
						</Row>
						<Statistic
							color="#EF6D63"
							title="맞춤법 검사"
							subTitle="SPELLCHECKER"
							desc={
								<p style={{ marginBottom: 0 }}>
									한국어 어문 규정(한글 맞춤법, 표준어 규정, 외래어 표기법)에 따른 검사결과입니다.
									<br /> 철자 오류, 어휘선택 오류 등과 같이 일반적인 문서작성 프로그램에서는 제공하지
									않는 맞춤법 검사 결과를 확인할 수 있습니다.
								</p>
							}
							datas={sourceDatas.spellcheckers}
						/>
						<Row style={{ marginTop: '5px', marginBottom: '10px' }}>
							<Col>
								<div style={{ width: '100%', height: '3px', background: 'rgba(0,0,0,0.65)' }} />
							</Col>
						</Row>
						<Statistic
							color="rgb(0,176,80)"
							title="어휘 통계"
							subTitle="WORD STATISTICS"
							desc={
								<p style={{ marginBottom: 0 }}>
									중요한 품사별로 정리된 다빈도 어휘 목록을 살펴볼 수 있습니다. <br /> 통계를 살펴보고
									문장 중에 더욱 전달하고 싶거나 강조하고 싶은 부분이 있는지 생각해 보세요.
								</p>
							}
							datas={sourceDatas.words}
						/>
						<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
							<Col>
								<div style={{ width: '100%', height: '3px', background: 'rgba(0,0,0,0.65)' }} />
							</Col>
						</Row>
					</div>
					<div
					//style={{ pageBreakBefore: 'always' }}
					>
						<div className="row">
							<div className="col-md-6">
								<Statistic
									color="rgb(255,192,0)"
									title="중복 표현"
									subTitle="DUPLICATE EXPRESSION"
									desc={
										<p style={{ marginBottom: 0 }}>
											중복된 표현이 자주 나오면 글의 집중도가 떨어지기 때문에 가능한 한 피하는
											것이 좋습니다. 상위 5개의 중복 표현 목록을 살펴보고 간결하면서도 다채로운
											표현의 글로 수정해 보세요.
										</p>
									}
								/>
								<Row gutter={8} type="flex" style={{ marginTop: '10px', paddingBottom: '10px' }}>
									{distinctDatas &&
										distinctDatas.map((x) => {
											return (
												<Col key={x.name} span={parseInt(24 / distinctDatas.length)}>
													<Row style={{ marginTop: '10px', height: '25px' }}>
														<Col>
															<span style={{ color: 'rgba(0,0,0,1)', fontWeight: 500 }}>
																{x.name}
															</span>
														</Col>
													</Row>
													{x.datas &&
														x.datas.map((y) => {
															return (
																<Row key={y.normal}>
																	<Col>
																		{y.normal}({y.count})
																	</Col>
																</Row>
															);
														})}
												</Col>
											);
										})}
								</Row>
							</div>
							<div className="col-md-6">
								<Statistic
									color="rgb(255,192,0)"
									title="분야별 어휘 분포"
									subTitle="WORD SPECTRUM"
									desc={
										<p style={{ marginBottom: 0 }}>
											나의 글에 포함된 어휘를 지식의 십진분류체계에 따라 파악한 분포 결과입니다.
											<br />
											십진분류체계는 총류, 철학, 종교, 사회과학, 자연과학, 기술과학, 예술, 언어,
											문학, 역사와 같이 10개의 분야로 구분되어 있습니다.
										</p>
									}
									charts={charts}
									width={width}
								/>
							</div>
						</div>
						<Row style={{ marginTop: '5px', marginBottom: '10px' }}>
							<Col>
								<div style={{ width: '100%', height: '3px', background: 'rgba(0,0,0,0.65)' }} />
							</Col>
						</Row>
						<Statistic color="#0070C0" title="난이도" subTitle="DIFFICULTY" />
						<div className="row">
							<div className="col-md-6">
								<Divider orientation="left">
									<span
										style={{
											color: 'rgba(0,0,0,1)',
											fontSize: '12px',
											lineHeight: '12px',
										}}>
										문장의 난이도
									</span>
								</Divider>
								<Row style={{ marginTop: '10px' }}>
									<Col>
										<div
											style={{
												background: '#DDEBF7',
												borderRadius: 5,
												padding: 10,
												verticalAlign: 'middle',
											}}>
											<p style={{ marginBottom: 0 }}>
												문장의 난이도는 어휘의 난이도와 문장 길이의 영향을 받습니다. 읽는 사람이
												이해하기 쉬운 문장이 좋은 문장입니다. 목표를 세우고 나의 글을 수정해
												보세요.
												<br />
												<br />※ 참고 사항: 일반적으로 도서와 같이 긴 글은 난이도가 정확하게
												분석되나 500어절보다 짧은 글은 통계 수치의 왜곡으로 인하여 문장의
												난이도가 예상과 다르게 보일 수도 있습니다.
											</p>
										</div>
									</Col>
								</Row>
								<Row gutter={0} type="flex" className="flex-container">
									<Col className="flex-item">
										<div style={{ width: 50, height: 150 }}>
											<div style={{ padding: 0, margin: 0, height: 20 }}>
												<Icon
													type="caret-right"
													theme="filled"
													style={{
														position: 'relative',
														top: 161 - (lqscore === 0 ? 100 : lqscore) / 13,
														padding: 0,
														margin: 0,
														color: 'red',
													}}
												/>
											</div>
											<BarChart
												width={90}
												height={154}
												data={[{ name: 'lq', start: lqscore - 100, end: 95, mst: 95, med: 10 }]}
												margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
												<YAxis
													type="number"
													domain={[100, 1850]}
													ticks={[100, 290, 490, 700, 860, 1050, 1285, 1585, 1850]}
													axisLine={false}
													allowDecimals={true}
													allowDataOverflow={true}
													tickLine={false}
													interval={0}
													tick={<CustomizedAxisTick />}
													orientation="right"
												/>
												<XAxis hide={true} allowDecimals={true} />
												<CartesianGrid
													vertical={true}
													horizontal={true}
													strokeOpacity={0}
													fill="#5D9CEC"
													fillOpacity={0.65}
												/>
												<Bar dataKey="start" stackId="a" fill="#ffffff" fillOpacity={0} />
												<Bar dataKey="mst" stackId="a" fill="#1E88E5" />
												<Bar dataKey="med" stackId="a" fill="#d4380d" fillOpacity={1} />
												<Bar dataKey="end" stackId="a" fill="#1E88E5" />
											</BarChart>{' '}
										</div>
									</Col>
									<Col className="flex-item">
										<div style={{ marginTop: 30, width: 360, fontSize: '11px' }}>
											<List
												bordered={false}
												dataSource={sentenceLabels}
												style={{ fontSize: '11px' }}
												renderItem={(item) => (
													<List.Item style={{ padding: '0px', margin: '5px' }}>
														<Typography.Text
															style={{
																width: '75px',
																color: '#1E88E5',
																fontWeight: 500,
															}}>
															{item.name}
														</Typography.Text>{' '}
														{item.label}
													</List.Item>
												)}
											/>
										</div>
									</Col>
									<Col className="flex-item">
										<div
											style={{
												marginTop: '30px',
												width: 120,
												fontSize: '11px',
												textAlign: 'center',
											}}>
											<Divider orientation="left">
												<span
													style={{
														color: 'rgba(0,0,0,1)',
														fontSize: '12px',
														lineHeight: '12px',
													}}>
													문단별 난이도
												</span>
											</Divider>
										</div>
										<div
											style={{
												marginTop: '20px',
												width: 120,
												fontSize: '11px',
												paddingBottom: '20px',
											}}>
											<BarChart
												width={120}
												height={65}
												data={paramsDatas}
												margin={{ top: 10, bottom: 0 }}>
												<CartesianGrid stroke="#fff" vertical={false} horizontal={false} />
												<XAxis dataKey="name" tickLine={false} tick={{ fontSize: '10px' }} />
												<Bar dataKey="value" label={<CustomizedLabel unit="%" />} barSize={10}>
													{paramsDatas &&
														paramsDatas.map((entry, index) => {
															return (
																<Cell
																	key={`cell-${index}`}
																	fill={colors[index]}
																	cursor="pointer"
																/>
															);
														})}
												</Bar>
											</BarChart>
										</div>
									</Col>
								</Row>
							</div>
							<div className="col-md-6">
								<Divider orientation="left">
									<span
										style={{
											color: 'rgba(0,0,0,1)',
											fontSize: '12px',
											lineHeight: '12px',
										}}>
										어휘의 난이도 분포
									</span>
								</Divider>
								<Row style={{ marginTop: '10px' }}>
									<Col>
										<div
											style={{
												background: '#DDEBF7',
												borderRadius: 5,
												padding: 10,
												verticalAlign: 'middle',
											}}>
											<p style={{ marginBottom: 0 }}>
												'한국어의 등급별 총어휘 선정 (낱말 V.2001, 서울대학교 국어교육과
												김광해)' 논문의 7등급 어휘목록을 기준으로 하였습니다.
												<br />
												쉽거나 어려운 문장 모두 1, 2등급 어휘가 가장 많이 포함되어 있지만 문장이
												어려워질수록 3, 4등급 이상의 어려운 어휘가 더 많이 포함되는 추세를
												보입니다.
											</p>
										</div>
									</Col>
								</Row>
								<Row gutter={8} className="flex-container" type="flex" style={{ marginTop: '20px' }}>
									<Col className="flex-item">
										{wordDatas != null && wordDatas.length > 0 && (
											<div style={{ width: 100, height: 155 }}>
												<BarChart
													width={100}
													height={155}
													data={wordDatas}
													margin={{ top: 10, bottom: 10, left: 0, right: 0 }}
													layout="vertical">
													<CartesianGrid stroke="#fff" vertical={false} horizontal={false} />
													<XAxis hide={true} type="number" />
													<YAxis
														dataKey="name"
														tickLine={false}
														tick={{ fontSize: '10px' }}
														type="category"
														allowDecimals={true}
														allowDataOverflow={true}
													/>
													<Bar dataKey="value" fill="#1E88E5" />
												</BarChart>
											</div>
										)}
									</Col>
									<Col className="flex-item">
										<div style={{ marginTop: 15, marginLeft: 38, width: 370, fontSize: '11px' }}>
											{wordLabels.map((x) => {
												return (
													<Row key={x.name} gutter={48} type="flex" style={{ marginTop: 5 }}>
														<Col>
															<span
																style={{
																	color: '#1E88E5',
																	fontWeight: 500,
																}}>
																{x.name}
															</span>
														</Col>
														<Col>
															<span>{x.label}</span>
														</Col>
													</Row>
												);
											})}
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</div>
					<div
					//style={{ pageBreakBefore: 'always' }}
					>
						<div className="row" style={{ marginTop: 25 }}>
							<div className="col-md-6">
								<Divider orientation="left">
									<span
										style={{
											color: 'rgba(0,0,0,1)',
											fontSize: '12px',
											lineHeight: '12px',
										}}>
										문장 길이 분포
									</span>
								</Divider>
								<Row style={{ marginTop: '15px' }}>
									<Col>
										<div
											style={{
												background: '#DDEBF7',
												borderRadius: 5,
												padding: 10,
												verticalAlign: 'middle',
											}}>
											<p>
												좋은 문장은 원하는 내용을 정확하고 충실하게 전달하는 문장입니다. <br />
												나의 글을 구성하는 문장의 어절 수를 살펴보고 짜임새 있는 문장을 구성해
												보세요.
												<br /> &nbsp;
											</p>
										</div>
									</Col>
								</Row>
								<Row gutter={8} type="flex" style={{ marginTop: '20px' }}>
									<Col>
										{sentsByWords != null && (
											<ResponsiveContainer width={width - dist} height={200}>
												<BarChart
													data={sentsByWords}
													margin={{ top: 10, bottom: 10, left: 0, right: 0 }}>
													<CartesianGrid stroke="#fff" vertical={false} horizontal={false} />
													<YAxis hide={true} type="number" />
													<XAxis
														dataKey="name"
														tickLine={false}
														tick={{ fontSize: '10px' }}
														type="category"
														allowDecimals={true}
														allowDataOverflow={true}
													/>
													<Bar
														dataKey="value"
														fill="#1E88E5"
														label={<CustomizedLabel unit="문장" />}>
														{sentsByWords &&
															sentsByWords.map((entry, index) => {
																return (
																	<Cell
																		key={`cell-${index}`}
																		fill={sentsColors[index]}
																		cursor="pointer"
																	/>
																);
															})}
													</Bar>
												</BarChart>
											</ResponsiveContainer>
										)}
									</Col>
								</Row>
							</div>
							<div className="col-md-6">
								<Divider orientation="left">
									<span
										style={{
											color: 'rgba(0,0,0,1)',
											fontSize: '12px',
											lineHeight: '12px',
										}}>
										문장 길이 (어절 수)
									</span>
								</Divider>
								<Row style={{ marginTop: '15px' }}>
									<Col>
										<div
											style={{
												background: '#DDEBF7',
												borderRadius: 5,
												padding: 10,
												verticalAlign: 'middle',
											}}>
											<p>
												긴 문장이 너무 많으면 이해하기에 어렵거나 복잡한 글이 될 수 있고, <br />
												짧은 문장이 너무 많으면 표현이 단조로울 수 있습니다.
												<br /> 시각적으로 표시된 통계를 살펴보고 나의 글에 리듬을 더해 보세요.
											</p>
										</div>
									</Col>
								</Row>
								<Row gutter={8} type="flex" style={{ marginTop: '20px' }}>
									<Col>
										{sents != null && (
											<ResponsiveContainer width={width - dist} height={200}>
												<BarChart
													data={sents}
													margin={{ top: 10, bottom: 10, left: 0, right: 0 }}>
													<CartesianGrid stroke="#fff" vertical={false} horizontal={false} />
													<YAxis hide={true} type="number" />
													<XAxis
														dataKey="name"
														tickLine={false}
														tick={{ fontSize: '10px' }}
														type="category"
														allowDecimals={true}
														allowDataOverflow={true}
													/>
													<Bar dataKey="value" fill="#1E88E5" label={<CustomizedLabel />} />
												</BarChart>
											</ResponsiveContainer>
										)}
									</Col>
								</Row>
							</div>
						</div>
						<Row style={{ marginTop: '40px', marginBottom: '30px' }}>
							<Col>
								<div style={{ width: '100%', height: '3px', background: 'rgba(0,0,0,0.65)' }} />
							</Col>
						</Row>
					</div>
				</div>
			</Drawer>
		);
	}
}

/*<Header color="#0070C0" title="문서 통계" subTitle="DOCUMENT STATISTICS" /> */

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Reports);
