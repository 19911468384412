/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { Popover, List, Icon, Typography, Tag, Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'actions';

import * as services from 'services/sentence';
/*

- WebSpeller 클래스 API 설명
참조 package
org.narainfo.WebSpeller
Method 설명
getResultHTML(string): 문자열을 입력하여 맞춤법 검사 결과를 XML 형태로 Return 받는 Method
AddUserDic(string), DeleteUserDic(string): 입력하는 문자열을 일반 사용자 사전에 등록/삭제하는 Method


AddExUserDic_Normal(string, string): 입력한 문자열을 확장 사용자 사전에 일반 명사로 등록하는 Method
AddExUserDic_Person(string, string): 입력한 문자열을 확장 사용자 사전에 사람 명사로 등록하는 Method
AddExUserDic_HadaNoun(string, string): 입력한 문자열을 확장 사용자 사전에 동작성 명사로 등록하는 Method
DeleteExUserDic(string): 입력한 문자열을 확장 사용자 사전에서 삭제하는 Method

- 일반 사용자 사전과 확장 사용자 사전 설명
확장 사용자 사전
오류어와 대치어 쌍을 넣을 수 있는 사전이다.
오류어만 등록하여 시스템에서 허용하게 할 수도 있다.
띄어쓰기가 포함된 다어절 단어도 등록할 수 있다. (ex) 부산대학교 정문)

조사 결합 형태를 구별하여 적용하기 위해 단어의 종류를 아래의 3가지로 구분한다.
일반 명사: 일반적인 고유명사로 취급. ‘-하다’, ‘-에게’등의 조사가 붙을 수 없음. (ex) 포도, 책상, 의자)
사람 명사: 사람에 관련된 고유명사로 취급. ‘-에게’, ‘한테’등의 사람에게 붙을 수 있는 조사가 허용됨.
            (ex) 최성기, 조합장, 대통형)
동작성 명사: 행동에 관련된 명사로 취급. ‘-하다’가 붙을 수 있음. (ex) 공부(하다), 사과(하다))

(2) 일반 사용자 사전
오류어를 등록하여 시스템에서 허용하게만 할 수 있다.
띄어쓰기가 포함된 단어는 등록할 수 없다.


- XML 구조 설명
<PnuNlpSpeller> : 최상위 노드, Root의 역할만 함
└<PnuErrorWordList> : 오류어 리스트 노드, Attribute { [repeat: 반복교정 여부, 값은 yes/no] }
  └<PnuErrorWord> : 오류어 노드, Attribute { [nErrorIdx: 오류어 번호, 값은 0부터 1씩 증가], [m_nStart: 원문에서 오류어가 시작하는 위치. 문서의 시작을 0으로 본다.],     [m_nEnd: 원문에서의 오류어가 끝나는 위치] }
  └<OrgStr> : 입력된 문자열에 대한 노드, 오류어의 원래 문자열을 가짐.
  └<CandWordList> : 대치어 리스트 노드, Attribute { [m_nCount: 대치어 갯수, 0인 경우 대치어가 없음] }
      └<CandWord> : 대치어 노드, 대치어의 문자열을 가짐.
  └<Help> : 도움말 노드, 도움말 정보가 그대로 표현되어 있음. 개행을 html형식으로 <br/>로 나타낸 것이 특이사항.Attribute { [nCorrectMethod: 교정 방법에 대한 정보. 해당 정보를 이용해 밑줄 색을 다르게 표기할 수도 있음. 0-에러가 없을 때, 1-형태소 분석이 안 될 때, 2-오용어로 분석될 때, 3-다수어절 오류, 4-의미 문체 오류, 5-문장 부호 오류, 6-통계정보를 이용한 붙여쓰기, 7-영어 오용어로 분석될 때, 8-태깅 오류, 9-복합명사 언더바 오류, 10-오류 형태에 따라 붙여쓰기] }
  └<Error> : 검사 도중 오류가 발생했을 때 나타나는 노드. 오류 내용을 msg Attribute를 통해 표현함. 오류가 없으면 나타나지 않음.
*/

const styles = {
	handle: {
		direction: 'ltr',
		unicodeBidi: 'bidi-override',
		borderBottom: '2px solid red',
	},
};

const mapStateToProps = state => ({
	selectedItem: state.spell.selectedItem,
	disableItems: state.spell.disableItems,
	searchFilter: state.spell.searchFilter,
	decos: state.spell.decos,
	status: state.status,
	text: state.info.text,
});

const mapDispatchToProps = dispatch => ({
	onSelectedItem: selectedItem => dispatch(actions.onSelectedItem(selectedItem)),
	onReplaceItem: replaceItem => dispatch(actions.onReplaceItem(replaceItem)),
	onDisableItem: disableItem => dispatch(actions.onDisableItem(disableItem)),
	onAddItem: decoItem => dispatch(actions.onAddItem(decoItem)),
	onRemoveItem: decoItem => dispatch(actions.onRemoveItem(decoItem)),
	onSpellSearchFilter: searchFilter => dispatch(actions.onSpellSearchFilter(searchFilter)),
	onDecosOpen: (decoVisible, decoType, post) => dispatch(actions.onDecosOpen({ decoVisible, decoType, post })),
	onDecosClose: decoVisible => dispatch(actions.onDecosClose({ decoVisible })),
});

const repSuggestion = (e, item, props) => {
	const start = props.children[0].props.start;
	const end = start + props.decoratedText.length;
	const blockKey = props.children[0].props.block.getKey();
	props.onReplaceItem({ blockKey, start, end, text: item, orgStr: props.decoratedText });
};

const fetchLogSave = async datas => {
	await services.postApi(this, '/api/sentence/spell', datas);
	return true;
};

const onClickDisableItem = (e, props) => {
	props.onDisableItem(props.decoratedText);
	props.onSelectedItem(null);
};

const helpcateToStr = helpcate => {
	let _helpcate = [];
	if (helpcate.SPACE === 1) _helpcate.push('띄어쓰기 오류');
	else if (helpcate.STANDARD === 1) _helpcate.push('표준어 오류');
	else if (helpcate.SPELL === 1) _helpcate.push('철자 오류');
	else if (helpcate.SPECIAL_CHAR === 1) _helpcate.push('문장 부호 오류');
	else if (helpcate.WORD_SELECT === 1) _helpcate.push('어휘 선택 오류');
	else _helpcate.push('기타 오류');
	return _helpcate.join(',');
};

const onDetailClick = (e, datas, props, callback) => {
	const title = helpcateToStr(datas.helpcate);
	const start = props.children[0].props.start;
	const end = start + props.decoratedText.length;
	const blockKey = props.children[0].props.block.getKey();
	props.onDecosOpen(true, 'decoinfo', {
		title,
		orgData: { start, end, blockKey, orgStr: props.decoratedText },
		datas,
	});
	callback.hide();
};

const menu = (datas, props, isSelected, callback) => {
	return (
		<div>
			<List
				footer={
					<div style={{ padding: '5px', textAlign: 'center' }}>
						<Button
							type="primary"
							shape="round"
							size={'small'}
							onClick={e => onDetailClick(e, datas, props, callback)}>
							자세히 보기
						</Button>
					</div>
				}
				dataSource={datas.candWord}
				locale={{
					emptyText: '대치어가 없습니다.',
				}}
				renderItem={item => (
					<List.Item onClick={e => repSuggestion(e, item, props)}>
						<Tag color="" style={{ fontSize: '10px', lineHeight: '12px', cursor: 'default' }}>
							대치어
						</Tag>
						<b>
							<span style={{ cursor: 'pointer' }}>{item}</span>
						</b>
					</List.Item>
				)}
			/>
		</div>
	);
};

class HandleSpan extends Component {
	constructor(props) {
		super(props);

		const blockKey = this.props.children[0].props.block.getKey();
		const start = this.props.children[0].props.start;
		const end = start + this.props.decoratedText.length;
		const text = this.props.decoratedText;
		const _datas = this.props.decos
			.map(item => {
				if (item.orgStr === this.props.decoratedText) return item;
				else return null;
			})
			.filter(i => i != null);

		const offsetKey = this.props.offsetKey;

		this.state = {
			visible: false,
			isVisible: false,
			blockKey,
			start,
			end,
			text,
			data: _datas[0],
			offsetKey,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.children[0].props.start !== nextProps.children[0].props.start) {
			//console.log('test');
		}
		if (this.props.searchFilter !== nextProps.searchFilter) {
			//console.log('test');
		}
	}

	componentDidUpdate(prevProps, prevState) {
		/*
		if (
			this.props.selectedItem.start !== prevProps.selectedItem.start &&
			this.props.selectedItem.blockKey !== prevProps.selectedItem.blockKey
		) {
			const blockKey = this.props.children[0].props.block.getKey();
			const start = this.props.children[0].props.start;
			const end = start + this.props.decoratedText.length;
			console.log(this.props.selectedItem);
			if (
				blockKey === this.props.selectedItem.blockKey &&
				start <= this.props.selectedItem.start &&
				this.props.selectedItem.start <= end
			) {
				//	this.setState({ isVisible: true });
			} else {
				//		this.setState({ isVisible: false });
			}
		}
		*/
	}

	hide = () => {
		this.setState({
			visible: false,
		});
	};

	handleVisibleChange = visible => {
		if (visible) {
			const blockKey = this.props.children[0].props.block.getKey();
			const start = this.props.children[0].props.start;
			const end = start + this.props.decoratedText.length;
			this.props.onDecosClose(false);

			this.props.onSelectedItem({
				blockKey,
				start,
				end,
				text: this.props.decoratedText,
				offsetKey: this.props.offsetKey,
				scrollNo: false,
			});
		}
		this.setState({ visible });
	};

	render() {
		const blockKey = this.props.children[0].props.block.getKey();
		const selectedItem = this.props.selectedItem;
		let isSelected = false;

		if (selectedItem && selectedItem.blockKey === blockKey && selectedItem.text === this.props.decoratedText) {
			isSelected = true;
		} else {
			isSelected = false;
		}

		const _datas = this.props.decos
			.filter(z => z.blockKey === blockKey)
			.flatMap(z => z.filterSpells)
			.flatMap(z => z.spells)
			.map(item => {
				if (
					//	item.blockKey === blockKey &&
					item.orgStr === this.props.decoratedText
				)
					return item;
				else return null;
			})
			.filter(i => i != null);

		const menuItems = _datas[0];
		const isExist =
			this.props.disableItems != null && this.props.disableItems.indexOf(this.props.decoratedText) !== -1;
		if (isExist) return <span>{this.props.children}</span>;

		if (menuItems != null) {
			return (
				<Fragment>
					<Popover
						trigger="click"
						placement="bottomLeft"
						visible={this.state.visible}
						onVisibleChange={this.handleVisibleChange}
						content={menu(menuItems, this.props, isSelected, this)}
						title={
							<div>
								<Typography.Text strong>{helpcateToStr(menuItems.helpcate)}</Typography.Text>
							</div>
						}>
						<span
							style={styles.handle}
							className={isSelected ? 'selected' : ''}
							id={isSelected ? 'selected' : 'noselected'}>
							{this.props.children}
						</span>
					</Popover>
				</Fragment>
			);
		} else {
			return <span>{this.props.children}</span>;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HandleSpan));
