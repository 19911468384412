import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as actions from 'actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const mapDispatchToProps = dispatch => ({
	validate: user => dispatch(actions.validate(user)),
	logout: () => dispatch(actions.logout()),
});

export default ComposedComponent => {
	// If user not authenticated render out to root

	class Authentication extends Component {
		_isMounted = false;

		static contextTypes = {
			router: PropTypes.object,
		};

		state = {
			authenticated: false,
		};

		componentDidMount() {
			this._isMounted = true;
			this.setState({
				authenticated: this.props.authenticated,
			});
		}

		componentWillMount() {
			const USER = JSON.parse(window.localStorage.getItem('user'));
			if (USER != null) {
				//console.log('ComposedComponent');
				this.props.validate(USER);
			}
			//console.log(this.props.authenticated);
			if (!this.props.authenticated && !this._isMounted && window.sessionStorage.getItem('user') == null) {
				this.context.router.history.push({ pathname: '/views/login', state: { from: this.props.location } });
			}
		}

		componentWillReceiveProps(nextProps) {
			//console.log(nextProps.authenticated);
			this.setState({ authenticated: nextProps.authenticated });
			if (!nextProps.authenticated) {
				this.context.router.history.push({ pathname: '/views/login', state: { from: this.props.location } });
			}
		}

		componentWillUnmount() {
			this._isMounted = false;
		}

		render() {
			return <ComposedComponent {...this.props} />;
		}
	}

	function mapStateToProps(state) {
		return { authenticated: state.auth.authenticated };
	}

	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(withRouter(Authentication));
};
