import * as types from 'actions/ActionTypes';

const initialState = {
    showOptions: false,
    dicType: '',
    selectedItem: null,
    historySelectedItem: null
};

const dicSearch = function(state = initialState, action) {
    switch(action.type) {
        case types.DICTIONARY_SHOW_OPTIONS:
            return {
                ...state,
                showOptions: action.showOptions
            }
        case types.DICTIONARY_SAVE_WORD:
            return {                
                userId: action.userId,
                dicType: action.dicType,
                selectedItem: action.selectedItem
            }
        case types.DICTIONARY_HISTORY_BACK:
            return {
                historySelectedItem: action.historySelectedItem
            }
        default:
            return state;
    }
}

export default dicSearch;