import * as types from 'actions/ActionTypes';

const initialState = {
    searchText: '',    
    minLq: 0,
    maxLq: 0,
    targetGrade: '',
    fromResults: false,
    detailMinLq: '',
    detailMmaxLq: '',
    detailTargetGrade: '',
    detailIsbnMain: '',
    detailIsbnSub: '',
    searchAction: '',
    pageNum: 0
};

const bookSearch = function(state = initialState, action) {
    switch(action.type) {
        case types.BOOK_SEARCH_KEYWORD:
            return {
                ...state,
                searchText: action.searchText,
                fromResults: false,
                minLq: 0,
                maxLq: 0,
                targetGrade: '',
                detailMinLq: '',
                detailMmaxLq: '',
                detailTargetGrade: '',
                detailIsbnMain: '',
                detailIsbnSub: '',
                searchAction: 'T'
            };
        case types.BOOK_SEARCH_LQ_GRADE:
            return {
                ...state,
                searchText: '',
                minLq: action.minLq,
                maxLq: action.maxLq,
                targetGrade: '',
                detailMinLq: '',
                detailMmaxLq: '',
                detailTargetGrade: '',
                detailIsbnMain: '',
                detailIsbnSub: '',
                searchAction: 'L'
            };
        case types.BOOK_SEARCH_SC_GRADE:
            return {
                ...state,
                searchText: '',                
                minLq: action.minLq,
                maxLq: action.maxLq,
                targetGrade: action.targetGrade,
                detailMinLq: '',
                detailMmaxLq: '',
                detailTargetGrade: '',
                detailIsbnMain: '',
                detailIsbnSub: '',
                searchAction: 'S'
            };
        case types.BOOK_SEARCH_DETAIL:
            return {
                ...state,
                fromResults: action.fromResults,
                detailMinLq: action.detailMinLq,
                detailMmaxLq: action.detailMmaxLq,
                detailTargetGrade: action.detailTargetGrade,
                detailIsbnMain: action.detailIsbnMain,
                detailIsbnSub: action.detailIsbnSub,
                searchAction: 'D'
            };
        default:
            return state;
    }

};

export default bookSearch;
