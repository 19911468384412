export const groups = {
  /**
   * 학교 일반 구분
   *
   * @memberof EtestsGroups
   */
  isGroupCommon: (div, schoolgubun) => {
    if (div && div.trim() === 'SCHOOL') {
      if (
        schoolgubun &&
        (schoolgubun.trim() === 'elem_list' ||
          schoolgubun.trim() === 'midd_list' ||
          schoolgubun.trim() === 'high_list' ||
          schoolgubun.trim() === 'univ_list')
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  },
};

export const groupBy = (datas, prop) => {
  return datas.reduce(function(groups, item) {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);

    return groups;
  }, {});
};

export const avg = (array) => {
  return (
    array.reduce((a, b) => {
      return a + b;
    }) / array.length
  );
};

export const mergeArrays = (arrays, prop) => {
  const merged = {};

  arrays.forEach((arr) => {
    arr.forEach((item) => {
      merged[item[prop]] = Object.assign({}, merged[item[prop]], item);
    });
  });

  return Object.values(merged);
};
