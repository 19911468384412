import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import { UK, JP, FR, ES, SA, MN, VN, TH, ID, RU, CH } from './icons';
import { connect } from 'react-redux';
import * as actions from 'actions';

const mapDispatchToProps = dispatch => ({
	onMultiLangOption: multioptions => dispatch(actions.onMultiLangOption(multioptions)),
	onDecosOpen: (decoVisible, decoType, post) => dispatch(actions.onDecosOpen({ decoVisible, decoType, post })),
});

const menus = [
	{ key: 'menu2', title: '일본', subname: '日本', value: '2', component: JP },
	{ key: 'menu8', title: '태국', subname: 'ภาษาไทย', value: '8', component: TH },
	{ key: 'menu9', title: '인도네시아', subname: 'Indonesia', value: '9', component: ID },
	{ key: 'menu3', title: '프랑스', subname: 'Français', value: '3', component: FR },
	{ key: 'menu4', title: '스페인', subname: 'Español', value: '4', component: ES },
	{ key: 'menu10', title: '러시아', subname: 'Pусский', value: '10', component: RU },
	{ key: 'menu5', title: '아랍', subname: 'Arabic', value: '5', component: SA },
	{ key: 'menu6', title: '몽골', subname: 'Монгол хэл', value: '6', component: MN },
	{ key: 'menu7', title: '베트남', subname: 'Tiếng Việt', value: '7', component: VN },
	{ key: 'menu11', title: '중국', subname: '中国', value: '11', component: CH },
];

class MultiLangSetup extends Component {
	onClickLanguage = (e, title, option) => {
		this.props.onMultiLangOption(parseInt(option));
		/*
		this.props.onDecosOpen(true, 'multilang', {
			title: title,
			//		helpcate,
			option,
		});
		*/
	};

	render() {
		return (
			<Fragment>
				<div
					style={{
						padding: '8px 10px',
						margin: '0',
						background: '#fff',
					}}>
					<Menu mode="inline" style={{ padding: 0, margin: 0 }}>
						<Menu.Item
							key="menu1"
							onClick={e => this.onClickLanguage(e, '영어', '1')}
							style={{ padding: 0, margin: 0 }}>
							<Icon component={UK} />
							영어 (English)
						</Menu.Item>
						{menus
							.sort((a, b) => {
								if (a.title < b.title) return -1;
								if (a.title > b.title) return 1;
								return 0;
							})
							.map(x => {
								return (
									<Menu.Item
										key={x.key}
										onClick={e => this.onClickLanguage(e, x.title, x.value)}
										style={{ padding: 0, margin: 0 }}>
										<Icon component={x.component} />
										{x.title} ({x.subname})
									</Menu.Item>
								);
							})}
					</Menu>
				</div>
			</Fragment>
		);
	}
}
/*
	<Menu.Item key="menu11" onClick={e => this.onClickLanguage(e, '중국어', '11')}>
							<Icon component={CH} />
							중국
						</Menu.Item>
*/

export default connect(null, mapDispatchToProps)(withRouter(MultiLangSetup));
