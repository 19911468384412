import React, { Component } from 'react';

class PuzzleRatingList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            puzzleRatingList: props.puzzleRatingList
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.puzzleRatingList !== nextProps.puzzleRatingList) {
            this.setState({
                puzzleRatingList: nextProps.puzzleRatingList
            })
        }       
    }

    render  () {

        const { puzzleRatingList } = this.state;

        return (
            <div className="card mb-6">
                <div className="card-body">
                    <div className="natmal-h3 natmal-color right-line right-line-white">
                        <span><i className="fas fa-cube"></i></span> <span className="font-weight-bold"> &nbsp;최신 퍼즐 평점</span>
                    </div>        
                    <div id="userCommentList" className="mt-1 pl-2 pr-2 mb-3">
                        <table className="table table-condensed natmal-table-bordered">
                            <tbody>
                                { puzzleRatingList.map !== undefined &&
                                    puzzleRatingList.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div className="flex flex-row">
                                                        <font className="small font-weight-boldm mr-1">{item.user.USEREMAIL.split('@')[0]}***</font>
                                                        <font className="small text-muted mr-1">{item.CREATED_DATE.split('T')[0]}</font>
                                                        {item.SCORE === 1 && <div><i className="fas fa-star small color-warning"></i></div>}
                                                        {item.SCORE === 2 && <div><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i></div>}
                                                        {item.SCORE === 3 && <div><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i></div>}
                                                        {item.SCORE === 4 && <div><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i></div>}
                                                        {item.SCORE === 5 && <div><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i><i className="fas fa-star small color-warning"></i></div>}
                                                    </div>
                                                    <div>
                                                        <span>{item.SCORE_NOTE}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default PuzzleRatingList;

