export const decosBySearch = (search, decos) => {
	console.log(decos);
	switch (search) {
		case 'SPACE': {
			let _decos = decos.filter(
				x => x.divideSpells.filter(y => y.spells.filter(z => z.helpcate.SPACE === 1).length > 0).length > 0
			);
			console.log(_decos);
			return _decos;
		}
		case 'STANDARD': {
			let _decos = Object.keys(decos)
				.filter(key => decos[key].helpcate.STANDARD === 1)
				.map(key => decos[key]);
			return _decos;
		}
		case 'SPELL': {
			let _decos = Object.keys(decos)
				.filter(key => decos[key].helpcate.SPELL === 1)
				.map(key => decos[key]);
			return _decos;
		}
		case 'SPECIAL_CHAR': {
			let _decos = Object.keys(decos)
				.filter(key => decos[key].helpcate.SPECIAL_CHAR === 1)
				.map(key => decos[key]);
			return _decos;
		}
		case 'WORD_SELECT': {
			let _decos = Object.keys(decos)
				.filter(key => decos[key].helpcate.WORD_SELECT === 1)
				.map(key => decos[key]);
			return _decos;
		}
		case 'ETC': {
			let _decos = Object.keys(decos)
				.filter(
					key =>
						decos[key].helpcate.SPACE !== 1 &&
						decos[key].helpcate.STANDARD !== 1 &&
						decos[key].helpcate.SPELL !== 1 &&
						decos[key].helpcate.SPECIAL_CHAR !== 1 &&
						decos[key].helpcate.WORD_SELECT !== 1
				)
				.map(key => decos[key]);
			return _decos;
		}
		default:
			return decos;
	}
};

export const getUniqueObjectArray = (array, key) => {
	return array.filter((item, i) => {
		return (
			array.findIndex((item2, j) => {
				return item[key] === item2[key];
			}) === i
		);
	});
};

export const getUniqueLastObjectArray = (array, key) => {
	return array.reverse().filter((item, i) => {
		return (
			array.findIndex((item2, j) => {
				return item[key] === item2[key];
			}) === i
		);
	});
};

export const groupBy = (list, keyGetter) => {
	const map = new Map();
	list.forEach(item => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
};

export const createMiniDicIframeSrc = (user, dic, word_no) => {
	let iframeSrc = '';
	if(user !== undefined && user !== null) {
		if(dic !== '') {
			if(word_no !== '') {
				iframeSrc = 'https://minidic.natmal.com/?linkType=SENTENCE&id='+user.id+'&email='+user.userEmail+'&dic='+dic+'&wordId='+word_no;
			}
		}
	}
	return iframeSrc;
};