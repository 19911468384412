import React, { Component, Fragment } from 'react';
import {
	Layout,
	Collapse,
	Tag,
	Badge,
	Icon,
	Row,
	Col,
	Card,
	message,
	Spin,
	Modal,
	Carousel,
	Button,
	Popover,
	List,
	Typography,
} from 'antd';
import { WordInfo, LiteracyInfo, DistinctInfo } from './informations';
import { SpellsSetup, SynoSetup, HanjaSetup, DialectSetup, MultiLangSetup } from './setups';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as services from 'services/sentence';
import * as actions from 'actions';

import Advance from './advance';
import './RightMenu.css';
import helperCate from './HelperCate.json';
import { _ } from 'core-js';
var nlp = require('compromise');

const { Content } = Layout;
const Panel = Collapse.Panel;

const customPanelStyle = {
	selected: {
		borderRadius: 4,
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: '0!important',
		border: '1px solid #596674',
		overflow: 'hidden',
	},
	unselected: {
		borderRadius: 4,
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: '0!important',
		border: '1px solid #e8e8e8',
		overflow: 'hidden',
	},
};
const mapStateToProps = (state) => ({
	lqInfosText: state.info.lqInfosText,
	blockKeys: state.blocks.blockKeys,
	blockTexts: state.blocks.blockTexts,
	flag: state.blocks.flag,
	disableItems: state.spell.disableItems,
	selectMenu: state.menu.selectMenu,
	filters: state.syno.filters,
	dialectFilters: state.dialect.dialectFilters,
	spellChecked: state.checked.spellChecked,
	synoChecked: state.checked.synoChecked,
	dialectChecked: state.checked.dialectChecked,
	lqChecked: state.checked.lqChecked,
	user: state.auth.user,
	authenticated: state.auth.authenticated,
	searchFilter: state.spell.searchFilter,
	//multioptions: state.multilang.multioptions,
	//type: state.blocks.type,
});

const info = () => {
	message.info('서비스 준비 중입니다.');
};

const data = [
	{ range: '1 (1320~1850)', text: '법조문이나 전문서적에 포함되는 글의 평균 수준' },
	{ range: '2 (1120~1450)', text: '신문 사설이나 대입 논술고사 지문에 포함되는 글의 평균 수준' },
	{ range: '3 (910~1190)', text: '고등학교 1~3학년 교과서에 포함되는 글의 평균 수준' },
	{ range: '4 (720~1000)', text: '초등학교 6~중학교 3학년 교과서에 포함되는 글의 평균 수준' },
	{ range: '5 (560~840)', text: '초등학교 3~5학년 교과서에 포함되는 글의 평균 수준' },
	{ range: '6 (350~630)', text: '초등학교 1~2학년 교과서에 포함되는 글의 평균 수준' },
	{ range: '7 (100~480)', text: '어린이 동화책에 포함되는 글의 평균 수준' },
];

const content = (
	<div>
		<p>
			글은 사용된 어휘들이 어렵고 문장 길이가 길수록 이해하기가 어려워집니다. <br />
			문서의 난이도는 이러한 이치를 바탕으로 과학적인 검증과정을 거쳐 결정되었습니다.
			<br /> 일반적으로 긴 문장은 정확하게 분석되나 500어절보다 짧은 글은 어휘의 난이도를 반영하는 통계 <br />
			수치의 왜곡으로 인하여 문장의 난이도가 예상과 다르게 높거나 낮게 보일 수 있습니다.
		</p>
		<p>문장분석기의 난이도는 다음의 지수 범위를 기준으로 설계되었습니다.</p>
		<List
			bordered={false}
			dataSource={data}
			renderItem={(item) => (
				<List.Item>
					<Typography.Text style={{ width: '120px', color: '#1E88E5', fontWeight: 500 }}>
						{item.range}
					</Typography.Text>{' '}
					{item.text}
				</List.Item>
			)}
		/>
	</div>
);

const content_spell = (
	<div style={{ width: '350px' }}>
		<p>
			한국어 어문 규정(한글 맞춤법, 표준어 규정, 외래어 표기법)에 따른 검사 결과입니다.
			<br /> 철자 오류, 어휘선택 오류 등과 같이 일반적인 문서작성 프로그램에서는 제공하지 않는 맞춤법 검사 결과를
			확인할 수 있습니다. 일부 결과는 부정확할 수 있으니 이 경우는 무시하거나 오류 신고를 하시면 프로그램의 기능
			향상에 도움이 됩니다.
		</p>
	</div>
);

const content_grammar = (
	<div style={{ width: '350px' }}>
		<p>
			어휘의 선택과 표현, 품사의 사용, 문장 성분의 호응과 같은 문법/어법과 관련된 검사 기능이 추가될 예정입니다.
		</p>
	</div>
);

const content_syn = (
	<div style={{ width: '350px' }}>
		<p>
			글을 쓰면서 유의어를 활용하면 풍부한 어휘를 구사할 수 있습니다.
			<br /> 쉽게 쓸 것인지 아니면 어렵게 쓸 것인지, 학술적인 표현을 할 것인지 아니면 일상적인 표현을 할 것인지의
			목표를 정하면 원하는 목적의 세련된 어휘를 사용할 수 있도록 도와 드립니다.
		</p>
	</div>
);

const content_distinct = (
	<div style={{ width: '350px' }}>
		<p>
			중복된 표현이 자주 나오면 글의 집중도가 떨어지기 때문에 가능한 한 피하는 것이 좋습니다. <br />
			중복 표현 목록을 살펴보고 간결하면서도 다채로운 표현의 글로 수정해 보세요.
		</p>
	</div>
);

const content_word = (
	<div style={{ width: '350px' }}>
		<p>
			중요한 품사별로 정리된 다빈도 어휘 목록을 살펴볼 수 있습니다.
			<br /> 통계를 살펴보고 문장 중에 더욱 전달하고 싶거나 강조하고 싶은 부분을 확인할 때 도움이 되는 기능입니다.
		</p>
	</div>
);

const content_hanja = (
	<div style={{ width: '350px' }}>
		<p>
			한국어에서 한자어(漢字語)는 고유어보다 더 큰 비중을 차지하는 어휘입니다. <br />
			한자어는 한글로도 표기할 수 있으나 정확한 의미의 전달이나 확인을 위해서는 한자(漢字)를 알아야 할 경우가
			있습니다.
		</p>
	</div>
);

const content_dialect = (
	<div style={{ width: '350px' }}>
		<p>
			방언은 고유한 가치를 지니는 우리의 소중한 언어 자산입니다.
			<br /> 8도 및 제주도의 방언을 적절히 활용하면 우리말의 지평을 넓힐 수 있습니다.
		</p>
	</div>
);

const content_multi = (
	<div style={{ width: '350px' }}>
		<p>
			한국어를 11개의 다국어로 살펴볼 수 있습니다. <br />
			한국어를 공부하는 외국인이나 외국어를 공부하는 한국인에게도 도움이 될 것입니다.
		</p>
	</div>
);

const getUniqueObjectArray = (array, key) => {
	return array.filter((item, i) => {
		return (
			array.findIndex((item2, j) => {
				return item[key] === item2[key];
			}) === i
		);
	});
};

const getUniqueObjectArrayByMultiKey = (array, key, key2) => {
	return array.filter((item, i) => {
		return (
			array.findIndex((item2, j) => {
				return item[key] === item2[key] && item[key2] === item2[key2];
			}) === i
		);
	});
};

let root;

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const SelectedHeader = (props) => {
	if (props.isSwitch) {
		root.state[props.name] = true;
	}
	return (
		<Card
			size="small"
			style={{ background: '#596674', borderRadius: 0, border: 0 }}
			bodyStyle={{ padding: 0, margin: 0 }}
			headStyle={{ borderBottom: 0 }}
			title={
				<Row type="flex" justify="space-between" align="middle" style={{ padding: '1px', margin: '0px' }}>
					<Col span={11}>
						<Row type="flex" justify="start">
							<Col span={6}>
								<Icon type="minus-circle" theme="filled" style={{ fontSize: '16px', color: '#fff' }} />
							</Col>
							<Col style={{ padding: '0px' }} span={10}>
								<span style={{ fontWeight: '500', color: '#fff' }}>
									{props.title} &nbsp;
									{props.lq == null && (
										<Popover placement="topLeft" title={props.title} content={props.content}>
											<Icon
												type="question-circle"
												theme="filled"
												style={{ fontSize: '16px', color: '#fff' }}
											/>
										</Popover>
									)}
								</span>
								<br />
								<span style={{ fontSize: '8pt', color: 'darkgrey' }}>{props.eng}</span>
							</Col>
						</Row>
					</Col>
					<Col>
						{props.isLoading && <Spin indicator={antIcon} />}
						{!props.isLoading && props.count != null && (
							<Badge count={props.count} style={props.countStyle} />
						)}
						{!props.isLoading && props.lq != null && (
							<span style={{ color: '#fff' }}>
								{props.lq} &nbsp; &nbsp;
								<Popover placement="topLeft" title="문서의 난이도" content={content}>
									<Icon
										type="question-circle"
										theme="filled"
										style={{ fontSize: '16px', color: '#fff' }}
									/>
								</Popover>
							</span>
						)}
					</Col>
				</Row>
			}
		/>
	);
};

const UnSelectedHeader = (props) => {
	return (
		<Card
			size="small"
			style={{ border: 0 }}
			bodyStyle={{ padding: 0 }}
			title={
				<Row type="flex" justify="space-between" align="middle" style={{ padding: '0px', margin: '0px' }}>
					<Col span={11}>
						<Row type="flex" justify="start">
							<Col span={6}>
								<Icon type="plus-circle" style={{ fontSize: '16px', color: '#08c' }} />
							</Col>
							<Col style={{ padding: '0px' }} span={10}>
								<span style={{ fontWeight: '500' }}>
									{props.title}&nbsp;
									{props.lq == null && (
										<Popover placement="topLeft" title={props.title} content={props.content}>
											<Icon
												type="question-circle"
												theme="filled"
												style={{ fontSize: '16px', color: 'rgb(89, 102, 116)' }}
											/>
										</Popover>
									)}
								</span>
								<br />
								<span style={{ fontSize: '8pt', color: 'darkgrey' }}>{props.eng}</span>
							</Col>
						</Row>
					</Col>
					<Col>
						{/*props.isSwitch && (
							<Row justify="end" align="top">
								<Col>
									<Switch
										size="small"
										checked={root.state[props.name]}
										onClick={(checked, e) => {
											e.stopPropagation();
											root.setState({ [props.name]: checked });
										}}
									/>
								</Col>
							</Row>

									)*/}
						<Row justify="end">
							<Col>
								{props.isLoading && <Spin indicator={antIcon} />}
								{!props.isLoading && props.count != null && (
									<Badge size="small" count={props.count} style={props.countStyle} />
								)}
								{!props.isLoading && props.lq != null && (
									<span style={{ color: 'rgb(19, 91, 205)' }}>
										{props.lq} &nbsp; &nbsp;
										<Popover placement="topLeft" title="문서의 난이도" content={content}>
											<Icon
												type="question-circle"
												theme="filled"
												style={{ fontSize: '16px', color: 'rgb(89, 102, 116)' }}
											/>
										</Popover>
									</span>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			}
		/>
	);
};

const mapDispatchToProps = (dispatch) => ({
	onTaggedText: (lqInfos) => dispatch(actions.onTaggedText(lqInfos)),
	onChangeBlockKey: (blockKeys) => dispatch(actions.onChangeBlockKey(blockKeys)),
	onBlockKeyText: (blockTexts, flag) => dispatch(actions.onBlockKeyText(blockTexts, flag)),
	onSpellDeco: (decos) => dispatch(actions.onSpellDeco(decos)),
	onSynoDeco: (synos) => dispatch(actions.onSynoDeco(synos)),
	onDialectDeco: (dialects) => dispatch(actions.onDialectDeco(dialects)),
	onHanjasBind: (hanjas) => dispatch(actions.onHanjasBind(hanjas)),
	onMultiLang: (multilangs) => dispatch(actions.onMultiLang(multilangs)),
	onSelectRightMenu: (selectMenu) => dispatch(actions.onSelectRightMenu(selectMenu)),
	onDecosClose: (decoVisible) => dispatch(actions.onDecosClose({ decoVisible })),
	onDecosOpen: (decoVisible, decoType, post) => dispatch(actions.onDecosOpen({ decoVisible, decoType, post })),
	onGetEditorState: () => dispatch(actions.onGetEditorState()),
	onLQScore: (lqscore) => dispatch(actions.onLQScore(lqscore)),
	onProcessComplete: (isComplete) => dispatch(actions.onProcessComplete(isComplete)),
});

const division = (arr, n) => {
	const len = arr.length;
	const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
	const tmp = [];
	for (let i = 0; i < cnt; i++) {
		tmp.push(arr.splice(0, n));
	}
	return tmp;
};

const decosFilter = (search, divideSpells) => {
	switch (search) {
		case 'SPACE': {
			let _decos = divideSpells.map((y) => {
				const spells = y.spells.filter((z) => z.helpcate.SPACE === 1);
				return Object.assign({}, y, { spells });
			});
			return _decos;
		}
		case 'STANDARD': {
			let _decos = divideSpells.map((y) => {
				const spells = y.spells.filter((z) => z.helpcate.STANDARD === 1);
				return Object.assign({}, y, { spells });
			});
			return _decos;
		}
		case 'SPELL': {
			let _decos = divideSpells.map((y) => {
				const spells = y.spells.filter((z) => z.helpcate.SPELL === 1);
				return Object.assign({}, y, { spells });
			});
			return _decos;
		}
		case 'SPECIAL_CHAR': {
			let _decos = divideSpells.map((y) => {
				const spells = y.spells.filter((z) => z.helpcate.SPECIAL_CHAR === 1);
				return Object.assign({}, y, { spells });
			});
			return _decos;
		}
		case 'WORD_SELECT': {
			let _decos = divideSpells.map((y) => {
				const spells = y.spells.filter((z) => z.helpcate.WORD_SELECT === 1);
				return Object.assign({}, y, { spells });
			});
			return _decos;
		}
		case 'ETC': {
			let _decos = divideSpells.map((y) => {
				const spells = y.spells.filter(
					(z) =>
						z.helpcate.SPACE !== 1 &&
						z.helpcate.STANDARD !== 1 &&
						z.helpcate.SPELL !== 1 &&
						z.helpcate.SPECIAL_CHAR !== 1 &&
						z.helpcate.WORD_SELECT !== 1
				);
				return Object.assign({}, y, { spells });
			});

			return _decos;
		}
		default:
			return divideSpells;
	}
};

const dialectFilter = (dialect, pattern) => {
	return dialect.map((item) => {
		const value = item.value;
		const _dialects = value
			.map((x) => {
				const dialects = x.dialects
					.map((i) => Object.assign({}, i, { LOCATION: i.LOCATION.split(',').map((x) => x.trim()) }))
					.filter(
						(i) =>
							i.LOCATION.filter((i) => pattern.exec(i.trim())) != null &&
							i.LOCATION.filter((i) => pattern.exec(i.trim())).length > 0
					);
				return dialects;
			})
			.filter((x) => x != null && x.length > 0)
			.map((x) =>
				Object.assign(
					{},
					{},
					{
						PYOJAE_NO: x[0].WORD_NO,
						PYOJAE_NAME: x[0].WORD_NAME,
						PYOJAE_HANJA: x[0].PYOJAE_HANJA,
						PUMSA: x[0].PART_SPEECH,
						dialects: [...x],
					}
				)
			);
		return Object.assign({}, item, { dialectsFilter: _dialects ? _dialects : [] });
	});
};

const synofilters = (synos, filters) => {
	return synos.map((item) => {
		const value = item.value;
		const _synonyms = value
			.map((x) => {
				let synonyms = [];
				if (filters.level === 1) {
					synonyms = x.synonyms.filter(
						(i) => i.SYN_LEVEL < i.PYOJAE_LEVEL && i.SYN_LEVEL > 0 && i.SYN_LEVEL < 33
					);
				}
				if (filters.level === 0) {
					synonyms = x.synonyms.filter(
						(i) => i.SYN_LEVEL > i.PYOJAE_LEVEL && i.SYN_LEVEL > 0 && i.SYN_LEVEL < 33
					);
				}
				if (filters.level === 2) {
					synonyms = x.synonyms;
				}

				if (filters.base1) {
					const pattern = /일상/;
					synonyms = synonyms.filter((i) => pattern.test(i.DIC_TAG));
				}

				if (filters.base2) {
					const pattern = /정서/;
					synonyms = synonyms.filter((i) => pattern.test(i.DIC_TAG));
				}

				if (filters.base3) {
					const pattern = /학술/;
					synonyms = synonyms.filter((i) => pattern.test(i.DIC_TAG));
				}

				if (filters.checkedKeys.length > 0) {
					const tfs = filters.checkedKeys.join('|');
					const pattern = new RegExp(tfs, 'g');
					const pattern2 = /일상/;
					synonyms = synonyms.filter((i) => pattern.test(i.TECH_TERM_01) && !pattern2.test(i.DIC_TAG));
				}
				return synonyms;
			})
			.filter((x) => x != null && x.length > 0)
			.map((x) =>
				Object.assign(
					{},
					{},
					{
						PYOJAE_NO: x[0].PYOJAE_NO,
						PYOJAE_NAME: x[0].PYOJAE_NAME,
						PYOJAE_HANJA: x[0].PYOJAE_HANJA,
						PUMSA: x[0].PART_SPEECH,
						synonyms: [...x],
					}
				)
			);
		return Object.assign({}, item, { synoymsFilter: _synonyms ? _synonyms : [] });
	});
};

const REQUEST_COUNT = 5;

class RightMenu extends Component {
	fetchLq = async (text) => {
		const post = await services.postApi(this, '/api/lq/book/analysis', text);
		if (post && post.status === 200) {
			let lqInfos = post.data.results;
			if (lqInfos != null) {
				this.props.onLQScore(lqInfos.lq);
				this.setState({ lq: lqInfos.lq, isLqInfosTextLoading: false });
			}
		}
	};

	fetchDatas = async (blocks) => {
		const _blocks = division(blocks, Math.ceil(blocks.length / REQUEST_COUNT));
		const taskArray = [];
		for (let i = 0; i < REQUEST_COUNT; i++) {
			const task1 = new Promise((resolve) => {
				if (_blocks[i] == null) return resolve({ apiCall: null });
				services.postApi(this, '/api/lq/book/blockAnalysis', _blocks[i]).then((res) => {
					if (res.status === 200) {
						return resolve({ apiCall: res.data });
					} else {
						return resolve({ apiCall: null });
					}
				});
			});
			const task2 = new Promise((resolve) => {
				if (_blocks[i] == null) return resolve({ apiSpellCall: null });
				services.postApi(this, '/api/sentence/spellBlocks', _blocks[i]).then((res) => {
					if (res.status === 200) {
						return resolve({ apiSpellCall: res.data });
					} else {
						return resolve({ apiSpellCall: null });
					}
				});
			});
			const task3 = new Promise((resolve) => {
				if (_blocks[i] == null) return resolve({ apiSynoCall: null });
				services.postApi(this, '/api/sentence/synonymBlocks', _blocks[i]).then((res) => {
					if (res.status === 200) {
						return resolve({ apiSynoCall: res.data });
					} else {
						return resolve({ apiSynoCall: null });
					}
				});
			});
			const task4 = new Promise((resolve) => {
				if (_blocks[i] == null) return resolve({ apiDialectCall: null });
				services.postApi(this, '/api/sentence/dialectBlocks', _blocks[i]).then((res) => {
					if (res.status === 200) {
						return resolve({ apiDialectCall: res.data });
					} else {
						return resolve({ apiDialectCall: null });
					}
				});
			});
			taskArray.push(task1, task2, task3, task4);
		}

		const apiCalls = await Promise.all(taskArray);
		const r_summary = apiCalls
			.filter((x) => x.apiCall != null)
			.flatMap((x) => x.apiCall.results)
			.filter((x) => x != null);
		const r_spells = apiCalls
			.filter((x) => x.apiSpellCall != null)
			.flatMap((x) => x.apiSpellCall.results)
			.filter((x) => x != null);
		const r_synonyms = apiCalls
			.filter((x) => x.apiSynoCall != null)
			.flatMap((x) => x.apiSynoCall.results)
			.filter((x) => x != null);
		const r_dialects = apiCalls
			.filter((x) => x.apiDialectCall != null)
			.flatMap((x) => x.apiDialectCall.results)
			.filter((x) => x != null);
		let _state = this.state;
		if (r_summary != null) {
			const { lqInfos } = this.state;
			const _lqInfos = r_summary
				.filter((x) => x != null && x.lqInfos != null)
				.map((x) => Object.assign({}, x.lqInfos, { blockKey: x.blockKey }));

			const newLqInfos = getUniqueObjectArray(_.union(lqInfos, _lqInfos), 'blockKey');
			this.props.onTaggedText(newLqInfos);
			_state = Object.assign({}, _state, {
				changeLqInfos: false,
				isLqInfosLoading: false,
				fetchLqStatus: false,
				lqInfos: newLqInfos,
			});
		}
		if (r_spells != null) {
			const { disableItems, decos, searchFilter } = this.state;
			const _decos = r_spells
				.filter((x) => x != null)
				.map((x) => {
					const divideSpells = x.divideSpells.map((s) => {
						const spells = s.spells.filter(
							(i) => !(disableItems != null && disableItems.indexOf(i.orgStr) !== -1)
						);
						return Object.assign({}, s, { spells });
					});
					const filterSpells = decosFilter(searchFilter, divideSpells);
					return Object.assign({}, x, { divideSpells, filterSpells });
				});
			const newDecos = getUniqueObjectArray(_.union(decos, _decos), 'blockKey');
			this.props.onSpellDeco(newDecos);
			_state = Object.assign({}, _state, {
				changeDecos: false,
				isSpellCheckLoading: false,
				decos: newDecos,
				count: newDecos.flatMap((x) => x.divideSpells.flatMap((s) => s.spells)).length,
			});
		}
		if (r_synonyms != null) {
			const { filters, synos } = this.state;
			const _synos = r_synonyms
				.filter((x) => x != null && x.synos != null)
				.flatMap((x) => Object.assign({}, x, { synos: synofilters(x.synos, filters) }))
				.filter((x) => x != null);
			if (_synos != null && _synos.length > 0) {
				const newSynos = getUniqueObjectArray(synos.concat(_synos), 'blockKey');
				const filterSynos = newSynos.flatMap((x) => x.synos).filter((x) => x.synoymsFilter.length > 0);
				let synosCount = filterSynos ? filterSynos.length : 0;
				this.props.onSynoDeco(newSynos);
				_state = Object.assign({}, _state, {
					changeSynos: false,
					isSynonymLoading: false,
					synos: newSynos,
					synosCount: synosCount,
				});
			}
		}
		if (r_dialects != null) {
			const { dialectFilters, dialects } = this.state;
			const tfs = dialectFilters.join('|');
			const pattern = new RegExp(tfs, '');
			const _dialects = r_dialects
				.filter((x) => x != null)
				.flatMap((x) => Object.assign({}, x, { dialect: dialectFilter(x.dialect, pattern) }))
				.filter((x) => x != null);
			if (_dialects != null && _dialects.length > 0) {
				const newDialects = getUniqueObjectArray(dialects.concat(_dialects), 'blockKey');
				const filterDialects = newDialects.flatMap((x) => x.dialect).filter((x) => x.dialectsFilter.length > 0);
				let dialectCount = filterDialects ? filterDialects.length : 0;
				this.props.onDialectDeco(newDialects);
				_state = Object.assign({}, _state, {
					changeDialect: false,
					isDialectLoading: false,
					dialects: newDialects,
					dialectCount: dialectCount,
				});
			}
		}

		this.setState(
			{
				..._state,
			},
			() => {
				this.props.onProcessComplete(true);
			}
		);
	};

	state = {
		blockKeys: [],
		blockTexts: [],
		decosByKey: [],
		decos: [],
		synos: [],
		synosByKey: [],
		lqInfos: [],
		lqInfosByKey: [],
		changeLqInfos: false,
		changeSpellCheck: false,
		changeDecos: false,
		dialects: [],
		dialectsByKey: [],
		changeDialect: false,
		hanja: [],
		hanjaByKey: [],
		changeHanja: false,
		multilangs: [],
		multiByKey: [],
		changeMultiLang: false,
		multioptions: 0,
		selectMenu: 0,
		filtersChange: false,
		changeText: false,
		lq: 0,
		isLoading: false,
		isChanges: false,
		isFiltersChanges: false,
		fetchLqStatus: false,
		modalVisible: false,
		synosCount: 0,
		dialectCount: 0,
		loadText: '',
	};

	constructor(props) {
		super(props);

		root = this;

		this.state = {
			...this.state,
			selectedKey: '1',
			disableItems: props.disableItems,
			lqInfosText: props.lqInfosText,
			selectMenu: props.selectMenu,
			filters: props.filters,
			dialectFilters: props.dialectFilters,
			searchFilter: props.searchFilter,
			blockKeys: props.blockKeys,
			blockTexts: props.blockTexts,
			flag: props.flag,
			spellChecked: props.spellChecked,
			synoChecked: props.synoChecked,
			dialectChecked: props.dialectChecked,
			lqChecked: props.lqChecked,
		};

		//	this.fetchLq(props.text);
	}

	componentDidMount() {
		this.props.onSelectRightMenu(this.state.selectedKey);
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.blockTexts !== nextProps.blockTexts) {
			this.setState({ blockTexts: nextProps.blockTexts });
		}
		this.setState({
			lqInfosText: nextProps.lqInfosText,
			disableItems: nextProps.disableItems,
			selectMenu: nextProps.selectMenu,
			filters: nextProps.filters,
			dialectFilters: nextProps.dialectFilters,
			blockKeys: nextProps.blockKeys,
			blockTexts: nextProps.blockTexts,
			flag: nextProps.flag,
			spellChecked: nextProps.spellChecked,
			synoChecked: nextProps.synoChecked,
			dialectChecked: nextProps.dialectChecked,
			lqChecked: nextProps.lqChecked,
			user: nextProps.user,
			authenticated: nextProps.authenticated,
			searchFilter: nextProps.searchFilter,
		});
		if (this.props.text !== nextProps.text) {
			this.fetchLq(nextProps.text);
			this.setState({ text: nextProps.text });
		}
		if (this.props.editorState !== nextProps.editorState) {
			this.setState({ editorState: nextProps.editorState });
		}
	}

	async componentDidUpdate(prevProps, prevState) {
		const {
			lqInfosText,
			disableItems,
			blockTexts,
			blockKeys,
			filters,
			dialectFilters,
			flag,
			searchFilter,
		} = this.state;

		if (lqInfosText !== prevState.lqInfosText) {
			if (lqInfosText != null && lqInfosText.trim() !== '') {
				//if (this.state.selectMenu === '2' || this.state.selectMenu === '4') {
				//this.fetchSummary(text);
				//}
				this.setState({ isLqInfosTextLoading: true });
				this.fetchLq(this.state.lqInfosText);
				const t = nlp(lqInfosText)
					.ngrams({ max: 3 })
					.data()
					.filter((i) => i.count > 1)
					.map((i) => {
						return { ...i, normal: i.normal.replace(/#q#/g, ',') };
					});

				//this.fetchSummary(text);
				this.setState({ distinctCount: t.length });
			} else {
				this.props.onTaggedText(null);
				this.props.onChangeBlockKey([]);
				this.props.onBlockKeyText([], false);
				this.props.onSpellDeco([]);
				this.setState({
					lqInfos: [],
					lqInfosByKey: [],
					decos: [],
					synos: [],
					dialects: [],
					hanjas: [],
					multilangs: [],
					count: 0,
					blockKeys: [],
					blockTexts: [],
					decosByKey: [],
					synosByKey: [],
					dialectsByKey: [],
					hanjaByKey: [],
					multiByKey: [],
					distinctCount: 0,
					lq: 0,
					isLqInfosTextLoading: false,
				});
			}
		}

		if (disableItems !== prevState.disableItems) {
			const { decos } = this.state;
			const _decos = decos.map((x) => {
				const divideSpells = x.divideSpells.map((s) => {
					const spells = s.spells.filter(
						(i) => !(disableItems != null && disableItems.indexOf(i.orgStr) !== -1)
					);
					return Object.assign({}, s, { spells });
				});
				const filterSpells = decosFilter(searchFilter, divideSpells);
				return Object.assign({}, x, { filterSpells });
			});
			const newDecos = getUniqueObjectArray(_decos, 'blockKey');
			this.props.onSpellDeco(newDecos);
			this.setState({ changeDecos: false, isSpellCheckLoading: false, decos: newDecos });
		}

		if (blockKeys !== prevState.blockKeys) {
			this.setState({
				changeLqInfos: true,
				changeDecos: true,
				changeSynos: true,
				changeDialect: true,
			});
		}

		if (searchFilter !== prevState.searchFilter) {
			const { decos } = this.state;
			const _decos = decos.map((x) => {
				const divideSpells = x.divideSpells.map((s) => {
					const spells = s.spells.filter(
						(i) => !(disableItems != null && disableItems.indexOf(i.orgStr) !== -1)
					);
					return Object.assign({}, s, { spells });
				});
				const filterSpells = decosFilter(searchFilter, divideSpells);
				return Object.assign({}, x, { filterSpells });
			});
			const newDecos = getUniqueObjectArray(_decos, 'blockKey');
			this.props.onSpellDeco(newDecos);
			this.setState({ changeDecos: false, isSpellCheckLoading: false, decos: newDecos });
		}

		if (filters !== prevState.filters) {
			const { synos } = this.state;
			const _synos = synos
				.map((x) => Object.assign({}, x, { synos: synofilters(x.synos, filters) }))
				.filter((x) => x != null);
			if (_synos != null && _synos.length > 0) {
				const newSynos = getUniqueObjectArray(_synos, 'blockKey');
				const filterSynos = newSynos.flatMap((x) => x.synos).filter((x) => x.synoymsFilter.length > 0);
				let synosCount = filterSynos ? filterSynos.length : 0;
				this.props.onSynoDeco(newSynos);
				this.setState({
					changeSynos: false,
					isSynonymLoading: false,
					synos: newSynos,
					synosCount: synosCount,
				});
			}
		}

		if (dialectFilters !== prevState.dialectFilters) {
			const { dialects } = this.state;
			const tfs = dialectFilters.join('|');
			const pattern = new RegExp(tfs, '');
			const _dialects = dialects
				.map((x) => Object.assign({}, x, { dialect: dialectFilter(x.dialect, pattern) }))
				.filter((x) => x != null);
			if (_dialects != null && _dialects.length > 0) {
				const newDialects = getUniqueObjectArray(_dialects, 'blockKey');
				const filterDialects = newDialects.flatMap((x) => x.dialect).filter((x) => x.dialectsFilter.length > 0);
				let dialectCount = filterDialects ? filterDialects.length : 0;
				this.props.onDialectDeco(newDialects);
				this.setState({
					changeDialect: false,
					isDialectLoading: false,
					dialects: newDialects,
					dialectCount: dialectCount,
				});
			}
		}

		if (blockTexts != null && blockTexts !== prevState.blockTexts) {
			if (!flag) {
				const _blockTexts = blockTexts.filter((x) => x.status === 'REQ');
				if (_blockTexts && _blockTexts.length > 0) {
					const loadText = _blockTexts.map((x) => x.blockText).join(' ');
					this.setState({ loadText }, () => {
						this.fetchDatas(_blockTexts);
					});
				} else {
					this.props.onProcessComplete(true);
				}
			}
		}
	}

	onAccordianChange = (key) => {
		if (key !== undefined) {
			//const USER = JSON.parse(window.sessionStorage.getItem('user'));
			const user = this.props.user;
			if (user != null) {
				if (user.status === 'valid' && user.item === 'premium') {
					if (key === '10') {
						info();
						this.setState({ selectedKey: key });
						return;
					} else {
						this.props.onSelectRightMenu(key);
						this.setState({ selectedKey: key }, () => {
							//this.props.onDecosOpen(true, 'baseInfo', {});
							//this.props.onDecosView(false);
						});
						//this.props.onDecosOpen(true, 'baseInfo', {});
						return;
					}
				} else {
					//난이도
					if (user == null && key === '1') {
						message.info('로그인 후 이용 가능합니다.');
						return;
					}
					//맞춤법 외
					if (key !== '1' && key !== '5' && key !== '10') {
						this.showPaymentModal(key);
						return;
					}

					if (key === '10') {
						info();
						this.setState({ selectedKey: key });
						return;
					} else {
						this.props.onSelectRightMenu(key);
						this.setState({ selectedKey: key });
						//this.props.onDecosClose(false);
						//this.props.onDecosView(true);
						return;
					}
				}
			} else {
				//난이도
				if (key === '1') {
					message.info('로그인 후 이용 가능합니다.');
					return;
				}
				//맞춤법 외
				if (key !== '5' && key !== '10') {
					this.showPaymentModal(key);
					return;
				}
			}
		}
	};

	showPaymentModal = (key) => {
		let name, url;
		switch (key) {
			case '6':
				name = '유의어 추천';
				url = ['function05_1.jpg', 'function05_2.jpg'];
				break;
			case '3':
				name = '중복 표현';
				url = ['function06_1.jpg'];
				break;
			case '2':
				name = '어휘 통계';
				url = ['function07_1.jpg'];
				break;
			case '7':
				name = '한자';
				url = ['function08_1.jpg'];
				break;
			case '8':
				name = '방언';
				url = ['function09_1.jpg'];
				break;
			case '9':
				name = '다국어';
				url = ['function10_1.jpg'];
				break;
			default:
				name = '';
				url = [];
				break;
		}
		this.setState({
			name,
			url,
			modalVisible: true,
		});
	};

	handleOk = (e) => {
		window.location.href = '/views/mypage/payment/step1';
		this.setState({
			modalVisible: false,
		});
	};

	handleCancel = (e) => {
		this.setState({
			modalVisible: false,
		});
	};

	setLq = (lq) => {
		this.setState({ lq });
	};

	setCount = (count) => {
		this.setState({ count });
	};

	render() {
		const {
			lqInfos,
			decos,
			selectedKey,
			count,
			synos,
			dialects,
			lqInfosText,
			distinctCount,
			lq,
			isLqInfosLoading,
			isFilterLoading,
			isSpellCheckLoading,
			isSynonymLoading,
			isDialectLoading,
			isLqInfosTextLoading,
			editorState,
			text,
			synosCount,
			dialectCount,
			loadText,
		} = this.state;

		return (
			<Content>
				<div className={'menucontent'}>
					<Collapse
						bordered={false}
						accordion={true}
						defaultActiveKey={[selectedKey]}
						activeKey={this.state.selectedKey}
						onChange={this.onAccordianChange}>
						<Panel
							key="1"
							showArrow={false}
							header={
								selectedKey === '1' ? (
									<SelectedHeader
										title="난이도"
										eng="DIFFICULTY"
										lq={lq}
										content={content}
										isLoading={isLqInfosLoading || isLqInfosTextLoading}
									/>
								) : (
									<UnSelectedHeader
										title="난이도"
										eng="DIFFICULTY"
										lq={lq}
										content={content}
										isLoading={isLqInfosLoading || isLqInfosTextLoading}
									/>
								)
							}
							style={selectedKey === '1' ? customPanelStyle.selected : customPanelStyle.unselected}>
							<LiteracyInfo lqInfos={lqInfos} lq={lq} />
						</Panel>
						<Panel
							key="5"
							showArrow={false}
							header={
								selectedKey === '5' ? (
									<SelectedHeader
										title="맞춤법 검사"
										eng="SPELLCHECKER"
										count={count}
										isLoading={isSpellCheckLoading}
										isSwitch={true}
										name="swspellcheck"
										content={content_spell}
									/>
								) : (
									<UnSelectedHeader
										title="맞춤법 검사"
										eng="SPELLCHECKER"
										count={count}
										isLoading={isSpellCheckLoading}
										isSwitch={true}
										name="swspellcheck"
										content={content_spell}
									/>
								)
							}
							style={selectedKey === '5' ? customPanelStyle.selected : customPanelStyle.unselected}>
							<SpellsSetup editorState={editorState} setCount={this.setCount} />
						</Panel>
						{/*
						<Panel
							key="10"
							showArrow={false}
							style={selectedKey === '10' ? customPanelStyle.selected : customPanelStyle.unselected}
							header={
								selectedKey === '10' ? (
									<SelectedHeader
										title={
											<Fragment>
												문법/어법 검사{' '}
												<Tag color="volcano" style={{ fontSize: '10px', lineHeight: '12px' }}>
													BETA
												</Tag>
											</Fragment>
										}
										eng="GRAMMAR CHECKER"
										content={content_grammar}
									/>
								) : (
									<UnSelectedHeader
										title={
											<Fragment>
												문법/어법 검사{' '}
												<Tag color="volcano" style={{ fontSize: '10px', lineHeight: '12px' }}>
													BETA
												</Tag>
											</Fragment>
										}
										eng="GRAMMAR CHECKER"
										content={content_grammar}
									/>
								)
							}>
							<Advance />
						</Panel>
						*/}
						<Panel
							key="6"
							showArrow={false}
							header={
								selectedKey === '6' ? (
									<SelectedHeader
										title="유의어 추천"
										eng="THESAURUS"
										count={synosCount}
										countStyle={{ backgroundColor: '#52c41a' }}
										isLoading={isSynonymLoading || isFilterLoading}
										isSwitch={true}
										name="swthesaurus"
										content={content_syn}
									/>
								) : (
									<UnSelectedHeader
										title="유의어 추천"
										eng="THESAURUS"
										count={synosCount}
										countStyle={{ backgroundColor: '#52c41a' }}
										isLoading={isSynonymLoading || isFilterLoading}
										isSwitch={true}
										name="swthesaurus"
										content={content_syn}
									/>
								)
							}
							style={selectedKey === '6' ? customPanelStyle.selected : customPanelStyle.unselected}>
							<SynoSetup isLoading={isSynonymLoading || isFilterLoading} />
						</Panel>
						<Panel
							key="3"
							showArrow={false}
							header={
								selectedKey === '3' ? (
									<SelectedHeader
										title="중복 표현"
										eng="DUPLICATE EXPRESSION"
										count={distinctCount}
										countStyle={{ backgroundColor: 'rgb(243, 171, 40)' }}
										isLoading={isLqInfosTextLoading}
										content={content_distinct}
									/>
								) : (
									<UnSelectedHeader
										title="중복 표현"
										eng="DUPLICATE EXPRESSION"
										count={distinctCount}
										countStyle={{ backgroundColor: 'rgb(243, 171, 40)' }}
										isLoading={isLqInfosTextLoading}
										content={content_distinct}
									/>
								)
							}
							style={selectedKey === '3' ? customPanelStyle.selected : customPanelStyle.unselected}>
							<DistinctInfo selectedKey={selectedKey} lqInfosText={loadText} />
						</Panel>
						<Panel
							key="2"
							showArrow={false}
							header={
								selectedKey === '2' ? (
									<SelectedHeader
										title="어휘 통계"
										eng="WORD STATISTICS"
										isLoading={isLqInfosTextLoading}
										content={content_word}
									/>
								) : (
									<UnSelectedHeader
										title="어휘 통계"
										eng="WORD STATISTICS"
										isLoading={isLqInfosTextLoading}
										content={content_word}
									/>
								)
							}
							style={selectedKey === '2' ? customPanelStyle.selected : customPanelStyle.unselected}>
							<WordInfo lqInfos={lqInfos} />
						</Panel>
						{/*
						<Panel
							key="7"
							showArrow={false}
							header={
								selectedKey === '7' ? (
									<SelectedHeader title="한자" eng="漢字" content={content_hanja} />
								) : (
									<UnSelectedHeader title="한자" eng="漢字" content={content_hanja} />
								)
							}
							style={selectedKey === '7' ? customPanelStyle.selected : customPanelStyle.unselected}>
							<HanjaSetup />
						</Panel>
						*/}
						<Panel
							key="8"
							showArrow={false}
							header={
								selectedKey === '8' ? (
									<SelectedHeader
										title="방언"
										eng="KOREAN DIALECTS"
										count={dialectCount}
										countStyle={{ backgroundColor: '#52c41a' }}
										isLoading={isDialectLoading}
										isSwitch={true}
										name="swdialects"
										content={content_dialect}
									/>
								) : (
									<UnSelectedHeader
										title="방언"
										eng="KOREAN DIALECTS"
										count={dialectCount}
										countStyle={{ backgroundColor: '#52c41a' }}
										isLoading={isDialectLoading}
										isSwitch={true}
										name="swdialects"
										content={content_dialect}
									/>
								)
							}
							style={selectedKey === '8' ? customPanelStyle.selected : customPanelStyle.unselected}>
							<DialectSetup />
						</Panel>
						{/*
						<Panel
							key="9"
							showArrow={false}
							header={
								selectedKey === '9' ? (
									<SelectedHeader title="다국어" eng="MULTILINGUAL" content={content_multi} />
								) : (
									<UnSelectedHeader title="다국어" eng="MULTILINGUAL" content={content_multi} />
								)
							}
							style={selectedKey === '9' ? customPanelStyle.selected : customPanelStyle.unselected}>
							<MultiLangSetup />
						</Panel>
						*/}
					</Collapse>
				</div>
				<Modal
					title="문장검사 서비스입니다."
					visible={this.state.modalVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					okText="예"
					width={'70%'}
					centered
					cancelText="아니오"
					bodyStyle={{ backgroundColor: '#4b4848', color: 'white' }}
					footer={null}>
					<div>
						<p>선택하신 {this.state.name} 서비스는 문장검사 서비스입니다.</p>
						<p>서비스 사용을 위해 결제하시겠습니까?</p>
						<p>
							<Button type="primary" onClick={this.handleOk}>
								예
							</Button>{' '}
							<Button onClick={this.handleCancel}>아니오</Button>
						</p>
						<Carousel autoplay>
							{this.state.url &&
								this.state.url.map((x) => (
									<div key={x}>
										<img src={'/assets/imgs/sentence/' + x} alt={this.state.name} width={'100%'} />
									</div>
								))}
						</Carousel>
					</div>
				</Modal>
			</Content>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(RightMenu));
