import React, { Component, Fragment } from 'react';
import { Checkbox } from 'antd';
import { connect } from 'react-redux';
import * as actions from 'actions';

const mapDispatchToProps = dispatch => ({
	onDialectFilters: dialectFilters => dispatch(actions.onDialectFilters(dialectFilters)),
});

class DialectSetup extends Component {
	state = {
		dialectFilters: [],
		isChange: false,
	};

	onCheck = e => {
		const { dialectFilters } = this.state;
		const _k = e.target.name.replace(/도/g, '');
		switch (_k) {
			case '경상':
				dialectFilters['경남'] = e.target.checked;
				dialectFilters['경북'] = e.target.checked;
				dialectFilters['경상'] = e.target.checked;
				break;
			case '전라':
				dialectFilters['전남'] = e.target.checked;
				dialectFilters['전북'] = e.target.checked;
				dialectFilters['전라'] = e.target.checked;
				break;
			case '충청':
				dialectFilters['충남'] = e.target.checked;
				dialectFilters['충북'] = e.target.checked;
				dialectFilters['충청'] = e.target.checked;
				break;
			case '함경':
				dialectFilters['함남'] = e.target.checked;
				dialectFilters['함북'] = e.target.checked;
				dialectFilters['함경'] = e.target.checked;
				break;
			case '평안':
				dialectFilters['평남'] = e.target.checked;
				dialectFilters['평북'] = e.target.checked;
				dialectFilters['평안'] = e.target.checked;
				break;
			default:
				dialectFilters[_k] = e.target.checked;
				break;
		}

		this.setState({
			dialectFilters,
			isChange: true,
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.isChange) {
			const { dialectFilters } = this.state;
			const _dialectFitlers = Object.keys(dialectFilters).filter(key => dialectFilters[key]);
			this.props.onDialectFilters(_dialectFitlers);
			this.setState({ isChange: false });
		}
	}

	render() {
		return (
			<Fragment>
				<div
					style={{
						padding: '8px 10px',
						margin: '0',
						background: '#fff',
					}}>
					<Checkbox size="small" name="강원도" onChange={this.onCheck}>
						강원도
					</Checkbox>
					<br />
					<Checkbox size="small" name="경기도" onChange={this.onCheck}>
						경기도
					</Checkbox>
					<br />
					<Checkbox size="small" name="경상도" onChange={this.onCheck}>
						경상도
					</Checkbox>
					<br />
					<Checkbox size="small" name="제주도" onChange={this.onCheck}>
						제주도
					</Checkbox>
					<br />
					<Checkbox size="small" name="전라도" onChange={this.onCheck}>
						전라도
					</Checkbox>
					<br />
					<Checkbox size="small" name="충청도" onChange={this.onCheck}>
						충청도
					</Checkbox>
					<br />
					<Checkbox size="small" name="평안도" onChange={this.onCheck}>
						평안도
					</Checkbox>
					<br />
					<Checkbox size="small" name="함경도" onChange={this.onCheck}>
						함경도
					</Checkbox>
					<br />
					<Checkbox size="small" name="황해도" onChange={this.onCheck}>
						황해도
					</Checkbox>
					<br />
				</div>
			</Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(DialectSetup);
