import React from 'react';

const PostWrapper = ({children}) => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {children}
          </div>
        </div>
      </div>
    );
};

export default PostWrapper;