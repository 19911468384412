import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import menu from 'components/menu/datas/mobile.json';
import { connect } from 'react-redux';
import { Label } from 'semantic-ui-react';
import * as actions from 'actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import PropTypes from 'prop-types';
import { Icon as AntdIcon, Button } from 'antd';

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logout()),
});

class MobileMenu extends Component {
  initialState() {
    return menu.menus;
  }

  constructor(props) {
    super(props);

    const USER = JSON.parse(window.localStorage.getItem('user'));
    let grade = null;
    if (props.user && !USER) {
      window.localStorage.setItem('user', JSON.stringify(props.user));
      grade = props.user.grade;
    } else if (USER) {
      grade = USER.grade;
    }

    this.state = {
      slidemenus: [],
      grade,
    };
  }

  componentDidMount() {
    let links = this.initialState();
    links = links.map((link, i) => {
      if (
        this.props.location.pathname.indexOf(link.path) > -1 &&
        link.path !== ''
      ) {
        link.isActive = true;
        link.sublinks = link.sublinks.map((sublink, k) => {
          if (this.props.location.pathname === sublink.path) {
            sublink.isActive = true;
          } else {
            sublink.isActive = false;
          }
          return sublink;
        });
      } else {
        link.isActive = false;
      }
      return link;
    });

    this.setState({
      slidemenus: links,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      //새로 고침 시
      let links = this.initialState();
      links = links.map((link, i) => {
        if (
          this.props.location.pathname.indexOf(link.path) > -1 &&
          link.path !== ''
        ) {
          link.isActive = true;
          link.sublinks = link.sublinks.map((sublink, k) => {
            if (this.props.location.pathname === sublink.path) {
              sublink.isActive = true;
            } else {
              sublink.isActive = false;
            }
            return sublink;
          });
        } else {
          link.isActive = false;
        }
        return link;
      });

      this.setState({
        slidemenus: links,
      });
    }

    if (this.props.authenticated !== prevProps.authenticated) {
      let grade = null;
      if (this.props.authenticated) {
        grade = this.props.user.grade;
      }
      this.setState({ grade });
    }

    return true;
  }

  onClick(e, menuItem) {
    e.preventDefault();
    const container = document.getElementsByClassName('ms-site-container');
    for (var i = 0; i < container.length; i++) {
      container[i].click();
    }
    switch (menuItem) {
      case 'LOGOUT':
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div
                className="logout-custom-ui"
                style={{
                  width: '400px',
                  backgroundColor: 'none',
                }}
              >
                <h3>
                  <AntdIcon type="logout" />
                  로그아웃
                </h3>
                <p>정말 로그아웃 하시겠습니까?</p>
                <div className="logout-btns">
                  <Button onClick={onClose}>머무르기</Button>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();

                      window.sessionStorage.removeItem('user');
                      window.localStorage.removeItem('user');
                      window.parent.postMessage({ loginStatus: false }, '*');

                      this.props.logout();
                      this.props.history.push('/');
                      onClose();
                    }}
                  >
                    로그아웃
                  </Button>
                </div>
              </div>
            );
          },
        });
        break;
      case 'MYPAGE':
        this.props.history.push({
          pathname: '/views/mypage/archive',
          hash: '',
        });
        break;
      case 'ADMIN':
        this.props.history.push({ pathname: '/views/systems', hash: '' });
        break;
      case 'LOGIN':
        this.props.history.push({
          pathname: '/views/login',
          hash: '',
          redirectUrl: this.props.location.pathname,
          state: { from: this.props.location },
        });
        break;
      case 'JOIN':
        this.props.history.push({
          pathname: '/views/login',
          hash: '#ms-register-tab',
          redirectUrl: this.props.location.pathname,
          state: { from: this.props.location },
        });
        break;
      default:
        //	this.props.history.push({ pathname: menuItem.path, hash: menuItem.hash });
        break;
    }
  }

  onSubClick(e, url) {
    if (url !== '') {
      e.preventDefault();
      const container = document.getElementsByClassName('ms-site-container');
      for (var i = 0; i < container.length; i++) {
        container[i].click();
      }
      this.props.history.push(url);
    }
  }
  goUrl(e) {
    e.preventDefault();
    //this.setState({ reflash: true, target: '/' });
    window.location.href = '/';
    //this.props.history.push({ pathname: '/' });
  }
  render() {
    const { authenticated } = this.props;
    const { slidemenus, grade } = this.state;

    return (
      <Fragment>
        <div
          className={'ms-slidebar sb-slidebar sb-left sb-style-overlay'}
          id="ms-slidebar"
        >
          <div className="sb-slidebar-container">
            <header className="ms-slidebar-header">
              {!authenticated && (
                <div className="ms-slidebar-login">
                  <Link
                    to="#0"
                    className="withripple small"
                    onClick={(e) => this.onClick(e, 'LOGIN')}
                  >
                    <i className="fas fa-sign-in-alt" /> 로그인
                  </Link>
                  <Link
                    to="#0"
                    className="withripple small"
                    onClick={(e) => this.onClick(e, 'JOIN')}
                  >
                    <i className="zmdi zmdi-account-add" /> 회원가입
                  </Link>
                </div>
              )}
              {authenticated && (
                <div className="ms-slidebar-login">
                  <Link
                    to="#0"
                    className="withripple small"
                    onClick={(e) => this.onClick(e, 'MYPAGE')}
                  >
                    <i className="zmdi zmdi-account" /> 마이페이지
                  </Link>
                  <Link
                    to="#0"
                    className="withripple small"
                    onClick={(e) => this.onClick(e, 'LOGOUT')}
                  >
                    <i className="fas fa-sign-out-alt" /> 로그아웃
                  </Link>
                  {grade === 0 && (
                    <Link
                      to="#0"
                      className="withripple"
                      onClick={(e) => this.onClick(e, 'ADMIN')}
                    >
                      <i className="fas fa-cogs" />
                    </Link>
                  )}
                </div>
              )}

              <div className="ms-slidebar-title">
                <div className="ms-slidebar-t">
                  <Link to="/" onClick={(e) => this.goUrl(e)}>
                    <img
                      className="animated zoomInDown animation-delay-2"
                      src="/assets/imgs/Logo_40_m_w.png"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </header>
            <ul
              className="panel-group ms-collapse-nav"
              id="components-nav"
              role="tablist"
              aria-multiselectable="true"
            >
              {slidemenus &&
                slidemenus.map((menuitem, i) => {
                  const subitems = menuitem.sublinks;
                  return (
                    <li className="card" role="tab" id={'e' + i} key={'e' + i}>
                      <a
                        role="button"
                        data-toggle={subitems.length > 0 && 'collapse'}
                        href={'#c' + i}
                        aria-expanded={menuitem.isActive}
                        aria-controls={'c' + i}
                        className={
                          subitems.length > 0 && menuitem.isActive
                            ? 'withripple'
                            : 'collapsed withripple'
                        }
                      >
                        <i
                          className={' ' + menuitem.icon}
                          style={menuitem.style && menuitem.style}
                        />
                        <span>{menuitem.text}</span>
                      </a>
                      {subitems.length > 0 && (
                        <ul
                          id={'c' + i}
                          className={
                            'panel-collapse collapse ' +
                            (menuitem.isActive ? 'show' : '')
                          }
                          role="tabpanel"
                          aria-labelledby={'e' + i}
                          data-parent="#components-nav"
                        >
                          {menuitem.sublinks.map((subitem, k) => (
                            <li key={'ck' + k}>
                              {subitem.isOpen && (
                                <Link
                                  to="#0"
                                  className={
                                    'withripple ' +
                                    (subitem.isActive ? 'active' : '')
                                  }
                                  onClick={(e) =>
                                    this.onSubClick(e, subitem.path)
                                  }
                                >
                                  <i className="fa fa-arrow-circle-right" />
                                  <span>&nbsp;{subitem.text}</span>
                                </Link>
                              )}
                              {!subitem.isOpen && (
                                <Link
                                  to="#"
                                  className={
                                    'withripple ' +
                                    (subitem.isActive ? 'active' : '')
                                  }
                                  onClick={(e) => this.onSubClick(e, '')}
                                >
                                  <i className="fa fa-arrow-circle-right" />
                                  <span>&nbsp;{subitem.text}</span>
                                  <Label className="ml-2" color="orange">
                                    준비중
                                  </Label>
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

MobileMenu.propTypes = {
  authenticated: PropTypes.bool,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobileMenu)
);
