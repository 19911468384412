import * as types from 'actions/ActionTypes';

const initalState = {
	highlights: null,
};

const searchDeco = (state = initalState, action) => {
	switch (action.type) {
		case types.SEARCH_DECO:
			return Object.assign({}, state, { highlights: action.highlights });
		default:
			return state;
	}
};

export default searchDeco;
