import React, { Component, Fragment } from 'react';
import { Tag, Badge, Row, Col, List, Typography, Popover } from 'antd';
import { connect } from 'react-redux';
import * as actions from 'actions';

const styles = {
	handle: {
		direction: 'ltr',
		unicodeBidi: 'bidi-override',
		color: 'black',
		fontWeight: 'bold',
	},
};

const mapStateToProps = state => ({
	selectedItem: state.spell.selectedItem,
});

const mapDispatchToProps = dispatch => ({
	onSelectedItem: selectedItem => dispatch(actions.onSelectedItem(selectedItem)),
	onDecosOpen: (decoVisible, decoType, post) => dispatch(actions.onDecosOpen({ decoVisible, decoType, post })),
	onDecosClose: decoVisible => dispatch(actions.onDecosClose({ decoVisible })),
});

const convertPumsa = pumsa => {
	switch (pumsa) {
		case 'NC':
			return '명사';
		case 'NP':
			return '대명사';
		case 'VV':
			return '동사';
		case 'VA':
			return '형용사';
		case 'MA':
			return '부사';
		default:
			return '';
	}
};

const menu = (datas, props, isSelected, callback) => {
	let title = '';
	let org = '';
	let count = 0;
	let dialects = [];
	let uniq = [];

	if (datas) {
		title = datas.PYOJAE_NAME;
		org = datas.PYOJAE_HANJA ? '(' + datas.PYOJAE_HANJA + ')' : '';
		dialects = datas.dialects;
		//count = dialects.length;

		let locations = dialects.flatMap(x => x.LOCATION);
		uniq = locations
			.reduce(function(a, b) {
				if (a.indexOf(b) < 0) a.push(b);
				return a;
			}, [])
			.map((item, key) => {
				const filtercount = dialects.filter(dialect => dialect.LOCATION.indexOf(item) > -1).length;
				count = count + filtercount;
				return {
					text: item,
					filtercount: dialects.filter(dialect => dialect.LOCATION.indexOf(item) > -1).length,
				};
			});
	} else {
		return <Fragment />;
	}
	if (!isSelected) return <Fragment />;
	return (
		<div>
			<List
				header={
					<div>
						<Row type="flex" justify="space-between">
							<Col>
								<span>
									<Tag>{datas && datas.PUMSA}</Tag>
									<Typography.Text strong>{title + org}</Typography.Text>       
								</span>
							</Col>
							<Col>
								<Badge style={{ backgroundColor: 'rgb(102, 204, 255)' }} count={count} />
							</Col>
						</Row>
					</div>
				}
				bordered={false}
				dataSource={uniq}
				locale={{
					emptyText: '조회된 방언이 없습니다.',
				}}
				renderItem={(item, key) => {
					return (
						<div
							key={item.text + key}
							style={{
								marginTop: '10px',
								marginBottom: '10px',
							}}>
							<Row type="flex">
								<Col>
									<Row type="flex" justify="start">
										<Col>
											<div className="arrow_box">
												<span
													style={{
														fontSize: '10px',
														marginLeft: '3px',
														marginRight: '3px',
													}}>
													{item.text}
												</span>
											</div>
										</Col>
									</Row>
								</Col>
								<Col style={{ marginLeft: '8px' }}>
									{dialects
										.filter(dialect => dialect.LOCATION.indexOf(item.text) > -1)
										.map((x, k) => {
											return (
												<span key={k} style={{ fontWeight: '500', fontSize: '13' }}>
													{k > 0 ? ' | ' + x.NAME : x.NAME}
												</span>
											);
										})}
								</Col>
							</Row>
							<Row>
								<Col />
							</Row>
						</div>
					);
				}}
			/>
		</div>
	);
};

class DialectSpan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
		};
	}

	onDropDownClick = e => {
		const blockKey = this.props.children[0].props.block.getKey();
		const start = this.props.children[0].props.start;
		const end = start + this.props.decoratedText.length;
		this.props.onSelectedItem({
			blockKey,
			start,
			end,
			text: this.props.decoratedText,
			offsetKey: this.props.offsetKey,
			scrollNo: false,
		});
	};

	hide = () => {
		this.setState({
			visible: false,
		});
	};

	handleVisibleChange = visible => {
		if (visible) {
			const blockKey = this.props.children[0].props.block.getKey();
			const start = this.props.children[0].props.start;
			const end = start + this.props.decoratedText.length;
			this.props.onDecosClose(false);
			this.props.onSelectedItem({
				blockKey,
				start,
				end,
				text: this.props.decoratedText,
				offsetKey: this.props.offsetKey,
				scrollNo: false,
			});
		}
		this.setState({ visible });
	};

	render() {
		const blockKey = this.props.children[0].props.block.getKey();
		const start = this.props.children[0].props.start;
		const selectedItem = this.props.selectedItem;
		let isSelected = false;
		if (selectedItem && selectedItem.blockKey === blockKey && selectedItem.text === this.props.decoratedText) {
			isSelected = true;
		} else {
			isSelected = false;
		}
		const _datas = this.props.dialects
			.filter(z => z.blockKey === blockKey)
			.flatMap(z => z.dialect)
			.filter(z => z.name === this.props.decoratedText)
			.filter(z => z.dialectsFilter.length > 0)
			.flatMap(z => z.dialectsFilter);

		const menuItems = _datas[0];
		if (menuItems != null) {
			return (
				<Fragment>
					<Popover
						trigger="click"
						placement="bottomLeft"
						visible={this.state.visible}
						onVisibleChange={this.handleVisibleChange}
						content={menu(menuItems, this.props, isSelected, this)}>
						<span
							style={styles.handle}
							className={isSelected ? 'selected' : ''}
							id={isSelected ? 'selected' : 'noselected'}>
							{this.props.children}
						</span>
					</Popover>
				</Fragment>
			);
		} else {
			return <span>{this.props.children}</span>;
		}

		/*
		const pyojae = this.props.dialects.filter(a => {
			return a.name === this.props.decoratedText.trim();
		});
		if (pyojae[0] != null && pyojae[0].dialect.length > 0) {
			return (
				<Dropdown
					overlay={menu(pyojae, this, isSelected)}
					trigger={['click']}
					data-offset-key={this.props.offsetKey}>
					<span
						style={{ fontWeight: 'bold' }}
						className={isSelected ? 'selected' : ''}
						onClick={e => this.onDropDownClick(e)}>
						{this.props.children}
					</span>
				</Dropdown>
			);
		} else {
			return <span>{this.props.children}</span>;
		}
		*/
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DialectSpan);
