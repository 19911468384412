import React, { Component, Fragment, PureComponent } from 'react';
import { Icon, Divider, Popover, Typography } from 'antd';
import { connect } from 'react-redux';
import * as actions from 'actions';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, ReferenceArea, BarChart, Cell } from 'recharts';
import './LiteracyInfo.css';
const data = [
	{
		name: '',
		lq: 0,
	},
];

const mapDispatchToProps = dispatch => ({
	//onBlockHighlight: (blocks, activeIndex) => dispatch(actions.onBlockHighlight(blocks, activeIndex)),
	onBlockHighlight: blocks => dispatch(actions.onBlockHighlight(blocks)),
});

const { Paragraph } = Typography;

const content = (
	<div>
		<p>
			글은 사용된 어휘들이 어렵고 문장 길이가 길수록 이해하기가 어려워집니다.
			<br />
			문서의 난이도는 이러한 이치를 바탕으로 과학적인 검증과정을 거쳐 결정되었습니다. <br />
			일반적으로 도서와 같이 긴 글은 정확하게 분석되나 500어절보다 짧은 글은 통계
			<br /> 수치의 왜곡으로 인하여 문장의 난이도가 예상과 다르게 보일 수도 있습니다.
		</p>
	</div>
);

const word_content = (
	<div>
		<p>
			'한국어의 등급별 총어휘 선정 (낱말 V.2001, 서울대학교 국어교육과 김광해)' 논문의
			<br />
			7등급 어휘목록을 기준으로 하였습니다.
			<br />
			쉽거나 어려운 문장 모두 1, 2등급 어휘가 가장 많이 포함되어 있지만 <br />
			문장이 어려워질수록 3, 4등급 이상의 어려운 어휘가 더 많이 포함되는 추세를 보입니다.
		</p>
		<div style={{ width: 500 }}>
			<u>쉬운 문장의 예 (권정생의 '강아지 똥' 중에서)</u>:
			<br />{' '}
			<Paragraph copyable>
				추운 겨울이었습니다. 흰둥이 강아지가 길가에 똥을 누었습니다. 참새 한 마리가 포르르 날아와 강아지 똥을
				콕콕 쪼았습니다. "똥! 똥! 에그, 더러워." 참새는 쫑알거리며 멀리 날아갔습니다.
			</Paragraph>
		</div>
		<div style={{ width: 500 }}>
			<u>어려운 문장의 예 (강홍렬의 '메가트랜드 코리아' 중에서)</u>:
			<br />{' '}
			<Paragraph copyable>
				‘사회적’이라 함은 개별 존재로서의 인간은 사회로부터 배제된 채 고립적으로 살아갈 수 없다는 사회의존성과
				더불어 자신이 속한 사회의 역사적, 상황적 산물이라는 사회구성적 의미를 내포한다. 따라서 사회체계나
				사회관계로부터 고립된 개인이란 오직 관념적으로나 상상 속에서만 존재하는 개념일 뿐, 실재하는 인간의
				진면목이라고는 할 수 없다.
			</Paragraph>
		</div>
	</div>
);

const para_content = (
	<div>
		<p>
			글은 사용된 어휘들이 어렵고 문장 길이가 길수록 이해하기가 어려워집니다. <br />
			문장의 난이도는 이러한 이치를 바탕으로 과학적인 검증과정을 거쳐 결정되었습니다.
			<br />
			일반적으로 도서와 같이 긴 글은 정확하게 분석되나 500어절보다 짧은 글은 통계 수치의 <br />
			왜곡으로 인하여 문장의 난이도가 예상과 다르게 보일 수도 있습니다.
		</p>
	</div>
);

const colors = ['#1E88E5', '#669933', '#cc6633'];

class CustomizedLabel extends PureComponent {
	render() {
		const { x, y, value, width } = this.props;
		return (
			<text x={x + width / 2} y={y} dy={-2} fill="#777" fontSize="10" textAnchor="middle">
				{value.toFixed(0)}%
			</text>
		);
	}
}

class CustomizedAxisTick extends PureComponent {
	convertLabel = value => {
		switch (value) {
			case 100:
				return '';
			case 290:
				return '7';
			case 490:
				return '6';
			case 700:
				return '5';
			case 860:
				return '4';
			case 1050:
				return '3';
			case 1285:
				return '2';
			case 1585:
				return '1';
			default:
				return '';
		}
	};

	render() {
		const { x, y, payload } = this.props;

		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={3} fill="#666" fontSize="9px">
					{this.convertLabel(payload.value)}
				</text>
			</g>
		);
	}
}

class LiteracyInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lqInfos: props.lqInfos,
			lq: props.lq,
			plq: 0,
			datas: [
				{ name: 1, value: 0 },
				{ name: 2, value: 0 },
				{ name: 3, value: 0 },
				{ name: 4, value: 0 },
				{ name: 5, value: 0 },
				{ name: 6, value: 0 },
				{ name: 7, value: 0 },
			],
			domain: [0, 100],
			ticks: [0, 20, 40, 60, 80, 100],
			changeLqInfos: false,
			activeIndex: 0,
		};
	}

	componentDidMount() {
		this.setState({
			lqInfos: this.props.lqInfos,
			lq: this.props.lq,
			changeLqInfos: true,
		});
	}

	componentWillReceiveProps(nextProps) {
		const { lqInfos, lq } = nextProps;
		this.setState({ lq, lqInfos, changeLqInfos: true });
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.changeLqInfos) {
			const { lqInfos, lq } = this.state;
			if (lqInfos != null && lqInfos.length > 0) {
				const strRateandHomos_ex = lqInfos
					.flatMap(x => x.strRateandHomos)
					.filter(
						i =>
							i.PUMSA === 'NC' ||
							i.PUMSA === 'NP' ||
							i.PUMSA === 'VV' ||
							i.PUMSA === 'VA' ||
							i.PUMSA === 'MA'
					);

				const nEojul = lqInfos.flatMap(x => x.nEojul);
				const rated1 = lqInfos
					.flatMap(x => x.rated['1'])
					.filter(x => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);
				const rated2 = lqInfos
					.flatMap(x => x.rated['2'])
					.filter(x => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);

				const rated3 = lqInfos
					.flatMap(x => x.rated['3'])
					.filter(x => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);

				const rated4 = lqInfos
					.flatMap(x => x.rated['4'])
					.filter(x => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);
				const rated5 = lqInfos
					.flatMap(x => x.rated['5'])
					.filter(x => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);
				const rated6 = lqInfos
					.flatMap(x => x.rated['6'])
					.filter(x => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);
				const rated7 = lqInfos
					.flatMap(x => x.rated['7'])
					.filter(x => x != null)
					.reduce(function(p, c) {
						return p + c;
					}, 0);
				const wordcnt = strRateandHomos_ex
					.map(i => i.CD_WORD)
					.reduce((count, word) => {
						count[word] = (count[word] || 0) + 1;
						return count;
					}, {});

				const nword = Object.keys(wordcnt).length;
				let plq = (nword / nEojul) * 100;
				plq = Math.min(plq, 100);

				//const domains = [[0, 20], [20, 40], [40, 60], [60, 80], [80, 100]];
				const domains = [[0, 100]];
				const domain = domains.filter(d => d[0] <= plq && d[1] >= plq)[0];
				const arr = new Array(5).fill(0);
				const start = domain != null ? domain[0] : 0;
				const interval = 20;
				const ticks = arr.map((i, index) => start + interval * index);

				const avg = this.state.lq;

				/*
				const dist = lqInfos
					.map(x => (x.lq - avg) ^ 2)
					.reduce(function(p, c, i, a) {
						return p + c / a.length;
					}, 0);
				*/
				//const standDist = Math.sqrt(dist);

				//const stands = lqInfos.map(x => (x.lq - avg) / standDist);

				//console.log(stands);

				const lq_min = lqInfos.filter(x => x.lq < avg - 100);
				const lq_max = lqInfos.filter(x => x.lq >= avg + 100);
				const lq_avg = lqInfos.filter(x => x.lq >= avg - 100 && x.lq < avg + 100);

				let paramsDatas = [];
				if (lqInfos.length > 0) {
					paramsDatas.push({
						name: '쉬움',
						value: (lq_min.length / lqInfos.length) * 100,
					});
					paramsDatas.push({
						name: '평균',
						value: (lq_avg.length / lqInfos.length) * 100,
					});
					paramsDatas.push({
						name: '어려움',
						value: (lq_max.length / lqInfos.length) * 100,
					});
				}

				const blocks = lqInfos.map(x => {
					if (x.lq < lq - 100) {
						return Object.assign({}, x, { index: 0 });
					} else if (x.lq >= lq - 100 && x.lq < lq + 100) {
						return Object.assign({}, x, { index: 1 });
					} else {
						return Object.assign({}, x, { index: 2 });
					}
				});
				this.props.onBlockHighlight(blocks);

				this.setState({
					lq,
					plq,
					datas: [
						{ name: 1, value: rated1 },
						{ name: 2, value: rated2 },
						{ name: 3, value: rated3 },
						{ name: 4, value: rated4 },
						{ name: 5, value: rated5 },
						{ name: 6, value: rated6 },
						{ name: 7, value: rated7 },
					],
					domain,
					ticks,
					paramsDatas,
					changeLqInfos: false,
				});
			} else {
				this.setState({
					lq: 0,
					plq: 0,
					datas: [
						{ name: 1, value: 0 },
						{ name: 2, value: 0 },
						{ name: 3, value: 0 },
						{ name: 4, value: 0 },
						{ name: 5, value: 0 },
						{ name: 6, value: 0 },
						{ name: 7, value: 0 },
					],
					paramsDatas: [
						{ name: '쉬움', value: 0 },
						{ name: '평균', value: 0 },
						{ name: '어려움', value: 0 },
					],
					domain: [0, 100],
					ticks: [0, 20, 40, 60, 80, 100],
					changeLqInfos: false,
				});
			}
		}
	}

	handleClick = (data, index) => {
		const { lq, lqInfos } = this.state;
		let blocks = [];
		if (index === 0) {
			blocks = lqInfos.filter(x => x.lq < lq - 100).map(x => Object.assign({}, x, { index: 0 }));
		} else if (index === 1) {
			blocks = lqInfos
				.filter(x => x.lq >= lq - 100 && x.lq < lq + 100)
				.map(x => Object.assign({}, x, { index: 1 }));
		} else {
			blocks = lqInfos.filter(x => x.lq >= lq + 100).map(x => Object.assign({}, x, { index: 2 }));
		}
		this.props.onBlockHighlight(blocks);
		this.setState({
			activeIndex: index,
		});
	};

	render() {
		const { lq, datas, paramsDatas } = this.state;
		return (
			<Fragment>
				<div
					style={{
						padding: '8px 10px',
						margin: '0',
						background: '#fff',
					}}>
					<table style={{ padding: '5px', lineHeight: '1.5em', width: '100%' }}>
						<tbody>
							<tr>
								<td colSpan="2">문서의 난이도 </td>
							</tr>
							<tr>
								<td colSpan="2">
									<div style={{ padding: 0, margin: 0, height: 20 }}>
										<Icon
											type="flag"
											theme="filled"
											style={{
												position: 'relative',
												top: 12,
												left: lq / 9.2,
												padding: 0,
												margin: 0,
												color: 'red',
											}}
										/>
									</div>
									<ComposedChart
										width={210}
										height={60}
										data={data}
										layout="horizontal"
										margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
										<XAxis
											type="number"
											domain={[100, 1850]}
											ticks={[100, 290, 490, 700, 860, 1050, 1285, 1585, 1850]}
											axisLine={false}
											allowDecimals={true}
											allowDataOverflow={true}
											interval={0}
											tick={<CustomizedAxisTick />}
											tickLine={false}
										/>
										<YAxis hide={true} allowDecimals={true} />
										<CartesianGrid vertical={false} horizontal={false} strokeOpacity={0} />
										<ReferenceArea
											x1={10}
											x2={1940}
											y1={0}
											y2={300}
											stroke="#5D9CEC"
											fill="#5D9CEC"
											ifOverflow="extendDomain"
											isFront={true}
											strokeOpacity={1}
										/>
										{lq != null && parseInt(lq) > 0 && (
											<ReferenceArea
												x1={parseInt(lq) - 100}
												x2={parseInt(lq) + 100}
												y1={0}
												y2={300}
												stroke="#1E88E5"
												fill="#1E88E5"
												ifOverflow="extendDomain"
												isFront={true}
												fillOpacity={1}
											/>
										)}
									</ComposedChart>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'left', marginTop: '5px', fontSize: '11px' }}>
									<Icon type="caret-left" theme="filled" />
									쉬움
								</td>
								<td style={{ textAlign: 'right', marginTop: '5px', fontSize: '11px' }}>
									어려움
									<Icon type="caret-right" theme="filled" />
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<Divider />
								</td>
							</tr>
							<tr>
								<td colSpan="2">문단별 난이도 </td>
							</tr>
							<tr>
								<td colSpan="2" style={{ height: 65 }}>
									<BarChart
										width={210}
										height={65}
										data={paramsDatas}
										margin={{ top: 10, bottom: 0 }}>
										<CartesianGrid stroke="#fff" vertical={false} horizontal={false} />
										<XAxis dataKey="name" tickLine={false} tick={{ fontSize: '10px' }} />
										<Bar dataKey="value" label={<CustomizedLabel />} onClick={this.handleClick}>
											{paramsDatas &&
												paramsDatas.map((entry, index) => {
													return (
														<Cell
															key={`cell-${index}`}
															fill={colors[index]}
															cursor="pointer"
														/>
													);
												})}
										</Bar>
									</BarChart>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<Divider />
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									어휘의 난이도 분포{' '}
									<Popover placement="topLeft" title="어휘의 난이도 분포" content={word_content}>
										<Icon type="question-circle" theme="filled" />
									</Popover>
								</td>
							</tr>
							<tr>
								<td colSpan="2" style={{ height: 65 }}>
									<BarChart width={210} height={65} data={datas} margin={{ top: 0, bottom: 0 }}>
										<CartesianGrid stroke="#fff" vertical={false} horizontal={false} />
										<XAxis dataKey="name" tickLine={false} tick={{ fontSize: '10px' }} />
										<Bar dataKey="value" fill="#1E88E5" />
									</BarChart>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'left', marginTop: '5px', fontSize: '11px' }}>
									<Icon type="caret-left" theme="filled" />
									쉬움
								</td>
								<td style={{ textAlign: 'right', marginTop: '5px', fontSize: '11px' }}>
									어려움
									<Icon type="caret-right" theme="filled" />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(LiteracyInfo);
