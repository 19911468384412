import axios from 'axios';
import 'react-app-polyfill/ie11';
import * as types from 'actions/ActionTypes';
import 'modern-toastr/dist/modern-toastr.css';

export const checkUserInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/checkUser',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const checkPrevUserInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/checkPrevUser',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const movePrevUserInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/movePrevUserInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const saveChangedUserInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/updateChangedUserInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const savePersonalInquiry = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/userExtra/insertPersonalInquiry',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const selectPersonalInquiry = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/userExtra/selectPersonalInquiry',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const saveUserCancel = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/userExtra/insertUserCancel',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const checkSecretInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/checkSecretInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const checkEmailCertiInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/checkEmailCertiInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const checkUserAuthKeyInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/checkUserAuthKeyInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateUserConnectInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/updateUserConnectInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const siteInfo = () => {
  return axios
    .get(types.LOCAL_API_URL + '/api/siteInfo', { withCredentials: true })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
