import React from 'react';
import { Row, Col, Spin } from 'antd';

const Loading = props => {
	return (
		<Row type="flex" justify="space-around" align="middle" style={{ height: '100%' }}>
			<Col>
				<Spin size="large" />
			</Col>
		</Row>
	);
};

export default Loading;
