import * as types from 'actions/ActionTypes';

const initalState = {
	sideBarOption: { left: 'init', right: 'init' },
};

const sidebar = (state = initalState, action) => {
	switch (action.type) {
		case types.SENT_SIDE_BAR:
			return Object.assign({}, state, { sideBarOption: action.sideBarOption });
		default:
			return state;
	}
};

export default sidebar;
