import React, { Component } from 'react';
import * as types from 'sentence/types';
import { Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import * as actions from 'actions';
const MenuItemGroup = Menu.ItemGroup;

const mapStateToProps = state => ({
	disableItems: state.spell.disableItems,
	replaceItem: state.spell.replaceItem,
	status: state.status,
});

const mapDispatchToProps = dispatch => ({
	onReplaceItem: replaceItem => dispatch(actions.onReplaceItem(replaceItem)),
	onDisableItem: disableItem => dispatch(actions.onDisableItem(disableItem)),
});

class SuggestMenu extends Component {
	constructor(props) {
		super(props);
		//	console.log(props.menuItems);
		this.state = {
			menuItems: props.menuItems,
		};
	}

	repSuggestion = (e, item) => {
		const start = this.props.children[0].props.start;
		const end = start + this.props.decoratedText.length;
		this.props.onReplaceItem({ start, end, item });
	};

	onClickDisableItem = e => {
		this.props.onDisableItem(this.props.decoratedText);
	};

	render() {
		const { menuItems } = this.state;

		if (menuItems != null && menuItems.data != null) {
			return (
				<Menu defaultSelectedKeys={['1']} style={{ minWidth: '0!important' }}>
					<MenuItemGroup
						key="g1"
						title={
							<span>
								{menuItems.data.nCorrectMethod &&
									types.nCorrectMethod[parseInt(menuItems.data.nCorrectMethod)].title}
							</span>
						}>
						{menuItems.data.candWord.map((item, key) => {
							return (
								<Menu.Item key={'menu' + key} onClick={e => this.repSuggestion(e, item)}>
									<span style={{ color: 'green' }}>{item}</span>
								</Menu.Item>
							);
						})}
					</MenuItemGroup>
					{/*!isSpace && datas.candWord.length === 0 && (
				<Menu.Item>
					<span>
						<Icon type="tag" />
						&nbsp;사전에 추가
					</span>
				</Menu.Item>
			)*/}
					<Menu.Item onClick={e => this.onClickDisableItem(e)}>
						<span>
							<Icon type="delete" />
							&nbsp;해제
						</span>
					</Menu.Item>
					<Menu.Item>
						<span>
							<Icon type="more" />
							&nbsp;자세히보기
						</span>
					</Menu.Item>
				</Menu>
			);
		} else {
			return '';
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestMenu);
