import React from 'react';
import { Row, Col, Empty } from 'antd';

const EmptyData = props => {
	return (
		<Row type="flex" justify="space-around" align="middle" style={{ height: '100%' }}>
			<Col>
				<Empty description={<span>{props.message}</span>} />
			</Col>
		</Row>
	);
};

export default EmptyData;
