import React, { Component, Fragment } from 'react';
import { Icon, Divider, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'actions';
import './WordInfo.css';

const mapDispatchToProps = dispatch => ({
	onDecosOpen: (decoVisible, decoType, post) => dispatch(actions.onDecosOpen({ decoVisible, decoType, post })),
	onDecosClose: decoVisible => dispatch(actions.onDecosClose({ decoVisible })),
	onSideBarView: sideBarOption => dispatch(actions.onSideBarView(sideBarOption)),
});

const styles = {
	limit: { color: 'rgba(193,22,22,0.65)', textAlign: 'right' },
	default: { color: '#135bcd', textAlign: 'right' },
};

class WordInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lqInfos: props.lqInfos,
			pumsa: '',
			distinct: 0,
			own_rate: 0,
			chn_rate: 0,
			frn_rate: 0,
			nc_count: 0,
			np_count: 0,
			vv_count: 0,
			va_count: 0,
			ma_count: 0,
			mj_count: 0,
			visible: false,
			order: '1',
		};
	}

	getTitle = pumsa => {
		switch (pumsa) {
			case 'NC':
				return '명사 목록';
			case 'NP':
				return '대명사 목록';
			case 'VV':
				return '동사 목록';
			case 'VA':
				return '형용사 목록';
			case 'MA':
				return '부사 목록';
			case 'MJ':
				return '접속어 목록';
			case 'OWN':
				return '고유어 목록';
			case 'CHN':
				return '한자어 목록';
			case 'FRN':
				return '외래어 목록';
			case 'DIS':
				return '중복 목록';
			default:
				return '';
		}
	};

	onClickDecos = (e, pumsa) => {
		this.props.onSideBarView({ left: 'hide', right: 'hide' });
		this.props.onDecosOpen(true, 'wordinfo', { title: this.getTitle(pumsa), pumsa, order: '1' });
	};

	handleCancel = e => {
		this.setState({
			visible: false,
		});
	};

	handleChange = value => {
		this.setState({ order: value });
	};

	componentDidMount() {
		if (this.state.lqInfos != null) {
			let own_rate = 0,
				chn_rate = 0,
				frn_rate = 0;
			let distinct = 0;
			let nc_count = 0,
				np_count = 0,
				vv_count = 0,
				va_count = 0,
				ma_count = 0,
				mj_count = 0;

			const strRateandHomos_ex = this.state.lqInfos
				.flatMap(x => x.strRateandHomos)
				.filter(
					i =>
						i.PUMSA === 'NC' ||
						i.PUMSA === 'NP' ||
						i.PUMSA === 'VV' ||
						i.PUMSA === 'VA' ||
						i.PUMSA === 'MA' ||
						i.PUMSA === 'MJ'
				)
				.filter(i => i.DIFFICULTY !== 11);

			nc_count = strRateandHomos_ex.filter(i => i.PUMSA === 'NC').length;
			np_count = strRateandHomos_ex.filter(i => i.PUMSA === 'NP').length;
			vv_count = strRateandHomos_ex.filter(i => i.PUMSA === 'VV').length;
			va_count = strRateandHomos_ex.filter(i => i.PUMSA === 'VA').length;
			ma_count = strRateandHomos_ex.filter(i => i.PUMSA === 'MA').length;
			mj_count = strRateandHomos_ex.filter(i => i.PUMSA === 'MJ').length;

			const nEojul = this.state.lqInfos
				.flatMap(x => x.nEojul)
				.reduce(function(p, c) {
					return p + c;
				}, 0);

			const own = strRateandHomos_ex.filter(i => i.OWN_YN === 1).length;
			own_rate = (own * 100) / nEojul;
			if (!isNaN(own_rate)) own_rate = own_rate.toFixed(1);
			else own_rate = 0;
			const chn = strRateandHomos_ex.filter(i => i.CHN_YN === 1).length;
			chn_rate = (chn * 100) / nEojul;
			if (!isNaN(chn_rate)) chn_rate = chn_rate.toFixed(1);
			else chn_rate = 0;
			const frn = strRateandHomos_ex.filter(i => i.FRN_YN === 1).length;
			frn_rate = (frn * 100) / nEojul;
			if (!isNaN(frn_rate)) frn_rate = frn_rate.toFixed(1);
			else frn_rate = 0;

			const wordcnt = strRateandHomos_ex
				.map(i => i.CD_WORD)
				.reduce((count, word) => {
					count[word] = (count[word] || 0) + 1;
					return count;
				}, {});

			const d = Object.keys(wordcnt)
				.filter(key => wordcnt[key] > 1)
				.map(key => {
					return { [key]: wordcnt[key] };
				});

			distinct = d.length;

			this.setState({
				nc_count,
				np_count,
				vv_count,
				va_count,
				ma_count,
				mj_count,
				distinct,
				own_rate,
				chn_rate,
				frn_rate,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		const { lqInfos } = nextProps;

		if (lqInfos !== this.state.lqInfos) {
			let own_rate = 0,
				chn_rate = 0,
				frn_rate = 0;
			let distinct = 0;
			let nc_count = 0,
				np_count = 0,
				vv_count = 0,
				va_count = 0,
				ma_count = 0,
				mj_count = 0;

			const strRateandHomos_ex = lqInfos
				.flatMap(x => x.strRateandHomos)
				.filter(
					i =>
						i.PUMSA === 'NC' ||
						i.PUMSA === 'NP' ||
						i.PUMSA === 'VV' ||
						i.PUMSA === 'VA' ||
						i.PUMSA === 'MA' ||
						i.PUMSA === 'MJ'
				)
				.filter(i => i.DIFFICULTY !== 11);

			nc_count = strRateandHomos_ex.filter(i => i.PUMSA === 'NC').length;
			np_count = strRateandHomos_ex.filter(i => i.PUMSA === 'NP').length;
			vv_count = strRateandHomos_ex.filter(i => i.PUMSA === 'VV').length;
			va_count = strRateandHomos_ex.filter(i => i.PUMSA === 'VA').length;
			ma_count = strRateandHomos_ex.filter(i => i.PUMSA === 'MA').length;
			mj_count = strRateandHomos_ex.filter(i => i.PUMSA === 'MJ').length;

			const nEojul = lqInfos
				.flatMap(x => x.nEojul)
				.reduce(function(p, c) {
					return p + c;
				}, 0);

			const own = strRateandHomos_ex.filter(i => i.OWN_YN === 1).length;
			own_rate = (own * 100) / nEojul;
			if (!isNaN(own_rate)) own_rate = own_rate.toFixed(1);
			else own_rate = 0;
			const chn = strRateandHomos_ex.filter(i => i.CHN_YN === 1).length;
			chn_rate = (chn * 100) / nEojul;
			if (!isNaN(chn_rate)) chn_rate = chn_rate.toFixed(1);
			else chn_rate = 0;
			const frn = strRateandHomos_ex.filter(i => i.FRN_YN === 1).length;
			frn_rate = (frn * 100) / nEojul;
			if (!isNaN(frn_rate)) frn_rate = frn_rate.toFixed(1);
			else frn_rate = 0;

			const wordcnt = strRateandHomos_ex
				.map(i => i.CD_WORD)
				.reduce((count, word) => {
					count[word] = (count[word] || 0) + 1;
					return count;
				}, {});

			const d = Object.keys(wordcnt)
				.filter(key => wordcnt[key] > 1)
				.map(key => {
					return { [key]: wordcnt[key] };
				});

			distinct = d.length;

			this.setState({
				lqInfos: nextProps.lqInfos,
				nc_count,
				np_count,
				vv_count,
				va_count,
				ma_count,
				mj_count,
				distinct,
				own_rate,
				chn_rate,
				frn_rate,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.pumsa !== prevState.pumsa && this.state.pumsa !== '') {
			const { lqInfos, pumsa } = this.state;

			if (lqInfos && lqInfos.hasOwnProperty('strRateandHomos')) {
				const strRateandHomos_ex = lqInfos
					.flatMap(x => x.strRateandHomos)
					.filter(
						i =>
							i.PUMSA === 'NC' ||
							i.PUMSA === 'NP' ||
							i.PUMSA === 'VV' ||
							i.PUMSA === 'VA' ||
							i.PUMSA === 'MA'
					);

				switch (pumsa) {
					case 'OWN': {
						const datas = strRateandHomos_ex
							.filter(i => i.OWN_YN === 1)
							.map(i => i.CD_WORD)
							.reduce((count, word) => {
								count[word] = (count[word] || 0) + 1;
								return count;
							}, {});

						const _datas = Object.keys(datas).sort((key1, key2) => {
							if (datas[key1] > datas[key2]) return -1;
							if (datas[key1] < datas[key2]) return 1;
							return 0;
						});

						this.setState({ datas, filterData: _datas, order: '1' });
						break;
					}
					case 'CHN': {
						const datas = strRateandHomos_ex
							.filter(i => i.CHN_YN === 1)
							.map(i => i.CD_WORD + '(' + i.ORG_LANGUAGE + ')')
							.reduce((count, word) => {
								count[word] = (count[word] || 0) + 1;
								return count;
							}, {});
						const _datas = Object.keys(datas).sort((key1, key2) => {
							if (datas[key1] > datas[key2]) return -1;
							if (datas[key1] < datas[key2]) return 1;
							return 0;
						});

						this.setState({ datas, filterData: _datas, order: '1' });
						break;
					}
					case 'FRN': {
						const datas = strRateandHomos_ex
							.filter(i => i.FRN_YN === 1)
							.map(i => i.CD_WORD + '(' + i.ORG_LANGUAGE + ')')
							.reduce((count, word) => {
								count[word] = (count[word] || 0) + 1;
								return count;
							}, {});
						const _datas = Object.keys(datas).sort((key1, key2) => {
							if (datas[key1] > datas[key2]) return -1;
							if (datas[key1] < datas[key2]) return 1;
							return 0;
						});

						this.setState({ datas, filterData: _datas, order: '1' });
						break;
					}
					case 'DIS': {
						const datas = strRateandHomos_ex
							.map(i => i.CD_WORD)
							.reduce((count, word) => {
								count[word] = (count[word] || 0) + 1;
								return count;
							}, {});
						const d = [];
						Object.keys(datas)
							.filter(key => datas[key] > 1)
							.map(key => {
								return (d[key] = datas[key]);
							});

						const _datas = Object.keys(d).sort((key1, key2) => {
							if (d[key1] > d[key2]) return -1;
							if (d[key1] < d[key2]) return 1;
							return 0;
						});

						this.setState({ datas: d, filterData: _datas, order: '1' });

						break;
					}
					default: {
						const datas = lqInfos
							.flatMap(x => x.strRateandHomos)
							.filter(i => i.PUMSA === pumsa)
							.map(i => i.CD_WORD)
							.reduce((count, word) => {
								count[word] = (count[word] || 0) + 1;
								return count;
							}, {});
						const _datas = Object.keys(datas).sort((key1, key2) => {
							if (datas[key1] > datas[key2]) return -1;
							if (datas[key1] < datas[key2]) return 1;
							return 0;
						});

						this.setState({ datas, filterData: _datas, order: '1' });
						break;
					}
				}
			} else {
				this.setState({ datas: null, order: '1' });
			}
		}

		if (this.state.order !== prevState.order) {
			const { datas } = this.state;
			if (this.state.order === '1') {
				const _datas = Object.keys(datas).sort((key1, key2) => {
					if (datas[key1] > datas[key2]) return -1;
					if (datas[key1] < datas[key2]) return 1;
					return 0;
				});
				this.setState({ filterData: _datas });
			} else {
				const _datas = Object.keys(datas).sort((key1, key2) => {
					if (key1 > key2) return 1;
					if (key1 < key2) return -1;
					return 0;
				});
				this.setState({ filterData: _datas });
			}
		}
	}

	render() {
		const { own_rate, chn_rate, frn_rate, nc_count, np_count, vv_count, va_count, ma_count, mj_count } = this.state;

		return (
			<Fragment>
				<div
					style={{
						padding: '8px 10px',
						margin: '0',
						background: '#fff',
					}}>
					<table style={{ padding: '5px', lineHeight: '1.5em', width: '100%' }}>
						<tbody>
							<tr>
								<td>명사</td>
								<td style={styles.default}>
									<b>{nc_count}</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<Button
										className="ant-btn-sm-custom"
										size="small"
										onClick={e => this.onClickDecos(e, 'NC')}>
										목록보기
									</Button>
								</td>
							</tr>
							<tr>
								<td>대명사</td>
								<td style={styles.default}>
									<b>{np_count}</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<Button
										className="ant-btn-sm-custom"
										size="small"
										onClick={e => this.onClickDecos(e, 'NP')}>
										목록보기
									</Button>
								</td>
							</tr>
							<tr>
								<td>동사</td>
								<td style={styles.default}>
									<b>{vv_count}</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<Button
										className="ant-btn-sm-custom"
										size="small"
										onClick={e => this.onClickDecos(e, 'VV')}>
										목록보기
									</Button>
								</td>
							</tr>
							<tr>
								<td>형용사</td>
								<td style={styles.default}>
									<b>{va_count}</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<Button
										className="ant-btn-sm-custom"
										size="small"
										onClick={e => this.onClickDecos(e, 'VA')}>
										목록보기
									</Button>
								</td>
							</tr>
							<tr>
								<td>부사</td>
								<td style={styles.default}>
									<b>{ma_count}</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<Button
										className="ant-btn-sm-custom"
										size="small"
										onClick={e => this.onClickDecos(e, 'MA')}>
										목록보기
									</Button>
								</td>
							</tr>
							<tr>
								<td>
									접속어 <Icon type="question-circle" theme="filled" />
								</td>
								<td style={styles.default}>
									<b>{mj_count}</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<Button
										className="ant-btn-sm-custom"
										size="small"
										onClick={e => this.onClickDecos(e, 'MJ')}>
										목록보기
									</Button>
								</td>
							</tr>
						</tbody>
					</table>
					<Divider />
					<table style={{ padding: '5px', lineHeight: '1.5em', width: '100%' }}>
						<tbody>
							<tr>
								<td>고유어</td>
								<td style={styles.default}>
									<b>{own_rate}%</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<Button
										className="ant-btn-sm-custom"
										size="small"
										onClick={e => this.onClickDecos(e, 'OWN')}>
										목록보기
									</Button>
								</td>
							</tr>
							<tr>
								<td>한자어</td>
								<td style={styles.default}>
									<b>{chn_rate}%</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<Button
										className="ant-btn-sm-custom"
										size="small"
										onClick={e => this.onClickDecos(e, 'CHN')}>
										목록보기
									</Button>
								</td>
							</tr>
							<tr>
								<td>외래어</td>
								<td style={styles.default}>
									<b>{frn_rate}%</b>
								</td>
								<td style={{ textAlign: 'right' }}>
									<Button
										className="ant-btn-sm-custom"
										size="small"
										onClick={e => this.onClickDecos(e, 'FRN')}>
										목록보기
									</Button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(withRouter(WordInfo));
