import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import * as actions from 'actions';

const mapDispatchToProps = dispatch => ({
	onHanjaMenuChange: div => dispatch(actions.onHanjaMenuChange(div)),
	onDecosOpen: (decoVisible, decoType, post) => dispatch(actions.onDecosOpen({ decoVisible, decoType, post })),
});

class HanjaSetup extends Component {
	onHanjaChange(div) {
		this.props.onHanjaMenuChange(div);
		//this.props.onDecosOpen(true, 'hanja', {});
	}

	render() {
		return (
			<Fragment>
				<div
					style={{
						padding: '8px 10px',
						margin: '0',
						background: '#fff',
					}}>
					<Menu>
						<Menu.Item key="menu1" onClick={() => this.onHanjaChange(true)}>
							한글 <Icon type="arrow-right" /> 한자 병기
						</Menu.Item>
						<Menu.Item key="menu2" onClick={() => this.onHanjaChange(false)}>
							한자 병기 취소
						</Menu.Item>
					</Menu>
				</div>
			</Fragment>
		);
	}
}

export default connect(null, mapDispatchToProps)(withRouter(HanjaSetup));
