import reloadable from './reloadable';
import breadcrumb from './breadcrumb';
import auth from './auth';
import quickPanel from './quickPanel';
import bookSearch from './bookSearch';
import puzzleSearch from './puzzleSearch';
import mobilemenu from './mobile';
import dicSearch from './dicSearch';
import {
	spell,
	decos,
	syno,
	dialect,
	sidebar,
	info,
	searchDeco,
	blocks,
	menu,
	hanjas,
	difficulty,
	multilang,
	report,
	checked,
	editor,
	process,
	postData,
} from './sentence';
import { combineReducers } from 'redux';

const reducers = combineReducers({
	reloadable,
	breadcrumb,
	auth,
	quickPanel,
	bookSearch,
	puzzleSearch,
	mobilemenu,
	dicSearch,
	spell,
	decos,
	syno,
	dialect,
	info,
	searchDeco,
	blocks,
	menu,
	hanjas,
	difficulty,
	multilang,
	report,
	checked,
	editor,
	sidebar,
	process,
	postData,
});

export default reducers;
