/* eslint-disable no-new-wrappers */
import React, { Component, Fragment } from 'react';
import { Menu, Icon, message, Divider, Row, Col, notification, Modal, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'actions';
import * as types from 'actions/ActionTypes';
import { ReactComponent as Logo } from 'sentence/components/logos/logo.svg';
import moment from 'moment';
import ReactGA from 'react-ga';

const LogoIcon = (props) => <Icon component={Logo} {...props} />;

const mapStateToProps = (state) => ({
	title: state.info.title,
	lqscore: state.info.lqscore,
	decos: state.spell.decos,
	synos: state.syno.synos,
	lqInfos: state.info.lqInfos,
	authenticated: state.auth.authenticated,
	user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
	onShowModalReport: (visiable) => dispatch(actions.onShowModalReport(visiable)),
	validate: (user) => dispatch(actions.validate(user)),
	logout: () => dispatch(actions.logout()),
	onCheckLqByPara: (checked) => dispatch(actions.onCheckLqByPara(checked)),
	onCheckSpell: (checked) => dispatch(actions.onCheckSpell(checked)),
	onCheckSyno: (checked) => dispatch(actions.onCheckSyno(checked)),
	onCheckDialect: (checked) => dispatch(actions.onCheckDialect(checked)),
	onDecosClose: (decoVisible) => dispatch(actions.onDecosClose({ decoVisible })),
	onSideBarView: (sideBarOption) => dispatch(actions.onSideBarView(sideBarOption)),
});

const info2 = () => {
	message.info('단어를 더블 클릭하시면 단어의 뜻풀이 정보를 확인하실 수 있습니다.');
};

const LoginMenu = (props) => (
	<div style={{ padding: 0, margin: 0 }}>
		<Row>
			<Col>
				<a href={types.LOCAL_URL + '/views/mypage/archive?tabs=grammar1'}>마이페이지</a>
			</Col>
		</Row>
		<Row>
			<Col>
				<Divider />
			</Col>
		</Row>
		<Row>
			<Col>
				<span onClick={props.onLogout} style={{ cursor: 'pointer' }}>
					로그아웃
				</span>
			</Col>
		</Row>
	</div>
);

let timerId = null;

class LeftMenu extends Component {
	state = {
		show: false,
		login: false,
		visible: false,
		logOff: false,
		checkSpell: true,
		checkSyno: true,
		checkDialect: true,
		checkAutoSave: false,
		prevText: '',
		modalVisible: false,
		url: '',
		name: '',
	};

	constructor(props) {
		super(props);
		const USER = JSON.parse(window.localStorage.getItem('user'));

		let infoModal = false;
		if (USER != null) {
			ReactGA.set({ userId: USER.id });
			this.props.validate(USER);
		} else {
			infoModal = true;
		}

		const date = moment();
		const remain = moment('2019-10-07').diff(date, 'days');

		this.state = {
			...this.state,
			remain,
			expireDate: moment('2019-10-07'),
			infoModal,
			text: this.props.text,
		};
	}

	componentDidMount() {
		let checkLqByPara = true,
			checkSpell = true,
			checkSyno = true,
			checkDialect = true,
			checkAutoSave = false;

		if (window.localStorage.getItem('checkLqByPara') != null) {
			checkLqByPara = JSON.parse(window.localStorage.getItem('checkLqByPara'));
		}
		if (window.localStorage.getItem('checkSpell') != null) {
			checkSpell = JSON.parse(window.localStorage.getItem('checkSpell'));
		}
		if (window.localStorage.getItem('checkSyno') != null) {
			checkSyno = JSON.parse(window.localStorage.getItem('checkSyno'));
		}
		if (window.localStorage.getItem('checkDialect') != null) {
			checkDialect = JSON.parse(window.localStorage.getItem('checkDialect'));
		}

		this.props.onCheckLqByPara(checkLqByPara);
		this.props.onCheckSpell(checkSpell);
		this.props.onCheckSyno(checkSyno);
		this.props.onCheckDialect(checkDialect);

		this.setState({
			user: this.props.user,
			login: this.props.authenticated,
			title: this.props.title,
			text: this.props.text,
			lqscore: this.props.lqscore,
			decos: this.props.decos,
			synos: this.props.synos,
			checkLqByPara,
			checkSpell,
			checkSyno,
			checkDialect,
			checkAutoSave,
		});
	}

	componentWillReceiveProps(nextProps) {
		let checkAutoSave = false;
		if (window.localStorage.getItem('checkAutoSave') != null && nextProps.authenticated) {
			checkAutoSave = JSON.parse(window.localStorage.getItem('checkAutoSave'));
		}
		this.setState({
			title: nextProps.title,
			text: nextProps.text,
			lqscore: nextProps.lqscore,
			decos: nextProps.decos,
			synos: nextProps.synos,
			authenticated: nextProps.authenticated,
			user: nextProps.user,
			login: nextProps.authenticated,
			checkAutoSave,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.authenticated !== prevState.authenticated) {
			if (!this.state.logOff) {
				this.setState({
					login: this.state.authenticated,
					userName: this.state.user && this.state.user.userName,
				});
			} else {
				this.setState({
					login: this.state.authenticated,
				});
			}
			if (this.state.authenticated) {
				const USER = JSON.parse(window.localStorage.getItem('user'));

				if (USER != null) {
					this.props.validate(USER);
					ReactGA.set({ userId: USER.id }, ['USER']);
				}
				this.setState({ visible: false });
			}
		}
		if (this.state.checkAutoSave !== prevState.checkAutoSave) {
			if (this.state.checkAutoSave) {
				timerId = setInterval(this.autoSave, 60000 * 1);
			} else {
				if (timerId != null) {
					clearInterval(timerId);
				}
			}
		}
	}

	showPaymentModal = (name, url) => {
		this.setState({
			name,
			url,
			modalVisible: true,
		});
	};

	handleOk = (e) => {
		window.location.href = '/views/mypage/payment/step1';
		this.setState({
			modalVisible: false,
		});
	};

	handleCancel = (e) => {
		this.setState({
			modalVisible: false,
		});
	};

	infoModalHandleCancel = (e) => {
		info2();
		this.setState({
			infoModal: false,
		});
	};

	infoModalClick = (e) => {
		e.preventDefault();
		this.setState({
			infoModal: false,
		});
		this.showDrawer(1);
	};

	showDrawer = (value) => {
		notification.destroy();
		this.setState({
			visible: true,
			value,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	onLogin = (e) => {
		e.preventDefault();
		this.showDrawer(0);
	};

	goToUrl = (url) => {
		window.location.href = url;
	};

	downLoad = () => {
		const element = document.createElement('a');
		const title = this.state.title != null ? this.state.title : '';
		const text = this.state.text != null ? this.state.text : '';

		const file = new Blob([title + '\n\n', text], { type: 'text/plain' });
		element.href = URL.createObjectURL(file);
		element.download = 'Documents.txt';
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
	};

	showReport(visiable) {
		//const user = this.props.user;
		//const USER = JSON.parse(window.sessionStorage.getItem('user'));

		//새로 고침시 로그인 유지
		//if (user != null) {
		//	if (user.status === 'valid' && user.item === 'premium') {
		if (this.state.text != null && this.state.text.trim() !== '') {
			this.props.onShowModalReport(visiable);
			this.props.onDecosClose(false);
			this.props.onSideBarView({ left: 'hide', right: 'hide' });
		} else {
			message.error('작성된 글이 없습니다. 보고서는 글을 작성 후 생성할 수 있습니다.');
		}
		//	} else {
		//		this.showPaymentModal('보고서', 'ani_img03_1.png');
		//		}
		//	} else {
		//		this.showPaymentModal('보고서', 'ani_img03_1.png');
		//	}
	}

	onLogout = (e) => {
		Modal.confirm({
			title: '확인',
			content: (
				<p>
					로그아웃 하시겠습니까? <br />
					저장 하지 않은 문서는 복구 되지 않습니다.
				</p>
			),
			okText: '예',
			cancelText: '아니오',
			onOk: () => {
				this.setState({
					logOff: true,
				});
				this.props.logout();
				this.goToUrl('/sentence-unlimit/0');
			},
		});
	};

	onNewDocuments = () => {
		Modal.confirm({
			title: '확인',
			content: (
				<p>
					새로운 문서를 작성하시겠습니까? <br />
					저장 하지 않은 문서는 복구 되지 않습니다.
				</p>
			),
			okText: '예',
			cancelText: '아니오',
			onOk: () => {
				this.goToUrl('/sentence-unlimit/0');
			},
		});
	};

	onCloseModal = (e) => {
		//this.props.onClose(e);
		this.props.history.goBack();
		this.props.onDecosClose(false);
		this.props.onSideBarView({ left: 'hide', right: 'hide' });
	};

	render() {
		return (
			<Fragment>
				<div style={{ marginTop: 0, padding: 0 }}>
					<Menu mode="inline" style={{ background: '#596674', color: '#fff' }}>
						<Menu.Item key="4" onClick={this.onCloseModal}>
							<Icon type="home" style={{ fontSize: '20px' }} theme="outlined" />
							<span>홈</span>
						</Menu.Item>
						<Menu.Item key="5" onClick={() => this.showReport(true)}>
							<Icon type="bar-chart" style={{ fontSize: '20px' }} theme="outlined" />
							<span>보고서</span>
						</Menu.Item>
						<Menu.Item key="6" onClick={this.downLoad}>
							<Icon type="download" style={{ fontSize: '20px' }} theme="outlined" />
							<span>다운로드</span>
						</Menu.Item>
					</Menu>
				</div>
				<Modal
					title="문장검사 서비스입니다."
					visible={this.state.modalVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					okText="예"
					cancelText="아니오"
					width={'70%'}
					centered
					bodyStyle={{ backgroundColor: '#4b4848', color: 'white' }}>
					<div>
						<p>선택하신 {this.state.name} 서비스는 문장검사 서비스입니다.</p>
						<p>문장검사 서비스 이용권을 결제하시겠습니까?</p>
						<div sytle={{ fontSize: 10, textAlign: 'center' }}>
							<img src={'/assets/imgs/sentence/' + this.state.url} alt={this.state.name} width={'100%'} />
						</div>
					</div>
				</Modal>
				{!this.props.user && this.state.remain > 0 && (
					<Modal
						visible={this.state.infoModal}
						onOk={this.infoModalHandleOk}
						onCancel={this.infoModalHandleCancel}
						footer={null}
						width={500}
						centered
						bodyStyle={{ backgroundColor: 'white', textAlign: 'center' }}>
						<div>
							<h3>
								지금 회원 가입하시고
								<br /> {this.state.expireDate.format('YYYY년 MM월 DD일')}까지 <br />
								모든 서비스를 무료로 이용하세요.
							</h3>
						</div>
						<div style={{ padding: 10 }}>
							<Button key="submit" type="primary" onClick={this.infoModalClick}>
								가입하기
							</Button>
						</div>
					</Modal>
				)}
			</Fragment>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(LeftMenu));
