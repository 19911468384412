/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DecosFunc } from 'sentence/components/utils';

const Iframe = React.createRef();

const mapStateToProps = state => ({
	filters: state.syno.filters,
	user: state.auth.user,
});

class DecoSynonym extends Component {
	state = { height: 800 };

	constructor(props) {
		super(props);
		if (props.post.dicCode === 'dic-std') {
			//console.log(props.post.word_no);
			this.state = {
				...this.state,
				word_no: props.post.word_no,
				iframeSrc: DecosFunc.createMiniDicIframeSrc(this.props.user, 'dic-std', props.post.word_no)
			};
		} else {
			//console.log(props.post.pyojae);
			//console.log(props.post.pyojae.wordno);
			this.state = {
				...this.state,
				filters: props.filters,
				pyojae: props.post.pyojae,
				iframeSrc: DecosFunc.createMiniDicIframeSrc(this.props.user, 'dic-syn', props.post.pyojae)
			};
		}

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.postMessage = this.postMessage.bind(this);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	postMessage() {
		const { user } = this.props;
		if (this.props.post.dicCode === 'dic-syn') {
			if (this.props.post != null) {
				const { pyojae } = this.props.post;
				//const { filters } = this.props;
				if (Iframe != null) {
					this.setState({
						iframeSrc: DecosFunc.createMiniDicIframeSrc(user, 'dic-syn', pyojae)
					});
					/*
					var data = {
						dicCode: 'dic-syn',
						wordNo: pyojae,
						...filters,
					};
					Iframe.current.contentWindow.postMessage(data, '*');
					*/
				}
			}
		}
		if (this.props.post.dicCode === 'dic-std') {
			const { word_no } = this.props.post;
			if (Iframe != null) {
				this.setState({
					iframeSrc: DecosFunc.createMiniDicIframeSrc(user, 'dic-std', word_no)
				});
				/*
				data = {
					dicCode: 'dic-std',
					wordNo: word_no,
				};
				Iframe.current.contentWindow.postMessage(data, '*');
				*/
			}
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		window.addEventListener('message', this.postMessage);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.post.pyojae !== this.props.post.pyojae) {
			this.setState({
				dicCode: nextProps.post.dicCode,
				pyojae: nextProps.post.pyojae,
				filters: nextProps.filters,
			});
		}

		if (nextProps.filters !== this.props.filters) {
			this.setState({
				dicCode: nextProps.post.dicCode,
				pyojae: nextProps.post.pyojae,
				filters: nextProps.filters,
			});
		}

		if (nextProps.post.word_no !== this.props.post.word_no) {
			this.setState({ dicCode: nextProps.post.dicCode, word_no: nextProps.post.word_no });
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		window.removeEventListener('message', this.postMessage);
	}

	componentDidUpdate(prevProps, prevState) {
		const { user } = this.props;
		if (this.state.dicCode === 'dic-syn') {
			if (prevState.pyojae !== this.state.pyojae || prevState.filters !== this.state.filters) {
				if (Iframe != null) {
					this.setState({
						iframeSrc: DecosFunc.createMiniDicIframeSrc(user, 'dic-syn', this.state.pyojae)
					});
					/*
					Iframe.current.contentWindow.postMessage(
						{
							dicCode: 'dic-syn',
							wordNo: this.state.pyojae,
							...this.state.filters,
						},
						'*'
					);
					*/
				}
			}
		}
		if (this.state.dicCode === 'dic-std') {
			if (prevState.word_no !== this.state.word_no) {
				if (Iframe != null) {
					this.setState({
						iframeSrc: DecosFunc.createMiniDicIframeSrc(user, 'dic-std', this.state.word_no)
					});
					/*
					Iframe.current.contentWindow.postMessage(
						{
							dicCode: 'dic-std',
							wordNo: this.state.word_no,
						},
						'*'
					);
					*/
				}
			}
		}
	}

	render() {
		//const { userEmail } = this.props.user;
		const { iframeSrc } = this.state;
		return (
			<Fragment>
				<div style={{ height: '100%', marginTop: '55px', padding: '0' }}>
					{/*}
					<iframe
						ref={Iframe}
						height={this.state.height}
						src={
							'https://minidic.natmal.com/?linkType=SENTENCE&userId=' +
							(userEmail != null ? userEmail : '')
						}
						style={{ border: 'none', margin: '0', padding: '0', width: '500px' }}
					/>
					*/}
					<iframe
						ref={Iframe}
						height={this.state.height}
						src={iframeSrc}
						style={{ border: 'none', margin: '0', padding: '0', width: '500px' }}
					/>
				</div>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, null)(DecoSynonym);
