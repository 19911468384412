import * as types from 'actions/ActionTypes';

const initialState = {
	lqChecked: true,
	spellChecked: true,
	synoChecked: true,
	dialectChecked: true,
};

const checked = (state = initialState, action) => {
	//console.log(action);
	switch (action.type) {
		case types.CHECKED_LQ_PARA:
			return Object.assign({}, state, { lqChecked: action.checked });
		case types.CHECKED_SPELL:
			return Object.assign({}, state, { spellChecked: action.checked });
		case types.CHECKED_SYNO:
			return Object.assign({}, state, { synoChecked: action.checked });
		case types.CHECKED_DIALECT:
			return Object.assign({}, state, { dialectChecked: action.checked });
		default:
			return state;
	}
};

export default checked;
