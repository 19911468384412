import * as types from 'actions/ActionTypes';

const initialState = {
	multilangs: [],
	multioptions: 0,
	selectedText: '',
};

const multilang = (state = initialState, action) => {
	switch (action.type) {
		case types.MULTI_LANG:
			return Object.assign({}, state, { multilangs: action.multilangs });
		case types.MULTI_LANG_OPTION:
			return Object.assign({}, state, { multioptions: action.multioptions });
		case types.MULTI_LANG_SELECTED_TEXT:
			return Object.assign({}, state, { selectedText: action.selectedText });
		default:
			return state;
	}
};

export default multilang;
